import moment, { Moment } from 'moment';
import { CustomField } from './custom-field';

export class Participant {
  index: number;
  sessionId: string;
  uuid: string;
  firstname: string;
  lastname: string;
  birthdate: Moment;
  email: string;
  customFields: CustomField[];

  constructor(obj: Partial<Participant>) {
    Object.assign(this, obj);

    if (this.birthdate) {
      this.birthdate = moment(this.birthdate, 'YYYY-MM-DD');
    }
  }

  get age(): number {
    return moment().diff(this.birthdate, 'years');
  }
}
