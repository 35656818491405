import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccommodationProductComponent } from './accommodation/accommodation-product/accommodation-product.component';
import { AccommodationComponent } from './accommodation/accommodation.component';
import { AgencyComponent } from './accommodation/agency/agency.component';
import { ConfirmBookingComponent } from './accommodation/confirm-booking/confirm-booking.component';
import { SearchAvailabilityAccommodationComponent } from './accommodation/search-availability-accommodation/search-availability-accommodation.component';
import { ActivityPartnerManagementComponent } from './activity/activity-partner-management/activity-partner-management.component';
import { ActivityComponent } from './activity/activity.component';
import { AgeRangeManagementComponent } from './activity/age-range-management/age-range-management.component';
import { ExperienceManagementComponent } from './activity/experience-management/experience-management.component';
import { PackageManagementComponent } from './activity/package-management/package-management.component';
import { AdminComponent } from './admin/admin.component';
import { CustomFieldComponent } from './admin/custom-field/custom-field.component';
import { ElibertyCatalogComponent } from './admin/eliberty-catalog/eliberty-catalog.component';
import { GenericActivityComponent } from './admin/generic-activity/generic-activity.component';
import { GenericCategoryComponent } from './admin/generic-category/generic-category.component';
import { GenericPartnerComponent } from './admin/generic-partner/generic-partner.component';
import { ImportFileComponent } from './admin/import-file/import-file.component';
import { InformationBannerComponent } from './admin/information-banner/information-banner.component';
import { ParametersComponent } from './admin/parameters/parameters.component';
import { PreMadeCartComponent } from './admin/premade-cart/pre-made-cart.component';
import { RefusedReservationsComponent } from './admin/refused-reservations/refused-reservations.component';
import { ResortLabelComponent } from './admin/resort-label/resort-label.component';
import { CreateResortComponent } from './admin/resort/create-resort.component';
import { SkisetOrdersComponent } from './admin/skiset-orders/skiset-orders.component';
import { TagComponent } from './admin/tag/tag.component';
import { UsersComponent } from './admin/users/users.component';
import { CalendarPartnerComponent } from './calendar-partner/calendar-partner.component';
import { CarouselProductComponent } from './cms/carousel-product/carousel-product.component';
import { CarouselZoneComponent } from './cms/carousel-zone/carousel-zone.component';
import { CarouselComponent } from './cms/carousel/carousel.component';
import { CmsComponent } from './cms/cms.component';
import { TextBlockComponent } from './cms/text-block/text-block.component';
import { CustomersManagementComponent } from './customers-management/customers-management.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { MyInvoicesComponent } from './invoices/my-invoices/my-invoices.component';
import { LoginComponent } from './login/login.component';
import { MarketingComponent } from './marketing/marketing.component';
import { PromoCodeComponent } from './marketing/promo-code/promo-code.component';
import { SalesReportComponent } from './marketing/sales-report/sales-report.component';
import { MyInformationsComponent } from './my-informations/my-informations.component';
import { MyReservationsComponent } from './my-reservations/my-reservations.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { Role } from './shared/models/enum/Role';
import { ShowActivitiesComponent } from './show-activities/show-activities.component';

const allRoles: Role[] = [
  Role.ROLE_ADMIN,
  Role.ROLE_PARTNER,
  Role.ROLE_COMMERCIAL,
  Role.ROLE_MARKETING
];

const VMRoles = [Role.ROLE_ADMIN, Role.ROLE_COMMERCIAL, Role.ROLE_MARKETING];

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'onboarding',
    component: OnboardingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: ActivityComponent,
    canActivate: [AuthGuard],
    data: {
      roles: allRoles
    },
    children: [
      {
        path: 'activity-partner',
        component: ActivityPartnerManagementComponent,
        canActivate: [AuthGuard],
        data: {
          roles: allRoles
        }
      },
      {
        path: 'options',
        component: ExperienceManagementComponent,
        canActivate: [AuthGuard],
        data: {
          roles: allRoles
        }
      },
      {
        path: 'age-range',
        component: AgeRangeManagementComponent,
        canActivate: [AuthGuard],
        data: {
          roles: allRoles
        }
      },
      {
        path: 'package-management',
        component: PackageManagementComponent,
        canActivate: [AuthGuard],
        data: {
          roles: allRoles
        }
      }
    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: {
      roles: VMRoles
    },
    children: [
      {
        path: 'resort',
        component: CreateResortComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN, Role.ROLE_COMMERCIAL]
        }
      },
      {
        path: 'resort-label',
        component: ResortLabelComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN]
        }
      },
      {
        path: 'activity',
        component: GenericActivityComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN]
        }
      },
      {
        path: 'custom-field',
        component: CustomFieldComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN]
        }
      },
      {
        path: 'partner',
        component: GenericPartnerComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN, Role.ROLE_COMMERCIAL]
        }
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN]
        }
      },
      {
        path: 'category',
        component: GenericCategoryComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN]
        }
      },
      {
        path: 'tag',
        component: TagComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN]
        }
      },
      {
        path: 'import',
        component: ImportFileComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN, Role.ROLE_COMMERCIAL]
        }
      },
      {
        path: 'e-liberty',
        component: ElibertyCatalogComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN]
        }
      },
      {
        path: 'parameters',
        component: ParametersComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN]
        }
      }
    ]
  },
  {
    path: 'marketing',
    component: MarketingComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING]
    },
    children: [
      {
        path: 'information-banner',
        component: InformationBannerComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING]
        }
      },
      {
        path: 'promo-code',
        component: PromoCodeComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING]
        }
      },
      {
        path: 'refused-reservations',
        component: RefusedReservationsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING]
        }
      },
      {
        path: 'before-sell',
        component: PreMadeCartComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING]
        }
      },
      {
        path: 'skiset-orders',
        component: SkisetOrdersComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING]
        }
      },
      {
        path: 'sales-report',
        component: SalesReportComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING]
        }
      }
    ]
  },
  {
    path: 'activity',
    component: ActivityComponent,
    canActivate: [AuthGuard],
    data: {
      roles: allRoles
    },
    children: [
      {
        path: 'activity-partner',
        component: ActivityPartnerManagementComponent,
        canActivate: [AuthGuard],
        data: {
          roles: allRoles
        }
      },
      {
        path: 'options',
        component: ExperienceManagementComponent,
        canActivate: [AuthGuard],
        data: {
          roles: allRoles
        }
      },
      {
        path: 'age-range',
        component: AgeRangeManagementComponent,
        canActivate: [AuthGuard],
        data: {
          roles: allRoles
        }
      },
      {
        path: 'package-management',
        component: PackageManagementComponent,
        canActivate: [AuthGuard],
        data: {
          roles: allRoles
        }
      }
    ]
  },
  {
    path: 'calendar',
    component: CalendarPartnerComponent,
    canActivate: [AuthGuard],
    data: {
      roles: allRoles
    }
  },
  {
    // ded
    path: 'show-activities',
    component: ShowActivitiesComponent,
    canActivate: [AuthGuard],
    data: {
      roles: allRoles
    }
  },
  {
    path: 'reservations',
    component: MyReservationsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: allRoles
    }
  },
  {
    // ded
    path: 'customers-management',
    component: CustomersManagementComponent,
    canActivate: [AuthGuard],
    data: {
      roles: allRoles
    }
  },
  {
    path: 'my-informations',
    component: MyInformationsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: allRoles
    }
  },
  {
    path: 'invoices',
    component: InvoicesComponent,
    canActivate: [AuthGuard],
    data: {
      roles: allRoles
    },
    children: [
      {
        path: 'my-invoices',
        component: MyInvoicesComponent,
        canActivate: [AuthGuard],
        data: {
          roles: allRoles
        }
      }
    ]
  },
  {
    path: 'cms',
    component: CmsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING]
    },
    children: [
      {
        path: 'text-block',
        component: TextBlockComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING]
        }
      },
      {
        path: 'carousel-product',
        component: CarouselProductComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING]
        }
      },
      {
        path: 'carousel',
        component: CarouselComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING]
        }
      },
      {
        path: 'carousel-zone',
        component: CarouselZoneComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING]
        }
      }
    ]
  },
  {
    path: 'accommodation',
    component: AccommodationComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.ROLE_ADMIN]
    },
    children: [
      {
        path: 'agency',
        component: AgencyComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN]
        }
      },
      {
        path: 'manage-products',
        component: AccommodationProductComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN]
        }
      },
      {
        path: 'search-rooms',
        component: SearchAvailabilityAccommodationComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN]
        }
      },
      {
        path: 'confirm-booking',
        component: ConfirmBookingComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN]
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
