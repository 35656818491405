import { Component } from '@angular/core';
import { Role } from '../../shared/models/enum/Role';
import { Link } from '../../shared/sub-menu/sub-menu.component';

@Component({
  selector: 'dash-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent {
  VMRoles = [Role.ROLE_ADMIN, Role.ROLE_COMMERCIAL, Role.ROLE_MARKETING];

  links: Link[] = [
    {
      roles: this.VMRoles,
      page: 'Partenaire',
      route: '/admin/partner',
      icon: 'person',
      id: 'id__link-admin-partner'
    },
    {
      roles: [Role.ROLE_ADMIN],
      page: 'Utilisateurs',
      route: '/admin/users',
      icon: 'user-group',
      id: 'id__link-admin-users'
    },
    {
      roles: this.VMRoles,
      page: 'Station',
      route: '/admin/resort',
      icon: 'cottage',
      id: 'id__link-admin-resort'
    },
    {
      roles: [Role.ROLE_ADMIN],
      page: 'Label de station',
      route: '/admin/resort-label',
      icon: 'tag',
      id: 'id__link-admin-resort-label'
    },
    {
      roles: [Role.ROLE_ADMIN],
      page: 'Catégorie',
      route: '/admin/category',
      icon: 'document',
      id: 'id__link-admin-category'
    },
    {
      roles: [Role.ROLE_ADMIN],
      page: 'Tag',
      route: '/admin/tag',
      icon: 'filters',
      id: 'id__link-admin-tag'
    },
    {
      roles: [Role.ROLE_ADMIN],
      page: 'Activité',
      route: '/admin/activity',
      icon: 'activity',
      id: 'id__link-admin-activity'
    },
    {
      roles: [Role.ROLE_ADMIN],
      page: 'Champs personnalisés',
      route: '/admin/custom-field',
      icon: 'pencil',
      id: 'id__link-admin-custom-field'
    },
    {
      roles: this.VMRoles,
      page: 'Import',
      route: '/admin/import',
      icon: 'arrow-circle-up',
      id: 'id__link-import'
    },
    {
      roles: [Role.ROLE_ADMIN],
      page: 'E-Liberty',
      route: '/admin/e-liberty',
      icon: 'e-liberty',
      id: 'id__link-e-liberty'
    },
    {
      roles: [Role.ROLE_ADMIN],
      page: 'Paramètres',
      route: '/admin/parameters',
      icon: 'filters',
      id: 'id__link-parameters'
    }
  ];
}
