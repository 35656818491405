import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '../../app.state';
import { MarketingService } from '../../service/marketing.service';
import { ResortService } from '../../service/resort.service';
import { PageComponent } from '../../shared/component/page.component';
import { SessionMaster } from '../../shared/models/marketing/session-master';
import { Partner } from '../../shared/models/partner';
import { Resort } from '../../shared/models/resort/resort';

@Component({
  selector: 'dash-pre-made-cart',
  templateUrl: './pre-made-cart.component.html',
  styleUrls: ['./pre-made-cart.component.scss']
})
export class PreMadeCartComponent extends PageComponent<SessionMaster> {
  @Select(AppState.partners) partners$: Observable<Partner[]>;

  resorts$: Observable<Resort[]>;

  constructor(
    protected service: MarketingService,
    protected resortService: ResortService
  ) {
    super(service);
    this.resorts$ = this.resortService.getAllStations();
  }

  delete() {}
}
