import { Component, Input } from '@angular/core';
import { ElibertyCatalogService } from '../../../service/eliberty-catalog.service';
import { PageComponent } from '../../../shared/component/page.component';
import { CatalogPriceReduction } from '../../../shared/models/eliberty/catalog-price-reduction';
import { Page } from '../../../shared/models/page';

@Component({
  selector: 'dash-catalog-price-reduction',
  templateUrl: './catalog-price-reduction.component.html',
  styleUrls: ['./catalog-price-reduction.component.scss']
})
export class CatalogPriceReductionComponent extends PageComponent<CatalogPriceReduction> {
  catalog: number;

  @Input() set catalogId(catalog: number | null) {
    if (catalog) {
      this.catalog = catalog;
      this.fetchPageable(this.page);
    }
  }

  constructor(elibertyCatalogService: ElibertyCatalogService) {
    super(elibertyCatalogService);

    this.page.pageable.pageSize = 12;
  }

  fetchPageable(page: Page<CatalogPriceReduction>): void {
    if (!this.catalog) {
      return;
    }

    this.register(
      this.service
        .getPageable(
          page.pageable.pageNumber,
          page.pageable.pageSize,
          this.catalog
        )
        .subscribe((page) => (this.page = page))
    );
  }
}
