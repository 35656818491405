import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from '../shared/models/page';
import { Resort } from '../shared/models/resort/resort';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ResortService
  extends RepositoryService
  implements PageableService<Resort>
{
  private URL_STATION = 'api/backoffice/resort';

  constructor(http: HttpClient) {
    super(http);
  }

  upsertResort(resort: Resort): Observable<void> {
    return this.post(this.URL_STATION + '/upsert-station', resort);
  }

  getAllStations(): Observable<Resort[]> {
    return this.get(this.URL_STATION + '/all-stations');
  }

  getAllResortsEliberty(): Observable<Resort[]> {
    return this.get(this.URL_STATION + '/stations-eliberty');
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<Resort>> {
    return this.get(
      this.URL_STATION +
        `/all-stations-pageable?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(map((page: Page<Resort>) => new Page<Resort>({ ...page }, Resort)));
  }

  deleteById(id: number): Observable<void> {
    return this.delete(this.URL_STATION + `?id=${id}`);
  }
}
