import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Page } from '../models/page';

@Component({ standalone: true, template: '' })
export class PageableComponent<T> {
  @Input() page: Page<T>;
  @Input() searchForm: FormControl<string | null> = new FormControl('');

  @Output() createChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() isChanged: EventEmitter<T> = new EventEmitter<T>();
  @Output() isDeleted: EventEmitter<T> = new EventEmitter<T>();
  @Output() pageChanged: EventEmitter<Page<T>> = new EventEmitter<Page<T>>();

  onTableDataChange(event: number) {
    this.page.pageable.pageNumber = event - 1;
    this.pageChanged.emit(this.page);
  }

  edit(objChanged: T): void {
    this.isChanged.emit(objChanged);
  }

  delete(objDeleted: T): void {
    this.isDeleted.emit(objDeleted);
  }
}
