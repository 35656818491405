<dash-list-edit (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                [headers]="headers"
                [searchForm]="searchForm"
                id="id__table-list-marketing"
                labelCreate="Ajouter une campagne"
                paginationId="marketing"
                searchLabel="Recherche de campagne"
                searchPlaceholder="Rechercher par nom ..."
                title="Avant vente">
  <ng-container *ngFor="let sessionMaster of page.content | paginate : {
                  id: 'banner',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }" body>
    <tr (click)="edit(sessionMaster)">
      <td>{{ sessionMaster.sessionId }}</td>
      <td>{{ sessionMaster.name }}</td>
      <td>{{ sessionMaster.dateToCheck.format('DD/MM/YYYY') }}</td>
      <td>{{ sessionMaster.resort.name }}</td>
      <td>{{ sessionMaster.promoCode?.name }}</td>
      <td>{{ sessionMaster.startDate.format('DD/MM/YYYY') }}</td>
      <td>{{ sessionMaster.endDate.format('DD/MM/YYYY') }}</td>
      <td><a [href]="getLinkResort(sessionMaster)" target="_blank">Lien station</a></td>
      <td><a [href]="getLinkPayment(sessionMaster)" target="_blank">Lien paiement</a></td>
    </tr>
  </ng-container>
</dash-list-edit>
