import { Component } from '@angular/core';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { Product } from '../../../shared/models/eliberty/product';

@Component({
  selector: 'dash-list-product',
  templateUrl: './list-product.component.html',
  styleUrls: ['./list-product.component.scss']
})
export class ListProductComponent extends PageableComponent<Product> {
  headers = ['Id', 'Nom', 'Type'];
}
