import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AtomicLibModule } from 'atomic-lib';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SharedModule } from '../shared/shared.module';
import { CreateCustomerComponent } from './create-customer/create-customer.component';
import { CustomersManagementComponent } from './customers-management.component';

@NgModule({
  declarations: [CustomersManagementComponent, CreateCustomerComponent],
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    AtomicLibModule
  ],
  exports: [CustomersManagementComponent, CreateCustomerComponent],
  providers: [provideNgxMask()]
})
export class CustomersManagementModule {}
