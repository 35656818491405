import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { TriggerAlert } from '../../../app.action';
import { TagService } from '../../../service/tag.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { Alert } from '../../../shared/models/alert';
import { Tag } from '../../../shared/models/tag';

@Component({
  selector: 'dash-edit-tag',
  templateUrl: './edit-tag.component.html',
  styleUrls: ['./edit-tag.component.scss']
})
export class EditTagComponent extends RxjsComponent {
  @Output() editionComplete = new EventEmitter<void>();

  @Input() set tagToEdit(value: Tag | undefined) {
    if (value) {
      this.tagId = value.id;
      this.labelForm.setValue(value.label);
      this.editMode = 'edit';
    }
  }

  editMode: 'create' | 'edit' = 'create';
  loading = false;
  tagId: number;
  labelForm = new FormControl<string>('', Validators.required);

  constructor(
    private tagService: TagService,
    private store: Store
  ) {
    super();
  }

  upsertTag() {
    if (this.labelForm.valid) {
      this.loading = true;
      this.register(
        this.tagService
          .upsertTag(
            new Tag({
              id: this.tagId,
              label: this.labelForm.value as string
            })
          )
          .subscribe(
            () => {
              this.loading = false;
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    message:
                      this.editMode === 'create'
                        ? 'Tag crée avec succès'
                        : 'Tag modifié avec succès',
                    level: 'success',
                    timeout: 2000
                  })
                )
              );
              this.editionComplete.emit();
            },
            () => {
              this.loading = false;
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    message:
                      'Une erreur est survenue, veuillez réessayer ultérieurement ...',
                    level: 'error',
                    timeout: 5000
                  })
                )
              );
            }
          )
      );
    }
  }

  delete() {
    this.register(this.tagService.deleteTag(this.tagId).subscribe());
  }
}
