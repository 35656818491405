<atc-side-drawer [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading"
                 (closeChange)="editionComplete.emit()">
  <div header class="w-full my-4">
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification code promo' : 'Nouveau code promo' }}</h1>
  </div>

  <div body class="vsk__body">
    <!-- Name -->
    <atc-input id="id__input-text-activity-name"
               label="Nom*"
               placeholder="Nom code promo (majuscule)"
               [control]="nameForm">
    </atc-input>

    <!-- Type promo code -->
    <atc-select id="id__input-edit-resort-region"
                label="Type code promo*"
                [control]="typeForm"
                [optionElements]="typeElements">
    </atc-select>

    <!-- Value to apply -->
    <atc-input label="Valeur à appliquer (entre 0 et 1 pour une réduction)"
               type="number"
               [control]="applyNumberForm"
               mask="0,0"
               [dropSpecialCharacters]="false"
               [specialCharacters]="[',']"
               [allowNegativeNumbers]="false"
               placeholder="10">
    </atc-input>

    <div class="flex flex-row gap-x-4">
      <!-- Usage limit -->
      <atc-input label="Nombre d'usage limite"
                 type="number"
                 [control]="usedLimitForm"
                 mask="0,0"
                 [dropSpecialCharacters]="false"
                 [specialCharacters]="[',']"
                 [allowNegativeNumbers]="false"
                 placeholder="4,3">
      </atc-input>

      <!-- Min Price -->
      <atc-input label="Prix minimum d'application"
                 type="number"
                 [control]="minPriceForm"
                 mask="0,0"
                 [dropSpecialCharacters]="false"
                 [specialCharacters]="[',']"
                 [allowNegativeNumbers]="false"
                 placeholder="4,3">
      </atc-input>
    </div>

    <div class="vsk__inline">
      <!-- Start date -->
      <atc-input-date id="id__input-promo-code-date-start"
                      label="Date de début de validité"
                      [control]="validityStartDateForm">
      </atc-input-date>

      <!-- End date -->
      <atc-input-date id="id__input-promo-code-date-end"
                      label="Date de fin de validité"
                      [control]="validityEndDateForm">
      </atc-input-date>
    </div>
  </div>

  <div footer class="flex flex-row justify-between w-full items-center pt-4">
    <atc-button *ngIf="editMode === 'edit'" color="danger" label="Supprimer" (click)="deleteById()"></atc-button>

    <atc-button id="id__button-validate-form"
                [disabled]="!isFormValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"

                (click)="create()">
    </atc-button>
  </div>
</atc-side-drawer>
