import { OriginEnum } from './enum/origin.enum';

export class Agency {
  partnerCode: string;
  name: string;
  origin: OriginEnum;
  commission: number;

  constructor(obj: Partial<Agency>) {
    Object.assign(this, obj);
  }
}
