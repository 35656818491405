import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService extends RepositoryService {
  private URL_EMAIL = 'api/admin/email';

  sendConfirmationOrder(sessionId: string): Observable<void> {
    return this.post(
      this.URL_EMAIL +
        `/send-email-command-by-session-id?sessionId=${sessionId}`,
      {}
    );
  }
}
