import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '../../app.state';
import { PartnerService } from '../../service/partner.service';
import { PageComponent } from '../../shared/component/page.component';
import { Partner } from '../../shared/models/partner';
import { UserSession } from '../../shared/models/user-session';

@Component({
  selector: 'dash-generic-partner',
  templateUrl: './generic-partner.component.html',
  styleUrls: ['./generic-partner.component.scss']
})
export class GenericPartnerComponent extends PageComponent<Partner> {
  @Select(AppState.user) user$: Observable<UserSession>;

  constructor(partnerService: PartnerService) {
    super(partnerService);

    this.page.pageable.pageSize = 12;
  }
}
