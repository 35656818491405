import { Component } from '@angular/core';
import { InvoiceService } from '../../service/invoice.service';
import { PageComponent } from '../../shared/component/page.component';
import { Invoice } from '../../shared/models/invoice';

@Component({
  selector: 'dash-my-invoices',
  templateUrl: './my-invoices.component.html',
  styleUrls: ['./my-invoices.component.scss']
})
export class MyInvoicesComponent extends PageComponent<Invoice> {
  constructor(protected service: InvoiceService) {
    super(service);
  }
}
