import moment, { Moment } from 'moment';
import { Customer } from './customer';
import { ParticipantCustomFields } from './participant-custom-fields';
import { ParticipantRegister } from './participant-register';

export class RegisterTimeSlot {
  id: number;
  userId: number;
  userEmail: string;
  userPhone: string;
  customer: Customer;
  participants: ParticipantRegister[] = [];
  dateRegistration: Moment;
  payed: boolean;
  comment: string;
  participantCustomFields: ParticipantCustomFields;

  constructor(obj: Partial<RegisterTimeSlot>) {
    Object.assign(this, obj);
    this.dateRegistration = moment(this.dateRegistration);
    if (this.participants) {
      this.participants = this.participants.map(
        (participant) => new ParticipantRegister({ ...participant })
      );
    }
  }
}
