import { Component } from '@angular/core';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { Category } from '../../../shared/models/category';

@Component({
  selector: 'dash-list-category',
  templateUrl: './list-category.component.html',
  styleUrls: ['./list-category.component.scss']
})
export class ListCategoryComponent extends PageableComponent<Category> {
  headers = ['Id', 'Nom'];
}
