import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'dash-list-edit',
  templateUrl: './list-edit.component.html',
  styleUrls: ['./list-edit.component.scss']
})
export class ListEditComponent {
  @Input() headers: string[] = [];
  @Input() title: string;
  @Input() labelCreate: string;
  @Input() isEmpty = false;
  @Input() searchPlaceholder: string;
  @Input() searchLabel: string;
  @Input() searchForm: FormControl<string | null>;
  @Input() paginationId: string;

  @Output() createEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() paginateEvent: EventEmitter<number> = new EventEmitter<number>();
}
