<div class="flex m-[1rem]">
  <img class="w-6 h-6" alt="Duplicate" src="assets/icones/admin.svg">
  <p class="inline pl-[1rem]" >
    Vous pouvez renseigner la TVA de vos activités directement sur la page
    <a class="blue underline" [routerLink]="['/activity/activity-partner']"> activités</a>
    (par défaut la TVA est a 20%).
  </p>
</div>

<dash-list-edit id="id__table-list-invoices"
                paginationId="invoices"
                [headers]="headers"
                (paginateEvent)="onTableDataChange($event)"
                (createEvent)="createChanged.emit()"
                title="Mes Factures">

  <div action class="flex items-center">
    <ng-container *ngIf="isLoadingMulti">
      <atc-loader class="pr-2" [size]="'small'"></atc-loader>
    </ng-container>
    <atc-button *ngIf="multiSelectLabel()"
                [disabled]="isLoadingMulti"
                [label]="multiSelectLabel()"
                color="primary"
                (click)="generateMultiPdfs()">
    </atc-button>
  </div>

  <ng-container body *ngFor="let invoice of page.content | paginate : {
                  id: 'banner',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr>
      <td>
        <input class="checkboxDownload" type="checkbox" (click)="addMultiSelect(invoice.sessionId , invoice.invoiceHeader)">
      </td>
      <td>{{ invoice.invoiceHeader }}</td>
      <td>{{ invoice.activityName }}</td>
      <td>{{ toDate(invoice.datePayed) }}</td>
      <td>{{ invoice.totalTTC }}€</td>
      <td>{{ invoice.totalDu }}€</td>
      <td>{{ invoice.vat }}</td>
      <td class="text-center z-50 relative" (click)="generatePdf(invoice.sessionId, invoice.invoiceHeader)">
        <img class="w-6 h-6"
             alt="Generate pdf"
             src="assets/icones/download.svg" >
      </td>
    </tr>
  </ng-container>
</dash-list-edit>

