<dash-list-edit (createEvent)="openDrawer()"
                [headers]="headers"
                labelCreate="Ajouter un tarif de groupe"
                title="Tarifs de groupe">
  <tr (click)="editPackageGroup($event, group)"
      *ngFor="let group of groupPackages"
      body>
    <td *ngIf="roles$ | async | isAdmin">{{ group.id }}</td>
    <td>{{ group.name }}</td>
    <td>{{ group.description }}</td>
    <td>{{ group.price | number : '1.2-2':'fr' }} €</td>
    <td>{{ group.publicPrice | number : '1.2-2':'fr' }} €</td>
    <td>{{ group.startDate.format('DD/MM/YYYY') }}</td>
    <td>{{ group.endDate.format('DD/MM/YYYY') }}</td>
    <td>
      <img (click)="editPackageGroup($event, group, true)" alt="Duplicate" src="assets/icones/copy.svg">
    </td>
  </tr>
</dash-list-edit>

<atc-side-drawer (closeChange)="showDrawer = false"
                 *ngIf="showDrawer"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading">
  <div class="w-full my-4" header>
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification tarif de groupe' : 'Nouveau tarif de groupe' }}</h1>
  </div>

  <div body class="flex flex-col gap-y-4 mt-4">
    <div class="flex flex-row gap-x-2">
      <!-- Group name -->
      <atc-input [control]="nameForm"
                 label="Nom du tarif de groupe*"
                 placeholder="Tarif famille"></atc-input>

      <atc-input [allowNegativeNumbers]="false"
                 [control]="priceForm"
                 [dropSpecialCharacters]="false"
                 [specialCharacters]="[',', '.']"
                 errorMessage="Le prix est obligatoire"
                 icon="credit-card"
                 label="Prix*"
                 mask="separator.2"
                 placeholder="15,50 €"
                 suffix=" €"></atc-input>

      <atc-input [allowNegativeNumbers]="false"
                 [control]="publicPriceForm"
                 [dropSpecialCharacters]="false"
                 [specialCharacters]="[',', '.']"
                 errorMessage="Le prix est obligatoire"
                 icon="credit-card"
                 label="Prix public*"
                 mask="separator.2"
                 placeholder="15,50 €"
                 suffix=" €"></atc-input>
    </div>

    <div class="flex flex-row gap-x-2">
      <atc-input-date [control]="startDateForm"
                      class="flex-1"
                      label="Date de début*">
      </atc-input-date>

      <atc-input-date [control]="endDateForm"
                      class="flex-1"
                      label="Date de fin*">
      </atc-input-date>
    </div>

    <!-- Group description -->
    <atc-input [control]="descriptionForm"
               label="Description du tarif de groupe*"
               placeholder="-10% tarif famille"></atc-input>

    <atc-select [control]="experienceForm"
                [optionElements]="experienceOptions"
                label="Expérience*">
    </atc-select>

    <atc-button
      (click)="addForm()"
      [fullWidth]="true"
      class="flex"
      label="Ajouter un tarif">
    </atc-button>

    <ng-container *ngIf="experienceForm.valid">
      <ng-container *ngFor="let packageForm of packagesForm;let i = index">
        <div class="flex flex-row gap-x-2">
          <atc-select
            [control]="packageForm"
            [label]="'Tarif ' + (i + 1)"
            [optionElements]="packageSelectItems"
            class="flex-1">
          </atc-select>

          <atc-button (click)="removeForm(i)"
                      *ngIf="i !== 0"
                      color="ternary"
                      label="-">
          </atc-button>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="flex flex-row justify-between w-full items-center pt-4" footer>
    <atc-button (click)="delete()" *ngIf="editMode === 'edit'" color="danger" label="Supprimer"></atc-button>

    <atc-button (click)="upsert()"
                [disabled]="!isFormValid()"

                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                id="id__button-validate-form">
    </atc-button>
  </div>
</atc-side-drawer>
