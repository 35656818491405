import { Injectable } from '@angular/core';
import { Stomp } from '@stomp/stompjs';
import { Subject } from 'rxjs';
import SockJS from 'sockjs-client';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  elibertyTopic: Subject<string> = new Subject();

  constructor() {
    const socket = new SockJS(`${environment.urlAddress}/socket`);
    const stompClient = Stomp.over(socket);

    stompClient.connect({}, () => {
      stompClient.subscribe('/topic/eliberty', (response) =>
        this.elibertyTopic.next(response.body)
      );
    });
  }

  get eliberty() {
    return this.elibertyTopic.asObservable();
  }
}
