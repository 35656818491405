import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Variant } from '../../../shared/models/eliberty/variant';

@Component({
  selector: 'dash-edit-variant',
  templateUrl: './edit-variant.component.html',
  styleUrls: ['./edit-variant.component.scss']
})
export class EditVariantComponent {
  @Input() variant: Variant;
  @Output() editionComplete: EventEmitter<void> = new EventEmitter<void>();
}
