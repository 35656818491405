import { Component, Input } from '@angular/core';
import { PageableComponent } from 'src/app/shared/component/pageable.component';
import { Carousel } from 'src/app/shared/models/carousel';
import { CarouselZone } from 'src/app/shared/models/carousel-zone';
import { CarouselProductTypeEnum } from 'src/app/shared/models/enum/carousel-product-type.enum';

@Component({
  selector: 'dash-list-carousel',
  templateUrl: './list-carousel.component.html',
  styleUrls: ['./list-carousel.component.scss']
})
export class ListCarouselComponent extends PageableComponent<Carousel> {
  headers = ['Id', 'Titre', 'Type', 'Zone', 'Activé'];
  zones: CarouselZone[] = [];

  @Input() set carouselZones(zones: CarouselZone[]) {
    this.zones = zones;
  }

  getType(type: CarouselProductTypeEnum): string {
    switch (type) {
      case CarouselProductTypeEnum.EXPERIENCE:
        return 'Expérience';
      case CarouselProductTypeEnum.STAY:
        return 'Séjour';
      case CarouselProductTypeEnum.ACCOMMODATION:
        return 'Hébergement';
      default:
        return '-';
    }
  }
}
