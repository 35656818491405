<div class="vsk__card vsk__margin-bottom-1">
  <div class="vsk__header-inline">
    <atc-button
      (click)="addActivity()"
      icon="more"
      label="Ajouter un créneau"
    ></atc-button>
    <div class="vsk__inline">
      <atc-button
        (click)="changeWeek(false)"
        [clicked]="false"
        [disabled]="false"
        color="ternary"
        icon="chevron-left">
      </atc-button>
      <atc-week-picker (weekChanged)="updateView($event)"
                       [currentDate]="today">
      </atc-week-picker>
      <atc-button
        (click)="changeWeek(true)"
        [clicked]="false"
        [disabled]="false"
        color="ternary"
        icon="chevron-right">
      </atc-button>
    </div>
    <atc-select [checkbox]="true"
                [control]="filterForm"
                [searchable]="true"
                label="Filtrer par"
                (selectChange)="changeFilters()"
                [optionElements]="filterView">
    </atc-select>
  </div>

  <p>Cliquez sur un événement pour le modifier</p>
  <atc-calendar
    (eventClicked)="eventClicked($event)"
    (internshipClicked)="internshipClicked($event)"
    [loading]="loading"
    [periodEvents]="period">
  </atc-calendar>
</div>

<dash-add-activity (closeChanged)="updateView(today)"
                   *ngIf="showEditTimeSlot"
                   [edit]="editTimeSlot"></dash-add-activity>
