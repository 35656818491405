<div class="vsk__column">
  <dash-package-grid [experiences]="experiences"></dash-package-grid>

  <dash-list-package
    (createChanged)="showEdit()"
    (isChanged)="edit($event)"
    (pageChanged)="fetchPageable($event, searchForm.value)"
    [page]="page"
    [searchForm]="searchForm">
  </dash-list-package>

  <dash-edit-package (packageChanged)="editCompleted()"
                     *ngIf="showEditor"
                     [ageRanges]="ageRanges"
                     [optionsActivity]="experienceActivityPartners"
                     [pckToEdit]="toEdit">
  </dash-edit-package>

  <dash-group-package (packageChanged)="updatePackagesChanged()"
                      [experiences]="experienceActivityPartners"
                      [groupPackages]="packagesGroup"
                      [packages]="packages"></dash-group-package>
</div>
