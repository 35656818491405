import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { AccountService } from '../service/account.service';
import { PartnerService } from '../service/partner.service';
import { RxjsComponent } from '../shared/component/rxjs.component';

export interface ResetPasswordForm {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
}

@Component({
  selector: 'dash-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends RxjsComponent {
  error = '';
  state: 'send' | 'reset' = 'send';
  isSend = false;
  loading = false;
  emailUserForm = new FormControl<string>('', [
    Validators.required,
    Validators.email
  ]);
  passwordFirstForm = new FormControl<string>('', [
    Validators.required,
    Validators.pattern(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&-*()]).{8,}$'
    )
  ]);
  passwordSecondForm = new FormControl<string>('', [
    Validators.required,
    Validators.pattern(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&-*()]).{8,}$'
    )
  ]);
  resetPasswordGroup: FormGroup<ResetPasswordForm>;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private partnerService: PartnerService
  ) {
    super();

    // Init form
    this.resetPasswordGroup = new FormGroup<ResetPasswordForm>({
      email: this.emailUserForm,
      password: this.passwordFirstForm
    });

    // Get email in url
    this.route.queryParams
      .pipe(
        tap((value) =>
          this.resetPasswordGroup.get('email')?.setValue(value.email)
        ),
        tap((value) => (this.state = value.token ? 'reset' : 'send'))
      )
      .subscribe();
  }

  askResetPassword() {
    if (this.emailUserForm.valid) {
      this.loading = true;
      this.register(
        this.partnerService
          .forgotPassword(this.emailUserForm.value as string)
          .subscribe(
            () => {
              this.isSend = true;
              this.loading = false;
            },
            () => {
              this.error =
                'Une erreur est survenue, veuillez réessayer plus tard ...';
              this.loading = false;
            }
          )
      );
    }
  }

  resetPassword() {
    if (
      this.resetPasswordGroup.valid &&
      this.passwordFirstForm.value === this.passwordSecondForm.value
    ) {
      this.route.queryParams
        .pipe(
          switchMap((value) =>
            this.accountService.resetPassword(value.token, {
              email: this.emailUserForm.value as string,
              password: this.passwordFirstForm.value as string
            })
          )
        )
        .subscribe(
          () => (this.isSend = true),
          (err) => {
            console.log(err);
            this.isSend = true;
            switch (err.status) {
              case 500:
                this.error =
                  'Une erreur inattendue est survenue, veuillez réessayer ultérieurement';
                break;
              case 400:
                this.error = err.error;
                break;
              case 404:
                this.error =
                  'La réinitialisation de mot de passe a expiré, veuillez refaire une demande de réinitialisation';
                break;
            }
          }
        );
    } else if (this.passwordFirstForm.value !== this.passwordSecondForm.value) {
      this.error = 'Les mots de passe ne sont pas identiques';
    } else {
      this.error =
        'Le mot de passe doit contenir au moins 8 caractères dont au moins 1 majuscule, 1 minuscule, 1 caractère spécial (#?!@$%^&-*()) et 1 chiffre';
    }
  }
}
