<dash-sidemenu *ngIf="isUserLoggedIn$ | async"></dash-sidemenu>
<div class="flex flex-col w-full max-h-full h-full">
  <ng-container *ngIf="partner$ | async as partner">
    <span (click)="changePartner()"
          *ngIf="containsRoles([Role.ROLE_ADMIN, Role.ROLE_COMMERCIAL, Role.ROLE_MARKETING])"
          [ngClass]="{'vsk__clickable': containsRoles([Role.ROLE_ADMIN, Role.ROLE_COMMERCIAL, Role.ROLE_MARKETING])}"
          class="vsk__button-link vsk__partner-name"
          id="id__link-home-switch-partner">
      {{ partner?.id }} - {{ partner?.name }}
    </span>
    <div *ngIf="containsRoles([Role.ROLE_ADMIN, Role.ROLE_COMMERCIAL, Role.ROLE_MARKETING])" class="header"></div>
  </ng-container>
  <router-outlet></router-outlet>
</div>
<dash-select-partner (closeChanged)="showPopupSelectPartner = false"
                     *ngIf="showPopupSelectPartner">
</dash-select-partner>
<atc-alert *ngIf="alert"
           [level]="alert.level"
           class="fixed top-0 left-0 right-0 w-full z-[101]"
           size="small">
  {{ alert.message }}
</atc-alert>
<atc-banner-environment *ngIf="!environment.production"
                        [env]="environment.name">
</atc-banner-environment>
