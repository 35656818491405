import { Sort } from './sort';

export class Pageable {
  sort: Sort;
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;

  constructor(obj: Partial<Pageable>) {
    Object.assign(this, obj);
    this.sort = new Sort({ ...this.sort });
  }
}
