import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { ExperienceGroupService } from '../../../../service/experience-group.service';
import { EditComponent } from '../../../../shared/component/edit.component';
import { Experience } from '../../../../shared/models/experience';
import { ExperienceGroup } from '../../../../shared/models/experience-group';

@Component({
  selector: 'dash-edit-experience-group',
  templateUrl: './edit-experience-group.component.html',
  styleUrls: ['./edit-experience-group.component.scss']
})
export class EditExperienceGroupComponent extends EditComponent<ExperienceGroup> {
  experiencesOptions: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une expérience',
      disabled: true,
      classCss: 'disabled'
    }
  ];
  _experiences: Experience[] = [];
  elements: Experience[] = [];

  nameForm = new FormControl<string | null>('', Validators.required);
  experienceForm = new FormControl<number | null>(null);

  @Input() set experiences(experiences: Experience[]) {
    if (experiences) {
      this._experiences = experiences;
      this.updateOptions(this.elements);
    }
  }
  @Input() set entityToEdit(entity: ExperienceGroup | undefined) {
    if (entity) {
      this.id = entity.id;
      this.nameForm.setValue(entity.name);
      this.updateOptions(entity.experiences);
      this.editMode = 'edit';
    }
  }

  constructor(
    protected service: ExperienceGroupService,
    protected store: Store
  ) {
    super(service, store);
    this.name = `Groupement d'expérience`;
  }

  public isFormValid(): boolean {
    return this.nameForm.valid && this.elements?.length > 1;
  }

  public buildEntity(): ExperienceGroup {
    return new ExperienceGroup({
      id: this.id as any,
      name: this.nameForm.value as string,
      experiences: this.elements
    });
  }

  public initForm(): void {}

  public addExperience() {
    const exp = this._experiences.find(
      (experience) => experience.id === this.experienceForm.value
    );

    if (exp) {
      this.elements.push(exp);
      this.experienceForm.setValue(null);
      this.updateOptions(this.elements);
    }
  }

  updateOptions(elements: Experience[]) {
    this.elements = elements;
    this.experiencesOptions = [
      {
        id: null,
        label: 'Choisir une expérience',
        disabled: true,
        classCss: 'disabled'
      },
      ...this._experiences
        .filter(
          (experience) =>
            this.elements?.map((el) => el.id).indexOf(experience.id) === -1
        )
        .map((experience) => {
          return {
            id: experience.id,
            label: experience.activity.name + ' · ' + experience.name
          };
        })
    ];
  }
}
