<dash-list-edit
  [headers]="headers"
  title="Réservations en attente">
  <ng-container *ngFor="let reservation of page.content | paginate : {
                  id: 'pending',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }" body>
    <tr>
      <td>{{ reservation?.experienceName }}</td>
      <td>{{ formatDate(reservation.dateStart, reservation.internshipId ? reservation.dateEnd : undefined) }}</td>
      <td>{{ formatDate(reservation.dateRegistration) }}</td>
      <td>{{ reservation?.participants?.length }}</td>
      <td>
        <img (click)="setReservationToShow(reservation)" alt="Edit" src="assets/icones/edit.svg">
      </td>
      <td>
        <div class="vsk__inline">
          <atc-button (click)="acceptReservationAndRefresh(reservation.registerTimeSlotId , STATUS_ACCEPTED)"
                      id="id__button-my-pending-reservations-list-accept"
                      label="Accepter">
          </atc-button>
          <atc-button (click)="modify(reservation)"
                      *ngIf="!reservation.internshipId"

                      id="id__button-my-pending-reservations-list-modify"
                      label="Reprogrammer"></atc-button>
          <atc-button (click)="openCloseRefusePopup(reservation)"
                      color="danger"
                      id="id__button-my-pending-reservations-list-deny"
                      label="Refuser"></atc-button>
        </div>
      </td>
      <td>{{ reservation?.user?.email }}</td>
      <td>{{ reservation?.user?.phone }}</td>
    </tr>
  </ng-container>

  <div class="flex flex-row justify-center mt-2" paginate>
    <pagination-controls
      (pageChange)="onTableDataChange($event)"
      [autoHide]="true"
      [id]="'pending'"
      [maxSize]="9"
      [responsive]="true"
      nextLabel="Suivant"
      previousLabel="Précédent"
    >
    </pagination-controls>
  </div>
</dash-list-edit>

<dash-refuse-reservation (closeChanged)="openCloseRefusePopup(undefined)"
                         (denialMessageChanged)="refuseReservation($event)"
                         *ngIf="showRefusePopup">
</dash-refuse-reservation>

<dash-my-pending-reservation-detail (visualisationComplete)="closeDrawer()"
                                    *ngIf="showEdit"
                                    [reservation]="reservationToShow">
</dash-my-pending-reservation-detail>
<dash-reprogram-time-slot (closeChanged)="setReservationModifyPopUptoUndefined()"
                          (timeSlotsChosen)="onPopupModifyClose($event)"
                          *ngIf="showModifyPopUp"
                          [loading]="loading"
                          [reservationModifyPopUp]="reservationModifyPopUp">
</dash-reprogram-time-slot>
