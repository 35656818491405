import moment, { Moment } from 'moment';
import { Participant } from '../participant';

export class OrderDetailsSkiPass {
  id: string;
  origin: string;
  passName: string;
  firstSkiDate: Moment;
  participant: Participant;
  price: number;
  cancelled: boolean;

  constructor(obj: Partial<OrderDetailsSkiPass>) {
    Object.assign(this, obj);

    if (this.participant) {
      this.participant = new Participant(this.participant);
    }

    if (this.firstSkiDate) {
      this.firstSkiDate = moment(this.firstSkiDate, 'YYYY-MM-DDTHH:mm:ss');
    }
  }
}
