import { Component } from '@angular/core';
import { CustomFieldService } from '../../service/custom-field.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { CustomField } from '../../shared/models/custom-field';

@Component({
  selector: 'dash-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.scss']
})
export class CustomFieldComponent extends RxjsComponent {
  customFields: CustomField[] = [];
  customFieldToEdit: CustomField | undefined;
  showEditor = false;

  constructor(private customFieldService: CustomFieldService) {
    super();
    this.updateCustomField();
  }

  onCustomFieldEdit(customFieldToEdit: CustomField): void {
    this.customFieldToEdit = customFieldToEdit;
    this.showEdit();
  }

  onEditionComplete(): void {
    this.customFieldToEdit = undefined;
    this.updateCustomField();
    this.showEdit();
  }

  updateCustomField(): void {
    this.register(
      this.customFieldService
        .getCustomFields()
        .subscribe((customFields) => (this.customFields = customFields))
    );
  }

  showEdit() {
    this.showEditor = !this.showEditor;
  }
}
