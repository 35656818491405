import { SeasonEnum } from './enum/season.enum';

export class TextBlockContent {
  id: number;
  title: string;
  description: string;
  season: SeasonEnum;
  coverPhotoUrl: string;

  constructor(obj: Partial<TextBlockContent>) {
    Object.assign(this, obj);
  }
}
