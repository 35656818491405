<atc-side-drawer (closeChange)="editionComplete.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']">
  <div class="w-full my-4" header>
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modifier activité' : 'Nouvelle activité' }}</h1>
  </div>
  <div body class="vsk__body">
    <atc-select [control]="activityForm"
                [optionElements]="activityElements"
                [searchable]="true"
                label="Activité">
    </atc-select>

    <atc-select [checkbox]="true"
                [optionElements]="resortElements"
                [searchable]="true"
                label="Stations">
    </atc-select>

    <atc-input [control]="VTAForm"
               [dropSpecialCharacters]="false"
               [specialCharacters]="[',']"
               id="id__input-edit-tva"
               label="TVA"
               mask="separator.2"
               suffix=" %">
    </atc-input>

    <atc-input *ngIf="containsRoles([Role.ROLE_ADMIN])"
               [control]="commissionRateForm"
               [dropSpecialCharacters]="false"
               [specialCharacters]="[',']"
               id="id__input-edit-activity-commission-rate"
               label="Taux de commission"
               mask="separator.2"
               suffix=" %">
    </atc-input>
  </div>

  <div class="flex flex-row justify-between w-full items-center pt-4" footer>
    <atc-button (click)="delete()" *ngIf="editMode === 'edit'" color="danger" label="Supprimer"></atc-button>
    <atc-button (click)="create()"
                [disabled]="!formIsValid()"
                [label]="id ? 'Modifier' : 'Créer'"

                id="id__button-validate-form">
    </atc-button>
  </div>
</atc-side-drawer>
