<dash-tab-group>
  <dash-tab label="Réservation à traiter" [selected]="true">
    <dash-my-pending-reservations-list>
    </dash-my-pending-reservations-list>
  </dash-tab>

  <dash-tab label="Réservation traitées">
    <dash-reservations-validated-list>
    </dash-reservations-validated-list>
  </dash-tab>
</dash-tab-group>
