import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from '../shared/models/page';
import { PromoCode } from '../shared/models/promo-code';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeService
  extends RepositoryService
  implements PageableService<PromoCode>, CrudService<PromoCode>
{
  private URL_PROMO_CODE = 'api/backoffice/promo-code';

  constructor(http: HttpClient) {
    super(http);
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<PromoCode>> {
    return this.get(
      this.URL_PROMO_CODE +
        `/all-promo-code?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map(
        (page: Page<PromoCode>) => new Page<PromoCode>({ ...page }, PromoCode)
      )
    );
  }

  upsert(promoCode: PromoCode): Observable<void> {
    return this.post(this.URL_PROMO_CODE, promoCode);
  }

  deleteById(id: number): Observable<void> {
    return this.delete(this.URL_PROMO_CODE + `?id=${id}`);
  }
}
