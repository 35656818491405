<atc-side-drawer (closeChange)="editionComplete.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading">
  <div class="w-full my-4" header>
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification connexion API' : 'Nouvelle connexion API' }}</h1>
  </div>

  <div body class="vsk__body">
    <p *ngIf="error" class="error">{{ error }}</p>
    <atc-select [control]="resortForm"
                [optionElements]="resorts"
                [searchable]="true"
                class="flex-1"
                label="Choisir une station">
    </atc-select>

    <div class="flex flex-row gap-4">
      <atc-input [control]="partnerELibertyForm"
                 label="Partenaire ELiberty ID (Uniquement B2B)"
                 placeholder="12730"
                 type="text"></atc-input>

      <atc-input [control]="partnerVeryMountainForm"
                 label="Partenaire VeryMountain ID"
                 placeholder="123"
                 type="text"></atc-input>
    </div>

    <atc-input [control]="clientIdForm"
               errorMessage="Ce champs est obligatoire"
               label="Client ID"
               placeholder="2369_3icdhsq2l3ggowsgw8ccw8s0wkcw8g0g008s0g88cg4kw0soko"
               type="text"></atc-input>

    <atc-input [control]="clientSecretForm"
               errorMessage="Ce champs est obligatoire"
               label="Client Secret"
               placeholder="465iythl26ec4w4oowskwcgcssoko8wksgs4kw4kskwcgwowgs"
               type="text"></atc-input>

    <atc-input [control]="urlForm"
               errorMessage="Ce champs est obligatoire"
               label="Lien URL B2B ou B2C"
               placeholder="https://b2b.skipass-2alpes.com"
               type="text"></atc-input>
  </div>

  <div class="flex flex-row justify-between w-full items-center pt-4" footer>
    <atc-button (click)="deleteById()" *ngIf="editMode === 'edit'" color="danger" label="Supprimer"></atc-button>

    <atc-button (click)="create()"
                [disabled]="!isFormValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                id="id__button-validate-form">
    </atc-button>
  </div>
</atc-side-drawer>
