import { SeasonEnum } from './enum/season.enum';

export class ResortLabel {
  id: number;
  name: string;
  period: SeasonEnum;
  link: string;

  constructor(obj: Partial<ResortLabel>) {
    Object.assign(this, obj);
  }
}
