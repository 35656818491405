<dash-list-edit (createEvent)="createEventChanged.emit()"
                [headers]="headers"
                labelCreate="Ajouter une tranche d'âge"
                title="Tranches d'âge">
  <tr (click)="editAge(age)"
      *ngFor="let age of ageRanges"
      body>
    <td *ngIf="roles$ | async | isAdmin">{{ age.id }}</td>
    <td>{{ age.name }}</td>
    <td>{{ age.start }} - {{ age.end }} ans</td>
    <td>{{ age.origin }}</td>
  </tr>
</dash-list-edit>
