import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../app.state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  @Select(AppState.roles) roles$: Observable<string[]>;
  @Select(AppState.isUserLoggedIn) connected$: Observable<boolean>;

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const allowedUserRoles = this.getRoutePermissions(route);
    return this.checkPermission(allowedUserRoles);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> {
    const allowedUserRoles = this.getRoutePermissions(childRoute);
    return this.checkPermission(allowedUserRoles);
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.checkPermission([]);
  }

  /**
   * Get allowed user roles from the route.
   * @param route route - The route.
   * @returns string[] All user roles that are allowed to access the route.
   */
  private getRoutePermissions(route: ActivatedRouteSnapshot): string[] {
    if (route.data && route.data.roles) {
      return route.data.roles as string[];
    }
    return [];
  }

  /**
   * Check if a user is authenticated
   * @param allowedUserRoles - These user roles have the permissions to access the route.
   * @returns Observable<boolean> True if user is authenticated otherwise false
   */
  private checkPermission(allowedUserRoles: string[]): Observable<boolean> {
    return this.roles$.pipe(
      map((roles: string[]) => {
        if (allowedUserRoles.length === 0) {
          return true; // if no user roles has been set, all user are allowed to access the route
        }

        if (
          allowedUserRoles.filter((role) => roles.indexOf(role) !== -1).length >
          0
        ) {
          return true;
        } else {
          this.router.navigateByUrl('/login');
          return false;
        }
      })
    );
  }
}
