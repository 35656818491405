import { Participant } from '../participant';
import { User } from '../user';
import { OrderDetailsAccommodation } from './order-details-accomodation';
import { OrderDetailsExperience } from './order-details-experience';
import { OrderDetailsSkiEquipment } from './order-details-skiequipment';
import { OrderDetailsSkiPass } from './order-details-skipass';

export class OrderDetails {
  user: User;
  uniqueParticipants: Participant[];
  accommodations: OrderDetailsAccommodation[];
  experiences: OrderDetailsExperience[];
  skiPasses: OrderDetailsSkiPass[];
  skiEquipments: OrderDetailsSkiEquipment[];

  constructor(obj: Partial<OrderDetails>) {
    Object.assign(this, obj);

    if (this.user) {
      this.user = new User(this.user);
    }

    if (this.uniqueParticipants) {
      this.uniqueParticipants = this.uniqueParticipants.map(
        (participant) => new Participant(participant)
      );
    }

    if (this.accommodations) {
      this.accommodations = this.accommodations.map(
        (accommodation) => new OrderDetailsAccommodation(accommodation)
      );
    }

    if (this.experiences) {
      this.experiences = this.experiences.map(
        (experience) => new OrderDetailsExperience(experience)
      );
    }

    if (this.skiPasses) {
      this.skiPasses = this.skiPasses.map(
        (skiPass) => new OrderDetailsSkiPass(skiPass)
      );
    }

    if (this.skiEquipments) {
      this.skiEquipments = this.skiEquipments.map(
        (skiEquipment) => new OrderDetailsSkiEquipment(skiEquipment)
      );
    }
  }
}
