<dash-list-edit id="id__table-list-catalog-price-reduction"
                paginationId="catalog-price-reduction"
                [headers]="headers"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                labelCreate="Ajouter une remise"
                title="Remise de prix commerciale">
  <ng-container body *ngFor="let catalogPriceReduction of page.content | paginate : {
                id: 'catalog-price-reduction',
                itemsPerPage: page.pageable.pageSize,
                currentPage: page.pageable.pageNumber + 1,
                totalItems: page.totalElements }">
    <tr (click)="edit(catalogPriceReduction)">
      <td>{{ catalogPriceReduction.id }}</td>
      <td>{{ catalogPriceReduction.startDate.format('DD/MM/YYYY') }}</td>
      <td>{{ catalogPriceReduction.endDate.format('DD/MM/YYYY') }}</td>
      <td>{{ catalogPriceReduction.reduction }} %</td>
    </tr>
  </ng-container>
</dash-list-edit>
