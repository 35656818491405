import { AfterViewInit, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { RegisterTimeSlotService } from '../../service/register-time-slot.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { RegisterStatusEnum } from '../../shared/models/enum/register-status.enum';
import { Page } from '../../shared/models/page';
import { Pageable } from '../../shared/models/pageable';
import { Reservation } from '../../shared/models/reservation';

@Component({
  selector: 'dash-reservations-validated-list',
  templateUrl: './reservations-validated-list.component.html',
  styleUrls: ['./reservations-validated-list.component.scss']
})
export class ReservationsValidatedListComponent
  extends RxjsComponent
  implements AfterViewInit
{
  @Select(AppState.partnerId) partnerId$: Observable<number>;
  headers = [
    'ACTIVITÉ',
    'CRÉNEAU',
    'RÉSERVATION',
    'PERSONNES',
    'STATUT',
    'EMAIL',
    'TELEPHONE'
  ];
  page: Page<Reservation> = new Page<Reservation>({
    content: [],
    pageable: new Pageable({
      pageNumber: 0,
      pageSize: 10
    }),
    totalElements: 0,
    numberOfElements: 0
  });

  constructor(private registerTimeSlotService: RegisterTimeSlotService) {
    super();
  }

  ngAfterViewInit(): void {
    this.getReservations();
  }

  getReservations() {
    this.register(
      this.partnerId$
        .pipe(
          switchMap((partnerId) => {
            return this.registerTimeSlotService.getReservations(
              partnerId,
              this.page.pageable.pageNumber,
              this.page.pageable.pageSize,
              [
                RegisterStatusEnum.ACCEPTED,
                RegisterStatusEnum.REFUSED,
                RegisterStatusEnum.ONGOING
              ]
            );
          })
        )
        .subscribe((page) => (this.page = page))
    );
  }

  onTableDataChange(event: any) {
    this.page.pageable.pageNumber = event - 1;
    this.getReservations();
  }

  formatDate(date: Moment): string {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  statusLabel(status: RegisterStatusEnum) {
    switch (status) {
      case RegisterStatusEnum.ACCEPTED:
        return 'Accepté';
      case RegisterStatusEnum.ONGOING:
        return 'Reprogrammé';
      case RegisterStatusEnum.REFUSED:
        return 'Refusé';
      default:
        return '-';
    }
  }
}
