import { Component } from '@angular/core';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { Tag } from '../../../shared/models/tag';

@Component({
  selector: 'dash-list-tag',
  templateUrl: './list-tag.component.html',
  styleUrls: ['./list-tag.component.scss']
})
export class ListTagComponent extends PageableComponent<Tag> {
  headers = ['Id', 'Tag'];
}
