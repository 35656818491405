import { Component } from '@angular/core';
import { CategoryService } from '../../service/category.service';
import { PageComponent } from '../../shared/component/page.component';
import { Category } from '../../shared/models/category';

@Component({
  selector: 'dash-generic-category',
  templateUrl: './generic-category.component.html',
  styleUrls: ['./generic-category.component.scss']
})
export class GenericCategoryComponent extends PageComponent<Category> {
  constructor(categoryService: CategoryService) {
    super(categoryService);

    this.page.pageable.pageSize = 12;
  }
}
