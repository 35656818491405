<atc-side-drawer [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading"
                 (closeChange)="editionComplete.emit()">
  <div header class="w-full my-4">
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification' : 'Création' }} d'une campagne</h1>
  </div>

  <div body class="vsk__body">
    <atc-input label="Nom"
               placeholder="Campagne Julie Paris - hébergement 2 personnes Alpes d'Huez"
               [control]="nameForm">
    </atc-input>

    <div class="flex flex-row gap-x-4 justify-between">
      <atc-input label="Session id"
                 placeholder="d58743d1-441a-4349-aad8-3e9f967da48f"
                 [control]="sessionForm">
      </atc-input>

      <atc-input-date label="Date de révision de la campagne"
                      class="flex-1"
                      [control]="dateToCheckForm">
      </atc-input-date>
    </div>

    <div class="flex flex-row gap-x-4 justify-between">
      <atc-select class="flex-1"
                  [optionElements]="promoCodeElements"
                  [searchable]="true"
                  [control]="promoCodeForm"
                  label="Choisir un code promo">
      </atc-select>

      <atc-select class="flex-1"
                  [optionElements]="resortElements"
                  [searchable]="true"
                  [control]="resortForm"
                  label="Choisir une destination">
      </atc-select>
    </div>

    <div class="flex flex-row gap-x-4 justify-between">
      <atc-input-date label="Date de début de séjour"
                      class="flex-1"
                      [control]="startDateForm">
      </atc-input-date>

      <atc-input-date label="Date de fin de séjour"
                      class="flex-1"
                      [control]="endDateForm">
      </atc-input-date>
    </div>
  </div>

  <div footer class="flex flex-row justify-between w-full items-center pt-4">
    <atc-button *ngIf="editMode === 'edit'" color="danger" label="Supprimer" (click)="deleteById()"></atc-button>

    <atc-button id="id__button-validate-form"
                [disabled]="!isFormValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"

                (click)="create()">
    </atc-button>
  </div>
</atc-side-drawer>
