import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import moment from 'moment/moment';
import { Observable } from 'rxjs';
import { AppState } from '../../../app.state';
import { Role } from '../../../shared/models/enum/Role';
import { Participant } from '../../../shared/models/participant';
import { Reservation } from '../../../shared/models/reservation';

@Component({
  selector: 'dash-my-pending-reservation-detail',
  templateUrl: './my-pending-reservation-detail.component.html',
  styleUrls: ['./my-pending-reservation-detail.component.scss']
})
export class MyPendingReservationDetailComponent {
  @Select(AppState.roles) roles$: Observable<string[]>;
  @Input() reservation: Reservation | undefined;
  @Output() visualisationComplete = new EventEmitter<void>();
  roles: string[] = [];
  protected readonly Role = Role;

  constructor() {
    this.roles$.subscribe((roles) => (this.roles = roles));
  }

  formatDate(
    date?: moment.Moment | undefined,
    dateEnd?: moment.Moment | undefined
  ): string {
    if (!date || !date.isValid()) {
      return '';
    }

    if (dateEnd) {
      return (
        moment(date).format('DD/MM/YYYY') +
        ' - ' +
        moment(dateEnd).format('DD/MM/YYYY')
      );
    }

    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  formatParticipant(participant: Participant): string {
    return (
      participant.firstname +
      ' ' +
      (participant.lastname ? participant.lastname : '') +
      ' · ' +
      participant.age +
      ' ans'
    );
  }

  containsRoles(rolesToHave: string[]): boolean {
    return (
      rolesToHave.filter((role) => this.roles.indexOf(role) !== -1).length > 0
    );
  }
}
