import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Carousel } from '../shared/models/carousel';
import { Page } from '../shared/models/page';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CarouselService
  extends RepositoryService
  implements PageableService<Carousel>, CrudService<Carousel>
{
  private URL_CMS = 'api/backoffice/cms/carousel';

  constructor(http: HttpClient) {
    super(http);
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<Carousel>> {
    return this.get(
      this.URL_CMS +
        `/all?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map((page: Page<Carousel>) => new Page<Carousel>({ ...page }, Carousel))
    );
  }

  getAllByZoneId(zoneId: number): Observable<Carousel[]> {
    return this.get(this.URL_CMS + `/zone?zoneId=${zoneId}`).pipe(
      map((carousels: Carousel[]) => carousels.map((c) => new Carousel(c)))
    );
  }

  upsert(carouselProduct: Carousel): Observable<void> {
    return this.post(this.URL_CMS, carouselProduct);
  }

  deleteById(id: number): Observable<void> {
    return this.delete(this.URL_CMS + `?id=${id}`);
  }

  updateCarouselsOrder(carousels: Carousel[]): Observable<void> {
    return this.post(this.URL_CMS + '/update-order', carousels);
  }
}
