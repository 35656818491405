import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import moment from 'moment';
import { ElibertyCatalogService } from '../../../../service/eliberty-catalog.service';
import { EditComponent } from '../../../../shared/component/edit.component';
import { CatalogPriceReduction } from '../../../../shared/models/eliberty/catalog-price-reduction';

export interface CatalogPriceReductionForm {
  startDate: FormControl<string | null>;
  endDate: FormControl<string | null>;
  reduction: FormControl<number | null>;
}

@Component({
  selector: 'dash-edit-catalog-price-reduction',
  templateUrl: './edit-catalog-price-reduction.component.html',
  styleUrls: ['./edit-catalog-price-reduction.component.scss']
})
export class EditCatalogPriceReductionComponent extends EditComponent<CatalogPriceReduction> {
  catalogPriceReductionForm: FormGroup<CatalogPriceReductionForm>;
  startDateForm = new FormControl<string>('', Validators.required);
  endDateForm = new FormControl<string>('', Validators.required);
  reductionForm = new FormControl<number | null>(null, Validators.required);

  @Input() catalog: number;
  @Input() set entityToEdit(entity: CatalogPriceReduction | undefined) {
    if (entity) {
      this.id = entity.id;
      this.startDateForm.setValue(entity.startDate.format('DD/MM/YYYY'));
      this.endDateForm.setValue(entity.endDate.format('DD/MM/YYYY'));
      this.reductionForm.setValue(entity.reduction);
      this.editMode = 'edit';
    }
  }

  constructor(
    protected service: ElibertyCatalogService,
    protected store: Store
  ) {
    super(service, store);
    this.name = 'Remise prix catalogue';
  }

  initForm(): void {
    this.catalogPriceReductionForm = new FormGroup<CatalogPriceReductionForm>({
      startDate: this.startDateForm,
      endDate: this.endDateForm,
      reduction: this.reductionForm
    });
  }

  buildEntity(): CatalogPriceReduction {
    return new CatalogPriceReduction({
      id: this.id as number,
      catalog: this.catalog,
      startDate: moment(this.startDateForm.value, 'DD/MM/YYYY'),
      endDate: moment(this.endDateForm.value, 'DD/MM/YYYY'),
      reduction: this.reductionForm.value as number
    });
  }

  isFormValid(): boolean {
    return this.catalogPriceReductionForm.valid;
  }
}
