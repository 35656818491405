import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Product } from '../shared/models/eliberty/product';
import { ProductPricePromotion } from '../shared/models/eliberty/product-price-promotion';
import { Page } from '../shared/models/page';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ElibertyProductService
  extends RepositoryService
  implements
    PageableService<ProductPricePromotion>,
    CrudService<ProductPricePromotion>
{
  private URL = 'api/backoffice/eliberty';

  getPageable(
    currentPage: number,
    pageSize: number,
    catalogId: number
  ): Observable<Page<ProductPricePromotion>> {
    return this.get(
      this.URL +
        `/pageable-product-price-reduction?page=${currentPage}&size=${pageSize}&catalogId=${catalogId}`
    ).pipe(
      map(
        (page: Page<ProductPricePromotion>) =>
          new Page<ProductPricePromotion>({ ...page }, ProductPricePromotion)
      )
    );
  }

  getAllProductsByCatalogId(catalogId: number): Observable<Product[]> {
    return this.get(
      this.URL + `/all-products-catalog?catalogId=${catalogId}`
    ).pipe(
      map((products) =>
        products.map((product: Product) => new Product({ ...product }))
      )
    );
  }

  upsert(entity: ProductPricePromotion): Observable<void> {
    return this.post(this.URL + '/upsert-product-price-reduction', entity);
  }

  deleteById(id: number): Observable<void> {
    return this.delete(this.URL + `/product-price-reduction?id=${id}`);
  }
}
