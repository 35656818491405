import { NgIf } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { Select } from '@ngxs/store';
import { OptionElement, PopupComponent } from 'atomic-lib';
import { Observable } from 'rxjs';
import { AppState } from '../../../app.state';
import { CredentialsSkiPassService } from '../../../service/credentials-ski-pass.service';
import { WebsocketService } from '../../../service/websocket.service';
import { PageComponent } from '../../../shared/component/page.component';
import { CredentialsSkiPass } from '../../../shared/models/resort/credentials-ski-pass';
import { UserSession } from '../../../shared/models/user-session';
import { EditCredentialsComponent } from './edit-credentials/edit-credentials.component';
import { ListCredentialsComponent } from './list-credentials/list-credentials.component';

@Component({
  selector: 'dash-eliberty-credentials',
  standalone: true,
  imports: [
    NgIf,
    ListCredentialsComponent,
    EditCredentialsComponent,
    PopupComponent
  ],
  templateUrl: './eliberty-credentials.component.html',
  styleUrl: './eliberty-credentials.component.scss'
})
export class ElibertyCredentialsComponent
  extends PageComponent<CredentialsSkiPass>
  implements AfterViewChecked
{
  @ViewChild('logArea') logArea: ElementRef<HTMLTextAreaElement>;
  @Select(AppState.user) user$: Observable<UserSession>;

  @Input() resorts: OptionElement<number>[] = [];

  rapportMessagesImport: string[] = [];

  constructor(
    credentialsSkiPassService: CredentialsSkiPassService,
    private websocketService: WebsocketService
  ) {
    super(credentialsSkiPassService);

    this.page.pageable.pageSize = 12;

    this.websocketService.elibertyTopic.subscribe((message) => {
      if (!this.showEditor) {
        this.rapportMessagesImport.push(message);
      }
    });
  }

  ngAfterViewChecked(): void {
    if (this.logArea) {
      this.scrollToBottom();
    }
  }

  scrollToBottom(): void {
    const logArea = this.logArea.nativeElement;
    logArea.scrollTop = logArea.scrollHeight;
  }
}
