<atc-side-drawer (closeChange)="editionComplete.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading">
  <div class="w-full my-4" header>
    <h1
      class="text-center">{{ editMode === 'edit' ? 'Modification grille de tarifs' : 'Nouvelle grille de tarifs' }}</h1>
  </div>

  <div body class="vsk__body">
    <div class="flex flex-row gap-x-4">
      <atc-input [control]="nameForm"
                 label="Nom de la grille"
                 placeholder="Cours du matin"></atc-input>

      <atc-input *ngIf="priceCategoryForm.value"
                 [control]="priceCategoryForm"
                 label="Price category (ELiberty)"
                 placeholder="Cours du matin"></atc-input>
    </div>

    <!-- Experience -->
    <atc-select [control]="experienceForm"
                [optionElements]="experiencesOptions"
                class="flex-1"
                label="Choisir une expérience">
    </atc-select>
  </div>

  <div class="flex flex-row justify-between w-full items-center pt-4" footer>
    <atc-button (click)="deleteById()" *ngIf="editMode === 'edit'" color="danger" label="Supprimer"></atc-button>

    <atc-button (click)="create()"
                [disabled]="!isFormValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"

                id="id__button-validate-form">
    </atc-button>
  </div>
</atc-side-drawer>
