import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  ButtonComponent,
  InputComponent,
  OptionElement,
  SelectComponent,
  SideDrawerComponent
} from 'atomic-lib';
import { CredentialsSkiPassService } from '../../../../service/credentials-ski-pass.service';
import { WebsocketService } from '../../../../service/websocket.service';
import { EditComponent } from '../../../../shared/component/edit.component';
import { OriginEnum } from '../../../../shared/models/enum/origin.enum';
import { ResortMin } from '../../../../shared/models/resort-min';
import { CredentialsSkiPass } from '../../../../shared/models/resort/credentials-ski-pass';

export interface CredentialsSkiPassForm {
  clientId: FormControl<string | null>;
  clientSecret: FormControl<string | null>;
  url: FormControl<string | null>;
  partnerVeryMountain: FormControl<number | null>;
}

@Component({
  selector: 'dash-edit-credentials',
  standalone: true,
  imports: [
    ButtonComponent,
    SideDrawerComponent,
    InputComponent,
    NgIf,
    SelectComponent
  ],
  templateUrl: './edit-credentials.component.html',
  styleUrl: './edit-credentials.component.scss'
})
export class EditCredentialsComponent extends EditComponent<CredentialsSkiPass> {
  error = '';

  // ELiberty
  clientIdForm = new FormControl<string | null>(null, Validators.required);
  clientSecretForm = new FormControl<string | null>(null, Validators.required);
  urlForm = new FormControl<string | null>(null, Validators.required);
  partnerVeryMountainForm = new FormControl<number | null>(
    null,
    Validators.required
  );
  partnerELibertyForm = new FormControl<number | null>(null);
  resortForm: FormControl<number | null> = new FormControl<number | null>(
    null,
    Validators.required
  );
  credentialsForm: FormGroup<CredentialsSkiPassForm>;

  @Input() resorts: OptionElement<number>[] = [];

  constructor(
    protected credentialsSkiPassService: CredentialsSkiPassService,
    private websocketService: WebsocketService,
    protected store: Store
  ) {
    super(credentialsSkiPassService, store);
    this.name = 'Connexion API ELiberty';

    this.websocketService.elibertyTopic.subscribe(
      (message) => (this.error = message)
    );
  }

  @Input() set entityToEdit(entity: CredentialsSkiPass | undefined) {
    if (entity) {
      this.partnerVeryMountainForm.setValue(entity.partnerVeryMountain);
      this.clientIdForm.setValue(entity.clientId);
      this.clientSecretForm.setValue(entity.clientSecret);
      this.urlForm.setValue(entity.url);
      this.partnerELibertyForm.setValue(entity.partner);
      this.resortForm.setValue(entity.resort.id);

      this.editMode = 'edit';
    }
  }

  public upsert(entity: CredentialsSkiPass) {
    super.upsert(entity);
  }

  public isFormValid(): boolean {
    return this.credentialsForm.valid && this.resortForm.valid;
  }

  public buildEntity(): CredentialsSkiPass {
    return new CredentialsSkiPass({
      ...(this.credentialsForm.getRawValue() as any),
      partner: this.partnerELibertyForm.value,
      origin: OriginEnum.ELIBERTY,
      resort: new ResortMin({
        id: this.resortForm.value as number
      })
    });
  }

  public initForm(): void {
    this.credentialsForm = new FormGroup<CredentialsSkiPassForm>({
      clientId: this.clientIdForm,
      clientSecret: this.clientSecretForm,
      partnerVeryMountain: this.partnerVeryMountainForm,
      url: this.urlForm
    });
  }
}
