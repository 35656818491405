import { Component } from '@angular/core';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { Variant } from '../../../shared/models/eliberty/variant';

@Component({
  selector: 'dash-list-variant',
  templateUrl: './list-variant.component.html',
  styleUrls: ['./list-variant.component.scss']
})
export class ListVariantComponent extends PageableComponent<Variant> {
  headers = ['Id', 'Sku', "Tranche d'âge", 'Âge min', 'Âge max'];
}
