import moment, { Moment } from 'moment';

export class OrderData {
  date: Moment;
  price: number;

  constructor(obj: Partial<OrderData>) {
    Object.assign(this, obj);

    if (this.date) {
      this.date = moment(this.date, 'YYYY-MM-DD');
    }
  }
}
