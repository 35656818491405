<div class="vsk__column">
  <dash-list-promo-code
    [searchForm]="searchForm"
    [page]="page"
    (pageChanged)="fetchPageable($event, searchForm.value)"
    (createChanged)="showEdit()"
    (isChanged)="edit($event)"
    (isDeleted)="delete()">
  </dash-list-promo-code>
  <dash-edit-promo-code
    id="form-edit-activity-partner"
    *ngIf="showEditor"
    [entityToEdit]="toEdit"
    (editionComplete)="editCompleted()">
  </dash-edit-promo-code>
</div>
