export class CancelOrderItem {
  itemId: string;
  itemType: string;
  skiequipmentId: number;
  cancelled: boolean;

  constructor(obj: Partial<CancelOrderItem>) {
    Object.assign(this, obj);
  }
}
