<dash-list-edit id="id__table-list-variants"
                paginationId="variants"
                [headers]="headers"
                (paginateEvent)="onTableDataChange($event)"
                title="Variants">
  <ng-container body *ngFor="let variant of page.content | paginate : {
                id: 'products',
                itemsPerPage: page.pageable.pageSize,
                currentPage: page.pageable.pageNumber + 1,
                totalItems: page.totalElements }">
    <tr (click)="edit(variant)">
      <td>{{ variant.id }}</td>
      <td>{{ variant.sku }}</td>
      <td>{{ variant.consumerCategory.name }}</td>
      <td>{{ variant.consumerCategory.ageMin }}</td>
      <td>{{ variant.consumerCategory.ageMax }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
