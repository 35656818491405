import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import moment from 'moment';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ImageUrl } from '../../../activity/experience-management/experience/edit-experience/edit-experience.component';
import { TriggerAlert } from '../../../app.action';
import { MapsResource } from '../../../resource/maps.resource';
import { ImagesService } from '../../../service/images.service';
import { RegionService } from '../../../service/region.service';
import { ResortService } from '../../../service/resort.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { Picture } from '../../../shared/models/accomodation/picture';
import { ResortMaeva } from '../../../shared/models/accomodation/resort-maeva';
import { Alert } from '../../../shared/models/alert';
import { SeasonEnum } from '../../../shared/models/enum/season.enum';
import { ResortLabel } from '../../../shared/models/resort-label';
import { Resort } from '../../../shared/models/resort/resort';
import { StringUtils } from '../../../utils/string-utils';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'dash-edit-resort',
  templateUrl: './edit-resort.component.html',
  styleUrls: ['./edit-resort.component.scss']
})
export class EditResortComponent extends RxjsComponent {
  resortId: number | undefined;
  loading = false;
  editMode: 'create' | 'edit' = 'create';
  // Photo
  winterCoverResortFirebase: string[] = [];
  winterCoverResort: HTMLImageElement[] = [];
  fileWinterResort: File[] = [];
  // Photo
  summerCoverResortFirebase: string[] = [];
  summerCoverResort: HTMLImageElement[] = [];
  fileSummerResort: File[] = [];
  // Logo
  logoResortFirebase: string[] = [];
  logoResort: HTMLImageElement[] = [];
  fileLogoResort: File[] = [];
  regions: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une région',
      disabled: true,
      classCss: 'disabled'
    }
  ];
  resortsMaevaOptions: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une ou plusieurs stations',
      disabled: true,
      classCss: 'disabled'
    }
  ];
  resortLabelsOptions: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir un ou plusieurs labels',
      disabled: true,
      classCss: 'disabled'
    }
  ];

  // Form
  nameForm = new UntypedFormControl('', Validators.required);
  regionForm = new FormControl<number>(0, Validators.required);
  addressForm = new UntypedFormControl('');
  latitudeForm = new UntypedFormControl('', Validators.required);
  longitudeForm = new UntypedFormControl('', Validators.required);
  totalSlopesForm = new FormControl<number | null>(null);
  greenSlopesForm = new FormControl<number | null>(null);
  blueSlopesForm = new FormControl<number | null>(null);
  redSlopesForm = new FormControl<number | null>(null);
  blackSlopesForm = new FormControl<number | null>(null);
  skiSlopesKmForm = new FormControl<number | null>(null);
  nordicSkiKmForm = new FormControl<number | null>(null);
  hikingKmForm = new FormControl<number | null>(null);
  bikeKmForm = new FormControl<number | null>(null);
  bikeParkForm = new FormControl<boolean>(false);
  batheForm = new FormControl<boolean>(false);
  altitudeLowestForm = new FormControl<number | null>(null);
  altitudeHighestForm = new FormControl<number | null>(null);
  priceSkiPassForm = new FormControl<string>('');
  skisetIdsForm = new FormControl('', [Validators.pattern(/^\d+(,\d+)*$/)]);
  axessForm = new FormControl<boolean>(true);
  skidataForm = new FormControl<boolean>(false);
  descriptionWinterForm = new UntypedFormControl('');
  descriptionSummerForm = new UntypedFormControl('');
  enabledForm = new FormControl<boolean>(true, Validators.required);
  withAccommodationForm = new FormControl<boolean | null>(false);
  openingWinterForm = new FormControl<string | null>(null, Validators.required);
  closingWinterForm = new FormControl<string | null>(null, Validators.required);
  openingSummerForm = new FormControl<string | null>(null, Validators.required);
  closingSummerForm = new FormControl<string | null>(null, Validators.required);

  createResortForm: UntypedFormGroup;

  @Output() editionComplete = new EventEmitter<void>();

  protected readonly SeasonEnum = SeasonEnum;

  constructor(
    private imagesService: ImagesService,
    private store: Store,
    private mapsResource: MapsResource,
    private resortService: ResortService,
    private regionService: RegionService
  ) {
    super();

    this.createResortForm = new UntypedFormGroup({
      region: this.regionForm,
      name: this.nameForm,
      address: this.addressForm,
      latitude: this.latitudeForm,
      longitude: this.longitudeForm,
      totalSlopes: this.totalSlopesForm,
      altitudeLowest: this.altitudeLowestForm,
      altitudeHighest: this.altitudeHighestForm,
      skisetIds: this.skisetIdsForm,
      axess: this.axessForm,
      skidata: this.skidataForm,
      descriptionWinter: this.descriptionWinterForm,
      descriptionSummer: this.descriptionSummerForm,
      enabled: this.enabledForm,
      priceSkiPass: this.priceSkiPassForm,
      withAccommodation: this.withAccommodationForm,
      greenSlopes: this.greenSlopesForm,
      blueSlopes: this.blueSlopesForm,
      redSlopes: this.redSlopesForm,
      blackSlopes: this.blackSlopesForm,
      skiSlopesKm: this.skiSlopesKmForm,
      nordicSkiKm: this.nordicSkiKmForm,
      hikingKm: this.hikingKmForm,
      bikeKm: this.bikeKmForm,
      bikePark: this.bikeParkForm,
      bathe: this.batheForm,
      openingWinter: this.openingWinterForm,
      openingSummer: this.openingSummerForm,
      closingWinter: this.closingWinterForm,
      closingSummer: this.closingSummerForm
    });

    this.regionService.getRegions().subscribe((regions) => {
      this.regions = [
        ...this.regions,
        ...regions.map((region) => {
          return {
            id: region.id,
            label: region.name
          };
        })
      ];
    });
  }

  @Input() set resortToEdit(value: Resort | undefined) {
    if (value) {
      this.resortId = value.id;
      this.regionForm.setValue(value.region);
      this.nameForm.setValue(value.name);
      this.latitudeForm.setValue(value.latitude);
      this.longitudeForm.setValue(value.longitude);
      this.totalSlopesForm.setValue(value.totalSlopes);
      this.altitudeLowestForm.setValue(value.altitudeLowest);
      this.altitudeHighestForm.setValue(value.altitudeHighest);
      this.axessForm.setValue(value.axess);
      this.skidataForm.setValue(value.skidata);
      this.descriptionWinterForm.setValue(value.descriptionWinter);
      this.descriptionSummerForm.setValue(value.descriptionSummer);
      this.greenSlopesForm.setValue(value.greenSlopes);
      this.blueSlopesForm.setValue(value.blueSlopes);
      this.redSlopesForm.setValue(value.redSlopes);
      this.blackSlopesForm.setValue(value.blackSlopes);
      this.skiSlopesKmForm.setValue(value.skiSlopesKm);
      this.nordicSkiKmForm.setValue(value.nordicSkiKm);
      this.hikingKmForm.setValue(value.hikingKm);
      this.bikeKmForm.setValue(value.bikeKm);
      this.bikeParkForm.setValue(value.bikePark);
      this.batheForm.setValue(value.bathe);
      this.enabledForm.setValue(value.enabled);
      this.priceSkiPassForm.setValue(value.priceSkiPass.toString());
      this.withAccommodationForm.setValue(value.withAccommodation);
      this.openingWinterForm.setValue(
        value.openingWinter?.format('DD/MM/YYYY') || null
      );
      this.openingSummerForm.setValue(
        value.openingSummer?.format('DD/MM/YYYY') || null
      );
      this.closingWinterForm.setValue(
        value.closingWinter?.format('DD/MM/YYYY') || null
      );
      this.closingSummerForm.setValue(
        value.closingSummer?.format('DD/MM/YYYY') || null
      );

      // Pictures
      this.loadPhotos(value);

      // Skiset
      const skisetIdsAsNumbers = value.skisetIds
        .map((skisetId) => skisetId.toString())
        .join(',');
      this.skisetIdsForm.setValue(skisetIdsAsNumbers);

      // Maeva
      this.resortsMaevaOptions = this.resortsMaevaOptions.map((resort) => {
        resort.control?.setValue(
          value.resortsMaeva.map((rm) => rm.id).indexOf(resort.id as number) !==
            -1
        );
        return {
          ...resort
        };
      });

      // Labels
      this.resortLabelsOptions = this.resortLabelsOptions.map((resortLabel) => {
        resortLabel.control?.setValue(
          value.labels.map((rm) => rm.id).indexOf(resortLabel.id as number) !==
            -1
        );
        return {
          ...resortLabel
        };
      });

      this.editMode = 'edit';
    }
  }

  @Input() set resortsMaeva(resorts: ResortMaeva[] | null) {
    if (resorts?.length) {
      this.resortsMaevaOptions = [
        ...this.resortsMaevaOptions,
        ...resorts.map((maevaResort) => {
          return {
            id: maevaResort.id,
            label: maevaResort.label,
            control: new FormControl<boolean | null>(false)
          };
        })
      ];
    }
  }

  @Input() set resortLabels(resortLabels: ResortLabel[] | null) {
    if (resortLabels?.length) {
      this.resortLabelsOptions = [
        ...this.resortLabelsOptions,
        ...resortLabels.map((resortLabel) => {
          return {
            id: resortLabel.id,
            label: resortLabel.name,
            control: new FormControl<boolean | null>(false)
          };
        })
      ];
    }
  }

  photosValidity(): boolean {
    if (this.editMode == 'create') {
      return !!this.fileWinterResort.length && !!this.fileLogoResort;
    }

    return (
      (!!this.fileWinterResort.length || !!this.winterCoverResort.length) &&
      (!!this.fileSummerResort.length || !!this.summerCoverResort.length) &&
      (!!this.fileLogoResort.length || !!this.logoResort.length)
    );
  }

  isFormValid(): boolean {
    const invalid = [];
    const controls = this.createResortForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }

    if (invalid.length) {
      console.error(invalid);
    }

    return this.createResortForm.valid && this.photosValidity();
  }

  listUpdated(imgs: HTMLImageElement[], season: SeasonEnum) {
    switch (season) {
      case SeasonEnum.WINTER:
        this.winterCoverResort = imgs;
        break;

      case SeasonEnum.SUMMER:
        this.summerCoverResort = imgs;
        break;
    }
  }

  addPhotoFile(files: File[], season: SeasonEnum) {
    if (season === SeasonEnum.WINTER) {
      this.fileWinterResort.push(...files);
    } else {
      this.fileSummerResort.push(...files);
    }
  }

  create(): void {
    if (this.isFormValid()) {
      this.loading = true;

      const resortsMaevaSelected = this.resortsMaevaOptions
        .filter((element) => !!element.control?.value)
        .map(
          (option) =>
            new ResortMaeva({
              id: option.id as number,
              label: option.label
            })
        );

      const labelsSelected = this.resortLabelsOptions
        .filter((element) => !!element.control?.value)
        .map(
          (option) =>
            new ResortLabel({
              id: option.id as number,
              name: option.label
            })
        );

      const coverWinter$ = this.uploadPictures(
        this.fileWinterResort,
        this.winterCoverResort,
        SeasonEnum.WINTER
      );
      const coverSummer$ = this.uploadPictures(
        this.fileSummerResort,
        this.summerCoverResort,
        SeasonEnum.SUMMER
      );

      const logo$ = this.fileLogoResort.length
        ? this.imagesService
            .uploadImage(
              this.fileLogoResort[0],
              `partner/${StringUtils.formatString(this.nameForm.value)}/logo/`,
              this.fileLogoResort[0].name
            )
            .pipe(catchError(this.catchErrorUpload.bind(this)))
        : of(this.logoResortFirebase[0]);

      forkJoin([coverWinter$, coverSummer$, logo$])
        .pipe(
          switchMap(([coverWinter, coverSummer, logo]) => {
            return this.resortService.upsertResort(
              new Resort({
                id: this.resortId,
                ...this.createResortForm.getRawValue(),
                resortsMaeva: resortsMaevaSelected,
                skisetIds: this.skisetIdsForm.value
                  ? this.skisetIdsForm.value
                      .split(',')
                      .map((skisetId) => Number(skisetId))
                  : [],
                urlsCover: [...coverWinter, ...coverSummer],
                urlLogo: logo,
                labels: labelsSelected,
                openingWinter: moment(
                  this.openingWinterForm.value,
                  'DD/MM/YYYY'
                ),
                closingWinter: moment(
                  this.openingSummerForm.value,
                  'DD/MM/YYYY'
                ),
                openingSummer: moment(
                  this.closingWinterForm.value,
                  'DD/MM/YYYY'
                ),
                closingSummer: moment(
                  this.closingSummerForm.value,
                  'DD/MM/YYYY'
                ),
                priceSkiPass: Number(
                  this.priceSkiPassForm.value
                    ?.replace(',', '.')
                    .replace(' €', '')
                    .replace(' ', '')
                )
              })
            );
          })
        )
        .subscribe(
          () => {
            this.loading = false;
            this.editionComplete.emit();

            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'success',
                  timeout: 2000,
                  message:
                    this.editMode === 'create'
                      ? 'Station créée avec succès'
                      : 'Station modifiée'
                })
              )
            );
          },
          (err: HttpErrorResponse) => {
            this.loading = false;

            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'error',
                  timeout: 5000,
                  message: err.error
                })
              )
            );
          }
        );
    }
  }

  deleteById() {
    if (this.resortId) {
      this.register(
        this.resortService.deleteById(this.resortId).subscribe(
          () => {
            this.loading = false;
            this.editionComplete.emit();

            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'success',
                  timeout: 2000,
                  message: 'Station supprimée avec succès'
                })
              )
            );
          },
          (err: HttpErrorResponse) => {
            this.loading = false;

            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'error',
                  timeout: 5000,
                  message: err.error
                })
              )
            );
          }
        )
      );
    }
  }

  geocodeAddress(address: PlaceResult): void {
    this.longitudeForm.setValue(address.geometry?.location?.lng());
    this.latitudeForm.setValue(address.geometry?.location?.lat());
    this.addressForm.setValue(address.formatted_address);
  }

  loadPhotos(resort: Resort): void {
    if (resort?.urlsCover?.length) {
      resort.urlsCover.forEach((pic, index) => {
        const image = new Image();
        image.srcset = pic.url;
        image.src = pic.url;
        switch (pic.season) {
          case SeasonEnum.WINTER:
            image.alt = `Image ${index} de couverture d'hiver de la station`;
            this.winterCoverResortFirebase.push(pic.url);
            this.winterCoverResort.push(image);
            break;
          case SeasonEnum.SUMMER:
            image.alt = `Image ${index} de couverture d'été de la station`;
            this.summerCoverResortFirebase.push(pic.url);
            this.summerCoverResort.push(image);
            break;
          default:
            break;
        }
      });
    }

    if (resort.urlLogo) {
      const image = new Image();
      image.srcset = resort.urlLogo;
      image.src = resort.urlLogo;
      image.alt = `Logo de la station`;

      this.logoResortFirebase = [resort.urlLogo];
      this.logoResort = [image];
    }
  }

  getLoader() {
    return this.mapsResource.mapsLoader();
  }

  uploadPictures(
    photos: File[],
    photoImages: HTMLImageElement[],
    season: SeasonEnum
  ): Observable<Picture[]> {
    if (!photos.length) {
      return of(
        photoImages.map(
          (img) => new Picture({ url: img.srcset, season: season })
        )
      );
    }
    return forkJoin(
      photos.map((file) =>
        this.imagesService
          .uploadImage(
            file,
            `partner/${StringUtils.formatString(this.nameForm.value)}/`,
            file.name
          )
          .pipe(
            map((url) => {
              return { name: file.name, url } as ImageUrl;
            })
          )
      )
    ).pipe(
      switchMap((imagesUrl) => {
        const pictures: Picture[] = photoImages.map((image) => {
          const found = imagesUrl.find((url) => url.name === image.alt);
          return found
            ? new Picture({ url: found.url, season: season })
            : new Picture({ url: image.srcset, season: season });
        });
        return of(pictures);
      })
    );
  }

  catchErrorUpload(err: any, caught: Observable<string>) {
    this.loading = false;

    this.store.dispatch(
      new TriggerAlert(
        new Alert({
          level: 'error',
          timeout: 5000,
          message: "Impossible d'uploader l'image : " + err
        })
      )
    );
    return caught;
  }
}
