import { Component } from '@angular/core';
import { CarouselZoneService } from 'src/app/service/carousel-zone.service';
import { CarouselService } from 'src/app/service/carousel.service';
import { PageComponent } from 'src/app/shared/component/page.component';
import { Carousel } from 'src/app/shared/models/carousel';
import { CarouselZone } from 'src/app/shared/models/carousel-zone';
import { Page } from 'src/app/shared/models/page';

@Component({
  selector: 'dash-carousel-zone',
  templateUrl: './carousel-zone.component.html',
  styleUrls: ['./carousel-zone.component.scss']
})
export class CarouselZoneComponent extends PageComponent<CarouselZone> {
  carousels: Carousel[] = [];

  constructor(
    protected service: CarouselZoneService,
    private carouselService: CarouselService
  ) {
    super(service);
    this.register(
      this.carouselService
        .getPageable(1, 10, '')
        .subscribe((page: Page<Carousel>) => (this.carousels = page.content))
    );
  }

  delete() {}
}
