import { Component } from '@angular/core';
import { AccountService } from '../../service/account.service';
import { PageComponent } from '../../shared/component/page.component';
import { User } from '../../shared/models/user';

@Component({
  selector: 'dash-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends PageComponent<User> {
  constructor(accountService: AccountService) {
    super(accountService);
    this.page.pageable.pageSize = 12;
  }
}
