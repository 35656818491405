<atc-popup (closeChanged)="closeChanged.emit()"
           [width]="'800px'"
           [withImg]="false"
>
  <div header>
    <h1 class="ml-4">Demande de changement de créneau</h1>
  </div>
  <div body>
    <div class="block-select flex flex-row items-center relative py-4 ml-37">
      <div class="mr-10 space-y-2">
        <div class="color-neutral-70">Creneau initial :</div>
        <div class="flex flex-row">
          <div class="color-secondary ordinary-text">{{ reservationModifyPopUp.activityName }}</div>
          <div class="color-neutral-70 ordinary-text ml-1">{{ formatExperienceName() }}</div>
        </div>
        <div class="neutral-color ordinary-text">{{ formatHourStartHourEnd() }}</div>
        <div class="neutral-color ordinary-text">{{ formatDateStart(reservationModifyPopUp.dateStart) }}</div>
      </div>

      <div class="flex flex-col space-y-2">
        <div class="color-neutral-70 ordinary-text">{{ formatFirstNameUserPhone() }}</div>
        <div class="neutral-color ordinary-text">Nombre de personnes
          : {{ reservationModifyPopUp.participants.length }}
        </div>
      </div>
    </div>

    <div class="border-t border-#69608A py-4 relative ml-8  w-90"></div>

    <div class="block-select relative left-43 w-90">
      <h2 class="mb-2">1. Choisissez un ou plusieurs nouveau(x) créneau(x) :</h2>
      <div *ngIf="timeSlots && timeSlots.length" class="grid grid-cols-3 gap-2 py-2 w-70">
        <atc-button-select (selectedChanged)="timeSlotSelectedChange(i,timeSlots)"
                           *ngFor="let timeSlot of timeSlots;let i = index"
                           [currentNumberSelected]="howManySelected(timeSlots)"
                           [label]="getLabelTimeSlot(timeSlot)"
                           [multiple]="true"
                           class="flex">
        </atc-button-select>
      </div>
    </div>

    <div class="vsk__inline">
      <div class="vsk__flex"></div>
      <atc-button (click)="sendMessage(timeSlots)"
                  [disabled]="!(howManySelected(timeSlots)>0)"

                  class="mr-4"
                  id="id__button-reason-deny-popup-add"
                  label="Envoyer la proposition">
      </atc-button>
    </div>

    <div *ngIf="loading" class="vsk__spinner-overlay">
      <atc-loader></atc-loader>
    </div>
  </div>
</atc-popup>
