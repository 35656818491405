import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ImportType } from '../admin/import-file/import-file.component';
import { ErrorsImport } from '../shared/models/import-file/errors-import';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ImportService extends RepositoryService {
  private URL_IMPORT = environment.urlAddress + '/api/admin/import';
  private URL_IMPORT_SHORT = 'api/admin/import';

  constructor(protected http: HttpClient) {
    super(http);
  }

  importFile(file: File, importType: ImportType): Observable<ErrorsImport> {
    const formData: FormData = new FormData();
    formData.append('file', file, importType.toString());

    const headersQuery = new HttpHeaders();
    headersQuery.append('Content-Type', 'multipart/form-data');
    headersQuery.append('Accept', 'application/json');
    headersQuery.append('Access-Control-Allow-Origin', '*');
    headersQuery.append('timeout', '20000');

    return this.http.post<ErrorsImport>(
      this.URL_IMPORT + '?importType=' + importType,
      formData,
      {
        headers: headersQuery,
        responseType: 'json'
      }
    );
  }

  exportFile(importType: ImportType, filterId?: number) {
    return this.get(
      this.URL_IMPORT_SHORT +
        `?importType=${importType}&filterId=${filterId || ''}`,
      {},
      'blob'
    ).pipe(
      tap((data: Blob) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(data);
        downloadLink.setAttribute('download', importType.toString());
        document.body.appendChild(downloadLink);
        downloadLink.click();
      })
    );
  }
}
