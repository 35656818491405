import { Component } from '@angular/core';
import { CarouselProductService } from 'src/app/service/carousel-product.service';
import { ResortService } from 'src/app/service/resort.service';
import { PageComponent } from 'src/app/shared/component/page.component';
import { CarouselProduct } from 'src/app/shared/models/carousel-product';
import { Resort } from '../../shared/models/resort/resort';

@Component({
  selector: 'dash-carousel-product',
  templateUrl: './carousel-product.component.html',
  styleUrls: ['./carousel-product.component.scss']
})
export class CarouselProductComponent extends PageComponent<CarouselProduct> {
  stations: Resort[] = [];

  constructor(
    protected service: CarouselProductService,
    private resortService: ResortService
  ) {
    super(service);
    this.register(
      this.resortService
        .getAllStations()
        .subscribe((stations: Resort[]) => (this.stations = stations))
    );
  }

  delete() {}
}
