import { Component } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DisconnectUser } from '../app.action';
import { AppState } from '../app.state';
import { Role } from '../shared/models/enum/Role';

@Component({
  selector: 'dash-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent {
  @Select(AppState.roles) roles$: Observable<string[]>;
  roles: string[] = [];
  page = '';

  constructor(
    private store: Store,
    private router: Router
  ) {
    this.roles$.subscribe((roles) => (this.roles = roles));
    this.router.events.subscribe((val) => {
      if (val instanceof RouterEvent) {
        this.page = val.url.split('/')[1];
      }
    });
  }

  containsRoles(rolesToHave: string[]): boolean {
    return (
      rolesToHave.filter((role) => this.roles.indexOf(role) !== -1).length > 0
    );
  }

  logout(): void {
    this.store.dispatch(new DisconnectUser());
  }

  protected readonly Role = Role;
}
