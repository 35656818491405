<div class="vsk__column">
  <dash-list-carousel
    [searchForm]="searchForm"
    [carouselZones]="carouselZones"
    [page]="page"
    (pageChanged)="fetchPageable($event, searchForm.value)"
    (createChanged)="showEdit()"
    (isChanged)="edit($event)"
    (isDeleted)="delete()">
  </dash-list-carousel>
  <dash-edit-carousel
    id="form-edit-carousel"
    *ngIf="showEditor"
    [carouselZones]="carouselZones"
    [entityToEdit]="toEdit"
    (editionComplete)="editCompleted()">
  </dash-edit-carousel>
</div>
