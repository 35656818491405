<dash-list-edit id="id__table-list-experience-group"
                paginationId="experienceGroup"
                [headers]="headers"
                [searchForm]="searchForm"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                labelCreate="Ajouter un groupement d'expériences"
                searchLabel="Recherche de groupement"
                searchPlaceholder="Rechercher par nom ..."
                title="Groupement d'expériences">
  <ng-container body *ngFor="let experienceGroup of page.content | paginate : {
                  id: 'experienceGroup',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(experienceGroup)">
      <td *ngIf="roles$ | async | isAdmin">{{ experienceGroup.id }}</td>
      <td>{{ experienceGroup.name }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
