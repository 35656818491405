import { AgeRange } from '../age-range';
import { Experience } from '../experience';
import { PackageGrid } from '../package-grid';
import { PackagePrice } from './package-price';

export class Package {
  id: number;
  prices: PackagePrice[];
  ageRange: AgeRange;
  packageGrid: PackageGrid;
  experience: Experience;
  enabled: boolean;
  hasErrors: boolean;

  constructor(obj: Partial<Package>) {
    Object.assign(this, obj);
    this.ageRange = new AgeRange({ ...this.ageRange });
    this.experience = new Experience({ ...this.experience });

    if (this.packageGrid) {
      this.packageGrid = new PackageGrid({ ...this.packageGrid });
    }

    if (this.prices) {
      this.prices = this.prices.map(
        (pckPrice) => new PackagePrice({ ...pckPrice })
      );
    }
  }
}
