<dash-list-experience-group
  [searchForm]="searchForm"
  [page]="page"
  (pageChanged)="fetchPageable($event, searchForm.value)"
  (createChanged)="showEdit()"
  (isChanged)="edit($event)">
</dash-list-experience-group>

<dash-edit-experience-group
  id="form-edit-experience"
  *ngIf="showEditor"
  [experiences]="experiences"
  [entityToEdit]="toEdit"
  (editionComplete)="editCompleted()">
</dash-edit-experience-group>
