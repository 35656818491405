<dash-list-experience (createEventChanged)="showEdit()"
                      (duplicateOptionChanged)="setOptionToEdit($event)"
                      (editOptionChanged)="setOptionToEdit($event)"
                      [activityPartners]="activitiesPartner">
</dash-list-experience>

<dash-edit-experience (optionChanged)="optionChanged()"
                      *ngIf="showEditor"
                      [activitiesPartner]="activitiesPartner"
                      [customFieldsInput]="customFields"
                      [experience]="optionToEdit"
                      [tags]="tags">
</dash-edit-experience>
