import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { TriggerAlert } from '../../../app.action';
import { CustomFieldService } from '../../../service/custom-field.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { Alert } from '../../../shared/models/alert';
import { CustomField } from '../../../shared/models/custom-field';

interface CustomFieldForm {
  label: FormControl<string | null>;
  type: FormControl<string | null>;
}

@Component({
  selector: 'dash-edit-custom-field',
  templateUrl: './edit-custom-field.component.html',
  styleUrls: ['./edit-custom-field.component.scss']
})
export class EditCustomFieldComponent extends RxjsComponent {
  @Input() set customFieldToEdit(value: CustomField | undefined) {
    if (value) {
      this.customFieldId = value.id;
      this.labelForm.setValue(value.label);
      this.typeForm.setValue(value.type);
      this.editMode = 'edit';
    }
  }

  @Output() customFieldToEditComplete: EventEmitter<void> =
    new EventEmitter<void>();

  loading = false;
  editMode: 'create' | 'edit' = 'create';

  customFieldId: number | undefined;
  labelForm = new FormControl<string>('', Validators.required);
  typeForm = new FormControl<string>('string', Validators.required);

  customFieldFormGroup: FormGroup<CustomFieldForm>;

  constructor(
    private customFieldService: CustomFieldService,
    private store: Store
  ) {
    super();
    this.customFieldFormGroup = new FormGroup<CustomFieldForm>({
      label: this.labelForm,
      type: this.typeForm
    });
  }

  upsertCustomField(): void {
    if (this.customFieldFormGroup.valid) {
      this.loading = true;
      this.register(
        this.customFieldService
          .upsertCustomField(
            new CustomField({
              id: this.customFieldId,
              ...this.customFieldFormGroup.getRawValue()
            })
          )
          .subscribe(
            () => {
              this.loading = false;
              this.customFieldToEditComplete.emit();
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    message:
                      this.editMode === 'create'
                        ? 'Champ personalisé crée avec succcès'
                        : 'Champ personalisé modifié avec succcès',
                    level: 'success',
                    timeout: 2000
                  })
                )
              );
            },
            () => {
              this.loading = false;
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    message:
                      'Une erreur est survenue, veuillez réessayer ultérieurement ...',
                    level: 'error',
                    timeout: 5000
                  })
                )
              );
            }
          )
      );
    } else {
      this.store.dispatch(
        new TriggerAlert(
          new Alert({
            message: 'Nom de libellé',
            level: 'warning',
            timeout: 5000
          })
        )
      );
    }
  }

  delete(): void {
    this.register(
      this.customFieldService
        .deleteCustomField(this.customFieldId as number)
        .subscribe(() => this.customFieldToEditComplete.emit())
    );
  }
}
