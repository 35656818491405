import moment, { Moment } from 'moment';
import { OriginEnum } from '../enum/origin.enum';
import { Resort } from '../resort/resort';
import { AccommodationType } from './accommodation-type.enum';
import { Picture } from './picture';

export class AccommodationEstablishment {
  id: number;
  origin: OriginEnum;
  partnerCode: string;
  name: string;
  address: string;
  city: string;
  zip: string;
  email: string;
  country: string;
  phone1: string;
  phone2: string;
  openingDate: Moment;
  closingDate: Moment;
  descriptionSummer: string;
  descriptionWinter: string;
  accessSummer: string;
  accessWinter: string;
  facilitiesSummer: string;
  facilitiesWinter: string;
  informationSummer: string;
  informationWinter: string;
  openingTime: Moment;
  closingTime: Moment;
  pictureEntities: Picture[];
  resort: Resort;
  type: AccommodationType;
  pool: boolean;
  spa: boolean;
  sauna: boolean;
  hammam: boolean;
  balconyTerrace: boolean;
  tv: boolean;
  chimney: boolean;
  bbq: boolean;
  distanceEsf: number;
  distanceSlopes: number;
  distanceLifts: number;
  distanceNursery: number;
  distanceShops: number;
  pmr: boolean;
  parking: boolean;
  animalsAdmitted: boolean;
  wifi: boolean;
  childrenClub: boolean;

  constructor(obj: Partial<AccommodationEstablishment>) {
    Object.assign(this, obj);

    if (this.openingDate) {
      this.openingDate = moment(this.openingDate);
    }

    if (this.closingDate) {
      this.closingDate = moment(this.closingDate);
    }

    if (this.openingTime) {
      this.openingTime = moment(this.openingTime, 'HH:mm');
    }

    if (this.closingTime) {
      this.closingTime = moment(this.closingTime, 'HH:mm');
    }

    if (this.resort) {
      this.resort = new Resort({ ...this.resort });
    }
  }
}
