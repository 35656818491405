import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';

@Injectable({
  providedIn: 'root'
})
export class MapsResource {
  private loader: Promise<typeof google>;

  mapsLoader(): Promise<typeof google> {
    if (!this.loader) {
      this.loader = new Loader({
        apiKey: 'AIzaSyCGeV3rF6XT5_LJ1KGFypDGay5Tj_HAKt8',
        language: 'fr',
        libraries: ['places']
      }).load();
    }

    return this.loader;
  }
}
