import { DragElement } from 'atomic-lib';
import { Picture } from './accomodation/picture';
import { Activity } from './activity';
import { CustomField } from './custom-field';
import { OriginEnum } from './enum/origin.enum';
import { Package } from './package/package';
import { Tag } from './tag';
import { TimeSlot } from './time-slot/time-slot';

export class Experience implements DragElement {
  id: number | null;
  name: string;
  difficulty: string | null;
  duration: number;
  color: string;
  activity: Activity;
  phone: string;
  address: string;
  latitude: number;
  longitude: number;
  videoUrl: string | null;
  origin: OriginEnum;

  message: string;
  description: string;

  weLove: string;
  included: string;
  notIncluded: string;
  predictable: string;

  customFieldsIds: number[];
  customFields: CustomField[];
  packages: Package[];
  timeSlots: TimeSlot[];
  pictureEntities: Picture[];
  tags: Tag[];
  withAccommodation: boolean;
  heart: boolean;
  experienceCategory: string | null;
  minDaysBeforeBooking: number;

  constructor(obj: Partial<Experience>) {
    Object.assign(this, obj);
  }
}
