import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import {
  CalendarEvent,
  InternshipEvent,
  OptionElement,
  PeriodEvents
} from 'atomic-lib';
import moment, { Moment } from 'moment';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { InternshipSelect, TimeSlotSelect } from '../app.action';
import { AppState } from '../app.state';
import { ActivityService } from '../service/activity.service';
import { CustomerService } from '../service/customer.service';
import { InternshipService } from '../service/internship.service';
import { TimeSlotService } from '../service/time-slot.service';
import { RxjsComponent } from '../shared/component/rxjs.component';
import { Customer } from '../shared/models/customer';
import { Role } from '../shared/models/enum/Role';
import { Experience } from '../shared/models/experience';
import { Internship } from '../shared/models/time-slot/internship';
import { TimeSlot } from '../shared/models/time-slot/time-slot';

@Component({
  selector: 'dash-calendar-partner',
  templateUrl: './calendar-partner.component.html',
  styleUrls: ['./calendar-partner.component.scss']
})
export class CalendarPartnerComponent extends RxjsComponent {
  @Select(AppState.partnerId) partnerId$: Observable<number>;

  showEditTimeSlot = false;
  editTimeSlot = false;
  today: Moment = moment();
  loading = false;
  period: PeriodEvents;
  timeSlots: TimeSlot[] = [];
  internships: Internship[] = [];
  filterView: OptionElement<number>[] = [
    {
      id: null,
      label: 'Aucune activité'
    }
  ];
  filterForm: FormControl<number | null> = new FormControl<number | null>(null);
  customers: Customer[] = [];
  isDragged = false;

  constructor(
    private customerService: CustomerService,
    private activityService: ActivityService,
    private timeSlotService: TimeSlotService,
    private internshipService: InternshipService,
    private store: Store
  ) {
    super();

    this.period = {
      events: [],
      internships: [],
      dateStart: this.today.clone().startOf('week'),
      dateEnd: this.today.clone().endOf('week')
    };

    this.register(
      this.partnerId$
        .pipe(
          switchMap((id) =>
            this.customerService.getAllCustomersByPartnerId(id)
          ),
          tap((customers) => (this.customers = customers))
        )
        .subscribe()
    );

    this.register(
      this.partnerId$
        .pipe(
          switchMap((id) => this.activityService.getExperienceByPartnerId(id))
        )
        .subscribe((experiences) => {
          if (experiences?.length) {
            this.filterView = [
              ...this.filterView,
              ...experiences.map((experience, index) => {
                return {
                  id: experience.id,
                  label: experience.name,
                  tags: this.getTagsForExperience(experience),
                  control: new FormControl<boolean | null>({
                    value: index === 0,
                    disabled: false
                  })
                } as OptionElement<number>;
              })
            ];
            this.updateView(this.today);
          }
        })
    );

    this.filterForm.valueChanges.subscribe(() => this.updateView(this.today));
    this.updateView(this.today);
  }

  changeWeek(isNext: boolean): void {
    this.today = moment(this.today).add(isNext ? 7 : -7, 'days');
    this.updateView(this.today);
  }

  updateView(date: Moment): void {
    this.showEditTimeSlot = false;
    this.loading = true;
    this.today = date;

    const filteredExperiences: number[] = this.filterView
      .filter((element) => !!element.control?.value)
      .map((element) => element.id as number);

    const timeSlots$ = this.timeSlotService
      .getAllBetweenDates(
        this.store.selectSnapshot(AppState.partnerId) as number,
        date.clone().startOf('week'),
        date.clone().endOf('week'),
        filteredExperiences
      )
      .pipe(
        tap((timeSlots) => (this.timeSlots = timeSlots)),
        map((timeSlots) =>
          timeSlots.map((timeSlot) => timeSlot.toCalendarEvent(date.clone()))
        )
      );

    const internships$ = this.internshipService
      .getBetweenDates(
        this.store.selectSnapshot(AppState.partnerId) as number,
        date.clone().startOf('week'),
        date.clone().endOf('week'),
        filteredExperiences
      )
      .pipe(tap((internships) => (this.internships = internships)));

    this.register(
      combineLatest([timeSlots$, internships$]).subscribe(
        ([events, internships]) => {
          this.period = {
            events: events,
            internships: internships.map((internship) =>
              internship.toInternshipEvent()
            ),
            dateStart: this.today.clone().startOf('week'),
            dateEnd: this.today.clone().endOf('week')
          };

          this.loading = false;
        },
        () => (this.loading = false)
      )
    );
  }

  eventClicked(event: CalendarEvent): void {
    if (!this.isDragged) {
      const timeSlot = this.timeSlots.find(
        (ts) => ts.uuid === event.eventId
      ) as TimeSlot;

      this.store.dispatch(new TimeSlotSelect(timeSlot));
      this.editTimeSlot = true;
      this.showEditTimeSlot = true;
    }
  }

  internshipClicked(event: InternshipEvent): void {
    const internship = this.internships.find(
      (ts) => ts.id === event.eventId
    ) as Internship;

    this.store.dispatch(new InternshipSelect(internship));
    this.editTimeSlot = true;
    this.showEditTimeSlot = true;
  }

  addActivity(): void {
    this.editTimeSlot = false;
    this.showEditTimeSlot = true;
  }

  changeFilters() {
    this.updateView(this.today);
  }

  private getTagsForExperience(experience: Experience) {
    const user = this.store.selectSnapshot(AppState.roles);
    const tags = [
      {
        color: 'mandatory',
        label: experience.activity.name
      }
    ];

    if (!user.find((role) => role === Role.ROLE_ADMIN)) {
      return tags;
    }

    if (experience.origin === 'YOPLANNING') {
      tags.unshift({
        color: 'info',
        label: 'YoPlanning'
      });
      return tags;
    }

    if (experience.origin === 'ELIBERTY') {
      tags.unshift({
        color: 'info',
        label: 'ELiberty'
      });
      return tags;
    }

    tags.unshift({
      color: 'primary',
      label: 'VeryMountain'
    });
    return tags;
  }
}
