<div class="vsk__column">
   <dash-list-sales
    [searchForm]="searchForm"
    [page]="page"
    (pageChanged)="fetchPageable($event, searchForm.value)"
    (createChanged)="showCreateOrder = true"
    (isChanged)="edit($event)"
    (isDeleted)="delete()">
   </dash-list-sales>
   <dash-edit-sales
    id="form-edit-sales"
    *ngIf="showEditor"
    [entityToEdit]="toEdit"
    (editionComplete)="editCompleted()">
   </dash-edit-sales>
   <dash-create-order
    *ngIf="showCreateOrder"
    (editionComplete)="editCompletedOrder()">
   </dash-create-order>
</div>
