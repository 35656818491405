import moment, { Moment } from 'moment';

export class UserSession {
  jwt: string;
  refreshToken: string;
  id: number;
  email: string;
  roles: string[];
  birthdate: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.birthdate = moment(this.birthdate);
  }
}
