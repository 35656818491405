export class PartnerInformation {
  id: number;
  address: string;
  siret: string;
  iban: string;
  bic: string;
  cartePro: string;

  constructor(obj: Partial<PartnerInformation>) {
    Object.assign(this, obj);
  }
}
