import { RoomDetails } from './room-details';

export class AccommodationEstablishmentRoom {
  id: number;
  partnerCode: string | null;
  name: string;
  rooms: RoomDetails[];

  constructor(obj: Partial<AccommodationEstablishmentRoom>) {
    Object.assign(this, obj);

    if (this.rooms) {
      this.rooms = this.rooms.map((room) => new RoomDetails({ ...room }));
    }
  }
}
