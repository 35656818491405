import { Component } from '@angular/core';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { User } from '../../../shared/models/user';

@Component({
  selector: 'dash-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent extends PageableComponent<User> {
  headers = ['Id', 'Email', 'FullName', 'Activé', 'Roles'];
}
