import { ActivityPartner } from './activity-partner';

export class Partner {
  id: number;
  name: string;
  address: string;
  email: string;
  phoneNumber: string;
  user: number;
  activities: ActivityPartner[];
  secondPhone: string;
  commissionRate: number;
  externalId: string;
  societyName: string;
  reservationEmail: string;
  contractorId: number;
  siret: string;
  iban: string;
  bic: string;
  cartePro: string;
  enabled: boolean;
  review: number;
  nbReviews: number;

  constructor(obj: Partial<Partner>) {
    Object.assign(this, obj);
    if (this.activities) {
      this.activities = this.activities.map(
        (activity) => new ActivityPartner(activity)
      );
    }
  }
}
