import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { AppState } from '../app.state';
import { CustomerService } from '../service/customer.service';
import { Customer } from '../shared/models/customer';

@Component({
  selector: 'dash-customers-management',
  templateUrl: './customers-management.component.html',
  styleUrls: ['./customers-management.component.scss']
})
export class CustomersManagementComponent {
  @Select(AppState.partnerId) partnerId$: Observable<number>;

  headers = [
    'Nom',
    'Prénom',
    'Email',
    'Téléphone',
    'Adresse',
    'Code postal',
    'Ville',
    'Type',
    ''
  ];
  state: 'read' | 'create' = 'read';
  customerEdit: Customer | null = null;
  clients: Customer[] = [];
  clientsToShow: Customer[] = [];
  searchForm: UntypedFormControl = new UntypedFormControl('');

  constructor(private customerService: CustomerService) {
    this.loadCustomers();
    this.searchForm.valueChanges.pipe(debounceTime(200)).subscribe((value) => {
      if (value.length === 0) {
        this.clientsToShow = this.clients;
        return;
      }

      this.clientsToShow = this.clients.filter(
        (customer) =>
          customer.firstname.toLowerCase().indexOf(value.toLowerCase()) !==
            -1 ||
          customer.lastname.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          customer.nameEnterprise.toLowerCase().indexOf(value.toLowerCase()) !==
            -1
      );
    });
  }

  loadCustomers(): void {
    this.partnerId$
      .pipe(
        switchMap((id) => this.customerService.getAllCustomersByPartnerId(id)),
        tap((customers) => (this.clients = customers)),
        tap((customers) => (this.clientsToShow = customers))
      )
      .subscribe();
  }

  changeState(state: 'read' | 'create', reload: boolean): void {
    this.state = state;

    if (reload) {
      this.customerEdit = null;
      this.loadCustomers();
    }
  }

  editCustomer(customer: Customer): void {
    this.customerEdit = customer;
    this.state = 'create';
  }
}
