<div class="vsk__card gap-4">
  <h1 class="mb-4">Réservation d'une chambre</h1>

  <div class="flex-row flex w-full gap-4 items-center">
    <atc-input [control]="sessionForm"
               errorMessage="Champs obligatoire"
               placeholder="608162ff-b3ce-4e65-a221-91e94249b78e"
               class="flex flex-1"
               label="Session id*"></atc-input>

    <atc-input [control]="userForm"
               errorMessage="Champs obligatoire"
               placeholder="500"
               type="number"
               class="flex flex-1"
               label="Client de la réservation (id)*"></atc-input>

    <atc-input-checkbox [control]="createOrderForm">
      <label>Création d'une commande</label>
    </atc-input-checkbox>
  </div>

  <div class="flex-row flex w-full justify-end">
    <atc-button
      icon="office"
      label="Réserver"
      [disabled]="userForm.invalid || sessionForm.invalid || loading"
      (click)="book()">
    </atc-button>
  </div>
</div>
