import { Component } from '@angular/core';
import { Link } from 'src/app/shared/sub-menu/sub-menu.component';
import { Role } from '../../shared/models/enum/Role';

@Component({
  selector: 'dash-cms-menu',
  templateUrl: './cms-menu.component.html',
  styleUrls: ['./cms-menu.component.scss']
})
export class CmsMenuComponent {
  links: Link[] = [
    {
      roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING],
      page: 'Bloc de texte',
      route: '/cms/text-block',
      icon: 'document',
      id: 'id__link-text-block'
    },
    {
      roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING],
      page: 'Produit de carrousel',
      route: '/cms/carousel-product',
      icon: 'add',
      id: 'id__link-carousel-product'
    },
    {
      roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING],
      page: 'Carrousel',
      route: '/cms/carousel',
      icon: 'list',
      id: 'id__link-carousel'
    },
    {
      roles: [Role.ROLE_ADMIN, Role.ROLE_MARKETING],
      page: 'Zone de carrousel',
      route: '/cms/carousel-zone',
      icon: 'location',
      id: 'id__link-carousel-zone'
    }
  ];
}
