import { Moment } from 'moment/moment';

export class Invoice {
  sessionId: string;
  invoiceHeader: string;
  activityName: string;
  datePayed: Moment;
  totalTTC: number;
  totalDu: number;
  vat: string;

  constructor(obj: Partial<Invoice>) {
    Object.assign(this, obj);
  }
}
