import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionMaster } from '../shared/models/marketing/session-master';
import { Page } from '../shared/models/page';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class MarketingService
  extends RepositoryService
  implements PageableService<SessionMaster>, CrudService<SessionMaster>
{
  private URL_SESSION = 'api/admin/session';

  constructor(http: HttpClient) {
    super(http);
  }

  deleteById(id: number): Observable<void> {
    return this.delete(this.URL_SESSION + `?id=${id}`);
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<SessionMaster>> {
    return this.get(
      this.URL_SESSION +
        `/all-session-master?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map(
        (page: Page<SessionMaster>) =>
          new Page<SessionMaster>({ ...page }, SessionMaster)
      )
    );
  }

  upsert(sessionMaster: SessionMaster): Observable<void> {
    return this.post(this.URL_SESSION, sessionMaster);
  }
}
