<div class="vsk__column">
  <dash-list-activity-partner [activities]="activitiesPartner"
                              (createActivity)="showEdit()"
                              (activityPartnerChanged)="edit($event)"></dash-list-activity-partner>
  <dash-edit-activity-partner *ngIf="showEditor"
                              [stations]="stations"
                              [activities]="activities"
                              [activityPartnerToEdit]="activityPartnerToEdit"
                              (editionComplete)="activityPartnerChanged()">
  </dash-edit-activity-partner>
</div>
