import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { Observable } from 'rxjs';
import { ChangePartner } from 'src/app/app.action';
import { PartnerService } from 'src/app/service/partner.service';
import { UserSession } from 'src/app/shared/models/user-session';
import { AppState } from '../../app.state';
import { Role } from '../../shared/models/enum/Role';
import { Partner } from '../../shared/models/partner';

@Component({
  selector: 'dash-select-partner',
  templateUrl: './select-partner.component.html',
  styleUrls: ['./select-partner.component.scss']
})
export class SelectPartnerComponent {
  @Select(AppState.roles) roles$: Observable<string[]>;

  partners: Partner[] = [];
  partnerForm: FormControl<number | null> = new FormControl<number | null>(
    null,
    Validators.required
  );
  error = '';
  partnerOptions: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir un partenaire',
      disabled: true,
      classCss: 'disabled'
    }
  ];

  @Output() closeChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private store: Store,
    private partnerService: PartnerService
  ) {
    const isAdmin = this.store
      .selectSnapshot(AppState.roles)
      ?.find((role) => role === Role.ROLE_ADMIN);

    if (!isAdmin) {
      this.closeChanged.emit();
    }

    const user = this.store.selectSnapshot(AppState.user) as UserSession;

    this.partnerService.getInformation(user?.id).subscribe((partners) => {
      this.partners = partners;

      this.partnerOptions = [
        ...this.partnerOptions,
        ...partners.map((partner) => {
          return {
            id: partner.id,
            label: partner.name
          };
        })
      ];
    });

    this.partnerForm.valueChanges.subscribe((value) => {
      if (value) {
        const partner = this.partners.find(
          (part) => part.id === value
        ) as Partner;
        this.store.dispatch(new ChangePartner(partner));
        this.closeChanged.next();
      } else {
        this.error = 'Partenaire inconnu';
      }
    });
  }

  choosePartner(partner: OptionElement<number> | null): void {
    if (partner) {
      const partner = this.partners.find(
        (part) => part.id === partner?.id
      ) as Partner;
      this.store.dispatch(new ChangePartner(partner));
      this.closeChanged.next();
    } else {
      this.error = 'Partenaire inconnu';
    }
  }
}
