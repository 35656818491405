<div class="vsk__card w-full gap-y-4">
  <h1>Envoi du voucher Skiset au client</h1>
  <atc-input
    label="Numéro de commande skiset (dans Skiset Pro)"
    type="number"
    [control]="skisetOrderForm"
    [errorMessage]="skisetOrderForm.hasError('required') ? 'Ce champ est obligatoire' : 'Veuillez entrer un numéro de commande valide'"
    placeholder="53063">
  </atc-input>
  <atc-button
    label="Envoyer le voucher par email"

    class="flex flex-row justify-end"
    [disabled]="skisetOrderForm.invalid"
    (click)="send()">
  </atc-button>
</div>
