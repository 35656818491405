import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Agency } from '../shared/models/agency';
import { Page } from '../shared/models/page';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class AgencyService
  extends RepositoryService
  implements PageableService<Agency>, CrudService<Agency>
{
  private URL_AGENCY = 'api/backoffice/agency';

  constructor(http: HttpClient) {
    super(http);
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<Agency>> {
    return this.get(
      this.URL_AGENCY +
        `/get-all?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(map((page: Page<Agency>) => new Page<Agency>({ ...page }, Agency)));
  }

  upsert(agency: Agency): Observable<void> {
    return this.post(this.URL_AGENCY, agency);
  }

  deleteById(): Observable<void> {
    return new Observable<void>();
  }
}
