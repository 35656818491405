<!-- Step 0 : choose connexion mode -->
<div class="vsk__background"></div>
<div class="vsk__block-form">
  <img alt="VeryDashboard" src="assets/images/logo.png"/>
  <div class="vsk__column">
    <atc-input id="id__input-home-login-email"
               label="Adresse email"
               type="email"
               [control]="emailForm"
               placeholder="jean-dupont@gmail.com"
               (keyup.enter)="nextStep()">
    </atc-input>

    <atc-input id="id__input-home-login-password"
               type="password"
               label="Mot de passe"
               [control]="passwordConnexionForm"
               placeholder="****************"
               (keyup.enter)="nextStep()">
    </atc-input>
  </div>
  <p *ngIf="error" class="vsk__error-text">{{error}}</p>
  <atc-button id="id__button-home-login-connexion"
              class="flex w-full vsk__spacing-bottom"
              label="Se connecter"

              [fullWidth]="true"
              (click)="nextStep()"></atc-button>
  <a routerLink="/forgot-password"
     routerLinkActive="true">
    Oubli de mot de passe
  </a>
</div>

<div *ngIf="loading" class="vsk__spinner-overlay">
  <atc-loader></atc-loader>
</div>
