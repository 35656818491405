import moment, { Moment } from 'moment';
import { RegisterStatusEnum } from './enum/register-status.enum';
import { Participant } from './participant';
import { TimeSlot } from './time-slot/time-slot';
import { User } from './user';

export class Reservation {
  registerTimeSlotId: number;
  timeSlotId: number;
  internshipId: number;
  experienceId: number;
  activityName: string;
  experienceName: string;
  participants: Participant[];
  dateStart: Moment;
  dateEnd: Moment;
  hourStart: string;
  hourEnd: string;
  dateRegistration: Moment;
  reservationSize: number;
  message: string;
  sessionId: string;
  status: RegisterStatusEnum;
  user: User;
  partnerName: string;
  partnerPhone: string;
  denyMessage: string;
  denyTime: Moment;
  timeSlots: TimeSlot[];

  constructor(obj: Partial<Reservation>) {
    Object.assign(this, obj);

    if (this.dateStart) {
      this.dateStart = moment(this.dateStart);
    }

    if (this.dateEnd) {
      this.dateEnd = moment(this.dateEnd);
    }

    if (this.hourStart) {
      this.dateStart = moment(this.dateStart).set({
        hours: Number(this.hourStart.split(':')[0]),
        minutes: Number(this.hourStart.split(':')[1])
      });
    }

    if (this.hourEnd) {
      this.dateEnd = moment(this.dateEnd).set({
        hours: Number(this.hourEnd.split(':')[0]),
        minutes: Number(this.hourEnd.split(':')[1])
      });
    }

    if (this.participants) {
      this.participants = this.participants.map(
        (participant) => new Participant({ ...participant })
      );
    }

    this.dateRegistration = moment(this.dateRegistration);
    this.denyTime = moment(this.denyTime);
  }
}
