import { InternshipEvent } from 'atomic-lib';
import moment, { Moment } from 'moment';
import { TimeSlotModel } from './time-slot-model';

export class Internship {
  id: number | string;
  parentId: number;
  name: string;
  experience: number;
  packageGrid: number;
  dateStart: Moment;
  dateEnd: Moment;
  dateEndFrequency: Moment;
  slotTaken: number;
  slotAvailable: number;
  enabled: boolean;
  timeSlots: TimeSlotModel[];

  constructor(obj: Partial<Internship>) {
    Object.assign(this, obj);

    if (this.dateStart) {
      this.dateStart = moment(this.dateStart, 'YYYY-MM-DD');
    }

    if (this.dateEnd) {
      this.dateEnd = moment(this.dateEnd, 'YYYY-MM-DD');
    }

    if (this.dateEndFrequency) {
      this.dateEndFrequency = moment(this.dateEndFrequency, 'YYYY-MM-DD');
    }

    if (this.timeSlots) {
      this.timeSlots = this.timeSlots.map(
        (timeSlot) => new TimeSlotModel({ ...timeSlot })
      );
    }
  }

  toInternshipEvent(): InternshipEvent {
    return {
      eventId: this.id ? this.id : this.parentId,
      name: this.name,
      start: this.dateStart,
      end: this.dateEnd,
      slotsAvailable: this.slotAvailable,
      slotsTaken: this.slotTaken,
      internshipDays: this.timeSlots.map((timeSlot) => {
        return {
          eventId: timeSlot.id,
          start: timeSlot.dateStart,
          end: timeSlot.dateEnd
        };
      })
    };
  }
}
