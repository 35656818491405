export class Parameters {
  id: number;
  resalys: boolean;
  eliberty: boolean;
  maeva: boolean;
  arkiane: boolean;
  skiset: boolean;

  constructor(obj: Partial<Parameters>) {
    Object.assign(this, obj);
  }
}
