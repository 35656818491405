import { Component } from '@angular/core';
import { PageableComponent } from '../../../../shared/component/pageable.component';
import { CatalogPriceReduction } from '../../../../shared/models/eliberty/catalog-price-reduction';

@Component({
  selector: 'dash-list-catalog-price-reduction',
  templateUrl: './list-catalog-price-reduction.component.html',
  styleUrls: ['./list-catalog-price-reduction.component.scss']
})
export class ListCatalogPriceReductionComponent extends PageableComponent<CatalogPriceReduction> {
  headers = ['Id', 'Date de début', 'Date de fin', 'Remise'];
}
