<div class="vsk__column gap-2">
  <ng-container *ngIf="state === 'read'">
    <div class="vsk__card">
      <div class="flex flex-row mb-4 w-full justify-between">
        <h1>Rechercher</h1>
        <atc-button (click)="changeState('create', false)"

                    label="Créer un client"></atc-button>
      </div>
      <atc-input [control]="searchForm"
                 icon="search"
                 placeholder="Rechercher par nom, prénom ou entreprise ..."
                 id="id__input-customers-management-search"
                 label="Rechercher"></atc-input>
    </div>

    <dash-list-edit [headers]="headers"
                    title="Mes clients">
      <tr body *ngFor="let customer of clientsToShow">
        <td>{{customer.isEnterprise ? '-' : customer.lastname}}</td>
        <td>{{customer.isEnterprise ? customer.nameEnterprise : customer.firstname}}</td>
        <td>{{customer.email ? customer.email : '-'}}</td>
        <td>{{customer.phoneNumber ? customer.phoneNumber : '-'}}</td>
        <td>{{customer.address}}</td>
        <td>{{customer.postCode}}</td>
        <td>{{customer.city}}</td>
        <td>{{customer.isEnterprise ? 'Professionnel' : 'Particulier'}}</td>
        <td>
          <img (click)="editCustomer(customer)" alt="Edit" src="assets/icones/edit.svg">
        </td>
      </tr>
    </dash-list-edit>
  </ng-container>

  <dash-create-customer (done)="changeState('read', $event)"
                        *ngIf="state === 'create'"
                        [edit]="customerEdit"
                        [partnerId]="partnerId$ | async"></dash-create-customer>
</div>
