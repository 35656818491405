<atc-side-drawer [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading"
                 (closeChange)="editionComplete.emit()">
  <div header class="w-full my-4">
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification bannière' : 'Nouvelle bannière' }}</h1>
  </div>

  <div body class="vsk__body">
    <!-- Name -->
    <atc-input id="id__input-text-activity-name"
               label="Nom"
               placeholder="Promo logement été"
               [control]="nameForm">
    </atc-input>

    <div class="vsk__inline">
      <!-- Start date -->
      <atc-input-date id="id__input-activity-date-start"
                      label="Date de début"
                      [control]="startDateForm">
      </atc-input-date>

      <!-- End date -->
      <atc-input-date id="id__input-activity-date-end"
                      label="Date de fin"
                      [control]="endDateForm">
      </atc-input-date>
    </div>

    <div class="vsk__column">
      <label>Contenu</label>
      <atc-text-editor [(text)]="this.contentForm"
                       [maxLength]="2000"
                       placeholder="Code promo sur les hébergements (Code : LOGEMENT20)"></atc-text-editor>
    </div>

    <div class="flex flex-row gap-x-4 items-center">
      <div class="flex flex-col gap-y-4 flex-1">
        <label>Couleur de police</label>
        <input [(colorPicker)]="fontColor"
               [style.background]="fontColor"/>
      </div>

      <div class="flex flex-col gap-y-4 flex-1">
        <label>Couleur de fond</label>
        <input [(colorPicker)]="backgroundColor"
               [style.background]="backgroundColor"/>
      </div>
    </div>

    <div class="flex flex-col gap-y-4">
      <label>Exemple de rendu</label>
      <div class="flex w-full h-[4rem] justify-center items-center" [style.background]="backgroundColor">
        <p [style.color]="fontColor" [innerHTML]="contentForm.value"></p>
      </div>
    </div>
  </div>

  <div footer class="flex flex-row justify-between w-full items-center pt-4">
    <atc-button *ngIf="editMode === 'edit'" color="danger" label="Supprimer" (click)="deleteById()"></atc-button>

    <atc-button id="id__button-validate-form"
                [disabled]="!isFormValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"

                (click)="create()">
    </atc-button>
  </div>
</atc-side-drawer>
