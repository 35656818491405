import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import moment from 'moment/moment';
import { ElibertyProductService } from '../../../../service/eliberty-product.service';
import { EditComponent } from '../../../../shared/component/edit.component';
import { Product } from '../../../../shared/models/eliberty/product';
import { ProductPricePromotion } from '../../../../shared/models/eliberty/product-price-promotion';

export interface ProductPricePromotionForm {
  startDate: FormControl<string | null>;
  endDate: FormControl<string | null>;
  promotion: FormControl<number | null>;
}

@Component({
  selector: 'dash-edit-product-price-promotion',
  templateUrl: './edit-product-price-promotion.component.html',
  styleUrls: ['./edit-product-price-promotion.component.scss']
})
export class EditProductPricePromotionComponent extends EditComponent<ProductPricePromotion> {
  productsElement: OptionElement<Product>[] = [];
  productPricePromotionForm: FormGroup<ProductPricePromotionForm>;
  startDateForm = new FormControl<string>('', Validators.required);
  endDateForm = new FormControl<string>('', Validators.required);
  promotionForm = new FormControl<number | null>(null, Validators.required);

  @Input() catalog: number;

  @Input() set entityToEdit(entity: ProductPricePromotion | undefined) {
    if (entity) {
      this.id = entity.id;
      this.startDateForm.setValue(entity.startDate.format('DD/MM/YYYY'));
      this.endDateForm.setValue(entity.endDate.format('DD/MM/YYYY'));
      this.promotionForm.setValue(entity.promotion);
      this.editMode = 'edit';
    }
  }

  constructor(
    protected service: ElibertyProductService,
    protected store: Store
  ) {
    super(service, store);
    this.name = 'Promotion prix produits';
  }

  initForm(): void {
    this.productPricePromotionForm = new FormGroup<ProductPricePromotionForm>({
      startDate: this.startDateForm,
      endDate: this.endDateForm,
      promotion: this.promotionForm
    });

    this.register(
      this.service
        .getAllProductsByCatalogId(this.catalog)
        .subscribe((products) => {
          this.productsElement = products.map((product) => {
            return {
              id: product,
              label: product.name,
              control: new FormControl<boolean>(false)
            };
          });
        })
    );
  }

  buildEntity(): ProductPricePromotion {
    return new ProductPricePromotion({
      id: this.id as number,
      products: this.productsElement
        .filter((element) => element.control?.value)
        .map((element) => element.id as Product),
      startDate: moment(this.startDateForm.value, 'DD/MM/YYYY'),
      endDate: moment(this.endDateForm.value, 'DD/MM/YYYY'),
      promotion: this.promotionForm.value as number
    });
  }

  isFormValid(): boolean {
    return (
      this.productPricePromotionForm.valid &&
      !!this.productsElement
        .map((element) => element.control?.value || false)
        .find((element) => element)
    );
  }
}
