import { Role } from './enum/Role';

export class Authority {
  authority: Role;

  constructor(obj: Partial<Authority>) {
    Object.assign(this, obj);
  }
  toString(): string {
    return this.authority;
  }
}
