import moment, { Moment } from 'moment';
import { PromoCode } from '../promo-code';
import { Resort } from '../resort/resort';

export class SessionMaster {
  sessionId: string;
  name: string;
  dateToCheck: Moment;
  resort: Resort;
  startDate: Moment;
  endDate: Moment;
  promoCode?: PromoCode;

  constructor(obj: Partial<SessionMaster>) {
    Object.assign(this, obj);

    if (this.dateToCheck) {
      this.dateToCheck = moment(this.dateToCheck, 'YYYY-MM-DD');
    }

    if (this.startDate) {
      this.startDate = moment(this.startDate, 'YYYY-MM-DD');
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate, 'YYYY-MM-DD');
    }

    if (this.resort) {
      this.resort = new Resort({ ...this.resort });
    }

    if (this.promoCode) {
      this.promoCode = new PromoCode({ ...this.promoCode });
    }
  }
}
