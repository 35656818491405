import moment, { Moment } from 'moment';
import { Participant } from '../participant';

export class OrderDetailsAccommodation {
  id: string;
  partnerName: string;
  reservationId: number;
  establishmentName: string;
  establishmentPhone: string;
  roomName: string;
  startDate: Moment;
  endDate: Moment;
  price: number;
  cancelled: boolean;
  participants: Participant[];

  constructor(obj: Partial<OrderDetailsAccommodation>) {
    Object.assign(this, obj);

    if (this.participants) {
      this.participants = this.participants.map(
        (participant) => new Participant(participant)
      );
    }

    if (this.startDate) {
      this.startDate = moment(this.startDate, 'YYYY-MM-DD');
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate, 'YYYY-MM-DD');
    }
  }
}
