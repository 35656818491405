<dash-list-edit id="id__table-list-products"
                paginationId="products"
                [headers]="headers"
                [searchForm]="searchForm"
                (paginateEvent)="onTableDataChange($event)"
                searchLabel="Recherche de produits"
                searchPlaceholder="Rechercher par nom"
                title="Produits">
  <ng-container body *ngFor="let product of page.content | paginate : {
                id: 'products',
                itemsPerPage: page.pageable.pageSize,
                currentPage: page.pageable.pageNumber + 1,
                totalItems: page.totalElements }">
    <tr (click)="edit(product)">
      <td>{{ product.id }}</td>
      <td>{{ product.name }}</td>
      <td>{{ product.type }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
