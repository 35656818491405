import { Component } from '@angular/core';
import { PageableComponent } from 'src/app/shared/component/pageable.component';
import { TextBlock } from 'src/app/shared/models/text-block';

@Component({
  selector: 'dash-list-text-block',
  templateUrl: './list-text-block.component.html',
  styleUrls: ['./list-text-block.component.scss']
})
export class ListTextBlockComponent extends PageableComponent<TextBlock> {
  headers = ['Id', 'Nom'];
}
