import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { ButtonToggle, Days, OptionElement } from 'atomic-lib';
import moment from 'moment';
import { Observable, Subject, combineLatest, forkJoin, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { TriggerAlert, UpdateTimeSlotSelected } from 'src/app/app.action';
import { AppState } from '../../app.state';
import { ActivityService } from '../../service/activity.service';
import { InternshipService } from '../../service/internship.service';
import { PackageGridService } from '../../service/package-grid.service';
import { TimeSlotService } from '../../service/time-slot.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { ActivityPartner } from '../../shared/models/activity-partner';
import { Alert } from '../../shared/models/alert';
import { FrequencyEnum } from '../../shared/models/enum/frequency.enum';
import { HttpError } from '../../shared/models/http-error';
import { PackageGrid } from '../../shared/models/package-grid';
import { Internship } from '../../shared/models/time-slot/internship';
import { TimeSlot } from '../../shared/models/time-slot/time-slot';
import { TimeSlotModel } from '../../shared/models/time-slot/time-slot-model';
import { HoursUtils } from '../../utils/hours-utils';

export interface DatesForm {
  idForm: FormControl<number | null>;
  dateForm: FormControl<string | null>;
  startHourForm: FormControl<string>;
  endHourForm: FormControl<string>;
}

@Component({
  selector: 'dash-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss']
})
export class AddActivityComponent extends RxjsComponent implements OnInit {
  @Select(AppState.activitiesPartner) activities$: Observable<
    ActivityPartner[]
  >;
  @Select(AppState.partnerId) partnerId$: Observable<number>;
  @Select(AppState.timeSlot) timeSlot$: Observable<TimeSlot>;
  @Select(AppState.internship) internship$: Observable<Internship>;
  @Select(AppState.isAdmin) isAdmin$: Observable<boolean>;

  hours: OptionElement<string>[] = [
    {
      id: null,
      label: 'Choisir une heure',
      disabled: true,
      classCss: 'disabled'
    },
    ...HoursUtils.hours.map((hour) => {
      return {
        id: hour,
        label: hour
      } as OptionElement<string>;
    })
  ];

  buttonsToggle: ButtonToggle<string>[] = [
    {
      label: 'Ce créneau',
      value: 'one',
      selected: true
    },
    {
      label: 'Tous les créneaux',
      value: 'all',
      selected: false
    }
  ];

  experienceElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une expérience',
      disabled: true,
      classCss: 'disabled'
    }
  ];

  packageGridElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une grille',
      disabled: true,
      classCss: 'disabled'
    }
  ];

  daysFrequency: Days[] = [
    Days.MONDAY,
    Days.TUESDAY,
    Days.WEDNESDAY,
    Days.THURSDAY,
    Days.FRIDAY
  ];

  idInternship: number | undefined = undefined;
  parentInternship: number | undefined = undefined;
  idSlot: number | undefined = undefined;
  idModel: number | undefined = undefined;
  multiTimeSlotForm = new FormControl<boolean | null>(false);
  experienceIdForm: FormControl<number | null> = new FormControl<number | null>(
    null,
    Validators.required
  );
  packageGridForm: FormControl<number | null> = new FormControl<number | null>(
    null,
    Validators.required
  );
  slotsForm = new FormControl<number>(10, Validators.required);
  repeatForm = new FormControl<boolean>(false, Validators.required);
  dateEndFrequencyForm = new FormControl<string>(
    moment().add(6, 'month').format('DD/MM/YYYY'),
    Validators.required
  );
  methodUpdateForm = new UntypedFormControl('one', Validators.required);
  commentForm = new FormControl<string>('');
  experienceForm: UntypedFormGroup;
  datesFormArray: DatesForm[] = [];
  timeSlot: TimeSlot;
  loading = false;
  $close = new Subject<void>();
  data: any;
  result: any;
  isAdmin = this.store.selectSnapshot(AppState.isAdmin);

  @Input() edit = false;
  @Output() activityChanged = new EventEmitter<void>();
  @Output() closeChanged = new EventEmitter<void>();

  constructor(
    private timeSlotService: TimeSlotService,
    private internshipService: InternshipService,
    private activityService: ActivityService,
    private store: Store,
    private packageGridService: PackageGridService
  ) {
    super();

    this.experienceForm = new UntypedFormGroup({
      experience: this.experienceIdForm,
      openSlots: this.slotsForm,
      repeatEveryWeek: this.repeatForm
    });
  }

  get showDateEndFrequency(): boolean {
    return !!this.repeatForm.value && !this.multiTimeSlotForm.value;
  }

  ngOnInit(): void {
    this.addLine(-1);

    this.register(
      this.timeSlot$
        .pipe(
          filter((timeSlot) => !!timeSlot),
          switchMap((timeSlot) =>
            combineLatest([
              of(timeSlot),
              this.packageGridService.fetchByExperienceId(timeSlot.experienceId)
            ])
          )
        )
        .subscribe(([timeSlot, pckGrids]) => {
          this.mapElementsPackageGrids(pckGrids);
          if (this.edit && !!timeSlot) {
            this.timeSlot = timeSlot;
            const timeSlotModel = timeSlot.toTimeSlotModel();
            this.idModel = timeSlotModel.id;
            this.idSlot = timeSlotModel.timeSlotId;
            this.experienceIdForm.setValue(timeSlot.experienceId);
            this.datesFormArray[0].idForm.setValue(this.idModel);
            this.datesFormArray[0].dateForm.setValue(
              timeSlotModel.dateStart.format('DD/MM/YYYY')
            );
            this.datesFormArray[0].endHourForm.setValue(timeSlotModel.hourEnd);
            this.datesFormArray[0].startHourForm.setValue(
              timeSlotModel.hourStart
            );
            this.slotsForm.setValue(timeSlotModel.slotsAvailable);
            this.repeatForm.setValue(
              timeSlotModel.frequency === FrequencyEnum.DAY
            );
            this.commentForm.setValue(timeSlot.comment);
            this.dateEndFrequencyForm.setValue(
              timeSlot.dateEndFrequency.format('DD/MM/YYYY')
            );
            this.daysFrequency = [...timeSlot.daysToRepeat];
            this.packageGridForm.setValue(timeSlot.packageGridId);
            this.multiTimeSlotForm.setValue(false);
          }
        })
    );

    this.register(
      this.internship$
        .pipe(
          filter((internship) => !!internship),
          switchMap((internship) =>
            combineLatest([
              of(internship),
              this.packageGridService.fetchByExperienceId(internship.experience)
            ])
          )
        )
        .subscribe(([internship, pckGrids]) => {
          this.mapElementsPackageGrids(pckGrids);
          if (this.edit && !!internship) {
            if (typeof internship.id === 'number') {
              this.idInternship = internship.id;
            }

            this.parentInternship = internship.parentId;
            this.multiTimeSlotForm.setValue(true);
            this.experienceIdForm.setValue(internship.experience);
            this.datesFormArray = internship.timeSlots.map((timeSlot) => {
              return {
                idForm: new FormControl<number>(timeSlot.id),
                dateForm: new FormControl<string | null>(
                  timeSlot.dateStart.format('DD/MM/YYYY'),
                  Validators.required
                ),
                startHourForm: new FormControl<string | null>(
                  timeSlot.hourStart,
                  Validators.required
                ),
                endHourForm: new FormControl<string | null>(
                  timeSlot.hourEnd,
                  Validators.required
                )
              } as DatesForm;
            });
            this.slotsForm.setValue(internship.slotAvailable);
            this.repeatForm.setValue(!!internship.dateEndFrequency);
            this.packageGridForm.setValue(internship.packageGrid);

            if (internship.dateEndFrequency) {
              this.dateEndFrequencyForm.setValue(
                internship.dateEndFrequency.format('DD/MM/YYYY')
              );
              this.daysFrequency = [
                moment(internship.dateStart, 'DD/MM/YYYY')
                  .set({
                    hours: 0,
                    minutes: 0,
                    milliseconds: 0
                  })
                  .locale('fr')
                  .weekday()
              ];
            }

            this.multiTimeSlotForm.setValue(true);
          }
        })
    );

    this.register(
      this.partnerId$
        .pipe(
          switchMap((partnerId) =>
            this.activityService.getActivitiesPartner(partnerId)
          )
        )
        .subscribe((activities) => {
          this.experienceElements = [
            ...this.experienceElements,
            ...activities
              .map((activity) => activity.experiences)
              .reduce((prev, curr) => prev.concat(curr), [])
              .map((experience) => {
                return {
                  id: experience.id,
                  label: this.isAdmin
                    ? `${experience?.id} - ${experience?.activity?.name} - ${experience?.name}`
                    : `${experience?.activity?.name} - ${experience?.name}`
                } as OptionElement<number>;
              })
          ];
        })
    );

    this.experienceIdForm.valueChanges
      .pipe(
        switchMap((exp) =>
          this.packageGridService.fetchByExperienceId(exp as number)
        )
      )
      .subscribe((packageGrids) => this.mapElementsPackageGrids(packageGrids));
  }

  changeModification(value: string) {
    this.methodUpdateForm.setValue(value);
  }

  isFormValid(): boolean {
    return (
      this.experienceForm.valid &&
      this.packageGridForm.valid &&
      this.datesAreValid()
    );
  }

  confirmSimple(): void {
    if (this.isFormValid()) {
      this.loading = true;
      this.datesFormArray.forEach((dateForm) => {
        this.addTimeSlotModel(
          new TimeSlotModel({
            id: this.idModel,
            timeSlotId: this.idSlot,
            experienceId: this.experienceIdForm.value as number,
            packageGridId: this.packageGridForm.value as number,
            originDate: moment(dateForm.dateForm.value, 'DD/MM/YYYY').set({
              hours: 0,
              minutes: 0,
              milliseconds: 0
            }),
            dateStart: moment(dateForm.dateForm.value, 'DD/MM/YYYY').set({
              hours: 0,
              minutes: 0,
              milliseconds: 0
            }),
            dateEnd: moment(dateForm.dateForm.value, 'DD/MM/YYYY').set({
              hours: 0,
              minutes: 0,
              milliseconds: 0
            }),
            hourStart: dateForm.startHourForm.value,
            hourEnd: dateForm.endHourForm.value,
            slotsAvailable: this.slotsForm.value as number,
            frequency: this.repeatForm.value
              ? FrequencyEnum.DAY
              : FrequencyEnum.NONE,
            enable: true,
            comment: this.commentForm.value as string,
            dateEndFrequency: moment(
              this.dateEndFrequencyForm.value,
              'DD/MM/YYYY'
            ).set({
              hours: 0,
              minutes: 0,
              milliseconds: 0
            }),
            daysToRepeat: this.daysFrequency
          })
        );
      });
    }
  }

  confirmMultiple(deleteInternship = false): void {
    if (this.isFormValid()) {
      this.loading = true;
      const upsertObservables: Observable<number>[] = [];

      const timeSlots = this.formsToTimeSlots();

      const startDate = timeSlots
        .map((timeSlot) => timeSlot.dateStart)
        .reduce((prev, curr) => (prev.isBefore(curr, 'minutes') ? prev : curr));

      const endDate = timeSlots
        .map((timeSlot) => timeSlot.dateEnd)
        .reduce((prev, curr) => (prev.isAfter(curr, 'minutes') ? prev : curr));

      const internship = new Internship({
        id: this.idInternship,
        parentId: this.parentInternship,
        experience: this.experienceIdForm.value as number,
        packageGrid: this.packageGridForm.value as number,
        dateStart: startDate,
        dateEnd: endDate,
        dateEndFrequency: this.repeatForm.value
          ? moment(this.dateEndFrequencyForm.value, 'DD/MM/YYYY').set({
              hours: 0,
              minutes: 0,
              milliseconds: 0
            })
          : undefined,
        slotAvailable: this.slotsForm.value as number,
        enabled: true
      });

      upsertObservables.push(
        this.internshipService.upsert(
          this.store.selectSnapshot(AppState.partnerId) as number,
          internship,
          timeSlots
        )
      );

      forkJoin(upsertObservables).subscribe(
        (results) => {
          if (!deleteInternship) {
            this.loading = false;
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'success',
                  timeout: 2000,
                  message: 'Stage créée / modifié avec succès'
                })
              )
            );
            this.activityChanged.emit();
            this.closeChanged.emit();
          } else {
            this.idInternship = results[results.length - 1];
            this.triggerDelete();
          }
        },
        (error: HttpError) => {
          this.loading = false;
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                level: 'error',
                timeout: 5000,
                message:
                  'Erreur lors de la création / modification du stage, ' +
                  error.message
              })
            )
          );
        }
      );
    }
  }

  formsToTimeSlots() {
    return this.datesFormArray.map(
      (dateForm) =>
        new TimeSlotModel({
          id: dateForm.idForm.value ?? undefined,
          timeSlotId: this.idSlot,
          experienceId: this.experienceIdForm.value as number,
          packageGridId: this.packageGridForm.value as number,
          originDate: moment(dateForm.dateForm.value, 'DD/MM/YYYY').set({
            hours: 0,
            minutes: 0,
            milliseconds: 0
          }),
          dateStart: moment(dateForm.dateForm.value, 'DD/MM/YYYY').set({
            hours: 0,
            minutes: 0,
            milliseconds: 0
          }),
          dateEnd: moment(dateForm.dateForm.value, 'DD/MM/YYYY').set({
            hours: 0,
            minutes: 0,
            milliseconds: 0
          }),
          hourStart: dateForm.startHourForm.value,
          hourEnd: dateForm.endHourForm.value,
          slotsAvailable: this.slotsForm.value as number,
          frequency: this.repeatForm.value
            ? FrequencyEnum.DAY
            : FrequencyEnum.NONE,
          enable: true,
          comment: this.commentForm.value as string,
          dateEndFrequency: moment(
            this.dateEndFrequencyForm.value,
            'DD/MM/YYYY'
          ).set({
            hours: 0,
            minutes: 0,
            milliseconds: 0
          }),
          daysToRepeat: [
            moment(dateForm.dateForm.value, 'DD/MM/YYYY')
              .set({
                hours: 0,
                minutes: 0,
                milliseconds: 0
              })
              .locale('fr')
              .weekday()
          ]
        })
    );
  }

  addTimeSlotModel(timeSlot: TimeSlotModel): void {
    if (this.idSlot || this.idModel) {
      this.store
        .dispatch(
          new UpdateTimeSlotSelected(timeSlot, this.methodUpdateForm.value)
        )
        .subscribe(
          () => {
            this.activityChanged.emit();
            this.closeChanged.emit();
            this.loading = false;
          },
          (error: HttpError) => {
            this.loading = false;
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'error',
                  timeout: 5000,
                  message:
                    'Erreur lors de la suppression du créneau, ' + error.message
                })
              )
            );
          }
        );
    } else {
      this.partnerId$
        .pipe(
          switchMap((partnerId) =>
            this.timeSlotService.createTimeSlotModel(partnerId, timeSlot)
          )
        )
        .subscribe(
          () => {
            this.closeChanged.emit();
            this.loading = false;
          },
          (error: HttpError) => {
            this.loading = false;
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'error',
                  timeout: 5000,
                  message:
                    'Erreur lors de la suppression du créneau, ' + error.message
                })
              )
            );
          }
        );
    }
  }

  delete(timeSlot: TimeSlot): void {
    // - Delete one (send email if registration and refund)
    // - Delete all (delete only in future with no registration)
    // - Update one (send email with new date and hours, check if time slot available for customer or propose new activity or refund)
    // - Update all (update only in future with no registration)
    const partnerId = this.store.selectSnapshot(AppState.partnerId) as number;
    const timeSlotModel: TimeSlotModel = new TimeSlotModel({
      ...timeSlot.toTimeSlotModel(),
      daysToRepeat: this.daysFrequency,
      enable: false
    });

    if (this.edit) {
      this.timeSlotService
        .update(partnerId, timeSlotModel, this.methodUpdateForm.value === 'all')
        .subscribe(() => {
          this.activityChanged.emit();
          this.closeChanged.emit();
        });
    } else {
      this.timeSlotService
        .deleteTimeSlot(
          partnerId,
          timeSlotModel,
          this.methodUpdateForm.value === 'all'
        )
        .subscribe(() => {
          this.activityChanged.emit();
          this.closeChanged.emit();
        });
    }
  }

  checkHours(
    startForm: FormControl<string>,
    endForm: FormControl<string>
  ): boolean {
    const start = moment(startForm.value, 'HH:mm');
    const end = moment(endForm.value, 'HH:mm');
    return start.isBefore(end);
  }

  setDaysToRepeat(days: Days[]) {
    this.daysFrequency = days;
  }

  previous() {
    this.activityChanged.emit();
    this.$close.next();
  }

  addLine(i: number) {
    let date = moment();
    let hourStart = '08:00';
    let hourEnd = '10:00';

    if (i >= 0) {
      date = moment(
        this.datesFormArray[0].dateForm.value as string,
        'DD/MM/YYYY'
      );
      hourStart = this.datesFormArray[0].startHourForm.value;
      hourEnd = this.datesFormArray[0].endHourForm.value;
    }

    if (this.multiTimeSlotForm.value && i >= 0) {
      date.add(this.datesFormArray.length, 'days');
    }

    this.datesFormArray.push({
      idForm: new FormControl<number | null>(null),
      dateForm: new FormControl<string>(
        date.format('DD/MM/YYYY'),
        Validators.required
      ),
      startHourForm: new FormControl<any>(hourStart, Validators.required),
      endHourForm: new FormControl<any>(hourEnd, Validators.required)
    });
  }

  removeLine(i: number) {
    this.datesFormArray = this.datesFormArray.filter((_, index) => i !== index);
  }

  datesAreValid() {
    return this.datesFormArray
      .map(
        (dateForm) =>
          dateForm.dateForm.valid &&
          dateForm.endHourForm.valid &&
          dateForm.startHourForm.valid &&
          this.checkHours(dateForm.startHourForm, dateForm.endHourForm)
      )
      .reduce((prev, curr) => prev && curr);
  }

  deleteMultiple() {
    this.loading = true;

    if (!this.idInternship) {
      this.confirmMultiple(true);
    } else {
      this.triggerDelete();
    }
  }

  triggerDelete() {
    const partnerId = this.store.selectSnapshot(AppState.partnerId) as number;

    this.internshipService
      .deleteById(this.idInternship as number, partnerId)
      .subscribe(
        () => {
          this.loading = false;
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                level: 'success',
                timeout: 2000,
                message: 'Stage supprimé avec succès'
              })
            )
          );
          this.activityChanged.emit();
          this.closeChanged.emit();
        },
        (error: HttpError) => {
          this.loading = false;
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                level: 'error',
                timeout: 5000,
                message:
                  'Erreur lors de la suppression du stage, ' + error.message
              })
            )
          );
        }
      );
  }

  private mapElementsPackageGrids(packageGrids: PackageGrid[]) {
    this.packageGridElements = [
      {
        id: null,
        label: 'Choisir une grille',
        disabled: true,
        classCss: 'disabled'
      },
      ...packageGrids.map((grid) => {
        return {
          id: grid.id,
          label: grid.name
        };
      })
    ];

    if (packageGrids.length === 1) {
      this.packageGridForm.setValue(packageGrids[0].id);
    }
  }
}
