<div class="vsk__column">
  <dash-list-text-block
    [searchForm]="searchForm"
    [page]="page"
    (pageChanged)="fetchPageable($event, searchForm.value)"
    (createChanged)="showEdit()"
    (isChanged)="edit($event)"
    (isDeleted)="delete()">
  </dash-list-text-block>
  <dash-edit-text-block
    id="form-edit-text-block"
    *ngIf="showEditor"
    [entityToEdit]="toEdit"
    (editionComplete)="editCompleted()">
  </dash-edit-text-block>
</div>
