import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import moment, { Moment } from 'moment';
import { AppState } from '../../../app.state';
import { TimeSlotService } from '../../../service/time-slot.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { Reservation } from '../../../shared/models/reservation';
import { TimeSlot } from '../../../shared/models/time-slot/time-slot';

@Component({
  selector: 'dash-reprogram-time-slot',
  templateUrl: './reprogram-time-slot.component.html',
  styleUrls: ['./reprogram-time-slot.component.scss']
})
export class ReprogramTimeSlotComponent
  extends RxjsComponent
  implements OnInit
{
  timeSlots: TimeSlot[] = [];
  @Input() reservationModifyPopUp: Reservation;
  @Input() loading = false;
  @Output() closeChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() timeSlotsChosen: EventEmitter<TimeSlot[]> = new EventEmitter<
    TimeSlot[]
  >();

  constructor(
    private timeSlotService: TimeSlotService,
    private store: Store
  ) {
    super();
  }

  ngOnInit() {
    this.register(
      this.timeSlotService
        .timeSlotsByExperienceName(
          this.store.selectSnapshot(AppState.partnerId) as number,
          this.reservationModifyPopUp.timeSlotId,
          this.reservationModifyPopUp.registerTimeSlotId,
          this.reservationModifyPopUp.experienceId,
          this.reservationModifyPopUp.sessionId
        )
        .subscribe((timeslots) => {
          this.timeSlots = timeslots.map(
            (timeSlot) =>
              new TimeSlot({
                ...timeSlot,
                selected: false
              })
          );
        })
    );
  }

  sendMessage(timeSlots: TimeSlot[]): void {
    const selectedTimeSlots = timeSlots.filter((timeSlot) => timeSlot.selected);
    this.timeSlotsChosen.emit(selectedTimeSlots);
  }

  timeSlotSelectedChange(indexSlot: number, timeSlots: TimeSlot[]) {
    if (
      this.howManySelected(timeSlots) < 4 ||
      this.timeSlots[indexSlot].selected
    ) {
      this.timeSlots[indexSlot].selected = !this.timeSlots[indexSlot].selected;
    }
  }

  howManySelected(timeSlots: TimeSlot[]): number {
    return timeSlots.filter((timeSlot) => timeSlot.selected).length;
  }

  getLabelTimeSlot(timeSlot: TimeSlot) {
    const formattedDate = moment(timeSlot.dateStart).format('DD MMMM yyyy');
    const formattedTime =
      this.formatStringTime(timeSlot.hourStart) +
      ' - ' +
      this.formatStringTime(timeSlot.hourEnd);

    return `${formattedDate}<br>${formattedTime}`;
  }

  formatDateStart(dateStart: Moment | undefined) {
    return moment(dateStart).format('DD MMMM yyyy');
  }

  formatStringTime(time: string): string {
    return moment(time, 'HH:mm:ss').format('HH:mm');
  }

  formatExperienceName(): string {
    return ' · ' + this.reservationModifyPopUp?.experienceName;
  }

  formatHourStartHourEnd(): string {
    return (
      this.formatStringTime(this.reservationModifyPopUp?.hourStart as string) +
      ' · ' +
      this.formatStringTime(this.reservationModifyPopUp?.hourEnd as string)
    );
  }

  formatFirstNameUserPhone(): string {
    return (
      this.reservationModifyPopUp?.user?.firstName +
      ' · ' +
      this.reservationModifyPopUp?.user?.phone
    );
  }
}
