export class SearchRoom {
  partner: string;
  startDate: string;
  endDate: string;
  minPeople: number;
  maxPeople: number;
  resort: number;

  constructor(obj: Partial<SearchRoom>) {
    Object.assign(this, obj);
  }
}
