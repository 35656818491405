import { Component } from '@angular/core';
import { TextBlockService } from 'src/app/service/text-block.service';
import { PageComponent } from 'src/app/shared/component/page.component';
import { TextBlock } from 'src/app/shared/models/text-block';

@Component({
  selector: 'dash-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.scss']
})
export class TextBlockComponent extends PageComponent<TextBlock> {
  constructor(protected service: TextBlockService) {
    super(service);
  }

  delete() {}
}
