import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { Resort } from '../../../shared/models/resort/resort';

@Component({
  selector: 'dash-list-resort',
  templateUrl: './list-resort.component.html',
  styleUrls: ['./list-resort.component.scss']
})
export class ListResortComponent extends PageableComponent<Resort> {
  headers = ['Id', 'Nom', 'Consulter'];

  generateRedirectLink(stationName: string): string {
    const baseUrl = environment.urlAddress;
    let urlAddress = 'http://localhost:4200';

    if (baseUrl.includes('verymountain-e2e.fr')) {
      urlAddress = 'http://verymountain-e2e.fr';
    } else if (baseUrl.includes('verymountain.fr')) {
      urlAddress = 'https://verymountain.fr';
    } else if (baseUrl.includes('veryski-test.fr')) {
      urlAddress = 'http://veryski-test.fr';
    }

    return `${urlAddress}/details-station?station=${stationName}`;
  }
}
