import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { TriggerAlert } from '../../app.action';
import { AccommodationService } from '../../service/accommodation.service';
import { Alert } from '../../shared/models/alert';

@Component({
  selector: 'dash-confirm-booking',
  templateUrl: './confirm-booking.component.html',
  styleUrls: ['./confirm-booking.component.scss']
})
export class ConfirmBookingComponent {
  sessionForm: FormControl<string | null> = new FormControl<string | null>(
    null,
    Validators.required
  );
  userForm: FormControl<number | null> = new FormControl<number | null>(
    null,
    Validators.required
  );
  createOrderForm: FormControl<boolean | null> = new FormControl<
    boolean | null
  >(false, Validators.required);

  loading = false;

  constructor(
    private accommodationService: AccommodationService,
    private store: Store
  ) {}

  book() {
    this.loading = true;
    this.accommodationService
      .bookRoom(
        this.userForm.value as number,
        this.sessionForm.value as string,
        this.createOrderForm.value || false
      )
      .subscribe(
        () => {
          this.loading = false;
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message: `Réservation effectuée avec succès`,
                level: 'success',
                timeout: 2000
              })
            )
          );
          this.userForm.setValue(null);
          this.sessionForm.setValue(null);
        },
        (err: any) => {
          this.loading = false;
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message: err.error,
                level: 'error',
                timeout: 5000
              })
            )
          );
        }
      );
  }
}
