import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import moment from 'moment';
import { combineLatest, forkJoin } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { ImageUrl } from '../../../activity/experience-management/experience/edit-experience/edit-experience.component';
import { TriggerAlert } from '../../../app.action';
import { AccommodationService } from '../../../service/accommodation.service';
import { ImagesService } from '../../../service/images.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { AccommodationEstablishment } from '../../../shared/models/accomodation/accommodation-establishment';
import { AccommodationType } from '../../../shared/models/accomodation/accommodation-type.enum';
import { Picture } from '../../../shared/models/accomodation/picture';
import { Alert } from '../../../shared/models/alert';
import { OriginEnum } from '../../../shared/models/enum/origin.enum';
import { SeasonEnum } from '../../../shared/models/enum/season.enum';
import { Page } from '../../../shared/models/page';
import { Pageable } from '../../../shared/models/pageable';
import { Resort } from '../../../shared/models/resort/resort';

export interface EstablishmentForm {
  name: FormControl<string | null>;
  address: FormControl<string | null>;
  city: FormControl<string | null>;
  zip: FormControl<string | null>;
  email: FormControl<string | null>;
  country: FormControl<string | null>;
  phone1: FormControl<string | null>;
  phone2: FormControl<string | null>;
  openingDate: FormControl<string | null>;
  closingDate: FormControl<string | null>;
  openingTime: FormControl<string | null>;
  closingTime: FormControl<string | null>;
  descriptionSummer: FormControl<string | null>;
  descriptionWinter: FormControl<string | null>;
  accessSummer: FormControl<string | null>;
  accessWinter: FormControl<string | null>;
  facilitiesSummer: FormControl<string | null>;
  facilitiesWinter: FormControl<string | null>;
  informationSummer: FormControl<string | null>;
  informationWinter: FormControl<string | null>;
  resort: FormControl<number | null>;
  type: FormControl<AccommodationType | null>;
  pool: FormControl<boolean | null>;
  spa: FormControl<boolean | null>;
  sauna: FormControl<boolean | null>;
  hammam: FormControl<boolean | null>;
  balconyTerrace: FormControl<boolean | null>;
  tv: FormControl<boolean | null>;
  chimney: FormControl<boolean | null>;
  bbq: FormControl<boolean | null>;
  pmr: FormControl<boolean | null>;
  parking: FormControl<boolean | null>;
  animalsAdmitted: FormControl<boolean | null>;
  wifi: FormControl<boolean | null>;
  childrenClub: FormControl<boolean | null>;
}

@Component({
  selector: 'dash-establishment-management',
  templateUrl: './establishment-management.component.html',
  styleUrls: ['./establishment-management.component.scss']
})
export class EstablishmentManagementComponent extends RxjsComponent {
  headers = ['Nom', "Date d'ouverture", 'Date de fermeture', 'Editer'];
  pageEstablishment: Page<AccommodationEstablishment> =
    new Page<AccommodationEstablishment>({
      content: [],
      pageable: new Pageable({
        pageNumber: 0,
        pageSize: 10
      }),
      totalElements: 0,
      numberOfElements: 0
    });
  id: number | undefined = undefined;
  editMode: 'create' | 'edit' = 'create';
  loading = false;
  openDrawer = false;
  partner: string;
  selectedEstablishment: AccommodationEstablishment | undefined;
  resortElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une station',
      disabled: true,
      classCss: 'disabled'
    }
  ];
  // Pictures
  photosImagesWinter: HTMLImageElement[] = [];
  photosImagesSummer: HTMLImageElement[] = [];
  photosFilesWinter: File[] = [];
  photosFilesSummer: File[] = [];
  nameSearchForm: FormControl<string | null> = new FormControl<string | null>(
    '',
    Validators.required
  );
  // Form
  nameForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  addressForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  cityForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  zipForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  emailForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  countryForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  phone1Form: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  phone2Form: FormControl<string | null> = new FormControl<string>('');
  openingDateForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  closingDateForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  openingTimeForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  closingTimeForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  descriptionSummerForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  descriptionWinterForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  accessSummerForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  accessWinterForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  facilitiesSummerForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  facilitiesWinterForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  informationSummerForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  informationWinterForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  resortForm: FormControl = new FormControl<number | null>(
    null,
    Validators.required
  );
  establishmentForm: FormGroup<EstablishmentForm>;
  // Equipments establishment & room
  poolForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  spaForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  saunaForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  hammamForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  balconyTerraceForm: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );
  tvForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  chimneyForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  bbqForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  // Access
  pmrForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  // Services
  parkingForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  animalsAdmittedForm: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );
  wifiForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  childrenClubForm: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );
  // Accommodation type
  typeForm: FormControl<AccommodationType | null> =
    new FormControl<AccommodationType | null>(null);
  accommodationTypesElements: OptionElement<AccommodationType>[] = [
    {
      id: AccommodationType.APPARTEMENT,
      label: 'Appartement'
    },
    {
      id: AccommodationType.CHALET,
      label: 'Chalet'
    },
    {
      id: AccommodationType.HOTEL,
      label: 'Hôtel'
    },
    {
      id: AccommodationType.HOUSE,
      label: 'Maison'
    },
    {
      id: AccommodationType.STUDIO,
      label: 'Studio'
    },
    {
      id: AccommodationType.RESIDENCE,
      label: 'Résidence'
    },
    {
      id: AccommodationType.HOLIDAYS_VILLAGE,
      label: 'Village Vacances'
    }
  ];
  equipmentsFilter: OptionElement<number>[] = [
    {
      id: 1,
      label: 'Piscine',
      control: this.poolForm
    },
    {
      id: 2,
      label: 'Spa',
      control: this.spaForm
    },
    {
      id: 3,
      label: 'Sauna',
      control: this.saunaForm
    },
    {
      id: 4,
      label: 'Hammam',
      control: this.hammamForm
    },
    {
      id: 5,
      label: 'Balcon / Terrasse',
      control: this.balconyTerraceForm
    },
    {
      id: 6,
      label: 'TV',
      control: this.tvForm
    },
    {
      id: 7,
      label: 'Cheminée',
      control: this.chimneyForm
    },
    {
      id: 8,
      label: 'Barbecue',
      control: this.bbqForm
    }
  ];
  accessFilter: OptionElement<number>[] = [
    {
      id: 1,
      label: 'Accès PMR',
      control: this.pmrForm
    }
  ];
  servicesFilter: OptionElement<number>[] = [
    {
      id: 1,
      label: 'Parking',
      control: this.parkingForm
    },
    {
      id: 2,
      label: 'Animaux admis',
      control: this.animalsAdmittedForm
    },
    {
      id: 3,
      label: 'Wifi',
      control: this.wifiForm
    },
    {
      id: 4,
      label: 'Club enfants',
      control: this.childrenClubForm
    }
  ];
  @Output() establishmentChanged: EventEmitter<number> =
    new EventEmitter<number>();
  protected readonly SeasonEnum = SeasonEnum;

  constructor(
    private accommodationService: AccommodationService,
    private imagesService: ImagesService,
    private store: Store
  ) {
    super();

    this.establishmentForm = new FormGroup<EstablishmentForm>({
      name: this.nameForm,
      address: this.addressForm,
      city: this.cityForm,
      email: this.emailForm,
      zip: this.zipForm,
      country: this.countryForm,
      phone1: this.phone1Form,
      phone2: this.phone2Form,
      openingDate: this.openingDateForm,
      closingDate: this.closingDateForm,
      openingTime: this.openingTimeForm,
      closingTime: this.closingTimeForm,
      descriptionSummer: this.descriptionSummerForm,
      descriptionWinter: this.descriptionWinterForm,
      accessSummer: this.accessSummerForm,
      accessWinter: this.accessWinterForm,
      facilitiesSummer: this.facilitiesSummerForm,
      facilitiesWinter: this.facilitiesWinterForm,
      informationSummer: this.informationSummerForm,
      informationWinter: this.informationWinterForm,
      resort: this.resortForm,
      type: this.typeForm,
      pool: this.poolForm,
      spa: this.spaForm,
      sauna: this.saunaForm,
      hammam: this.hammamForm,
      balconyTerrace: this.balconyTerraceForm,
      tv: this.tvForm,
      chimney: this.chimneyForm,
      bbq: this.bbqForm,
      pmr: this.pmrForm,
      parking: this.parkingForm,
      animalsAdmitted: this.animalsAdmittedForm,
      wifi: this.wifiForm,
      childrenClub: this.childrenClubForm
    });

    this.nameSearchForm.valueChanges
      .pipe(debounceTime(200))
      .subscribe(() => this.getEstablishments(this.partner));
  }

  @Input() set partnerCode(code: string | null) {
    if (code) {
      this.getEstablishments(code);
      this.partner = code;
    }
  }

  @Input() set resorts(resorts: Resort[]) {
    if (resorts) {
      this.resortElements = [
        ...this.resortElements,
        ...resorts.map((resort) => {
          return {
            id: resort.id,
            label: resort.name
          } as OptionElement<number>;
        })
      ];
    }
  }

  getEstablishments(partnerCode: string) {
    this.register(
      this.accommodationService
        .getEstablishmentsByPartnerCode(
          partnerCode,
          this.nameSearchForm.value as string,
          this.pageEstablishment.pageable.pageNumber,
          this.pageEstablishment.pageable.pageSize
        )
        .subscribe((page) => (this.pageEstablishment = page))
    );
  }

  openEditDrawer(establishment?: AccommodationEstablishment) {
    this.id = undefined;
    this.establishmentForm.reset();
    this.photosFilesWinter = [];
    this.photosFilesSummer = [];
    this.photosImagesWinter = [];
    this.photosImagesSummer = [];

    if (establishment) {
      this.id = establishment.id;
      this.nameForm.setValue(establishment.name);
      this.addressForm.setValue(establishment.address);
      this.emailForm.setValue(establishment.email);
      this.cityForm.setValue(establishment.city);
      this.zipForm.setValue(establishment.zip);
      this.countryForm.setValue(establishment.country);
      this.phone1Form.setValue(establishment.phone1);
      this.phone2Form.setValue(establishment.phone2);
      this.openingDateForm.setValue(
        establishment.openingDate.format('DD/MM/YYYY')
      );
      this.closingDateForm.setValue(
        establishment.closingDate.format('DD/MM/YYYY')
      );
      this.openingTimeForm.setValue(establishment.openingTime.format('HH:mm'));
      this.closingTimeForm.setValue(establishment.closingTime.format('HH:mm'));
      this.descriptionSummerForm.setValue(establishment.descriptionSummer);
      this.descriptionWinterForm.setValue(establishment.descriptionWinter);
      this.accessSummerForm.setValue(establishment.accessSummer);
      this.accessWinterForm.setValue(establishment.accessWinter);
      this.facilitiesSummerForm.setValue(establishment.facilitiesSummer);
      this.facilitiesWinterForm.setValue(establishment.facilitiesWinter);
      this.informationSummerForm.setValue(establishment.informationSummer);
      this.informationWinterForm.setValue(establishment.informationWinter);

      this.typeForm.setValue(establishment.type);
      this.poolForm.setValue(establishment.pool);
      this.spaForm.setValue(establishment.spa);
      this.saunaForm.setValue(establishment.sauna);
      this.hammamForm.setValue(establishment.hammam);
      this.balconyTerraceForm.setValue(establishment.balconyTerrace);
      this.tvForm.setValue(establishment.tv);
      this.chimneyForm.setValue(establishment.chimney);
      this.bbqForm.setValue(establishment.bbq);
      this.pmrForm.setValue(establishment.pmr);
      this.parkingForm.setValue(establishment.parking);
      this.animalsAdmittedForm.setValue(establishment.animalsAdmitted);
      this.wifiForm.setValue(establishment.wifi);
      this.childrenClubForm.setValue(establishment.childrenClub);

      if (establishment.pictureEntities) {
        establishment.pictureEntities.map((pic, index) => {
          const image = new Image();
          image.srcset = pic.url;
          image.alt = `Photo ${index} de l'hébergement`;

          switch (pic.season) {
            case SeasonEnum.WINTER:
              this.photosImagesWinter.push(image);
              break;
            case SeasonEnum.SUMMER:
              this.photosImagesSummer.push(image);
              break;
            case SeasonEnum.YEAR:
              this.photosImagesSummer.push(image);
              this.photosImagesWinter.push(image);
              break;
            default:
              break;
          }
        });
      }

      if (establishment.resort) {
        this.resortForm.setValue(establishment.resort.id);
      }

      this.editMode = 'edit';
    } else {
      this.editMode = 'create';
    }

    this.openDrawer = true;
  }

  onTableDataChange(event: any) {
    this.selectedEstablishment = undefined;
    this.pageEstablishment.pageable.pageNumber = event - 1;
    this.getEstablishments(this.partner);
  }

  addPhotoFile(files: File[], season: SeasonEnum) {
    if (season === SeasonEnum.WINTER) {
      this.photosFilesWinter.push(...files);
    } else {
      this.photosFilesSummer.push(...files);
    }
  }

  uploadPictures(
    establishmentId: number,
    photos: File[],
    photoImages: HTMLImageElement[],
    season: SeasonEnum
  ) {
    if (!photos.length) {
      return this.accommodationService.upsertEstablishmentPictures(
        establishmentId,
        this.partner,
        photoImages.map(
          (img) => new Picture({ url: img.srcset, season: season })
        )
      );
    }
    return forkJoin(
      photos.map((file) =>
        this.imagesService
          .uploadImage(
            file,
            `accommodation/${OriginEnum.RESALYS}/${this.partner}/${establishmentId}/`,
            `image-establishment-${uuidv4()}`,
            `webp`
          )
          .pipe(
            map((url) => {
              return { name: file.name, url } as ImageUrl;
            })
          )
      )
    ).pipe(
      switchMap((pictures) => {
        const allPictures: Picture[] = photoImages.map((image) => {
          const found = pictures.find((picture) => picture.name === image.alt);
          return found
            ? new Picture({ url: found.url, season: season })
            : new Picture({ url: image.srcset, season: season });
        });
        return this.accommodationService.upsertEstablishmentPictures(
          establishmentId,
          this.partner,
          allPictures
        );
      })
    );
  }

  upsert() {
    this.loading = true;

    const establishment: AccommodationEstablishment = {
      id: this.id,
      ...(this.establishmentForm.getRawValue() as any),
      openingDate: moment(this.openingDateForm.value, 'DD/MM/YYYY'),
      closingDate: moment(this.closingDateForm.value, 'DD/MM/YYYY'),
      partnerCode: this.partner,
      resort: new Resort({
        id: this.resortForm.value
      }),
      origin: OriginEnum.RESALYS
    };

    this.accommodationService
      .upsertEstablishment(establishment)
      .pipe(
        switchMap((establishmentId) => {
          return combineLatest([
            this.uploadPictures(
              establishmentId,
              this.photosFilesSummer,
              this.photosImagesSummer,
              this.SeasonEnum.SUMMER
            ),
            this.uploadPictures(
              establishmentId,
              this.photosFilesWinter,
              this.photosImagesWinter,
              this.SeasonEnum.WINTER
            )
          ]);
        })
      )
      .subscribe(
        () => {
          this.loading = false;
          this.photosFilesWinter = [];
          this.photosFilesSummer = [];
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                timeout: 2000,
                level: 'success',
                message:
                  this.editMode === 'create'
                    ? "Création d'un établissement"
                    : "Modification d'un établissement"
              })
            )
          );
          this.openDrawer = false;
          this.getEstablishments(this.partner);
        },
        () => {
          this.loading = false;
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                timeout: 5000,
                level: 'error',
                message: "Une erreur s'est produite"
              })
            )
          );
        }
      );
  }

  selectEstablishment(establishment: AccommodationEstablishment) {
    this.selectedEstablishment = establishment;
    this.establishmentChanged.emit(this.selectedEstablishment.id);
  }

  listUpdated(imgs: HTMLImageElement[], season: SeasonEnum) {
    switch (season) {
      case SeasonEnum.WINTER:
        this.photosImagesWinter = imgs;
        break;

      case SeasonEnum.SUMMER:
        this.photosImagesSummer = imgs;
        break;
    }
  }

  getControl(control: FormControl<boolean | null> | undefined) {
    return control as FormControl<boolean | null>;
  }
}
