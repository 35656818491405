import { Component } from '@angular/core';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { PromoCode } from '../../../shared/models/promo-code';

@Component({
  selector: 'dash-list-promo-code',
  templateUrl: './list-promo-code.component.html',
  styleUrls: ['./list-promo-code.component.scss']
})
export class ListPromoCodeComponent extends PageableComponent<PromoCode> {
  headers = [
    'Id',
    'Nom',
    'Valeur à appliquer',
    'Type',
    'Prix min.',
    'Nb. utilisation',
    'Nb. utilisation max',
    'Date de début',
    'Date de fin'
  ];
}
