import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../app.state';
import { AgeRange } from '../../../shared/models/age-range';
import { Role } from '../../../shared/models/enum/Role';

@Component({
  selector: 'dash-list-age-range',
  templateUrl: './list-age-range.component.html',
  styleUrls: ['./list-age-range.component.scss']
})
export class ListAgeRangeComponent {
  @Select(AppState.roles) roles$: Observable<string[]>;
  headers = ['Catégorie', 'Âge', 'Origine'];

  @Input() ageRanges: AgeRange[] = [];
  @Output() editAgeChanged: EventEmitter<AgeRange> =
    new EventEmitter<AgeRange>();
  @Output() createEventChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
    this.roles$.subscribe((roles) => {
      if (roles.indexOf(Role.ROLE_ADMIN) !== -1) {
        this.headers.unshift('Id');
      }
    });
  }

  editAge(age: AgeRange): void {
    this.editAgeChanged.emit(age);
  }
}
