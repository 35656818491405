import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomField } from '../shared/models/custom-field';
import { CustomFields } from '../shared/models/custom-fields-ids';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService extends RepositoryService {
  private URL_CUSTOM_FIELD = 'api/backoffice/custom-field';

  constructor(http: HttpClient) {
    super(http);
  }

  getCustomFields(): Observable<CustomField[]> {
    return this.get(this.URL_CUSTOM_FIELD + '/get-custom-fields');
  }

  upsertCustomField(customField: CustomField): Observable<void> {
    return this.post(
      this.URL_CUSTOM_FIELD + '/upsert-custom-field',
      customField
    );
  }

  deleteCustomField(customFieldId: number): Observable<void> {
    return this.delete(
      this.URL_CUSTOM_FIELD +
        '/delete-custom-field?customFieldId=' +
        customFieldId
    );
  }

  deleteFieldsExperience(
    experienceId: number,
    customFields: CustomFields
  ): Observable<void> {
    return this.delete(
      this.URL_CUSTOM_FIELD +
        `/delete-field-experience?experienceId=${experienceId}`,
      customFields
    );
  }
}
