import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Experience } from '../shared/models/experience';
import { Page } from '../shared/models/page';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ExperienceService
  extends RepositoryService
  implements PageableService<Experience>, CrudService<Experience>
{
  private URL_EXPERIENCE = 'api/backoffice/experience';

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<Experience>> {
    return this.get(
      this.URL_EXPERIENCE +
        `/all-experience-group?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map(
        (page: Page<Experience>) =>
          new Page<Experience>({ ...page }, Experience)
      )
    );
  }

  upsert(experienceGroup: Experience): Observable<void> {
    return this.post(this.URL_EXPERIENCE, experienceGroup);
  }

  deleteById(id: number): Observable<void> {
    return this.delete(this.URL_EXPERIENCE + `?id=${id}`);
  }

  getExperiencesByPartnerId(partnerId: number): Observable<Experience[]> {
    return this.get(
      this.URL_EXPERIENCE +
        `/get-experience-by-partner-id?partnerId=${partnerId}`
    ).pipe(
      map((experiences: Experience[]) =>
        experiences.map(
          (experience: Experience) => new Experience({ ...experience })
        )
      )
    );
  }

  getExperiencesByResortAndDates(
    resortId: number | null
  ): Observable<Experience[]> {
    return this.get(
      this.URL_EXPERIENCE + `/by-resort?resortId=${resortId}`
    ).pipe(
      map((experiences: Experience[]) =>
        experiences.map(
          (experience: Experience) => new Experience({ ...experience })
        )
      )
    );
  }
}
