import { Component } from '@angular/core';
import { MaevaService } from '../../service/maeva.service';
import { ResortLabelService } from '../../service/resort-label.service';
import { ResortService } from '../../service/resort.service';
import { PageComponent } from '../../shared/component/page.component';
import { ResortMaeva } from '../../shared/models/accomodation/resort-maeva';
import { ResortLabel } from '../../shared/models/resort-label';
import { Resort } from '../../shared/models/resort/resort';

@Component({
  selector: 'dash-create-resort',
  templateUrl: './create-resort.component.html',
  styleUrls: ['./create-resort.component.scss']
})
export class CreateResortComponent extends PageComponent<Resort> {
  resortsMaeva: ResortMaeva[] = [];
  resortLabels: ResortLabel[] = [];

  constructor(
    resortService: ResortService,
    private resortLabelService: ResortLabelService,
    private maevaService: MaevaService
  ) {
    super(resortService);

    this.maevaService
      .getResorts()
      .subscribe((resorts) => (this.resortsMaeva = resorts));

    this.resortLabelService
      .fetchAll()
      .subscribe((resortLabels) => (this.resortLabels = resortLabels));

    this.page.pageable.pageSize = 12;
  }
}
