import moment, { Moment } from 'moment';

export class Customer {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  birthdate: Moment;
  phoneNumber: string;
  verymountain = false;
  partner: number;
  nameEnterprise: string;
  siretEnterprise: string;
  address: string;
  postCode: string;
  city: string;

  constructor(obj: Partial<Customer>) {
    Object.assign(this, obj);
    if (this.birthdate !== null) {
      this.birthdate = moment(this.birthdate);
    }
  }

  get isEnterprise(): boolean {
    return (
      this.nameEnterprise !== null &&
      this.nameEnterprise !== undefined &&
      this.nameEnterprise.length > 0
    );
  }
}
