import moment, { Moment } from 'moment';
import { User } from '../user';

export class Order {
  id: number;
  resort: string;
  user: User;
  startDate: Moment;
  endDate: Moment;
  payed: boolean;
  transactionId: string;
  date: Moment;
  totalPrice: number;
  status: string;
  paymentIntent: string;
  paymentIntentComplete: string;
  fullPaymentLimitDate: Moment;
  hasPayedFull: boolean;

  constructor(obj: Partial<Order>) {
    Object.assign(this, obj);

    if (this.date) {
      this.date = moment(this.date, 'YYYY-MM-DDTHH:mm:ss.SSSSSS');
    }

    if (this.startDate) {
      this.startDate = moment(this.startDate, 'YYYY-MM-DD');
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate, 'YYYY-MM-DD');
    }

    if (this.fullPaymentLimitDate) {
      this.fullPaymentLimitDate = moment(
        this.fullPaymentLimitDate,
        'YYYY-MM-DD'
      );
    }
  }
}
