<dash-list-edit title="Stations"
                labelCreate="Ajouter une station"
                searchLabel="Recherche de station"
                searchPlaceholder="Rechercher par nom ..."
                paginationId="resorts"
                [searchForm]="searchForm"
                [headers]="headers"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)">
  <ng-container body *ngFor="let resort of page.content | paginate : {
                  id: 'resorts',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(resort)">
      <td>{{ resort.id }}</td>
      <td>{{ resort.name }}</td>
      <td><a [href]="generateRedirectLink(resort.name)" target="_blank">Voir la page de station</a></td>
    </tr>
  </ng-container>
</dash-list-edit>

