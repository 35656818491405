import { Component } from '@angular/core';
import { ActivityService } from '../../service/activity.service';
import { PageComponent } from '../../shared/component/page.component';
import { Activity } from '../../shared/models/activity';

@Component({
  selector: 'dash-upsert-activity',
  templateUrl: './generic-activity.component.html',
  styleUrls: ['./generic-activity.component.scss']
})
export class GenericActivityComponent extends PageComponent<Activity> {
  constructor(private activityService: ActivityService) {
    super(activityService);

    this.page.pageable.pageSize = 12;
  }

  delete(activity: Activity): void {
    this.activityService
      .deleteActivity(activity)
      .subscribe(() =>
        this.fetchPageable(this.page, this.searchForm.value as string)
      );
  }
}
