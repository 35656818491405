<dash-list-edit id="id__table-list-products-price-promotion"
                paginationId="products-price-promotion"
                [headers]="headers"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                labelCreate="Ajouter une promotion"
                title="Promotion sur remise de produits">
  <ng-container body *ngFor="let productPricePromotion of page.content | paginate : {
                id: 'products-price-promotion',
                itemsPerPage: page.pageable.pageSize,
                currentPage: page.pageable.pageNumber + 1,
                totalItems: page.totalElements }">
    <tr (click)="edit(productPricePromotion)">
      <td>{{ productPricePromotion.id }}</td>
      <td>{{ productPricePromotion.startDate.format('DD/MM/YYYY') }} - {{ productPricePromotion.endDate.format('DD/MM/YYYY') }}</td>
      <td>{{ productPricePromotion.promotion }} %</td>
      <td>{{ getLabelProducts(productPricePromotion.products) }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
