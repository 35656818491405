import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PackageGrid } from '../shared/models/package-grid';
import { Page } from '../shared/models/page';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class PackageGridService
  extends RepositoryService
  implements PageableService<PackageGrid>, CrudService<PackageGrid>
{
  private URL_PACKAGE_GRID = 'api/backoffice/package-grid';

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<PackageGrid>> {
    return this.get(
      this.URL_PACKAGE_GRID +
        `/all-package-grid?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map(
        (page: Page<PackageGrid>) =>
          new Page<PackageGrid>({ ...page }, PackageGrid)
      )
    );
  }

  upsert(pckGrid: PackageGrid): Observable<void> {
    return this.post(this.URL_PACKAGE_GRID, pckGrid);
  }

  deleteById(id: number): Observable<void> {
    return this.delete(this.URL_PACKAGE_GRID + `?id=${id}`);
  }

  fetchByExperienceId(experienceId: number) {
    return this.get(
      this.URL_PACKAGE_GRID + `/fetch-by-experience?id=${experienceId}`
    ).pipe(
      map((pcksGrid: PackageGrid[]) =>
        pcksGrid.map((pckGrid) => new PackageGrid({ ...pckGrid }))
      )
    );
  }
}
