import { DragElement } from 'atomic-lib';
import { CarouselProduct } from './carousel-product';
import { CarouselProductTypeEnum } from './enum/carousel-product-type.enum';

export class Carousel implements DragElement {
  id: number;
  title: string;
  type: CarouselProductTypeEnum;
  zoneId: number;
  enabled: boolean;
  products: CarouselProduct[];
  name: string;

  constructor(obj: Partial<CarouselProduct>) {
    Object.assign(this, obj);

    if (this.products) {
      this.products = this.products.map(
        (product) => new CarouselProduct(product)
      );
    }

    if (this.title) {
      this.name = this.title;
    }
  }
}
