import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OptionElement } from 'atomic-lib';
import { filter, switchMap } from 'rxjs/operators';
import { AccommodationService } from '../../service/accommodation.service';
import { ResortService } from '../../service/resort.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { AccommodationEstablishment } from '../../shared/models/accomodation/accommodation-establishment';
import { RoomFeature } from '../../shared/models/accomodation/room-feature';
import { Resort } from '../../shared/models/resort/resort';

@Component({
  selector: 'dash-accommodation-product',
  templateUrl: './accommodation-product.component.html',
  styleUrls: ['./accommodation-product.component.scss']
})
export class AccommodationProductComponent extends RxjsComponent {
  partnerControl: FormControl<string | null> = new FormControl<string | null>(
    null,
    Validators.required
  );
  partners: OptionElement<string>[] = [
    {
      id: null,
      label: 'Choisir un partenaire',
      disabled: true,
      classCss: 'disabled'
    }
  ];
  establishments: AccommodationEstablishment[] = [];
  establishment: number | undefined;
  resorts: Resort[] = [];
  roomFeatures: RoomFeature[] = [];

  constructor(
    private accommodationService: AccommodationService,
    private resortService: ResortService
  ) {
    super();

    this.register(
      this.accommodationService.getAllPartners().subscribe((partners) => {
        this.partners = [
          ...this.partners,
          ...partners.map((partner) => {
            return {
              id: partner.partnerCode,
              label: partner.name + ' - ' + partner.origin.toLowerCase()
            } as OptionElement<string>;
          })
        ];

        this.partnerControl.setValue(this.partners[0].id);
      })
    );

    this.register(
      this.partnerControl.valueChanges
        .pipe(
          filter((partnerCode) => !!partnerCode),
          switchMap((partnerCode) =>
            this.accommodationService.getAllEstablishmentsByPartnerCode(
              partnerCode as string
            )
          )
        )
        .subscribe((establishments) => {
          this.establishments = establishments;
          this.establishment = undefined;
        })
    );

    this.register(
      this.resortService
        .getAllStations()
        .subscribe((resorts) => (this.resorts = resorts))
    );

    this.register(
      this.accommodationService
        .getAllRoomFeatures()
        .subscribe((features) => (this.roomFeatures = features))
    );
  }
}
