import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CarouselZone } from '../shared/models/carousel-zone';
import { Page } from '../shared/models/page';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CarouselZoneService
  extends RepositoryService
  implements PageableService<CarouselZone>, CrudService<CarouselZone>
{
  private URL_CMS = 'api/backoffice/cms/carousel-zone';

  constructor(http: HttpClient) {
    super(http);
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<CarouselZone>> {
    return this.get(
      this.URL_CMS +
        `/get-page?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map(
        (page: Page<CarouselZone>) =>
          new Page<CarouselZone>({ ...page }, CarouselZone)
      )
    );
  }

  getAll(): Observable<CarouselZone[]> {
    return this.get(this.URL_CMS + '/all').pipe(
      map((carouselZones: CarouselZone[]) =>
        carouselZones.map(
          (carouselZone: CarouselZone) => new CarouselZone({ ...carouselZone })
        )
      )
    );
  }

  upsert(entity: CarouselZone): Observable<void> {
    return this.post(this.URL_CMS, entity);
  }
  deleteById(id: number): Observable<void> {
    return this.delete(this.URL_CMS + `?id=${id}`);
  }
}
