<div class="vsk__card gap-4">
  <h1 class="mb-4">Rechercher</h1>

  <div class="flex-row flex w-full gap-4 items-center">
    <!-- Establishment's opening date -->
    <atc-input-date [control]="startDateForm"
                    class="flex flex-1"
                    label="Début de séjour*"></atc-input-date>

    <!-- Establishment's closing date -->
    <atc-input-date [control]="endDateForm"
                    class="flex flex-1"
                    label="Fin de séjour*"></atc-input-date>

    <atc-select label="Partenaire"
                class="flex-1 flex"
                [searchable]="true"
                [control]="partnerForm"
                [optionElements]="partners">
    </atc-select>
  </div>

  <div class="flex-row flex w-full gap-4 items-center">
    <atc-select [control]="resortForm"
                [optionElements]="resortElements"
                [searchable]="true"
                class="flex flex-1"
                label="Station">
    </atc-select>

    <!-- Room's min people -->
    <atc-input [control]="minPeopleForm"
               errorMessage="Champs obligatoire de type nombre"
               placeholder="1"
               type="number"
               class="flex flex-1"
               label="Nombre de personnes min.*"></atc-input>

    <!-- Room's max people -->
    <atc-input [control]="maxPeopleForm"
               errorMessage="Champs obligatoire de type nombre"
               placeholder="4"
               type="number"
               class="flex flex-1"
               label="Nombre de personnes max.*"></atc-input>
  </div>

  <div class="flex-row flex w-full justify-end">
    <atc-button
                icon="search"
                label="Rechercher"
                [disabled]="searchForm.invalid"
                (click)="search()"></atc-button>
  </div>
</div>

<!-- Room's list -->
<dash-list-edit title="Chambres"
                [isEmpty]="!page.content.length"
                [headers]="headers">
  <ng-container body *ngFor="let room of page.content | paginate : {
                  id: 'roomSearch',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr>
      <td>{{room.partner.name}}</td>
      <td>{{room.establishment.resort.name || 'Pas de station'}}</td>
      <td>{{room.establishment.name}}</td>
      <td>{{room.room.name}}</td>
      <td>{{room.room.minPeople}}</td>
      <td>{{room.room.maxPeople}}</td>
      <td>{{room.publicPrice}} €</td>
      <td>{{room.price}} €</td>
    </tr>
  </ng-container>

  <div paginate class="flex flex-row justify-center mt-2">
    <pagination-controls
      (pageChange)="onTableDataChange($event)"
      [id]="'roomSearch'"
      [maxSize]="9"
      [autoHide]="true"
      [responsive]="true"
      nextLabel="Suivant"
      previousLabel="Précédent"
    >
    </pagination-controls>
  </div>
</dash-list-edit>
