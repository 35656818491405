import { OriginEnum } from '../enum/origin.enum';

export class AccommodationPartner {
  origin: OriginEnum;
  name: string;
  partnerCode: string;

  constructor(obj: Partial<AccommodationPartner>) {
    Object.assign(this, obj);
  }
}
