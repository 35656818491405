import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import moment from 'moment';
import { switchMap } from 'rxjs/operators';
import { RxjsComponent } from 'src/app/shared/component/rxjs.component';
import { TriggerAlert } from '../../../app.action';
import { AppState } from '../../../app.state';
import { PackageGridService } from '../../../service/package-grid.service';
import { PackageService } from '../../../service/package.service';
import { AgeRange } from '../../../shared/models/age-range';
import { Alert } from '../../../shared/models/alert';
import { ExperienceActivityPartner } from '../../../shared/models/experience-activity-partner';
import { Package } from '../../../shared/models/package/package';
import { PackageMin } from '../../../shared/models/package/package-min';
import { PackagePrice } from '../../../shared/models/package/package-price';

export interface PackagePriceForm {
  nbMax: FormControl<number | null>;
  nbMin: FormControl<number | null>;
  startDate: FormControl<string | null>;
  endDate: FormControl<string | null>;
  price: FormControl<string | null>;
  publicPrice: FormControl<string | null>;
}

export interface PackageForm {
  ageRange: FormControl<number | null>;
  experience: FormControl<number | null>;
  packageGrid: FormControl<number | null>;
}

@Component({
  selector: 'dash-edit-package',
  templateUrl: './edit-package.component.html',
  styleUrls: ['./edit-package.component.scss']
})
export class EditPackageComponent extends RxjsComponent {
  loading = false;
  id: number | undefined;
  enabled = true;
  prices: PackagePrice[] = [];
  editIndex: number | undefined;
  hasErrors = false;

  experienceOptions: OptionElement<number>[] = [
    {
      id: null,
      label: `Choisir une expérience`,
      disabled: true,
      classCss: 'disabled'
    }
  ];

  packageGridElements: OptionElement<number>[] = [
    {
      id: null,
      label: `Choisir une expérience*`,
      disabled: true,
      classCss: 'disabled'
    }
  ];

  ageRangeExperiences: OptionElement<number>[] = [
    {
      id: null,
      label: `Choisir une tranche d'âge`,
      disabled: true,
      classCss: 'disabled'
    }
  ];

  // Form
  nbMaxForm = new FormControl<number | null>(10, Validators.required);
  nbMinForm = new FormControl<number | null>(1, Validators.required);
  startDateForm = new FormControl<string | null>(
    moment().format('DD/MM/YYYY'),
    Validators.required
  );
  endDateForm = new FormControl<string | null>(
    moment().add(6, 'month').format('DD/MM/YYYY'),
    Validators.required
  );
  priceForm = new FormControl<string | null>(null, Validators.required);
  publicPriceForm = new FormControl<string | null>(null, Validators.required);
  packagePriceForm: FormGroup<PackagePriceForm>;

  ageRangeForm = new FormControl<number | null>(null, Validators.required);
  experienceForm = new FormControl<number | null>(null, Validators.required);
  packageGridForm = new FormControl<number | null>(null, Validators.required);
  createPackageForm: FormGroup<PackageForm>;

  @Input() editMode: 'create' | 'edit' = 'create';
  @Output() packageChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private packageService: PackageService,
    private store: Store,
    private packageGridService: PackageGridService
  ) {
    super();
    this.createPackageForm = new FormGroup<PackageForm>({
      ageRange: this.ageRangeForm,
      experience: this.experienceForm,
      packageGrid: this.packageGridForm
    });

    this.packagePriceForm = new FormGroup<PackagePriceForm>({
      nbMin: this.nbMinForm,
      nbMax: this.nbMaxForm,
      startDate: this.startDateForm,
      endDate: this.endDateForm,
      price: this.priceForm,
      publicPrice: this.publicPriceForm
    });

    this.experienceForm.valueChanges
      .pipe(
        switchMap((exp) =>
          this.packageGridService.fetchByExperienceId(exp as number)
        )
      )
      .subscribe((pckGrids) => {
        this.packageGridElements = [
          {
            id: null,
            label: `Choisir une grille*`,
            disabled: true,
            classCss: 'disabled'
          },
          ...pckGrids.map((pckGrid) => {
            return {
              id: pckGrid.id,
              label: pckGrid.name
            };
          })
        ];

        if (pckGrids.length === 1) {
          this.packageGridForm.setValue(pckGrids[0].id);
        }
      });
  }

  @Input() set optionsActivity(experiences: ExperienceActivityPartner[]) {
    this.experienceOptions = [
      ...this.experienceOptions,
      ...experiences.map((exp) => {
        return {
          id: exp.option.id,
          label: exp.option.name + ' - ' + exp.activity.activity.name
        };
      })
    ];
  }

  @Input() set ageRanges(range: AgeRange[]) {
    this.ageRangeExperiences = [
      ...this.ageRangeExperiences,
      ...range.map((age) => {
        return {
          id: age.id,
          label: age.name
        };
      })
    ];
  }

  @Input() set pckToEdit(pck: Package | undefined) {
    if (pck) {
      this.prices = [...pck.prices];
      this.ageRangeForm.setValue(pck.ageRange.id);
      this.experienceForm.setValue(pck.experience.id);
      this.packageGridForm.setValue(pck.packageGrid.id);
      this.hasErrors = pck.hasErrors;

      if (pck.id) {
        this.editMode = 'edit';
        this.id = pck.id;
      } else {
        this.editMode = 'create';
      }
    }
  }

  toastError(err: HttpErrorResponse): void {
    switch (err.status) {
      case 302:
        this.store.dispatch(
          new TriggerAlert(
            new Alert({
              message: err.error.message,
              level: 'error',
              timeout: 5000
            })
          )
        );
        break;
      default:
        this.store.dispatch(
          new TriggerAlert(
            new Alert({
              message:
                'Une erreur est survenue, veuillez réessayer dans quelques instants ...',
              level: 'error',
              timeout: 5000
            })
          )
        );
    }
  }

  upsert(): void {
    this.loading = true;
    const partner = this.store.selectSnapshot(AppState.partnerId);
    this.register(
      this.packageService
        .upsertPackage(
          new PackageMin({
            id: this.id,
            ...(this.createPackageForm.getRawValue() as any),
            prices: this.prices,
            enabled: this.enabled,
            partner
          })
        )
        .subscribe(
          () => {
            this.packageChanged.emit();
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  message: 'Nouveau tarif créé / modifié',
                  level: 'success',
                  timeout: 2000
                })
              )
            );
          },
          (err: HttpErrorResponse) => {
            this.loading = false;
            this.toastError(err);
          }
        )
    );
  }

  delete(): void {
    this.enabled = false;
    this.loading = true;
    const partner = this.store.selectSnapshot(AppState.partnerId) as number;
    this.register(
      this.packageService
        .upsertPackage(
          new PackageMin({
            id: this.id,
            ...(this.createPackageForm.getRawValue() as any),
            prices: this.prices,
            enabled: this.enabled,
            partner
          })
        )
        .subscribe(
          () => {
            this.packageChanged.emit();
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  message: 'Tarif supprimé',
                  level: 'success',
                  timeout: 2000
                })
              )
            );
          },
          (err: HttpErrorResponse) => {
            this.loading = false;
            this.toastError(err);
          }
        )
    );
  }

  addPrice() {
    const pckPrice = new PackagePrice({
      ...(this.packagePriceForm.getRawValue() as any),
      startDate: moment(this.startDateForm.value, 'DD/MM/YYYY'),
      endDate: moment(this.endDateForm.value, 'DD/MM/YYYY'),
      price: Number(
        this.priceForm.value
          ?.replace(',', '.')
          .replace(' €', '')
          .replace(' ', '')
      ),
      publicPrice: Number(
        this.publicPriceForm.value
          ?.replace(',', '.')
          .replace(' €', '')
          .replace(' ', '')
      )
    });

    if (this.editIndex !== undefined) {
      this.prices[this.editIndex] = pckPrice;
      this.editIndex = undefined;
    } else {
      this.prices.push(pckPrice);
    }

    this.packagePriceForm.reset();
    this.nbMinForm.setValue(1);
    this.nbMaxForm.setValue(10);
    this.startDateForm.setValue(moment().format('DD/MM/YYYY'));
    this.endDateForm.setValue(moment().add(6, 'month').format('DD/MM/YYYY'));
  }

  deletePrice(index: number) {
    if (this.prices.length === 1) {
      this.prices = [];
      return;
    }

    this.prices.splice(index, 1);
  }

  editPrice(price: PackagePrice, index: number) {
    this.nbMinForm.setValue(price.nbMin);
    this.nbMaxForm.setValue(price.nbMax);
    this.priceForm.setValue(price.price.toString());
    this.publicPriceForm.setValue(price.publicPrice.toString());
    this.startDateForm.setValue(price.startDate.format('DD/MM/YYYY'));
    this.endDateForm.setValue(price.endDate.format('DD/MM/YYYY'));
    this.editIndex = index;
  }

  priceFormInvalid() {
    if (this.packagePriceForm.invalid) {
      return true;
    }

    const startDate = moment(this.startDateForm.value, 'DD/MM/YYYY');
    const endDate = moment(this.endDateForm.value, 'DD/MM/YYYY');

    if (startDate.isAfter(endDate)) {
      return true;
    }

    return (
      this.prices.filter((price, index) => {
        if (this.editIndex === index) {
          return false;
        }

        if (
          price.startDate.isBetween(startDate, endDate, 'day', '[]') ||
          price.endDate.isBetween(startDate, endDate, 'day', '[]')
        ) {
          return this.filterGroupSize(price);
        }

        return false;
      }).length > 0
    );
  }

  filterGroupSize(price: PackagePrice) {
    const min = this.nbMinForm.value as number;
    const max = this.nbMaxForm.value as number;

    return !((price.nbMax < min || max < price.nbMin) && min <= max);
  }

  isFormValid() {
    return this.createPackageForm.valid && this.prices?.length > 0;
  }
}
