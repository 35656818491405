import moment, { Moment } from 'moment';
import { Product } from './product';

export class ProductPricePromotion {
  id: number;
  products: Product[];
  startDate: Moment;
  endDate: Moment;
  promotion: number;

  constructor(obj: Partial<ProductPricePromotion>) {
    Object.assign(this, obj);

    if (this.products) {
      this.products = this.products.map(
        (product) => new Product({ ...product })
      );
    }

    if (this.startDate) {
      this.startDate = moment(this.startDate);
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate);
    }
  }
}
