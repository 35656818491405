import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OptionElement } from 'atomic-lib';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ElibertyService } from '../../service/eliberty.service';
import { ResortService } from '../../service/resort.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { Product } from '../../shared/models/eliberty/product';
import { Variant } from '../../shared/models/eliberty/variant';
import { Page } from '../../shared/models/page';
import { Pageable } from '../../shared/models/pageable';

@Component({
  selector: 'dash-eliberty-catalog',
  templateUrl: './eliberty-catalog.component.html',
  styleUrls: ['./eliberty-catalog.component.scss']
})
export class ElibertyCatalogComponent extends RxjsComponent implements OnInit {
  resortEliberty: OptionElement<number>[] = [];
  allResorts: OptionElement<number>[] = [];
  catalogOptions: OptionElement<number>[] = [];
  currentProduct: Product | null;
  currentVariant: Variant | null;

  resortForm: FormControl<number | null> = new FormControl<number | null>(null);
  catalogForm: FormControl<number | null> = new FormControl<number | null>(
    null
  );
  searchForm: FormControl<string | null> = new FormControl();

  pageSize = 10;
  pageProduct: Page<Product> = this.resetPage();
  pageVariant: Page<Variant> = this.resetPage();

  constructor(
    private resortService: ResortService,
    private elibertyService: ElibertyService
  ) {
    super();

    this.register(
      this.resortService.getAllResortsEliberty().subscribe((resorts) => {
        this.resortEliberty = resorts.map((resort) => {
          return {
            id: resort.credentialsSkiPass?.contractorId as number,
            label: resort.name
          };
        });
      })
    );

    this.register(
      this.resortService.getAllStations().subscribe((resorts) => {
        this.allResorts = resorts.map((resort) => {
          return {
            id: resort.id,
            label: resort.name
          };
        });
      })
    );

    this.register(
      this.resortForm.valueChanges
        .pipe(
          switchMap((contractorId) =>
            this.elibertyService.getCatalogs(contractorId as number)
          )
        )
        .subscribe((catalogs) => {
          this.catalogForm.setValue(null);
          this.pageProduct = this.resetPage();
          this.pageVariant = this.resetPage();
          this.catalogOptions = catalogs.map((catalog) => {
            return {
              id: catalog.id,
              label: catalog.name
            };
          });

          if (catalogs.length === 1) {
            this.catalogForm.setValue(catalogs[0].id);
          }
        })
    );

    this.register(
      this.catalogForm.valueChanges.subscribe(() => this.fetchProducts())
    );
  }

  ngOnInit(): void {
    this.searchForm = new FormControl();
    this.searchForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.pageProduct.pageable.pageNumber = 0;
      this.fetchProducts();
    });
  }

  editProduct(product: Product) {
    this.currentProduct = product;
    this.fetchVariants();
  }

  editVariant(variant: Variant) {
    this.currentVariant = variant;
  }

  fetchProducts() {
    if (!this.catalogForm.value) {
      return;
    }

    this.elibertyService
      .getProductsPageable(
        this.pageProduct.pageable.pageNumber,
        this.pageSize,
        this.searchForm.value ?? '',
        this.catalogForm.value
      )
      .subscribe((page) => (this.pageProduct = page));
  }

  fetchVariants() {
    if (!this.currentProduct) {
      return;
    }

    this.elibertyService
      .getVariantsPageable(
        this.pageVariant.pageable.pageNumber,
        this.pageSize,
        this.currentProduct.id
      )
      .subscribe((page) => (this.pageVariant = page));
  }

  private resetPage() {
    return new Page<any>({
      content: [],
      pageable: new Pageable({
        pageNumber: 0,
        pageSize: 12
      }),
      totalElements: 0,
      numberOfElements: 0
    });
  }
}
