<atc-side-drawer [loading]="loading"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 (closeChange)="editionComplete.emit()">
  <div header class="w-full my-4">
    <h1 class="text-center">{{editMode === 'edit' ? 'Modification de tag' : 'Nouveau tag'}}</h1>
  </div>

  <div body class="vsk__body">
    <atc-input [control]="labelForm"
               id="id__input-text-edit-tag-label"
               label="Nom du tag"
               placeholder="Tag">
    </atc-input>
  </div>

  <div footer class="vsk__footer">
    <atc-button *ngIf="editMode === 'edit'" color="danger" label="Supprimer" (click)="delete()"></atc-button>
    <div class="vsk__flex"></div>
    <atc-button id="id__button-validate-form"
                [disabled]="!labelForm.valid"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                (click)="upsertTag()">
    </atc-button>
  </div>
</atc-side-drawer>
