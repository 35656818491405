import moment, { Moment } from 'moment';

export class ValidityPeriod {
  id: number;
  type: string;
  active: boolean;
  startDate?: Moment;
  endDate?: Moment;

  constructor(obj: Partial<ValidityPeriod>) {
    Object.assign(this, obj);

    if (this.startDate) {
      this.startDate = moment(this.startDate, 'YYYY-MM-DDTHH:mm:ss');
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate, 'YYYY-MM-DDTHH:mm:ss');
    }
  }
}
