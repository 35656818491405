import { NgForOf } from '@angular/common';
import { Component } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { PageableComponent } from '../../../../shared/component/pageable.component';
import { CredentialsSkiPass } from '../../../../shared/models/resort/credentials-ski-pass';
import { SharedModule } from '../../../../shared/shared.module';

@Component({
  selector: 'dash-list-credentials',
  standalone: true,
  imports: [SharedModule, NgxPaginationModule, NgForOf],
  templateUrl: './list-credentials.component.html',
  styleUrl: './list-credentials.component.scss'
})
export class ListCredentialsComponent extends PageableComponent<CredentialsSkiPass> {
  headers = ['Id station', 'Station'];
}
