<div class="vsk__block-form">
  <img alt="VeryDashboard" src="assets/images/logo.png"/>
  <ng-container *ngIf="state === 'send'">
    <h2>Demande de réinitialisation de mot de passe</h2>

    <div *ngIf="!isSend" class="vsk__inline">
      <atc-input label="Email*"
                 placeholder="jean@monentreprise.fr"
                 errorMessage="Ce champs doit être un email"
                 [control]="emailUserForm"></atc-input>

      <atc-button label="Envoyer"
                  [disabled]="emailUserForm.invalid"
                  (click)="askResetPassword()"></atc-button>
    </div>

    <div class="vsk__loading" *ngIf="loading">
      <atc-loader></atc-loader>
    </div>

    <p *ngIf="isSend && !error">Un mail de réinitialisation de mot de passe vous a été envoyé
      à {{emailUserForm.value}}</p>
    <p class="error-happened" *ngIf="error">{{error}}</p>
    <a routerLink="/login"
       routerLinkActive="true">
      Connexion
    </a>
  </ng-container>

  <ng-container *ngIf="state === 'reset'">
    <h2>Réinitialisation de mot de passe</h2>

    <ng-container *ngIf="!isSend">
      <!-- Email -->
      <atc-input label="Email"
                 [control]="emailUserForm"
                 errorMessage="Ce champs doit être un email"
                 type="email"></atc-input>

      <!-- First password -->
      <atc-input label="Mot de passe"
                 [control]="passwordFirstForm"
                 type="password"
                 placeholder="*****************"
                 [focus]="true"></atc-input>

      <!-- Confirmation password -->
      <atc-input label="Confirmation du mot de passe"
                 [control]="passwordSecondForm"
                 type="password"
                 placeholder="*****************"></atc-input>

      <div class="vsk__inline">
        <div class="vsk__flex"></div>
        <atc-button label="Changer de mot de passe"
                    [disabled]="resetPasswordGroup.invalid"
                    (click)="resetPassword()"></atc-button>
      </div>
    </ng-container>

    <p class="error-happened" *ngIf="error">{{error}}</p>
    <p *ngIf="isSend && !error">Votre mot de passe à bien été changé</p>
    <a routerLink="/login"
       routerLinkActive="true">
      Connexion
    </a>
  </ng-container>
</div>
