import { Component } from '@angular/core';
import { InformationBannerService } from '../../service/information-banner.service';
import { PageComponent } from '../../shared/component/page.component';
import { InformationBanner } from '../../shared/models/information-banner';

@Component({
  selector: 'dash-information-banner',
  templateUrl: './information-banner.component.html',
  styleUrls: ['./information-banner.component.scss']
})
export class InformationBannerComponent extends PageComponent<InformationBanner> {
  constructor(protected service: InformationBannerService) {
    super(service);
  }

  delete() {}
}
