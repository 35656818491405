import { Component } from '@angular/core';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { Partner } from '../../../shared/models/partner';

@Component({
  selector: 'dash-list-partner',
  templateUrl: './list-partner.component.html',
  styleUrls: ['./list-partner.component.scss']
})
export class ListPartnerComponent extends PageableComponent<Partner> {
  headers = ['Id', 'Partenaire', 'Adresse'];
}
