import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { TriggerAlert } from '../../app.action';
import { CrudService } from '../../service/interface/crud.service';
import { Alert } from '../models/alert';
import { RxjsComponent } from './rxjs.component';

@Component({ standalone: true, template: '' })
export abstract class EditComponent<T> extends RxjsComponent implements OnInit {
  loading = false;
  editMode: 'create' | 'edit' = 'create';

  // Form
  id: number | string | undefined;
  name: string;

  public abstract set entityToEdit(entity: T | undefined);

  @Output() editionComplete = new EventEmitter<void>();

  constructor(
    protected service: CrudService<T>,
    protected store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  public abstract isFormValid(): boolean;

  public abstract buildEntity(): T;

  public abstract initForm(): void;

  public create(): void {
    const entity = this.buildEntity();
    this.upsert(entity);
  }

  public upsert(entity: T): void {
    this.loading = true;
    this.register(
      this.service.upsert(entity).subscribe(
        () => {
          this.loading = false;
          const message =
            this.name != null
              ? `${this.name} créé(e) avec succès`
              : 'Créé(e) avec succès';
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message: message,
                level: 'success',
                timeout: 2000
              })
            )
          );
          this.editionComplete.emit();
        },
        (err: any) => {
          this.loading = false;
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message: err.error,
                level: 'error',
                timeout: 5000
              })
            )
          );
        }
      )
    );
  }

  public deleteById(): void {
    this.register(
      this.service.deleteById(this.id as number).subscribe(
        () => {
          this.loading = false;
          this.editionComplete.emit();

          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                level: 'success',
                timeout: 2000,
                message: `${this.name} supprimé(e) avec succès`
              })
            )
          );
        },
        (err: HttpErrorResponse) => {
          this.loading = false;

          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                level: 'error',
                timeout: 5000,
                message: err.error
              })
            )
          );
        }
      )
    );
  }
}
