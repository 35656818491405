<dash-list-edit id="id__list-edit-activity-partner"
                title="Activités"
                labelCreate="Ajouter une activité"
                [headers]="headers"
                (createEvent)="createActivity.emit()">
  <tr body
      *ngFor="let activity of activities"
      [id]="'id__tr-list-edit-activity-'"
      (click)="editActivity(activity)">
    <td *ngIf="roles$ | async | isAdmin">{{activity.id}}</td>
    <td>{{toStringResorts(activity.resorts)}}</td>
    <td>{{activity.activity.name}}</td>
  </tr>
</dash-list-edit>
