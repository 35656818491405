import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(value: number): string {
    const hours = Math.trunc(value / 60);
    const minutes = value % 60;
    return minutes < 10 ? hours + 'h0' + minutes : hours + 'h' + minutes;
  }
}
