import { Partner } from './partner';
import { User } from './user';

export class PartnerAccount {
  partner: Partner;
  user: User;

  constructor(partner: Partner, user: User) {
    this.partner = partner;
    this.user = user;
  }
}
