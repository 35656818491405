import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import moment, { Moment } from 'moment/moment';
import { TriggerAlert } from '../../../app.action';
import { InvoiceService } from '../../../service/invoice.service';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { Invoice } from '../../../shared/models/invoice';
import { DownloadUtils } from '../../../utils/download-utils';

@Component({
  selector: 'dash-list-invoices',
  templateUrl: './list-invoices.component.html',
  styleUrls: ['./list-invoices.component.scss']
})
export class ListInvoicesComponent extends PageableComponent<Invoice> {
  constructor(
    private invoiceService: InvoiceService,
    private store: Store
  ) {
    super();
  }
  headers = [
    '',
    'Facture',
    'Activité(s)',
    'Date',
    'Prix TTC',
    'Dû',
    'TVA',
    'PDF'
  ];
  multiInvoice: PdfInfos[] = [];
  isLoadingMulti = false;

  multiSelectLabel() {
    if (this.multiInvoice.length === 0) {
      return 'Télécharger plusieurs factures';
    }
    return (
      'Télécharger [ ' +
      this.multiInvoice.length +
      ' ] facture' +
      (this.multiInvoice.length > 1 ? 's' : '')
    );
  }

  toDate(date: Moment): string {
    return moment(date).format('DD/MM/YYYY');
  }

  generatePdf(sessionId: string, invoiceHeader: string) {
    this.invoiceService.generatePdf(sessionId).subscribe(
      (pdf) => {
        const name = 'facture-' + invoiceHeader + '.pdf';
        DownloadUtils.downloadPDF(pdf, name);
      },
      (error) => {
        this.store.dispatch(
          new TriggerAlert({
            level: 'error',
            message: 'Erreur lors de la génération du PDF',
            timeout: 5000
          })
        );
        console.error('Error while generating PDF', error);
      }
    );
  }

  generateMultiPdfs() {
    if (this.multiInvoice.length === 0) {
      this.store.dispatch(
        new TriggerAlert({
          level: 'error',
          message:
            'Veuillez sélectionner au moins une facture pour la télécharger',
          timeout: 5000
        })
      );
    } else {
      this.isLoadingMulti = true;
      this.invoiceService
        .generateMultiPdf(this.multiInvoice.map((pdfInfo) => pdfInfo.sessionId))
        .subscribe(
          (zip) => {
            const name = 'factures.zip';
            DownloadUtils.downloadZIP(zip, name);
            this.isLoadingMulti = false;
          },
          () => {
            this.isLoadingMulti = false;
            this.store.dispatch(
              new TriggerAlert({
                level: 'error',
                message:
                  'La génération des factures a échoué, veuillez réessayer plus tard ',
                timeout: 5000
              })
            );
          }
        );
    }
  }

  addMultiSelect(sessionId: string, invoiceHeader: string) {
    const existingPdfInfo = this.multiInvoice.find(
      (info) => info.sessionId === sessionId
    );

    if (existingPdfInfo) {
      this.multiInvoice = this.multiInvoice.filter(
        (info) => info.sessionId !== sessionId
      );
    } else {
      this.multiInvoice.push(new PdfInfos(sessionId, invoiceHeader));
    }
  }
}

class PdfInfos {
  sessionId: string;
  invoiceHeader: string;
  constructor(sessionId: string, invoiceHeader: string) {
    this.sessionId = sessionId;
    this.invoiceHeader = invoiceHeader;
  }
}
