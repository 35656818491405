<div class="flex flex-col justify-evenly">
  <dash-list-credentials (createChanged)="showEdit()"
                         (isChanged)="edit($event)"
                         (pageChanged)="fetchPageable($event, searchForm.value)"
                         [page]="page"
                         [searchForm]="searchForm">
  </dash-list-credentials>
  <dash-edit-credentials (editionComplete)="editCompleted()"
                         *ngIf="showEditor"
                         [entityToEdit]="toEdit"
                         [resorts]="resorts">
  </dash-edit-credentials>
</div>

<atc-popup (closeChanged)="rapportMessagesImport = []"
           *ngIf="rapportMessagesImport.length"
           [withImg]="false"
           height="430px"
           width="900px">
  <div body class="flex flex-col gap-y-4">
    <h1 class="text-center">Rapport de l'import ELiberty</h1>

    <textarea #logArea readonly rows="15">
      {{ rapportMessagesImport.join('\n') }}
    </textarea>
  </div>
</atc-popup>
