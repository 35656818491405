import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Login } from '../shared/models/login';
import { Page } from '../shared/models/page';
import { TokenRefresh } from '../shared/models/token-refresh';
import { User } from '../shared/models/user';
import { UserSession } from '../shared/models/user-session';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService
  extends RepositoryService
  implements PageableService<User>, CrudService<User>
{
  private URL_ACCOUNT = 'api/account';

  constructor(
    http: HttpClient,
    private route: ActivatedRoute
  ) {
    super(http);
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<User>> {
    return this.get(
      this.URL_ACCOUNT +
        `/all-users?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(map((page: Page<User>) => new Page<User>({ ...page }, User)));
  }

  connectUser(
    authentication: Login,
    isCartNotEmpty: boolean
  ): Observable<UserSession> {
    return this.post(
      this.URL_ACCOUNT + '/sign-in?emptyCart=' + isCartNotEmpty,
      authentication
    );
  }

  upsert(entity: User): Observable<void> {
    return this.post(this.URL_ACCOUNT + '/upsert-user', entity);
  }

  refreshSession(refreshToken: string): Observable<TokenRefresh> {
    return this.post(this.URL_ACCOUNT + '/refresh-token', refreshToken);
  }

  getUserById(userId: number): Observable<User> {
    return this.get(this.URL_ACCOUNT + '/get-user?userId=' + userId);
  }

  resetPassword(token: string, authentication: Login): Observable<boolean> {
    return this.post(
      this.URL_ACCOUNT + '/reset-password?token=' + token,
      authentication
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.put(this.URL_ACCOUNT + '/forgot-password', email);
  }

  resetPasswordPartner(userId: number, partnerId: number): Observable<boolean> {
    return this.post(
      this.URL_ACCOUNT +
        '/reset-user-partner-password?userId=' +
        userId +
        '&partnerId=' +
        partnerId,
      null
    );
  }

  deleteById(): Observable<void> {
    return new Observable<void>();
  }
}
