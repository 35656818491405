import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from '../shared/models/category';
import { Page } from '../shared/models/page';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService
  extends RepositoryService
  implements PageableService<Category>
{
  private URL_CATEGORY = 'api/backoffice/category';

  constructor(http: HttpClient) {
    super(http);
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<Category>> {
    return this.get(
      this.URL_CATEGORY +
        `/all-categories?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map((page: Page<Category>) => new Page<Category>({ ...page }, Category))
    );
  }

  getCategories(): Observable<Category[]> {
    return this.get(this.URL_CATEGORY + '/get-categories');
  }

  upsertCategory(category: Category): Observable<void> {
    return this.post(this.URL_CATEGORY + '/upsert-category', category);
  }

  deleteCategory(categoryId: number): Observable<void> {
    return this.delete(
      this.URL_CATEGORY + '/delete-category?categoryId=' + categoryId
    );
  }
}
