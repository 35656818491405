import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { ActivityService } from '../../service/activity.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { Experience } from '../../shared/models/experience';

@Component({
  selector: 'dash-experience-management',
  templateUrl: './experience-management.component.html',
  styleUrls: ['./experience-management.component.scss']
})
export class ExperienceManagementComponent extends RxjsComponent {
  @Select(AppState.partnerId) partnerId$: Observable<number>;

  experiences: Experience[] = [];

  constructor(private activityService: ActivityService) {
    super();
    this.register(
      this.partnerId$
        .pipe(
          switchMap((partnerId) =>
            this.activityService.getActivitiesPartner(partnerId)
          )
        )
        .subscribe((activities) => {
          this.experiences = activities
            .map((activityPartner) => activityPartner.experiences)
            .reduce((prev, curr) => [...prev, ...curr]);
        })
    );
  }
}
