<atc-side-drawer (closeChange)="closeChanged.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading">
  <div class="w-full my-4" header>
    <h1 class="text-center">{{ edit ? 'Modifier un créneau' : 'Nouveau créneau' }}</h1>
  </div>
  <div body class="vsk__body">
    <p *ngIf="timeSlot?.hasNoPrices" class="error">
      Pas de prix disponibles pour ce créneau
    </p>
    <p *ngIf="timeSlot?.pricesAreNotValid" class="error">
      Plusieurs prix sont applicables pour une même tranche d'âge pour ce créneau
    </p>

    <atc-toggle-switch *ngIf="!edit"
                       [control]="multiTimeSlotForm"
                       [label]="multiTimeSlotForm.value ? 'Stage' : 'Créneau simple'">
    </atc-toggle-switch>
    <p *ngIf="multiTimeSlotForm.value">Tous les créneaux crées sont liés les uns aux autres et forment un "stage".</p>
    <p *ngIf="!multiTimeSlotForm.value">Tous les créneaux crées sont dissociés les uns aux autres.</p>

    <hr>

    <div class="vsk__inline vsk__center-v">
      <atc-select [control]="experienceIdForm"
                  [optionElements]="experienceElements"
                  [searchable]="!edit"
                  class="flex-1"
                  label="Expérience">
      </atc-select>

      <atc-select [control]="packageGridForm"
                  [optionElements]="packageGridElements"
                  class="flex-1"
                  label="Grille de tarif">
      </atc-select>

      <atc-input [control]="slotsForm"
                 class="vsk__flex vsk__flex-1"
                 label="Nombre de places"
                 type="number"></atc-input>
    </div>

    <div *ngFor="let form of datesFormArray;let i = index" class="vsk__column">
      <div class="vsk__inline">
        <atc-input-date [control]="form.dateForm"
                        class="vsk__flex"
                        label="Date de l'activité">
        </atc-input-date>

        <atc-input-time [control]="form.startHourForm"
                        class="vsk__flex"
                        label="Début"></atc-input-time>

        <atc-input-time [control]="form.endHourForm"
                        class="vsk__flex"
                        label="Fin"></atc-input-time>

        <atc-button (click)="addLine(i)"
                    *ngIf="i === 0 && !idModel && !idSlot"
                    color="ternary"
                    icon="more"></atc-button>

        <atc-button (click)="removeLine(i)"
                    *ngIf="i !== 0 && !idModel && !idSlot"
                    color="ternary"
                    icon="minus"></atc-button>
      </div>

      <hr>
    </div>


    <atc-input-checkbox *ngIf="!edit"
                        [control]="repeatForm">
      <label>Répéter</label>
    </atc-input-checkbox>

    <atc-input-date *ngIf="!!this.multiTimeSlotForm.value && !!repeatForm.value"
                    [control]="dateEndFrequencyForm"
                    label="Date de fin de fréquence">
    </atc-input-date>

    <ng-container *ngIf="showDateEndFrequency">
      <atc-day-frequency-picker (daysChange)="setDaysToRepeat($event)" [days]="daysFrequency">
      </atc-day-frequency-picker>

      <atc-input-date [control]="dateEndFrequencyForm"
                      label="Date de fin de fréquence">
      </atc-input-date>
    </ng-container>

    <div *ngIf="edit && !multiTimeSlotForm.value" class="vsk__column">
      <label>Que souhaitez-vous modifier ?</label>
      <atc-group-button-toggle (selectedButton)="changeModification($event.value)"
                               [buttons]="buttonsToggle"
                               class="vsk__margin-bottom-1">
      </atc-group-button-toggle>
    </div>

  </div>

  <div class="flex flex-row w-full gap-x-4" footer>
    <atc-button (click)="delete(timeSlot)"
                *ngIf="edit && !multiTimeSlotForm.value"
                color="danger"
                label="Supprimer"></atc-button>

    <atc-button (click)="deleteMultiple()"
                *ngIf="edit && !!multiTimeSlotForm.value"
                color="danger"
                label="Supprimer"></atc-button>

    <atc-button (click)="confirmSimple()"
                *ngIf="!multiTimeSlotForm.value"
                [disabled]="!isFormValid()"
                [fullWidth]="true"
                [label]="edit ? 'Modifier créneau' : 'Ajouter créneau'"

                class="flex flex-1"></atc-button>

    <atc-button (click)="confirmMultiple()"
                *ngIf="!!multiTimeSlotForm.value"
                [disabled]="!isFormValid()"
                [fullWidth]="true"
                [label]="edit ? 'Modifier stage' : 'Ajouter stage'"

                class="flex flex-1"></atc-button>
  </div>
</atc-side-drawer>
