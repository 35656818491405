<dash-list-edit id="id__table-list-promo-code"
                paginationId="promoCode"
                [headers]="headers"
                [searchForm]="searchForm"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                labelCreate="Ajouter un code promo"
                searchLabel="Recherche de code promo"
                searchPlaceholder="Rechercher par nom ..."
                title="Code promo">
  <ng-container body *ngFor="let promoCode of page.content | paginate : {
                  id: 'promoCode',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(promoCode)">
      <td>{{ promoCode.id }}</td>
      <td>{{ promoCode.name }}</td>
      <td>{{ promoCode.applyNumber }}</td>
      <td>{{ promoCode.typePromo }}</td>
      <td>{{ promoCode.minPrice }}</td>
      <td>{{ promoCode.used }}</td>
      <td>{{ promoCode.usedLimit }}</td>
      <td>{{ promoCode.dateValidityStart.format('DD/MM/YYYY') }}</td>
      <td>{{ promoCode.dateValidityEnd.format('DD/MM/YYYY') }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
