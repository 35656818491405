import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Customer } from '../shared/models/customer';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends RepositoryService {
  private URL_ACTIVITY = 'api/backoffice/customer';

  constructor(http: HttpClient) {
    super(http);
  }

  create(partnerId: number, customer: Customer): Observable<void> {
    return this.post(
      this.URL_ACTIVITY + '/create?partnerId=' + partnerId,
      customer
    );
  }

  update(partnerId: number, customer: Customer): Observable<void> {
    return this.put(
      this.URL_ACTIVITY + '/update?partnerId=' + partnerId,
      customer
    );
  }

  deleteCustomer(partnerId: number, customerId: number): Observable<void> {
    return this.delete(
      this.URL_ACTIVITY + '/delete?partnerId=' + partnerId + '&id=' + customerId
    );
  }

  getAllCustomersByPartnerId(partnerId: number): Observable<Customer[]> {
    return this.get(
      this.URL_ACTIVITY + '/get-all-by-partner-id?partnerId=' + partnerId
    ).pipe(
      map((customers) =>
        customers.map((customer: Customer) => new Customer({ ...customer }))
      )
    );
  }
}
