import moment, { Moment } from 'moment';
import { Authority } from './authority';
import { Role } from './enum/Role';

export class User {
  id: number;
  firstName: string;
  lastName: string;
  birthdate: Moment;
  email: string;
  phone: string;
  address: string;
  city: string;
  postCode: string;
  country: string;
  enabled: boolean;
  authorities: Authority[];

  constructor(obj: Partial<User>) {
    Object.assign(this, obj);
    if (this.birthdate !== null) {
      this.birthdate = moment(this.birthdate);
    }
  }

  get fullname(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get role(): string {
    if (
      this.authorities.some(
        (authority) => authority.authority === Role.ROLE_ADMIN
      )
    ) {
      return 'Admin';
    } else {
      return this.authorities
        .filter((authority) => authority.authority !== Role.ROLE_USER)
        .map((authority) =>
          authority.authority
            .replace('ROLE_', '')
            .toLowerCase()
            .replace(/\b\w/g, (l) => l.toUpperCase())
        )
        .join(' - ');
    }
  }
}
