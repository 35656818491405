<div class="vsk__column">
  <dash-list-carousel-product
    [searchForm]="searchForm"
    [stations]="stations"
    [page]="page"
    (pageChanged)="fetchPageable($event, searchForm.value)"
    (createChanged)="showEdit()"
    (isChanged)="edit($event)"
    (isDeleted)="delete()">
  </dash-list-carousel-product>
  <dash-edit-carousel-product
    id="form-edit-carousel-product"
    *ngIf="showEditor"
    [stations]="stations"
    [entityToEdit]="toEdit"
    (editionComplete)="editCompleted()">
  </dash-edit-carousel-product>
</div>
