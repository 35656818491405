import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../app.state';
import { ActivityService } from '../../../service/activity.service';
import { CustomFieldService } from '../../../service/custom-field.service';
import { TagService } from '../../../service/tag.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { ActivityPartner } from '../../../shared/models/activity-partner';
import { CustomField } from '../../../shared/models/custom-field';
import { Experience } from '../../../shared/models/experience';
import { Tag } from '../../../shared/models/tag';
import { OptionEdit } from './edit-experience/edit-experience.component';

@Component({
  selector: 'dash-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent extends RxjsComponent {
  @Select(AppState.partnerId) partnerId$: Observable<number>;

  activitiesPartner: ActivityPartner[] = [];
  customFields: CustomField[] = [];
  tags: Tag[] = [];
  optionToEdit: OptionEdit | undefined;
  showEditor = false;
  experiences: Experience[] = [];

  constructor(
    private activityService: ActivityService,
    private store: Store,
    private customFieldService: CustomFieldService,
    private tagService: TagService
  ) {
    super();
    this.register(
      this.customFieldService
        .getCustomFields()
        .subscribe((customFields) => (this.customFields = customFields))
    );
    this.register(
      this.tagService.getTags().subscribe((tags) => (this.tags = tags))
    );
    this.updateActivities();
  }

  optionChanged(): void {
    this.optionToEdit = undefined;
    this.updateActivities();
    this.showEdit();
  }

  updateActivities(): void {
    const partnerId = this.store.selectSnapshot(AppState.partnerId) as number;
    this.register(
      this.activityService
        .getActivitiesPartner(partnerId)
        .subscribe((activities) => {
          this.activitiesPartner = activities;
          this.getExperiences();
        })
    );
  }

  showEdit() {
    this.showEditor = !this.showEditor;
  }

  setOptionToEdit(optionEdit: OptionEdit) {
    this.optionToEdit = optionEdit;
    this.showEdit();
  }

  getExperiences() {
    if (!this.activitiesPartner?.length) {
      return;
    }

    this.experiences = this.activitiesPartner
      .map((activityPartner) => activityPartner.experiences)
      .reduce((prev, curr) => [...prev, ...curr]);
  }
}
