<div class="vsk__block-form">
  <img alt="VeryDashboard" src="assets/images/logo.png"/>
  <ng-container *ngIf="partner && status === 'create'">
    <h2>Création du compte utilisateur - <b>{{partner.name}}</b></h2>
    <p *ngIf="message">{{message}}</p>
    <!-- Lastname -->
    <atc-input label="Nom*"
               placeholder="Dupond"
               errorMessage="Ce champs ne doit pas être vide"
               [control]="lastnameUserForm"></atc-input>

    <!-- Firstname -->
    <atc-input label="Prénom*"
               placeholder="Jean"
               errorMessage="Ce champs ne doit pas être vide"
               [control]="firstnameUserForm"></atc-input>

    <!-- Email -->
    <atc-input label="Email*"
               placeholder="jean@monentreprise.fr"
               errorMessage="Ce champs doit être un email"
               [control]="emailUserForm"></atc-input>

    <!-- Mot de passe -->
    <atc-input label="Mot de passe*"
               type="password"
               placeholder="***************"
               errorMessage="Le mot de passe doit contenir au moins 8 caractères dont au moins 1 majuscule, 1 minuscule, 1 caractère spécial (#?!@$%^&-*()) et 1 chiffre"
               [control]="passwordForm"></atc-input>

    <!-- Confirmation de mot de passe -->
    <atc-input label="Confirmation du mot de passe*"
               type="password"
               placeholder="***************"
               errorMessage="Ce champs ne doit pas être vide"
               [control]="passwordConfirmationForm"></atc-input>
    <div class="vsk__inline">
      <div class="vsk__flex"></div>
      <atc-button label="Créer mon compte utilisateur"
                  [disabled]="createAccountForm.invalid"
                  (click)="createUser()"></atc-button>
    </div>
  </ng-container>
  <ng-container *ngIf="partner && status === 'done'">
    <h2>Bienvenue chez VeryMountain !</h2>
    <p>Votre compte est créé !</p>
    <atc-button label="Me connecter"
                class="vsk__margin-auto"
                (click)="goToLogin()"></atc-button>
  </ng-container>
  <ng-container *ngIf="!partner">
    <p>{{message}}</p>
    <a routerLink="/forgot-password"
       routerLinkActive="true">
      Oubli de mot de passe
    </a>
  </ng-container>
  <div class="vsk__loading" *ngIf="loading">
    <atc-loader color="simple"></atc-loader>
  </div>
</div>
