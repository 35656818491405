import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { ActivityService } from '../../service/activity.service';
import { ResortService } from '../../service/resort.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { Activity } from '../../shared/models/activity';
import { ActivityPartner } from '../../shared/models/activity-partner';
import { Resort } from '../../shared/models/resort/resort';

@Component({
  selector: 'dash-activity-partner-management',
  templateUrl: './activity-partner-management.component.html',
  styleUrls: ['./activity-partner-management.component.scss']
})
export class ActivityPartnerManagementComponent extends RxjsComponent {
  @Select(AppState.partnerId) partnerId$: Observable<number>;

  stations: Resort[] = [];
  activities: Activity[] = [];
  activitiesPartner: ActivityPartner[] = [];
  activityPartnerToEdit: ActivityPartner | undefined;
  showEditor = false;

  constructor(
    private activityService: ActivityService,
    private resortService: ResortService
  ) {
    super();

    this.register(
      this.resortService
        .getAllStations()
        .subscribe((stations) => (this.stations = stations))
    );

    this.register(
      this.activityService
        .getActivities()
        .subscribe((activities) => (this.activities = activities))
    );

    this.updateActivitiesPartner();
  }

  edit(activity: ActivityPartner | undefined): void {
    this.activityPartnerToEdit = activity;
    this.showEdit();
  }

  showEdit() {
    this.showEditor = !this.showEditor;
  }

  activityPartnerChanged(): void {
    this.activityPartnerToEdit = undefined;
    this.updateActivitiesPartner();
    this.showEdit();
  }

  updateActivitiesPartner(): void {
    this.register(
      this.partnerId$
        .pipe(
          switchMap((partnerId) =>
            this.activityService.getActivitiesPartner(partnerId)
          )
        )
        .subscribe((activities) => {
          this.activitiesPartner = activities;
        })
    );
  }
}
