<dash-list-edit (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                [headers]="headers"
                [searchForm]="searchForm"
                id="id__table-list-package-grid"
                labelCreate="Ajouter une grille de tarif"
                paginationId="experienceGrid"
                searchLabel="Recherche de grille de tarif"
                searchPlaceholder="Rechercher par nom ..."
                title="Grille de tarifications">
  <ng-container *ngFor="let packageGrid of page.content | paginate : {
                  id: 'experienceGrid',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }" body>
    <tr (click)="edit(packageGrid)">
      <td *ngIf="roles$ | async | isAdmin">{{ packageGrid.id }}</td>
      <td>{{ packageGrid.name }}</td>
      <td>{{ packageGrid.activityName }}</td>
      <td>{{ packageGrid.experienceName }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
