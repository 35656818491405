import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Package } from '../shared/models/package/package';
import { PackageGroup } from '../shared/models/package/package-group';
import { PackageMin } from '../shared/models/package/package-min';
import { Page } from '../shared/models/page';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class PackageService
  extends RepositoryService
  implements PageableService<Package>
{
  private URL_PACKAGE_PARTNER = 'api/backoffice/package';

  constructor(http: HttpClient) {
    super(http);
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string | number
  ): Observable<Page<Package>> {
    return this.get(
      this.URL_PACKAGE_PARTNER +
        `/all-package?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map((page: Page<Package>) => new Page<Package>({ ...page }, Package))
    );
  }

  upsertPackage(packageMin: PackageMin): Observable<number> {
    return this.put(this.URL_PACKAGE_PARTNER + '/upsert', packageMin);
  }

  getAllByPartnerId(partnerId: number): Observable<Package[]> {
    return this.get(
      this.URL_PACKAGE_PARTNER + '/all-by-id-partner?partnerId=' + partnerId
    ).pipe(map((pcks) => pcks.map((pck: Package) => new Package({ ...pck }))));
  }

  getAllByExperienceId(
    optionId: number,
    partnerId: number
  ): Observable<Package[]> {
    return this.get(
      this.URL_PACKAGE_PARTNER +
        '/all-by-id-option?optionId=' +
        optionId +
        '&partnerId=' +
        partnerId
    ).pipe(map((pcks) => pcks.map((pck: Package) => new Package({ ...pck }))));
  }

  upsertGroup(partnerId: number, packageGroup: PackageGroup): Observable<void> {
    return this.put(
      this.URL_PACKAGE_PARTNER + '/upsert-group?partnerId=' + partnerId,
      packageGroup
    );
  }

  fetchGroup(partnerId: number): Observable<PackageGroup[]> {
    return this.get(
      this.URL_PACKAGE_PARTNER + '/fetch-group-packages?partnerId=' + partnerId
    ).pipe(
      map((groups) =>
        groups.map((grp: PackageGroup) => new PackageGroup({ ...grp }))
      )
    );
  }

  deleteGroup(groupId: number): Observable<void> {
    return this.delete(
      this.URL_PACKAGE_PARTNER + '/delete-group?groupId=' + groupId
    );
  }
}
