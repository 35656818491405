import { Component } from '@angular/core';
import { ResortLabelService } from '../../service/resort-label.service';
import { PageComponent } from '../../shared/component/page.component';
import { ResortLabel } from '../../shared/models/resort-label';

@Component({
  selector: 'dash-resort-label',
  templateUrl: './resort-label.component.html',
  styleUrls: ['./resort-label.component.scss']
})
export class ResortLabelComponent extends PageComponent<ResortLabel> {
  constructor(resortLabelService: ResortLabelService) {
    super(resortLabelService);

    this.page.pageable.pageSize = 12;
  }
}
