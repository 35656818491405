import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { filter, switchMap } from 'rxjs/operators';
import { PartnerService } from '../service/partner.service';
import { Account } from '../shared/models/account';
import { Partner } from '../shared/models/partner';

export interface CreateAccountForm {
  firstname: FormControl<string | null>;
  lastname: FormControl<string | null>;
  email: FormControl<string | null>;
  password: FormControl<string | null>;
  provider: FormControl<string | null>;
}

@Component({
  selector: 'dash-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {
  partner: Partner;
  loading = true;
  message = '';
  alreadyExist = false;
  token = '';
  status: 'create' | 'done' = 'create';

  // Form user
  firstnameUserForm = new FormControl<string | null>('', Validators.required);
  lastnameUserForm = new FormControl<string | null>('', Validators.required);
  emailUserForm = new FormControl<string | null>('', [
    Validators.required,
    Validators.email
  ]);
  passwordForm = new FormControl<string | null>('', [
    Validators.required,
    Validators.pattern(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&-*()]).{8,}$'
    )
  ]);
  passwordConfirmationForm = new FormControl<string | null>(
    '',
    Validators.required
  );
  providerForm = new FormControl<string | null>('veryski', Validators.required);
  createAccountForm: FormGroup<CreateAccountForm>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private partnerService: PartnerService
  ) {
    this.createAccountForm = new FormGroup<CreateAccountForm>({
      firstname: this.firstnameUserForm,
      lastname: this.lastnameUserForm,
      email: this.emailUserForm,
      password: this.passwordForm,
      provider: this.providerForm
    });

    this.activatedRoute.queryParams
      .pipe(
        filter((params) => !!params['token']),
        switchMap((params) => {
          this.token = params['token'];
          return partnerService.getPartnerByToken(params['token']);
        })
      )
      .subscribe(
        (partner: Partner) => {
          this.partner = partner;
          this.loading = false;
        },
        (error: any) => {
          switch (error.status) {
            case 404:
              this.message =
                'Un utilisateur est déjà crée pour votre entreprise, si vous avez oublié votre mot de passe, vous pouvez le réinitialiser ici';
              this.alreadyExist = true;
              break;
            default:
              this.message =
                'Une erreur est survenue, veuillez réessayer plus tard ou vous pouvez nous contacter par email : françois@verymountain.fr';
          }
          this.loading = false;
        }
      );
  }

  createUser() {
    if (this.createAccountForm.valid && this.checkPassword()) {
      this.loading = true;
      const account = new Account({
        ...this.createAccountForm.getRawValue(),
        birthdate: moment().add(-25, 'years')
      });

      this.partnerService.createUserPartner(account, this.token).subscribe(
        () => {
          this.status = 'done';
          this.loading = false;
        },
        (error: any) => {
          switch (error.status) {
            case 409:
              this.message =
                'Cet utilisateur existe déjà, si vous souhaitez le lier à votre entreprise vous pouvez nous contacter par email : françois@verymountain.fr';
              break;
            default:
              this.message =
                'Une erreur est survenue, veuillez réessayer plus tard ou vous pouvez nous contacter par email : françois@verymountain.fr';
          }
          this.loading = false;
        }
      );
    } else if (!this.checkPassword()) {
      this.passwordConfirmationForm.setErrors({
        error: 'Les mots de passe doivent être similaires'
      });
    }
  }

  private checkPassword() {
    return this.passwordForm.value === this.passwordConfirmationForm.value;
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }
}
