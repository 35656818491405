<div class="vsk__column">
  <dash-list-category (isChanged)="edit($event)"
                      (createChanged)="showEdit()"
                      (pageChanged)="fetchPageable($event, searchForm.value)"
                      [searchForm]="searchForm"
                      [page]="page">
  </dash-list-category>
  <dash-edit-category *ngIf="showEditor"
                      (editionComplete)="editCompleted()"
                      [categoryToEdit]="toEdit">
  </dash-edit-category>
</div>
