import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import moment from 'moment/moment';
import { TriggerAlert } from '../../../app.action';
import { AccountService } from '../../../service/account.service';
import { EditComponent } from '../../../shared/component/edit.component';
import { Authority } from '../../../shared/models/authority';
import { Role } from '../../../shared/models/enum/Role';
import { User } from '../../../shared/models/user';

export interface CreateUserForm {
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  email: FormControl<string | null>;
  birthDate: FormControl<string | null>;
  userAddress: FormControl<string | null>;
  postalCode: FormControl<string | null>;
  city: FormControl<string | null>;
  country: FormControl<string | null>;
  phone: FormControl<string | null>;
  roles: FormControl<Role>;
}

@Component({
  selector: 'dash-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.scss']
})
export class EditUsersComponent extends EditComponent<User> {
  @Output() editionComplete = new EventEmitter<void>();

  @Input() set entityToEdit(value: User | undefined) {
    if (value) {
      this.userId = value.id;
      this.userEnabled = value.enabled;
      this.editMode = 'edit';

      this.firstNameForm.setValue(value.firstName);
      this.lastNameForm.setValue(value.lastName);
      this.birthdateForm.setValue(moment(value.birthdate).format('DD/MM/YYYY'));
      this.emailUserForm.setValue(value.email);
      this.postalCodeForm.setValue(value.postCode);
      this.cityForm.setValue(value.city);
      this.countryForm.setValue(value.country);
      this.phoneForm.setValue(value.phone);
      this.addressForm.setValue(value.address);
      this.rolesOptions.forEach((option) => {
        option.control?.setValue(
          value.authorities.some(
            (authority) => authority.authority === option.id
          )
        );
      });
    }
  }

  editMode: 'create' | 'edit' = 'create';
  loading = false;
  userId: number;
  userEnabled: boolean;

  createPartnerUserForm: FormGroup<CreateUserForm>;
  firstNameForm = new FormControl<string>('', Validators.required);
  lastNameForm = new FormControl<string>('', Validators.required);
  emailUserForm = new FormControl<string>('', Validators.required);
  birthdateForm = new FormControl<string | null>('', Validators.required);
  postalCodeForm = new FormControl<string>('');
  cityForm = new FormControl<string>('');
  countryForm = new FormControl<string>('');
  phoneForm = new FormControl<string>('');
  addressForm = new FormControl<string>('');

  rolesOptions: OptionElement<Role>[] = [
    {
      id: Role.ROLE_PARTNER,
      label: 'Partenaire',
      control: new FormControl<boolean | null>({
        value: false,
        disabled: false
      })
    },
    {
      id: Role.ROLE_COMMERCIAL,
      label: 'Commercial',
      control: new FormControl<boolean | null>({
        value: false,
        disabled: false
      })
    },
    {
      id: Role.ROLE_MARKETING,
      label: 'Marketing',
      control: new FormControl<boolean | null>({
        value: false,
        disabled: false
      })
    },
    {
      id: Role.ROLE_ADMIN,
      label: 'Admin',
      control: new FormControl<boolean | null>({
        value: false,
        disabled: false
      })
    }
  ];

  constructor(
    protected accountService: AccountService,
    protected store: Store
  ) {
    super(accountService, store);
  }

  toggleDisable() {
    this.userEnabled = !this.userEnabled;
  }

  buildEntity(): User {
    return new User({
      ...(this.createPartnerUserForm.getRawValue() as any),
      id: this.userId,
      enabled: this.userEnabled,
      authorities: this.rolesOptions
        .filter((option) => !!option.control?.value)
        .map((role) => new Authority({ authority: role.id as Role }))
        .concat([new Authority({ authority: Role.ROLE_USER })])
    });
  }

  initForm(): void {
    this.createPartnerUserForm = new FormGroup<CreateUserForm>(<CreateUserForm>{
      firstName: this.firstNameForm,
      lastName: this.lastNameForm,
      email: this.emailUserForm,
      birthDate: this.birthdateForm,
      userAddress: this.addressForm,
      postalCode: this.postalCodeForm,
      city: this.cityForm,
      country: this.countryForm,
      phone: this.phoneForm
    });
  }

  isFormValid(): boolean {
    return this.createPartnerUserForm.valid;
  }

  resetPassword() {
    this.accountService
      .forgotPassword(this.emailUserForm?.getRawValue() as string)
      .subscribe(
        () => {
          this.store.dispatch(
            new TriggerAlert({
              level: 'success',
              message:
                'Un email de récupération de mot de passe a été envoyé à :' +
                this.emailUserForm?.getRawValue(),
              timeout: 2000
            })
          );
        },
        () => {
          this.store.dispatch(
            new TriggerAlert({
              level: 'error',
              message:
                "L'email de récupération de mot de passe n'a pas pu être envoyé",
              timeout: 5000
            })
          );
        }
      );
  }
}
