import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Invoice } from '../shared/models/invoice';
import { Page } from '../shared/models/page';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService
  extends RepositoryService
  implements PageableService<Invoice>
{
  private URL_INVOICE = 'api/backoffice/invoices';

  constructor(http: HttpClient) {
    super(http);
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<Invoice>> {
    return this.get(
      this.URL_INVOICE +
        `/invoices-by-partner?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map((page: Page<Invoice>) => new Page<Invoice>({ ...page }, Invoice))
    );
  }

  generatePdf(sessionId: string) {
    return this.get(
      this.URL_INVOICE + `/generate-invoice?sessionId=${sessionId}`,
      {},
      'blob'
    );
  }

  generateMultiPdf(sessionsId: string[]) {
    return this.post(
      this.URL_INVOICE + '/generate-multi-invoices',
      sessionsId,
      'blob'
    );
  }
}
