<div *ngIf="env !== 'production'" class="vsk__card flex">
  <h1>Connexion de l'environnement <span>{{ env }}</span></h1>
  <hr>

  <h2>Pour information, toutes commandes poussées sur ce serveur enverra les informations correspondantes au partenaire
    si celui-ci est noté comme connecté !</h2>

  <div class="grid grid-cols-3 gap-4">
    <atc-input-checkbox [control]="eLibertyForm">
      <label>Connection à ELiberty</label>
    </atc-input-checkbox>
    <atc-input-checkbox [control]="resalysForm">
      <label>Connection à Resalys</label>
    </atc-input-checkbox>
    <atc-input-checkbox [control]="arkianeForm">
      <label>Connection à Arkiane</label>
    </atc-input-checkbox>
    <atc-input-checkbox [control]="maevaForm">
      <label>Connection à Maeva</label>
    </atc-input-checkbox>
    <atc-input-checkbox [control]="skisetForm">
      <label>Connection à Skiset</label>
    </atc-input-checkbox>
  </div>

  <atc-button (click)="upsert()"
              class="mt-4 justify-end"
              label="Mettre à jour">
  </atc-button>
</div>

<div class="vsk__card flex">
  <h1>Indexation des données</h1>
  <hr>

  <h2>Toutes les données sont réindexées à certains horaires automatiquement</h2>
  <p class="italic">Merci de bien attendre les délais affichés avant de relancer une indexation</p>

  <div class="grid grid-cols-2 gap-4">
    <div class="flex gap-4 items-center">
      <atc-button (click)="indexExperiences()"
                  label="Indexer les suggestions expériences">
      </atc-button>
      <p>~5 mins</p>
    </div>

    <div class="flex gap-4 items-center">
      <atc-button (click)="indexExperiencesGroup()"
                  label="Indexer les expériences">
      </atc-button>
      <p>~5 mins</p>
    </div>

    <div class="flex gap-4 items-center">
      <atc-button (click)="indexAccommodation()"
                  label="Indexer les hébergements">
      </atc-button>
      <p>~10 mins</p>
    </div>

    <div class="flex gap-4 items-center">
      <atc-button (click)="indexTrip()"
                  label="Indexer les séjours">
      </atc-button>
      <p>~20 mins</p>
    </div>
  </div>
</div>

