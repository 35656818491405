export class PackageGrid {
  id: number;
  name: string;
  priceCategory: string;
  experienceId: number;
  experienceName: string;
  activityName: string;

  constructor(obj: Partial<PackageGrid>) {
    Object.assign(this, obj);
  }
}
