import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import moment, { Moment } from 'moment/moment';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { RegisterTimeSlotService } from '../../service/register-time-slot.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { Page } from '../../shared/models/page';
import { Pageable } from '../../shared/models/pageable';
import { Reservation } from '../../shared/models/reservation';

@Component({
  selector: 'dash-refused-reservations',
  templateUrl: './refused-reservations.component.html',
  styleUrls: ['./refused-reservations.component.scss']
})
export class RefusedReservationsComponent extends RxjsComponent {
  @Select(AppState.partnerId) partnerId$: Observable<number>;
  headers = [
    'ACTIVITÉ',
    'CRÉNEAU',
    'RÉSERVATION',
    'PERSONNES',
    'VOIR LE DÉTAIL'
  ];
  page: Page<Reservation> = new Page<Reservation>({
    content: [],
    pageable: new Pageable({
      pageNumber: 0,
      pageSize: 10
    }),
    totalElements: 0,
    numberOfElements: 0
  });
  showEdit = false;
  reservationToShow: Reservation | undefined;

  constructor(private registerTimeSlotService: RegisterTimeSlotService) {
    super();
    this.getReservations();
  }

  setReservationToShow(reservation: Reservation) {
    this.reservationToShow = reservation;
    this.showEdit = !this.showEdit;
  }

  closeDrawer() {
    this.reservationToShow = undefined;
    this.showEdit = !this.showEdit;
  }

  getReservations() {
    this.register(
      this.partnerId$
        .pipe(
          switchMap((partnerId) =>
            this.registerTimeSlotService.getAllRefusedRegisterTimeSlot(
              partnerId,
              this.page.pageable.pageNumber,
              this.page.pageable.pageSize
            )
          )
        )
        .subscribe((page) => (this.page = page))
    );
  }

  onTableDataChange(event: any) {
    this.page.pageable.pageNumber = event - 1;
    this.getReservations();
  }

  formatDate(date: Moment): string {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }
}
