import { Component } from '@angular/core';
import { Role } from '../../shared/models/enum/Role';
import { Link } from '../../shared/sub-menu/sub-menu.component';

@Component({
  selector: 'dash-activity-menu',
  templateUrl: './activity-menu.component.html',
  styleUrls: ['./activity-menu.component.scss']
})
export class ActivityMenuComponent {
  links: Link[] = [
    {
      id: 'activity-partner',
      roles: [Role.ROLE_ADMIN, Role.ROLE_PARTNER],
      page: 'Activités',
      icon: 'activity',
      route: '/activity/activity-partner'
    },
    {
      id: 'options',
      roles: [Role.ROLE_ADMIN, Role.ROLE_PARTNER],
      page: 'Expériences',
      icon: 'document',
      route: '/activity/options'
    },
    {
      id: 'age-range',
      roles: [Role.ROLE_ADMIN, Role.ROLE_PARTNER],
      page: "Tranches d'âge",
      icon: 'group',
      route: '/activity/age-range'
    },
    {
      id: 'package-management',
      roles: [Role.ROLE_ADMIN, Role.ROLE_PARTNER],
      page: 'Tarifs',
      icon: 'credit-card',
      route: '/activity/package-management'
    }
  ];
}
