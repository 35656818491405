import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../../app.state';
import { PageableComponent } from '../../../../shared/component/pageable.component';
import { Role } from '../../../../shared/models/enum/Role';
import { PackageGrid } from '../../../../shared/models/package-grid';

@Component({
  selector: 'dash-list-package-grid',
  templateUrl: './list-package-grid.component.html',
  styleUrls: ['./list-package-grid.component.scss']
})
export class ListPackageGridComponent extends PageableComponent<PackageGrid> {
  @Select(AppState.roles) roles$: Observable<string[]>;
  headers: string[] = ['Nom', 'Activité', 'Expérience'];

  constructor() {
    super();
    this.roles$.subscribe((roles) => {
      if (roles.indexOf(Role.ROLE_ADMIN) !== -1) {
        this.headers.unshift('Id');
      }
    });
  }
}
