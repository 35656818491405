import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../../app.state';
import { PageableComponent } from '../../../../shared/component/pageable.component';
import { Role } from '../../../../shared/models/enum/Role';
import { ExperienceGroup } from '../../../../shared/models/experience-group';

@Component({
  selector: 'dash-list-experience-group',
  templateUrl: './list-experience-group.component.html',
  styleUrls: ['./list-experience-group.component.scss']
})
export class ListExperienceGroupComponent extends PageableComponent<ExperienceGroup> {
  @Select(AppState.roles) roles$: Observable<string[]>;
  headers = ['Nom'];

  constructor() {
    super();
    this.roles$.subscribe((roles) => {
      if (roles.indexOf(Role.ROLE_ADMIN) !== -1) {
        this.headers.unshift('Id');
      }
    });
  }
}
