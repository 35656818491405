import moment, { Moment } from 'moment';
import { CarouselProductTypeEnum } from './enum/carousel-product-type.enum';

export class CarouselProduct {
  id: number;
  name: string;
  productType: CarouselProductTypeEnum;
  resortId: number;
  startDate: Moment;
  endDate: Moment;
  agencyPartnerCode: string;
  establishmentId: number;
  codeRoom: string;
  experienceId: number;
  sessionMaster: string;

  constructor(obj: Partial<CarouselProduct>) {
    Object.assign(this, obj);

    if (this.startDate) {
      this.startDate = moment(this.startDate, 'YYYY-MM-DD');
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate, 'YYYY-MM-DD');
    }
  }
}
