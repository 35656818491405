import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Parameters } from '../shared/models/parameters';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ParametersService extends RepositoryService {
  private URL_PARAMETERS = 'api/admin/parameters';

  constructor(http: HttpClient) {
    super(http);
  }

  upsert(parameters: Parameters): Observable<Parameters> {
    return this.put(this.URL_PARAMETERS + '/upsert', parameters);
  }

  getCurrentParameters(): Observable<Parameters> {
    return this.get(this.URL_PARAMETERS);
  }
}
