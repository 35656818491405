import { Component } from '@angular/core';
import { PageableComponent } from 'src/app/shared/component/pageable.component';
import { CarouselZone } from 'src/app/shared/models/carousel-zone';

@Component({
  selector: 'dash-list-carousel-zone',
  templateUrl: './list-carousel-zone.component.html',
  styleUrls: ['./list-carousel-zone.component.scss']
})
export class ListCarouselZoneComponent extends PageableComponent<CarouselZone> {
  headers = ['Id', 'Nom', 'Index'];
}
