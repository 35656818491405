import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { SessionMaster } from '../../../shared/models/marketing/session-master';

@Component({
  selector: 'dash-list-pre-made-cart',
  templateUrl: './list-pre-made-cart.component.html',
  styleUrls: ['./list-pre-made-cart.component.scss']
})
export class ListPreMadeCartComponent extends PageableComponent<SessionMaster> {
  headers = [
    'Id',
    'Nom',
    'Date de révision',
    'Destination',
    'Code Promo',
    'Date de début',
    'Date de fin',
    'Station',
    'Paiement'
  ];

  getLinkResort(sessionMaster: SessionMaster) {
    const baseUrl = `${environment.urlWebapp}/resort/accommodation?station=${sessionMaster.resort.name}&sessionMaster=${sessionMaster.sessionId}`;

    if (sessionMaster.promoCode) {
      return `${baseUrl}&promoCode=${sessionMaster.promoCode.name}`;
    }

    return baseUrl;
  }

  getLinkPayment(sessionMaster: SessionMaster) {
    const baseUrl = `${environment.urlWebapp}/payment?station=${sessionMaster.resort.name}&sessionMaster=${sessionMaster.sessionId}`;

    if (sessionMaster.promoCode) {
      return `${baseUrl}&promoCode=${sessionMaster.promoCode.name}`;
    }

    return baseUrl;
  }
}
