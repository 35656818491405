import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Picture } from '../shared/models/accomodation/picture';
import { Activity } from '../shared/models/activity';
import { ActivityPartner } from '../shared/models/activity-partner';
import { Experience } from '../shared/models/experience';
import { Page } from '../shared/models/page';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityService
  extends RepositoryService
  implements PageableService<Activity>
{
  private URL_ACTIVITY = 'api/backoffice/activity';
  private URL_ACTIVITY_PARTNER = 'api/backoffice/activity-partner';

  constructor(http: HttpClient) {
    super(http);
  }

  upsertActivity(activity: Activity): Observable<void> {
    return this.post(this.URL_ACTIVITY + `/upsert-activity`, activity);
  }

  upsertActivityPartner(
    activityId: number,
    partnerId: number,
    activityPartner: ActivityPartner
  ): Observable<number> {
    return this.post(
      this.URL_ACTIVITY_PARTNER +
        `/upsert-activity-partner?activityId=${activityId}&partnerId=${partnerId}`,
      activityPartner
    );
  }

  upsertExperience(
    activityPartnerId: number,
    partnerId: number,
    optionPartner: Experience
  ): Observable<number> {
    return this.post(
      this.URL_ACTIVITY_PARTNER +
        `/upsert-experience?activityPartnerId=${activityPartnerId}&partnerId=${partnerId}`,
      optionPartner,
      'text'
    );
  }

  upsertExperiencePictures(
    experienceId: number,
    pictures: Picture[]
  ): Observable<void> {
    return this.post(
      this.URL_ACTIVITY_PARTNER + `/upsert-experience-pictures`,
      {
        experienceId,
        pictures
      }
    );
  }

  deleteOptionPartner(optionId: number, partnerId: number): Observable<void> {
    return this.delete(
      this.URL_ACTIVITY_PARTNER +
        `/delete-option?optionId=${optionId}&partnerId=${partnerId}`
    );
  }

  getActivities(): Observable<Activity[]> {
    return this.get(this.URL_ACTIVITY + '/get-all');
  }

  deleteActivity(activity: Activity): Observable<void> {
    return this.delete(this.URL_ACTIVITY + `/delete-by-id/${activity.id}`);
  }

  getActivitiesPartner(partnerId: number): Observable<ActivityPartner[]> {
    return this.get(
      this.URL_ACTIVITY_PARTNER +
        '/get-all-activities-by-partner-id?partnerId=' +
        partnerId
    );
  }

  getExperienceByPartnerId(partnerId: number): Observable<Experience[]> {
    return this.get(
      this.URL_ACTIVITY_PARTNER +
        '/get-all-options-by-partner-id?partnerId=' +
        partnerId
    );
  }

  deleteActivityPartner(activityId: number): Observable<void> {
    return this.delete(
      this.URL_ACTIVITY_PARTNER +
        `/delete-activity-partner?activityPartnerId=${activityId}`
    );
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<Activity>> {
    return this.get(
      this.URL_ACTIVITY +
        `/all-activities?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map((page: Page<Activity>) => new Page<Activity>({ ...page }, Activity))
    );
  }
}
