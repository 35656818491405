import moment, { Moment } from 'moment';
import { Category } from './category';

export class Activity {
  id: number;
  name: string;
  urlIcon: string;
  dateStart: Moment;
  dateEnd: Moment;
  enabled: boolean;
  category: Category;

  constructor(obj: Partial<Activity>) {
    Object.assign(this, obj);
    this.dateStart = moment(this.dateStart, 'YYYY-MM-DD');
    this.dateEnd = moment(this.dateEnd, 'YYYY-MM-DD');
  }
}
