import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExperienceGroup } from '../shared/models/experience-group';
import { Page } from '../shared/models/page';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ExperienceGroupService
  extends RepositoryService
  implements PageableService<ExperienceGroup>, CrudService<ExperienceGroup>
{
  private URL_EXPERIENCE_GROUP = 'api/backoffice/experience-group';

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<ExperienceGroup>> {
    return this.get(
      this.URL_EXPERIENCE_GROUP +
        `/all-experience-group?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map(
        (page: Page<ExperienceGroup>) =>
          new Page<ExperienceGroup>({ ...page }, ExperienceGroup)
      )
    );
  }

  upsert(experienceGroup: ExperienceGroup): Observable<void> {
    return this.post(this.URL_EXPERIENCE_GROUP, experienceGroup);
  }

  deleteById(id: number): Observable<void> {
    return this.delete(this.URL_EXPERIENCE_GROUP + `?id=${id}`);
  }
}
