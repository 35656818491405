import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { RegisterTimeSlotService } from '../../service/register-time-slot.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { RegisterStatusEnum } from '../../shared/models/enum/register-status.enum';
import { Page } from '../../shared/models/page';
import { Pageable } from '../../shared/models/pageable';
import { Reservation } from '../../shared/models/reservation';
import { TimeSlot } from '../../shared/models/time-slot/time-slot';
import { DenialMessage } from './refuse-reservation/refuse-reservation.component';

@Component({
  selector: 'dash-my-pending-reservations-list',
  templateUrl: './my-pending-reservations-list.component.html',
  styleUrls: ['./my-pending-reservations-list.component.scss']
})
export class MyPendingReservationsListComponent extends RxjsComponent {
  @Select(AppState.partnerId) partnerId$: Observable<number>;
  headers = [
    'ACTIVITÉ',
    'CRÉNEAU',
    'RÉSERVATION',
    'PERSONNES',
    'STATUT',
    'VOIR LE DÉTAIL',
    'EMAIL',
    'TELEPHONE'
  ];
  page: Page<Reservation> = new Page<Reservation>({
    content: [],
    pageable: new Pageable({
      pageNumber: 0,
      pageSize: 10
    }),
    totalElements: 0,
    numberOfElements: 0
  });
  STATUS_ACCEPTED = RegisterStatusEnum.ACCEPTED;

  showEdit = false;
  showRefusePopup = false;
  showModifyPopUp = false;
  loading = false;

  reservationToShow: Reservation | undefined;
  reservationModifyPopUp: Reservation;

  constructor(
    private registerTimeSlotService: RegisterTimeSlotService,
    private store: Store
  ) {
    super();
    this.getReservations();
  }

  setReservationToShow(reservation: Reservation) {
    this.reservationToShow = reservation;
    this.showEdit = !this.showEdit;
  }

  closeDrawer() {
    this.reservationToShow = undefined;
    this.showEdit = !this.showEdit;
  }

  getReservations() {
    this.register(
      this.partnerId$
        .pipe(
          switchMap((partnerId) =>
            this.registerTimeSlotService.getReservations(
              partnerId,
              this.page.pageable.pageNumber,
              this.page.pageable.pageSize,
              [RegisterStatusEnum.PENDING]
            )
          )
        )
        .subscribe((page) => (this.page = page))
    );
  }

  acceptReservationAndRefresh(
    registerTimeSlotId: number,
    statusEnum: RegisterStatusEnum
  ) {
    this.register(
      this.registerTimeSlotService
        .updateRegisterStatus(
          registerTimeSlotId,
          statusEnum,
          this.store.selectSnapshot(AppState.partnerId) as number
        )
        .subscribe(() => this.getReservations())
    );
  }

  modify(reservation: Reservation) {
    this.showModifyPopUp = true;
    this.reservationModifyPopUp = reservation;
  }

  setReservationModifyPopUptoUndefined() {
    this.showModifyPopUp = false;
  }

  onPopupModifyClose(timeSlotsChosen: TimeSlot[]) {
    this.loading = true;
    this.register(
      this.registerTimeSlotService
        .updateRegisterStatus(
          this.reservationModifyPopUp?.registerTimeSlotId,
          RegisterStatusEnum.ONGOING,
          this.store.selectSnapshot(AppState.partnerId) as number
        )
        .pipe(
          switchMap(() =>
            this.registerTimeSlotService.reprogramOrSendDeny(
              this.store.selectSnapshot(AppState.partnerId) as number,
              this.reservationModifyPopUp.registerTimeSlotId,
              this.reservationModifyPopUp.internshipId,
              timeSlotsChosen,
              this.reservationModifyPopUp.sessionId,
              'Reprogrammation',
              this.reservationModifyPopUp.timeSlotId
            )
          )
        )
        .subscribe(() => {
          this.loading = false;
          this.showModifyPopUp = false;
          this.getReservations();
        })
    );
  }

  openCloseRefusePopup(reservation?: Reservation) {
    this.reservationToShow = reservation;
    this.showRefusePopup = !this.showRefusePopup;
  }

  refuseReservation(denyMessage: DenialMessage) {
    if (this.reservationToShow) {
      const reservation = this.reservationToShow;
      this.register(
        this.registerTimeSlotService
          .updateRegisterStatus(
            reservation.registerTimeSlotId,
            RegisterStatusEnum.REFUSED,
            this.store.selectSnapshot(AppState.partnerId) as number
          )
          .pipe(
            switchMap(() =>
              this.registerTimeSlotService.reprogramOrSendDeny(
                this.store.selectSnapshot(AppState.partnerId) as number,
                reservation.registerTimeSlotId,
                reservation.internshipId,
                [],
                this.reservationToShow?.sessionId,
                denyMessage.comment ?? '',
                reservation.timeSlotId,
                denyMessage.denyReason
              )
            )
          )
          .subscribe(() => this.getReservations())
      );
    }
  }

  onTableDataChange(event: any) {
    this.page.pageable.pageNumber = event - 1;
    this.getReservations();
  }

  formatDate(date: Moment, dateEnd?: Moment): string {
    if (dateEnd) {
      return (
        moment(date).format('DD/MM/YYYY') +
        ' - ' +
        moment(dateEnd).format('DD/MM/YYYY')
      );
    }

    return moment(date).format('DD/MM/YYYY HH:mm');
  }
}
