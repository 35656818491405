import { AfterContentInit, Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TriggerAlert } from '../app.action';
import { AppState } from '../app.state';
import { AccountResource } from '../resource/account.resource';
import { RxjsComponent } from '../shared/component/rxjs.component';
import { Alert } from '../shared/models/alert';
import { Login } from '../shared/models/login';

@Component({
  selector: 'dash-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends RxjsComponent implements AfterContentInit {
  @Select(AppState.isUserLoggedIn) isUserLoggedIn$: Observable<boolean>;
  loading = false;

  connexionForm: UntypedFormGroup;
  passwordConnexionForm = new UntypedFormControl('', Validators.required);
  emailForm = new UntypedFormControl('', [
    Validators.email,
    Validators.required
  ]);

  error: string | undefined;

  constructor(
    private accountResource: AccountResource,
    private store: Store,
    private router: Router
  ) {
    super();
    this.isUserLoggedIn$.subscribe(() => this.router.navigateByUrl('/'));
  }

  ngAfterContentInit(): void {
    this.connexionForm = new UntypedFormGroup({
      email: this.emailForm,
      password: this.passwordConnexionForm
    });
  }

  nextStep(): void {
    this.loading = true;
    const identification: Login = new Login(this.connexionForm.getRawValue());
    this.register(
      this.accountResource.connectUser(identification).subscribe(
        () => {},
        (err) => this.afterConnectError(err)
      )
    );
  }

  afterConnectError(error: any): void {
    this.loading = false;
    switch (error.status) {
      case 401:
        this.store.dispatch(
          new TriggerAlert(
            new Alert({
              message: 'Mot de passe incorrect',
              level: 'error',
              timeout: 5000
            })
          )
        );
        break;
      case 412:
        this.store.dispatch(
          new TriggerAlert(
            new Alert({
              message: "Le compte associé à cet email n'est pas activé",
              level: 'error',
              timeout: 5000
            })
          )
        );
        break;
      default:
        this.store.dispatch(
          new TriggerAlert(
            new Alert({
              message:
                'Une erreur est survenue, veuillez réessayer dans quelques instants',
              level: 'error',
              timeout: 5000
            })
          )
        );
        break;
    }
  }
}
