<atc-side-drawer (closeChange)="optionChanged.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading">
  <div body class="vsk__body">
    <div class="flex">
      <!-- Activité -->
      <atc-select [control]="activityForm"
                  [optionElements]="activities"
                  class="max-w-[40%]"
                  id="id__select-edit-activity-option-activity"
                  label="Activité*">
      </atc-select>
      <p class="prefix">{{ getPrefix() }}</p>
      <!-- Option's name -->
      <atc-input [control]="nameForm"
                 id="id__input-edit-activity-option-variant"
                 label="Nom de l'expérience*"
                 placeholder="Baptême de l'air">
      </atc-input>
    </div>

    <div class="flex flex-row gap-x-4 items-center">
      <!-- Address -->
      <atc-google-search-places (addressChanged)="geocodeAddress($event)"
                                [control]="addressForm"
                                [googleMapsService]="getLoader()"
                                class="flex-1"
                                id="id__input-edit-activity-option-address"
                                label="Adresse*">
      </atc-google-search-places>
    </div>

    <div class="vsk__inline">
      <atc-select *ngIf="containsRoles([Role.ROLE_ADMIN])"
                  [control]="experienceCategoryForm"
                  [optionElements]="categories"
                  id="id__select-edit-activity-option-atc"
                  label="Catégorie*">
      </atc-select>

      <!-- Min days before booking -->
      <atc-input [control]="minDaysBeforeBookingForm"
                 label="Nombre de jours min. avant réservation*"
                 type="number"></atc-input>
    </div>

    <div class="vsk__inline">
      <!-- Phone number -->
      <atc-input [control]="phoneForm"
                 [dropSpecialCharacters]="false"
                 [specialCharacters]="['.']"
                 id="id__input-edit-activity-option-phone"
                 label="Téléphone*"
                 mask="00.00.00.00.00"
                 placeholder="06.01.02.03.04">
      </atc-input>

      <!-- Difficulty -->
      <atc-select [control]="difficultyForm"
                  [optionElements]="difficulties"
                  id="id__select-edit-activity-option-difficulty"
                  label="Niveau de difficulté*"></atc-select>

      <!-- Duration -->
      <atc-input [control]="durationForm"
                 id="id__input-edit-activity-option-minutes"
                 label="Durée (en minutes)*"
                 type="number"></atc-input>

    </div>

    <div class="flex flex-row gap-x-4 items-center">
      <atc-select
        [checkbox]="true"
        [optionElements]="customFieldSelectItem"
        class="flex-1"
        id="id__select-edit-activity-option-custom-field"
        label="Champs personnalisés">
      </atc-select>

      <!-- With accommodation -->
      <atc-input-checkbox *ngIf="containsRoles([Role.ROLE_ADMIN])"
                          [control]="withAccommodationForm"
                          id="id__checkbox-package-with-accommodation">
        <label>Avec hébergement</label>
      </atc-input-checkbox>

      <!-- Bike park -->
      <atc-input-checkbox *ngIf="containsRoles([Role.ROLE_ADMIN])"
                          [control]="heartForm">
        <label>Coup de coeur</label>
      </atc-input-checkbox>
    </div>

    <atc-select
      [checkbox]="true"
      [optionElements]="tagSelectItem"
      id="id__select-edit-activity-option-tags"
      label="Tags">
    </atc-select>

    <!-- Image été -->
    <atc-input-file (filesChanged)="addPhotoFile($event, SeasonEnum.SUMMER)"
                    (imagesChanged)="listUpdated($event, SeasonEnum.SUMMER)"
                    [files]="photosFilesSummer"
                    [images]="photosImagesSummer"
                    id="id__input-edit-activity-option-photos-experience-summer"
                    label="Eté - Photos de l'expérience (1 photo minimum, 3 recommandées)">
    </atc-input-file>

    <!-- Image Hiver -->
    <atc-input-file (filesChanged)="addPhotoFile($event, SeasonEnum.WINTER)"
                    (imagesChanged)="listUpdated($event, SeasonEnum.WINTER)"
                    [files]="photosFilesWinter"
                    [images]="photosImagesWinter"
                    id="id__input-edit-activity-option-photos-experience-winter"
                    label="Hiver - Photos de l'expérience (1 photo minimum, 3 recommandées)">
    </atc-input-file>

    <atc-input [control]="videoForm"
               id="id__input-edit-activity-option-video"
               label="Vidéo (lien Youtube)"
               placeholder="https://www.youtube.com/watch?v=...">
    </atc-input>

    <div class="vsk__column">
      <label>On vous raconte</label>
      <atc-text-editor [(text)]="descriptionForm"
                       [maxLength]=15000
                       id="id__input-edit-activity-option-on-vous-raconte"
                       placeholder="Vous retrouverez xxx 1h avant pour découvrir l’activité et vous équiper. Vous vous dirigerez ensuite en camion jusqu’au lieu de décollage…"></atc-text-editor>
    </div>

    <div class="vsk__column">
      <label>On Adore</label>
      <atc-text-editor [(text)]="weLoveForm"
                       [maxLength]=15000
                       id="id__input-edit-activity-option-on-adore"
                       placeholder="Découvrez xxx à xxx et survolez l’un des plus beaux panoramas au monde face au Mont-Blanc…"></atc-text-editor>
    </div>

    <div class="vsk__column">
      <label>Inclus dans la prestation</label>
      <atc-text-editor [(text)]="includedForm"
                       [maxLength]=15000
                       id="id__input-edit-activity-option-inclus"
                       placeholder="La prestation contient..."></atc-text-editor>
    </div>

    <div class="vsk__column">
      <label>Non inclus</label>
      <atc-text-editor [(text)]="notIncludedForm"
                       [maxLength]=15000
                       id="id__input-edit-activity-option-non-inclus"
                       placeholder="Attention, ceci n'est pas inclus : xxx "></atc-text-editor>
    </div>

    <div class="vsk__column">
      <label>A prévoir</label>
      <atc-text-editor [(text)]="predictableForm"
                       [maxLength]=15000
                       id="id__input-edit-activity-option-a-prevoir"
                       placeholder="Prévoyez de prendre xxx afin de passer la meilleure expérience possible"></atc-text-editor>
    </div>


    <div class="vsk__column">
      <label>Message</label>
      <atc-text-editor [(text)]="messageForm"
                       [maxLength]=99999
                       id="id__input-edit-activity-option-message"
                       placeholder="Tips à destination des clients lors d'une réservation"></atc-text-editor>
    </div>


    <div class="vsk__column">
      <label>Thème*</label>
      <div class="vsk__sub-label">La couleur vous permet de distinguer vos différentes activités dans le planning</div>
      <dash-color-selector [colorForm]="colorForm" id="id__div-edit-activity-option-color"></dash-color-selector>
    </div>
  </div>
  <div class="w-full my-4" header>
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modifier expérience' : 'Nouvelle expérience' }}</h1>
  </div>

  <div class="flex flex-row justify-between w-full items-center pt-4" footer>
    <atc-button (click)="delete()" *ngIf="editMode === 'edit'" color="danger" label="Supprimer"></atc-button>
    <atc-button (click)="upsert()"
                [disabled]="!formIsValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                id="id__button-validate-form">
    </atc-button>
  </div>
</atc-side-drawer>
