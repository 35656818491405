import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { PackageGridService } from '../../../../service/package-grid.service';
import { EditComponent } from '../../../../shared/component/edit.component';
import { Experience } from '../../../../shared/models/experience';
import { PackageGrid } from '../../../../shared/models/package-grid';

@Component({
  selector: 'dash-edit-package-grid',
  templateUrl: './edit-package-grid.component.html',
  styleUrls: ['./edit-package-grid.component.scss']
})
export class EditPackageGridComponent extends EditComponent<PackageGrid> {
  experiencesOptions: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une expérience',
      disabled: true,
      classCss: 'disabled'
    }
  ];

  nameForm = new FormControl<string | null>('', Validators.required);
  priceCategoryForm = new FormControl<string | null>('', Validators.required);
  experienceForm = new FormControl<number | null>(null);

  constructor(
    protected service: PackageGridService,
    protected store: Store
  ) {
    super(service, store);
    this.name = `Grille de tarification`;
    this.priceCategoryForm.disable();
  }

  @Input() set experiences(experiences: Experience[]) {
    if (experiences) {
      this.experiencesOptions = [
        {
          id: null,
          label: 'Choisir une expérience',
          disabled: true,
          classCss: 'disabled'
        },
        ...experiences.map((experience) => {
          return {
            id: experience.id,
            label: experience.activity.name + ' · ' + experience.name
          };
        })
      ];
    }
  }

  @Input() set entityToEdit(entity: PackageGrid | undefined) {
    if (entity) {
      this.id = entity.id;
      this.nameForm.setValue(entity.name);
      this.experienceForm.setValue(entity.experienceId);
      this.priceCategoryForm.setValue(entity.priceCategory);
      this.editMode = 'edit';
    }
  }

  public isFormValid(): boolean {
    return this.nameForm.valid && this.experienceForm.valid;
  }

  public buildEntity(): PackageGrid {
    return new PackageGrid({
      id: this.id as number,
      name: this.nameForm.value as string,
      experienceId: this.experienceForm.value as number
    });
  }

  public initForm(): void {
    // Pas besoin d'initialisation de formulaire
  }
}
