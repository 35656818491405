<atc-side-drawer [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading"
                 (closeChange)="editionComplete.emit()">
  <div header class="w-full my-4">
    <h1 class="text-center">{{ editMode === 'edit' ? 'Organisation zone de carrousel' : 'Nouvelle zone de carrousel' }}</h1>
  </div>

  <div body class="vsk__body">
    <div class="vsk__inline flex flex-row gap-x-4 items-center">
      <!-- Nom -->
      <atc-input id="id__input-carousel-zone-name"
                label="Nom de la zone"
                placeholder="Zone de carrousel"
                [control]="nameForm">
      </atc-input>
      <!-- Index -->
      <atc-input id="id__input-carousel-zone-index"
                label="Index de la zone"
                placeholder="1"
                [control]="indexForm">
      </atc-input>
    </div>

    <p *ngIf="carousels?.length">Ordre des carrousels</p>

    <atc-drag-drop-list [elements]="carousels"
                        (elementsChanged)="updateOptions($event)">
    </atc-drag-drop-list>

  </div>

  <div footer class="flex flex-row justify-between w-full items-center pt-4">
      <atc-button id="id__button-validate-form"
                [disabled]="!isFormValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                (click)="create()">
      </atc-button>
  </div>
</atc-side-drawer>
