import { Pageable } from './pageable';
import { Sort } from './sort';

export class Page<T> {
  content: T[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;

  constructor(obj: Partial<Page<T>>, TCreator?: { new (val: T): T }) {
    Object.assign(this, obj);
    this.content = TCreator
      ? this.content.map((content) => new TCreator({ ...content }))
      : this.content;
    this.pageable = new Pageable({ ...this.pageable });
  }
}
