import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from '../shared/models/page';
import { TextBlock } from '../shared/models/text-block';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class TextBlockService
  extends RepositoryService
  implements PageableService<TextBlock>, CrudService<TextBlock>
{
  private URL_CMS = 'api/backoffice/cms/text-block';

  constructor(http: HttpClient) {
    super(http);
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<TextBlock>> {
    return this.get(
      this.URL_CMS +
        `/all?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map(
        (page: Page<TextBlock>) => new Page<TextBlock>({ ...page }, TextBlock)
      )
    );
  }

  upsert(textBlock: TextBlock): Observable<void> {
    return this.post(this.URL_CMS, textBlock);
  }

  deleteById(id: number): Observable<void> {
    return this.delete(this.URL_CMS + `?id=${id}`);
  }
}
