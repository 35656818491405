<div [ngClass]="{'vsk__card': !inPopup, 'vsk__popup': inPopup}">
  <div *ngIf="!inPopup" class="flex flex-row w-full justify-between">
    <h1 *ngIf="!editMode">Nouveau client</h1>
    <h1 *ngIf="editMode">Modifier client</h1>
    <atc-group-button-toggle (selectedButton)="changeClientType($event.value)"
                             [buttons]="buttonsToggle">
    </atc-group-button-toggle>
  </div>

  <div *ngIf="inPopup" class="vsk__inline vsk__margin-bottom-1">
    <atc-group-button-toggle (selectedButton)="changeClientType($event.value)"
                             [buttons]="buttonsToggle">
    </atc-group-button-toggle>
    <div class="vsk__flex"></div>
  </div>

  <div class="vsk__column">
    <div *ngIf="toggleClientType === 'particular'">
      <div class="vsk__column vsk__margin-top-1">
        <atc-input [control]="lastnameForm"
                   id="id__input-create-customer-lastname"
                   label="Nom"></atc-input>
      </div>
      <div class="vsk__column">
        <atc-input [control]="firstnameForm"
                   label="Prénom"></atc-input>
      </div>
      <div class="vsk__column vsk__margin-top-1">
        <atc-input [control]="emailForm"
                   label="Email"></atc-input>
      </div>
      <atc-input-date [control]="birthdateForm"
                      label="Date de naissance"></atc-input-date>
      <div class="vsk__column vsk__margin-top-1">
        <atc-input [control]="phoneNumberForm"
                   [specialCharacters]="['.']"
                   [dropSpecialCharacters]="false"
                   label="Téléphone*"
                   placeholder="06.01.02.03.04"
                   mask="00.00.00.00.00"></atc-input>
      </div>
      <div class="vsk__column vsk__margin-top-1">
        <atc-input [control]="addressForm"
                   label="Adresse"></atc-input>
      </div>
      <div class="vsk__column vsk__margin-top-1">
        <atc-input [control]="postCodeForm"
                   label="Code Postal" mask="00000" type="text"></atc-input>
      </div>
      <div class="vsk__column vsk__margin-top-1">
        <atc-input [control]="cityForm"
                   label="Ville"></atc-input>
      </div>
    </div>

    <div *ngIf="toggleClientType === 'professional'">
      <div class="vsk__column mt-4">
        <atc-input [control]="nameEnterpriseForm"
                   label="Nom de l'entreprise"></atc-input>
      </div>
      <div class="vsk__column vsk__margin-top-1">
        <atc-input [control]="siretEnterpriseForm"
                   label="SIRET" mask="00000000000000"></atc-input>
      </div>
      <div class="vsk__column vsk__margin-top-1">
        <atc-input [control]="emailForm"
                   label="Email"></atc-input>
      </div>
      <div class="vsk__column vsk__margin-top-1">
        <atc-input [control]="addressForm"
                   label="Adresse"></atc-input>
      </div>
      <div class="vsk__column vsk__margin-top-1">
        <atc-input [control]="postCodeForm"
                   label="Code Postal" mask="00000"></atc-input>
      </div>
      <div class="vsk__column vsk__margin-top-1">
        <atc-input [control]="cityForm"
                   label="Ville"></atc-input>
      </div>
    </div>
  </div>

  <div class="flex flex-row w-full justify-between">
    <div class="flex flex-row gap-4">
      <atc-button (click)="delete()"
                  *ngIf="editMode"
                  class="vsk__margin-right-1"
                  label="Supprimer"></atc-button>
      <atc-button (click)="done.emit(false)"
                  color="ternary"
                  label="Annuler"></atc-button>
    </div>
    <atc-button (click)="confirm()"

                label="Valider"></atc-button>
  </div>
</div>
