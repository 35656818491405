import { Component } from '@angular/core';
import { Role } from '../../shared/models/enum/Role';
import { Link } from '../../shared/sub-menu/sub-menu.component';

@Component({
  selector: 'dash-accommodation-menu',
  templateUrl: './accommodation-menu.component.html',
  styleUrls: ['./accommodation-menu.component.scss']
})
export class AccommodationMenuComponent {
  links: Link[] = [
    {
      roles: [Role.ROLE_ADMIN],
      page: 'Agence',
      route: '/accommodation/agency',
      icon: 'home',
      id: 'id__link-agency'
    },
    {
      roles: [Role.ROLE_ADMIN],
      page: 'Résidences & Chambres',
      route: '/accommodation/manage-products',
      icon: 'double-bed',
      id: 'id__link-accommodation-products'
    },
    {
      roles: [Role.ROLE_ADMIN],
      page: 'Recherche chambres',
      route: '/accommodation/search-rooms',
      icon: 'search',
      id: 'id__link-admin-search-rooms'
    },
    {
      roles: [Role.ROLE_ADMIN],
      page: 'Réserver une chambre',
      route: '/accommodation/confirm-booking',
      icon: 'checkmark',
      id: 'id__link-admin-confirm-booking'
    }
  ];
}
