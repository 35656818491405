import moment, { Moment } from 'moment';

export class PackagePrice {
  nbMin: number;
  nbMax: number;
  startDate: Moment;
  endDate: Moment;
  price: number;
  publicPrice: number;

  constructor(obj: Partial<PackagePrice>) {
    Object.assign(this, obj);

    if (this.startDate) {
      this.startDate = moment(this.startDate, 'YYYY-MM-DD');
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate, 'YYYY-MM-DD');
    }
  }
}
