import moment, { Moment } from 'moment';
import { Participant } from '../participant';

export class OrderDetailsSkiEquipment {
  id: number;
  packName: string;
  packDescription: string;
  packAgeCategory: string;
  startDate: Moment;
  endDate: Moment;
  duration: number;
  price: number;
  externalOrderId: number;
  cancelled: boolean;
  participant: Participant;

  constructor(obj: Partial<OrderDetailsSkiEquipment>) {
    Object.assign(this, obj);

    if (this.startDate) {
      this.startDate = moment(this.startDate, 'YYYY-MM-DD');
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate, 'YYYY-MM-DD');
    }

    if (this.participant) {
      this.participant = new Participant(this.participant);
    }
  }
}
