import { Component } from '@angular/core';
import { CarouselZoneService } from 'src/app/service/carousel-zone.service';
import { CarouselService } from 'src/app/service/carousel.service';
import { PageComponent } from 'src/app/shared/component/page.component';
import { Carousel } from 'src/app/shared/models/carousel';
import { CarouselZone } from 'src/app/shared/models/carousel-zone';

@Component({
  selector: 'dash-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent extends PageComponent<Carousel> {
  carouselZones: CarouselZone[] = [];
  constructor(
    protected service: CarouselService,
    private carouselZoneService: CarouselZoneService
  ) {
    super(service);
    this.register(
      this.carouselZoneService
        .getAll()
        .subscribe(
          (carouselZones: CarouselZone[]) =>
            (this.carouselZones = carouselZones)
        )
    );
  }

  delete() {}
}
