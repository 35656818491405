<!-- Room's list -->
<dash-list-edit title="Chambres"
                labelCreate="Ajouter une chambre"
                paginationId="room"
                (createEvent)="openEditDrawer()"
                (paginateEvent)="onTableDataChange($event)"
                [isEmpty]="!page.content.length"
                [headers]="headers">
  <ng-container body *ngFor="let room of page.content | paginate : {
                  id: 'room',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr>
      <td>{{room.codeRoom}}</td>
      <td>{{room.name}}</td>
      <td>{{room.totalBeds}}</td>
      <td>{{room.minPeople}}</td>
      <td>{{room.maxPeople}}</td>
      <td>{{room.category}}</td>
      <td class="flex items-center cursor-pointer h-[48px]">
        <svg class="max-h-6 w-6 stroke-primary" (click)="openEditDrawer(room)">
          <use [attr.xlink:href]="'assets/icons/icons.svg#pencil'"></use>
        </svg>
      </td>
    </tr>
  </ng-container>
</dash-list-edit>

<!-- Room's edition -->
<atc-side-drawer *ngIf="openDrawer"
                 [close]="false"
                 [loading]="loading"
                 [cssClass]="['backoffice']"
                 (closeChange)="openDrawer = false">
  <div header class="w-full my-4">
    <h1 class="text-center">{{editMode === 'edit' ? 'Modification chambre' : 'Nouvelle chambre'}}</h1>
  </div>

  <div body class="vsk__body">
    <div class="vsk__inline">
      <!-- Room's name -->
      <atc-input [control]="nameForm"
                 errorMessage="Champs obligatoire"
                 placeholder="Appartement 4 personnes 2 pièces"
                 label="Nom de la chambre*"></atc-input>

      <!-- Room's code -->
      <atc-input [control]="codeForm"
                 errorMessage="Champs obligatoire"
                 placeholder="A4P2"
                 label="Code de la chambre*"></atc-input>
    </div>

    <div class="vsk__inline">
      <!-- Room's surface -->
      <atc-input [control]="surfaceForm"
                 errorMessage="Champs obligatoire de type nombre"
                 placeholder="24"
                 type="number"
                 label="Surface en m2*"></atc-input>

      <!-- Room's total beds -->
      <atc-input [control]="totalBedsForm"
                 errorMessage="Champs obligatoire de type nombre"
                 placeholder="4"
                 type="number"
                 label="Nombre de lits*"></atc-input>
    </div>

    <div class="vsk__inline">
      <!-- Room's min people -->
      <atc-input [control]="minPeopleForm"
                 errorMessage="Champs obligatoire de type nombre"
                 placeholder="1"
                 type="number"
                 label="Nombre de personnes min.*"></atc-input>

      <!-- Room's max people -->
      <atc-input [control]="maxPeopleForm"
                 errorMessage="Champs obligatoire de type nombre"
                 placeholder="4"
                 type="number"
                 label="Nombre de personnes max.*"></atc-input>
    </div>

    <!-- Room's category -->
    <atc-input [control]="categoryForm"
               errorMessage="Champs obligatoire"
               placeholder="Locatif"
               label="Catégorie*"></atc-input>

    <atc-select [optionElements]="featuresElements"
                [searchable]="true"
                [checkbox]="true"
                label="Équipements">
    </atc-select>

    <hr>

    <div class="flex flex-col gap-y-4 px-2">
      <label>Équipements</label>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of equipmentsFilter"
                            [control]="getControl(element.control)">
          {{element.label}}
        </atc-input-checkbox>
      </div>
    </div>

    <hr>

    <div class="flex flex-col gap-y-4 px-2">
      <label>Service</label>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of servicesFilter"
                            [control]="getControl(element.control)">
          {{element.label}}
        </atc-input-checkbox>
      </div>
    </div>

    <hr>

    <div class="flex flex-col gap-y-4 px-2">
      <label>Accessibilité</label>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of accessFilter"
                            [control]="getControl(element.control)">
          {{element.label}}
        </atc-input-checkbox>
      </div>
    </div>

    <hr>

    <!-- Room's Images -->
    <atc-input-file label="Photos de l'établissement"
                    [files]="photosFiles"
                    [images]="photosImages"
                    (imagesChanged)="photosImages = $event"
                    (filesChanged)="addPhotoFile($event)">
    </atc-input-file>

    <!-- Room's description -->
    <div class="vsk__column">
      <label>Description</label>
      <atc-text-editor [(text)]="descriptionForm"
                       label="Description"></atc-text-editor>
    </div>
  </div>

  <div footer class="flex flex-row justify-between items-center w-full">
    <atc-toggle-switch [label]="labelSwitch"
                       [control]="upsertAllRoomsToggleForm">
    </atc-toggle-switch>

    <atc-button [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                [disabled]="!formIsValid()"

                (click)="upsert()">
    </atc-button>
  </div>
</atc-side-drawer>
