import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../app.state';
import { ActivityPartner } from '../../../shared/models/activity-partner';
import { Role } from '../../../shared/models/enum/Role';
import { ResortMin } from '../../../shared/models/resort-min';

@Component({
  selector: 'dash-list-activity-partner',
  templateUrl: './list-activity-partner.component.html',
  styleUrls: ['./list-activity-partner.component.scss']
})
export class ListActivityPartnerComponent {
  @Select(AppState.roles) roles$: Observable<string[]>;

  headers = ['Station', 'Activité'];

  @Input() activities: ActivityPartner[] = [];
  @Output() createActivity: EventEmitter<void> = new EventEmitter<void>();
  @Output() activityPartnerChanged: EventEmitter<ActivityPartner> =
    new EventEmitter<ActivityPartner>();

  constructor() {
    this.roles$.subscribe((roles) => {
      if (roles.indexOf(Role.ROLE_ADMIN) !== -1) {
        this.headers.unshift('Id');
      }
    });
  }

  editActivity(activity: ActivityPartner): void {
    this.activityPartnerChanged.emit(activity);
  }

  toStringResorts(resorts: ResortMin[]) {
    if (!resorts || !resorts.length) {
      return '';
    }

    return resorts.map((resort) => resort.name).join(', ');
  }
}
