<atc-side-drawer [close]="false"
                 [cssClass]="['backoffice']"
                 (closeChange)="editionComplete.emit()">
  <div header class="w-full my-4">
    <h1 class="text-center">{{editMode === 'edit' ? 'Modification activité' : 'Nouvelle activité'}}</h1>
  </div>

  <div body class="vsk__body">
    <div class="vsk__inline">
      <!-- Name -->
      <atc-input id="id__input-text-activity-name"
                 label="Nom"
                 placeholder="Ski"
                 [control]="nameForm">
      </atc-input>

      <!-- Category -->
      <atc-select id="id__select-edit-activity-category"
                  label="Catégorie*"
                  [control]="categoryForm"
                  [optionElements]="categories">
      </atc-select>
    </div>

    <div class="vsk__inline">
      <!-- Start date -->
      <atc-input-date id="id__input-activity-date-start"
                      label="Date de début"
                      [control]="dateStartForm">
      </atc-input-date>

      <!-- End date -->
      <atc-input-date id="id__input-activity-date-end"
                      label="Date de fin"
                      [control]="dateEndForm">
      </atc-input-date>
    </div>

    <!-- Icone -->
    <atc-input-file label="Icone"
                    id="id__input-edit-resort-logo"
                    accept="image/svg+xml"
                    [multiple]="false"
                    [files]="iconFile"
                    [images]="iconImage"
                    (imagesChanged)="iconImage = $event"
                    (filesChanged)="iconFile = $event">
    </atc-input-file>

    <atc-toggle-switch id="id__checkbox-activity-enabled"
                       label="Activé"
                       [control]="enabledForm">
    </atc-toggle-switch>
  </div>

  <div footer class="vsk__footer">
    <atc-button id="id__button-validate-form"
                [disabled]="!formIsValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"

                (click)="create()">
    </atc-button>
  </div>
</atc-side-drawer>
