import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OriginEnum } from 'src/app/shared/models/enum/origin.enum';
import { AppState } from '../../../../app.state';
import { ActivityPartner } from '../../../../shared/models/activity-partner';
import { Role } from '../../../../shared/models/enum/Role';
import { Experience } from '../../../../shared/models/experience';
import { OptionEdit } from '../edit-experience/edit-experience.component';

@Component({
  selector: 'dash-list-experience',
  templateUrl: './list-experience.component.html',
  styleUrls: ['./list-experience.component.scss']
})
export class ListExperienceComponent {
  @Select(AppState.roles) roles$: Observable<string[]>;

  headers = ['Activité', 'Nom', 'Difficulté', 'Durée', 'Dupliquer'];

  @Input() activityPartners: ActivityPartner[] = [];
  @Output() editOptionChanged: EventEmitter<OptionEdit> =
    new EventEmitter<OptionEdit>();
  @Output() duplicateOptionChanged: EventEmitter<OptionEdit> =
    new EventEmitter<OptionEdit>();
  @Output() createEventChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
    this.roles$.subscribe((roles) => {
      if (roles.indexOf(Role.ROLE_ADMIN) !== -1) {
        this.headers.unshift('Id');
      }
    });
  }

  editExperience(activity: ActivityPartner, experience: Experience): void {
    this.editOptionChanged.emit({ activity, option: experience });
  }

  translateDifficulty(difficulty: string | null): string {
    switch (difficulty) {
      case 'FOR_ALL':
        return 'Pour tous';
      case 'SPORT':
        return 'Sportif';
      case 'EXPERIMENTED':
        return 'Expérimenté';
      default:
        return '-';
    }
  }

  duplicateExperience(
    event: Event,
    activity: ActivityPartner,
    experience: Experience
  ) {
    event.stopPropagation();
    this.duplicateOptionChanged.emit({
      activity,
      option: new Experience({ ...experience, id: null })
    });
  }

  isYoPlanning(origin: OriginEnum): boolean {
    return origin === OriginEnum.YOPLANNING;
  }
}
