<dash-list-edit (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                [headers]="headers"
                [searchForm]="searchForm"
                labelCreate="Ajouter un tarif"
                paginationId="experiencePackage"
                searchLabel="Recherche de tarif"
                searchPlaceholder="Rechercher par nom d'expérience ..."
                title="Tarifs unitaires">
  <ng-container *ngFor="let pck of page.content | paginate : {
                  id: 'experiencePackage',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }" body>
    <tr (click)="edit(pck)">
      <td *ngIf="roles$ | async | isAdmin">{{ pck.id }}</td>
      <td>{{ pck.packageGrid.name }}</td>
      <td>{{ pck.experience.activity.name }}</td>
      <td>{{ pck.experience.name }}</td>
      <td>{{ pck.ageRange.name }}</td>
      <td>
        <svg *ngIf="pck.hasErrors" class="stroke-red-700 h-[24px] w-[24px]">
          <use [attr.xlink:href]="'assets/icons/icons.svg#no-check'"></use>
        </svg>
        <svg *ngIf="!pck.hasErrors" class="stroke-green-700 h-[24px] w-[24px]">
          <use [attr.xlink:href]="'assets/icons/icons.svg#checkmark'"></use>
        </svg>
      </td>
      <td>
        <img (click)="duplicatePackage($event, pck)"
             alt="Duplicate" src="assets/icones/copy.svg">
      </td>
    </tr>
  </ng-container>
</dash-list-edit>
