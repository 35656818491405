<dash-list-edit id="id__table-list-carousel-product"
                paginationId="carousel-product"
                [headers]="headers"
                [searchForm]="searchForm"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                labelCreate="Ajouter"
                searchLabel="Recherche par nom"
                searchPlaceholder="Canyon, VTT, etc."
                title="Produits de carrousel">
  <ng-container body *ngFor="let carouselProduct of page.content | paginate : {
                  id: 'carousel-product',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(carouselProduct)">
      <td>{{ carouselProduct.id }}</td>
      <td>{{ carouselProduct.name }}</td>
      <td>{{ getType(carouselProduct.productType) }}</td>
      <td>{{ getResortName(carouselProduct.resortId) }}</td>
      <td>{{ carouselProduct.startDate.format('DD/MM/YYYY') }}</td>
      <td>{{ carouselProduct.endDate.format('DD/MM/YYYY') }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
