import { TimeSlot } from './time-slot/time-slot';

export class MinReservation {
  registerTimeSlotId: number;
  internshipId: number;
  message: string;
  denyReason: string;
  timeSlots: TimeSlot[];
  partnerId: number;
  sessionId: string;
  timeSlotOriginId: number;

  constructor(obj: Partial<MinReservation>) {
    Object.assign(this, obj);
  }
}
