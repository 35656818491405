import { Alert } from './shared/models/alert';
import { Login } from './shared/models/login';
import { Partner } from './shared/models/partner';
import { RegisterTimeSlot } from './shared/models/register-time-slot';
import { Internship } from './shared/models/time-slot/internship';
import { TimeSlot } from './shared/models/time-slot/time-slot';
import { TimeSlotModel } from './shared/models/time-slot/time-slot-model';

export class ConnectUser {
  static readonly type = '[Account API] Connect user session';

  constructor(public login: Login) {}
}

export class ChangePartner {
  static readonly type = '[Account API] Change partner';

  constructor(public partner: Partner) {}
}

export class TimeSlotSelect {
  static readonly type = '[TimeSlot] Select a time slot';

  constructor(public timeSlot: TimeSlot) {}
}

export class InternshipSelect {
  static readonly type = '[TimeSlot] Select an internship';

  constructor(public internship: Internship) {}
}

export class UpdateTimeSlotSelected {
  static readonly type = '[TimeSlot] Update selected a time slot';

  constructor(
    public timeSlotModel: TimeSlotModel,
    public methodeUpdate: string
  ) {}
}

export class RegisterToTimeSlot {
  static readonly type = '[TimeSlot] Register to selected a time slot';

  constructor(public registered: RegisterTimeSlot[]) {}
}

export class DisconnectUser {
  static readonly type = '[Account API] Disconnect user session';
}

export class RenewToken {
  static readonly type = '[Account API] Renew token session';
}

export class TriggerAlert {
  static readonly type = '[APP] Show an alert';

  constructor(public alert: Alert) {}
}
