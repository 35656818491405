import { ActivityPartner } from './activity-partner';
import { Experience } from './experience';

export class ExperienceActivityPartner {
  option: Experience;
  activity: ActivityPartner;

  constructor(obj: Partial<ExperienceActivityPartner>) {
    Object.assign(this, obj);
  }
}
