import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AtomicLibModule } from 'atomic-lib';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CustomersManagementModule } from '../customers-management/customers-management.module';
import { SharedModule } from '../shared/shared.module';
import { AddActivityComponent } from './add-activity/add-activity.component';
import { CalendarPartnerComponent } from './calendar-partner.component';
import { EditRegisterCustomerToSlotComponent } from './edit-register-customer-to-slot/edit-register-customer-to-slot.component';

@NgModule({
  declarations: [
    CalendarPartnerComponent,
    AddActivityComponent,
    EditRegisterCustomerToSlotComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CustomersManagementModule,
    NgxMaskDirective,
    NgxMaskPipe,
    AtomicLibModule
  ],
  exports: [CalendarPartnerComponent],
  providers: [provideNgxMask()]
})
export class CalendarPartnerModule {}
