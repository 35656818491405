import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { TriggerAlert } from 'src/app/app.action';
import { Alert } from 'src/app/shared/models/alert';
import { AppState } from '../../../app.state';
import { AgeRangeService } from '../../../service/age-range.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { AgeRange } from '../../../shared/models/age-range';
import { Role } from '../../../shared/models/enum/Role';

@Component({
  selector: 'dash-edit-age-range',
  templateUrl: './edit-age-range.component.html',
  styleUrls: ['./edit-age-range.component.scss']
})
export class EditAgeRangeComponent extends RxjsComponent {
  loading = false;
  id: number | undefined;

  // Form
  nameForm = new UntypedFormControl('', Validators.required);
  minAgeForm = new UntypedFormControl(0, Validators.required);
  maxAgeForm = new UntypedFormControl(99, Validators.required);
  keyCardForm = new UntypedFormControl('');
  createAgeRangeForm: UntypedFormGroup;

  keyCardOptions: OptionElement<string>[] = [];

  @Input() editMode: 'create' | 'edit' = 'create';
  @Output() ageRangeChanged: EventEmitter<void> = new EventEmitter<void>();
  protected readonly Role = Role;

  constructor(
    private ageRangeService: AgeRangeService,
    private store: Store
  ) {
    super();
    this.createAgeRangeForm = new UntypedFormGroup({
      name: this.nameForm,
      start: this.minAgeForm,
      end: this.maxAgeForm,
      keyCardSlug: this.keyCardForm
    });
  }

  @Input() set ageRange(value: AgeRange | undefined) {
    if (value) {
      this.editMode = 'edit';
      this.nameForm.setValue(value.name);
      this.minAgeForm.setValue(value.start);
      this.maxAgeForm.setValue(value.end);
      this.keyCardForm.setValue(value.keyCardSlug);
      this.id = value.id;

      if (value.origin === 'ELIBERTY') {
        this.keyCardForm.setValidators(Validators.required);
        this.ageRangeService
          .getKeyCardsForAgeRange(this.id)
          .subscribe((keyCards) => {
            this.keyCardOptions = keyCards.map((keyCard) => {
              return {
                id: keyCard,
                label: keyCard
              };
            });
          });
      }
    }
  }

  upsert(): void {
    if (this.createAgeRangeForm.valid) {
      if (Number(this.minAgeForm.value) > Number(this.maxAgeForm.value)) {
        this.store.dispatch(
          new TriggerAlert(
            new Alert({
              message: `L'âge minimum ne peut pas être supérieur à l'âge maximum`,
              level: 'warning',
              timeout: 5000
            })
          )
        );
        return;
      }

      this.loading = true;
      const idPartner = this.store.selectSnapshot(AppState.partnerId);
      this.register(
        this.ageRangeService
          .upsert(
            new AgeRange({
              id: this.id,
              ...this.createAgeRangeForm.getRawValue(),
              partner: idPartner
            })
          )
          .subscribe(
            () => {
              this.loading = false;
              this.ageRangeChanged.emit();
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    message: "Tranche d'âge créée / modifiée",
                    level: 'success',
                    timeout: 2000
                  })
                )
              );
            },
            (err) => {
              console.error(err);
              this.loading = false;
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    message:
                      'Une erreur est survenue, veuillez réessayer plus tard ...',
                    level: 'error',
                    timeout: 5000
                  })
                )
              );
            }
          )
      );
    }
  }

  delete() {
    this.register(
      this.ageRangeService.deleteById(this.id as number).subscribe(
        () => {
          this.loading = false;
          this.ageRangeChanged.emit();
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message: "Tranche d'âge supprimée",
                level: 'success',
                timeout: 2000
              })
            )
          );
        },
        (err) => {
          console.error(err);
          this.loading = false;
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message:
                  'Une erreur est survenue, veuillez réessayer plus tard ...',
                level: 'error',
                timeout: 5000
              })
            )
          );
        }
      )
    );
  }
}
