<atc-side-drawer [loading]="loading"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 (closeChange)="editionComplete.emit()">

  <div header class="w-full my-4">
    <h1 class="text-center">{{editMode === 'edit' ? 'Modification d\'utilisateur ' : 'Nouvel utilisateur'}}</h1>
  </div>
  <div body class="vsk__body">
    <div>
      <div class="vsk__inline pb-2">

        <!-- Prénom -->
        <atc-input id="id__form-create-partner-first-name"
                   class="pr-1"
                   label="Prénom"
                   placeholder="Jean-Christophe"
                   [control]="firstNameForm"
                   [errorMessage]="'Champs obligatoire'">
        </atc-input>

        <!-- Nom -->
        <atc-input id="id__form-create-partner-last-name"
                   label="Nom"
                   class="pr-1"
                   placeholder="Lafaille"
                   [control]="lastNameForm"
                   [errorMessage]="'Champs obligatoire'">
        </atc-input>

        <!-- Date de naissance -->
        <atc-input-date id="id__form-create-partner-birth-date"
                        label="Date de naissance"
                        [control]="birthdateForm">
        </atc-input-date>

      </div>
      <div class="py-2">
        <!-- Email de connexion -->
        <atc-input id="id__form-create-partner-email"
                   label="Email de connexion"
                   placeholder="jean@monentreprise.fr"
                   [errorMessage]="'Champs obligatoire'"
                   [control]="emailUserForm">
        </atc-input>
      </div>

      <div class="vsk__inline py-2">
        <!-- Addresse de l'utilisateur-->
        <atc-input id="id__form-create-partner-company-address"
                   label="Adresse de l'utilisateur"
                   class="pr-1"
                   [errorMessage]="'Champs obligatoire'"
                   placeholder="10 rue de la station de ski"
                   [control]="addressForm">
        </atc-input>
        <!-- Pays -->
        <atc-input id="id__form-create-partner-country"
                   label="Pays"
                   placeholder="France"
                   [control]="countryForm"
                   [errorMessage]="'Champs obligatoire'">
        </atc-input>

      </div>

      <div class="vsk__inline py-2">
        <!-- Ville -->
        <atc-input id="id__form-create-partner-city"
                   label="Ville"
                   placeholder="Bourg-Saint-Maurice"
                   [control]="cityForm"
                   [errorMessage]="'Champs obligatoire'">
        </atc-input>
      </div>

      <div class="vsk__inline py-2">
        <!-- Code Postal -->
        <atc-input id="id__form-create-partner-postal-code"
                   label="Code Postal"
                   class="pr-1"
                   placeholder="73700"
                   [control]="postalCodeForm"
                   [errorMessage]="'Champs obligatoire'">
        </atc-input>

        <!-- Téléphone -->
        <atc-input id="id__form-create-partner-phone"
                   label="Téléphone"
                   placeholder="0601020304"
                   [control]="phoneForm"
                   mask="0000000000"
                   [errorMessage]="'Champs obligatoire'">
        </atc-input>


      </div>

      <!-- Roles -->
      <atc-select class="flex-1"
                  id="id__form-create-roles"
                  label="Roles"
                  [optionElements]="rolesOptions"
                  [checkbox]="true">
      </atc-select>
    </div>
  </div>

  <div footer class="vsk__footer">

    <atc-button
      *ngIf="editMode === 'edit'"
      color="ternary"
      class="mr-1"
      label="Réinitialiser le mot de passe"
      (click)="resetPassword()">

    </atc-button>

    <atc-button
      *ngIf="editMode === 'edit'"
      color="ternary"
      [label]="userEnabled ? 'Désactiver' : 'Activer'"
      label="Désactiver"
      (click)="toggleDisable()">
    </atc-button>

    <div class="vsk__flex"></div>
    <atc-button id="id__button-validate-form"
                [disabled]="!createPartnerUserForm.valid"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                (click)="create()">
    </atc-button>
  </div>
</atc-side-drawer>
