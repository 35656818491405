<dash-list-edit id="id__table-list-carousel"
                paginationId="carousel"
                [headers]="headers"
                [searchForm]="searchForm"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                labelCreate="Ajouter"
                searchLabel="Recherche par nom"
                searchPlaceholder="Hébergement, activité, etc."
                title="Carrousels">
  <ng-container body *ngFor="let carousel of page.content | paginate : {
                  id: 'carousel',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(carousel)">
      <td>{{ carousel.id }}</td>
      <td>{{ carousel.title }}</td>
      <td>{{ getType(carousel.type) }}</td>
      <td>{{ carousel.zoneId }}</td>
      <td>{{ carousel.enabled ? 'Oui' : 'Non' }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
