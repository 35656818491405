<atc-side-drawer [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading"
                 (closeChange)="editionComplete.emit()">
  <div header class="w-full my-4">
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification remise de prix catalogue' : 'Nouvelle remise de prix catalogue' }}</h1>
  </div>

  <div body class="vsk__body">
    <!-- Value to apply -->
    <atc-input label="Réduction de 0 à 100"
               type="number"
               [control]="reductionForm"
               mask="0,0"
               [dropSpecialCharacters]="false"
               [specialCharacters]="[',']"
               [allowNegativeNumbers]="false"
               placeholder="10">
    </atc-input>

    <div class="vsk__inline">
      <!-- Start date -->
      <atc-input-date id="id__input-promo-code-date-start"
                      label="Date de début de validité"
                      [control]="startDateForm">
      </atc-input-date>

      <!-- End date -->
      <atc-input-date id="id__input-promo-code-date-end"
                      label="Date de fin de validité"
                      [control]="endDateForm">
      </atc-input-date>
    </div>
  </div>

  <div footer class="flex flex-row justify-between w-full items-center pt-4">
    <atc-button *ngIf="editMode === 'edit'" color="danger" label="Supprimer" (click)="deleteById()"></atc-button>

    <atc-button id="id__button-validate-form"
                [disabled]="!isFormValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"

                (click)="create()">
    </atc-button>
  </div>
</atc-side-drawer>
