import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { TriggerAlert } from 'src/app/app.action';
import { SkisetService } from 'src/app/service/skiset-voucher.service';
import { Alert } from 'src/app/shared/models/alert';

@Component({
  selector: 'dash-skiset-orders',
  templateUrl: './skiset-orders.component.html',
  styleUrls: ['./skiset-orders.component.scss']
})
export class SkisetOrdersComponent {
  skisetOrderForm = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\d+$/)
  ]);

  constructor(
    private store: Store,
    private skisetService: SkisetService
  ) {}

  send() {
    if (this.skisetOrderForm.valid && this.skisetOrderForm.value) {
      this.skisetService.sendVoucher(this.skisetOrderForm.value).subscribe(
        () => {
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                level: 'success',
                message: 'Voucher envoyé avec succès !',
                timeout: 2000
              })
            )
          );
          this.skisetOrderForm.reset();
        },
        (err) =>
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                level: 'error',
                message: 'Une erreur est survenue : ' + err.message,
                timeout: 5000
              })
            )
          )
      );
    } else {
      this.store.dispatch(
        new TriggerAlert(
          new Alert({
            level: 'error',
            message: 'Le numéro de commande est invalide',
            timeout: 5000
          })
        )
      );
    }
  }
}
