import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, filter } from 'rxjs/operators';
import { OrderService } from 'src/app/service/order.service';
import { PageComponent } from 'src/app/shared/component/page.component';
import { Order } from 'src/app/shared/models/order/order';

@Component({
  selector: 'dash-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.scss']
})
export class SalesReportComponent extends PageComponent<Order> {
  showCreateOrder = false;

  constructor(
    protected service: OrderService,
    private activatedRoute: ActivatedRoute
  ) {
    super(service);
    this.activatedRoute.queryParamMap
      .pipe(
        debounceTime(2000),
        filter((params) => params.has('orderId'))
      )
      .subscribe((params) => {
        const orderId = Number(params.get('orderId'));
        const order = this.page.content.find((order) => order.id === orderId);

        if (order) {
          this.showEditor = true;
          this.toEdit = order;
        }
      });
  }

  delete() {}

  editCompletedOrder() {
    this.fetchPageable(this.page, this.searchForm.value as string);
    this.showCreateOrder = false;
  }
}
