export class CustomField {
  id: number;
  label: string | null;
  type: string | null;
  value: string;

  constructor(obj: Partial<CustomField>) {
    Object.assign(this, obj);
  }
}
