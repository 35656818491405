<div class="vsk__column">
  <dash-list-activity
    [searchForm]="searchForm"
    [page]="page"
    (pageChanged)="fetchPageable($event, searchForm.value)"
    (createChanged)="showEdit()"
    (isChanged)="edit($event)"
    (isDeleted)="delete($event)">
  </dash-list-activity>
  <dash-edit-activity
    id="form-edit-activity-partner"
    *ngIf="showEditor"
    [activityToEdit]="toEdit"
    (editionComplete)="editCompleted()">
  </dash-edit-activity>
</div>
