import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgeRange } from '../shared/models/age-range';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class AgeRangeService extends RepositoryService {
  private URL_AGE_RANGE = 'api/backoffice/age-range';

  upsert(ageRange: AgeRange): Observable<number> {
    return this.put(this.URL_AGE_RANGE + '/upsert', ageRange);
  }

  getAllByPartnerId(partnerId: number): Observable<AgeRange[]> {
    return this.get(
      this.URL_AGE_RANGE + '/by-id-partner?partnerId=' + partnerId
    ).pipe(
      map((ageRanges) =>
        ageRanges.map((ageRange: AgeRange) => new AgeRange({ ...ageRange }))
      )
    );
  }

  deleteById(id: number): Observable<void> {
    return this.delete(this.URL_AGE_RANGE + '/delete?id=' + id);
  }

  getKeyCardsForAgeRange(id: number): Observable<string[]> {
    return this.get(
      this.URL_AGE_RANGE + '/get-key-card-for-age-range?id=' + id
    );
  }
}
