<div class="flex flex-col justify-evenly">
  <dash-list-partner [page]="page"
                     [searchForm]="searchForm"
                     (pageChanged)="fetchPageable($event, searchForm.value)"
                     (createChanged)="showEdit()"
                     (isChanged)="edit($event)">
  </dash-list-partner>
  <dash-edit-partner id="form-edit-activity-partner"
                     *ngIf="showEditor"
                     [partnerToEdit]="toEdit"
                     (editionComplete)="editCompleted()">
  </dash-edit-partner>
</div>
