import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  ChangePartner,
  ConnectUser,
  DisconnectUser,
  InternshipSelect,
  RegisterToTimeSlot,
  RenewToken,
  TimeSlotSelect,
  TriggerAlert,
  UpdateTimeSlotSelected
} from './app.action';
import { LocalStorageResource } from './resource/local-storage.resource';
import { AccountService } from './service/account.service';
import { PartnerService } from './service/partner.service';
import { TimeSlotService } from './service/time-slot.service';
import { ActivityPartner } from './shared/models/activity-partner';
import { Alert } from './shared/models/alert';
import { Role } from './shared/models/enum/Role';
import { Partner } from './shared/models/partner';
import { Internship } from './shared/models/time-slot/internship';
import { TimeSlot } from './shared/models/time-slot/time-slot';
import { TokenRefresh } from './shared/models/token-refresh';
import { UserSession } from './shared/models/user-session';

const TOKEN_KEY = 'auth-token';
const REFRESH_TOKEN_KEY = 'auth-refresh-token';
const PARTNER_KEY = 'partner';
const USER_KEY = 'auth-user';
const PARTNERS_KEY = 'partners';

export interface AppStateModel {
  user: UserSession | null;
  currentPartner: Partner | null;
  partners: Partner[];
  timeSlot: TimeSlot | null;
  internship: Internship | null;
  token: string | null;
  refreshToken: string | null;
  alert: Alert | null;
}

@State<AppStateModel>({
  name: 'vsk',
  defaults: {
    user: LocalStorageResource.getUser(),
    currentPartner: LocalStorageResource.getPartner(),
    partners: LocalStorageResource.getPartners(),
    timeSlot: null,
    internship: null,
    token: LocalStorageResource.getToken(),
    refreshToken: LocalStorageResource.getRefreshToken(),
    alert: null
  }
})
@Injectable()
export class AppState {
  constructor(
    private accountService: AccountService,
    private partnerService: PartnerService,
    private timeSlotService: TimeSlotService,
    private router: Router
  ) {}

  @Selector()
  static user(state: AppStateModel): UserSession | null {
    return state.user;
  }

  @Selector()
  static partner(state: AppStateModel): Partner | null {
    return state.currentPartner;
  }

  @Selector()
  static partners(state: AppStateModel): Partner[] {
    return state.partners.sort((prev, curr) =>
      prev.name < curr.name ? -1 : 1
    );
  }

  @Selector()
  static partnerId(state: AppStateModel): number | undefined {
    return state.currentPartner?.id;
  }

  @Selector()
  static isEliberty(state: AppStateModel): boolean {
    return state.currentPartner?.contractorId !== null;
  }

  @Selector()
  static activitiesPartner(state: AppStateModel): ActivityPartner[] {
    return state.currentPartner ? state.currentPartner.activities : [];
  }

  @Selector()
  static timeSlot(state: AppStateModel): TimeSlot | null {
    return state.timeSlot;
  }

  @Selector()
  static internship(state: AppStateModel): Internship | null {
    return state.internship;
  }

  @Selector()
  static roles(state: AppStateModel): string[] {
    return state.user === null ? [] : state.user.roles;
  }

  @Selector()
  static isAdmin(state: AppStateModel): boolean {
    return state.user === null
      ? false
      : state.user.roles.includes(Role.ROLE_ADMIN);
  }

  @Selector()
  static token(state: AppStateModel): string | null {
    return state.token;
  }

  @Selector()
  static isUserLoggedIn(state: AppStateModel): boolean {
    return state.user !== null;
  }

  @Selector()
  static alert(state: AppStateModel): Alert | null {
    return state.alert;
  }

  @Action(ConnectUser)
  connectUser(
    ctx: StateContext<AppStateModel>,
    action: ConnectUser
  ): Observable<any> {
    const state = ctx.getState();
    return this.accountService.connectUser(action.login, false).pipe(
      tap((user) => {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(USER_KEY);
        window.localStorage.removeItem(REFRESH_TOKEN_KEY);
        window.localStorage.setItem(TOKEN_KEY, user.jwt);
        window.localStorage.setItem(REFRESH_TOKEN_KEY, user.refreshToken);
        window.localStorage.setItem(USER_KEY, JSON.stringify(user));
        ctx.setState({
          ...state,
          user,
          token: user.jwt
        });
      }),
      switchMap((user) => this.partnerService.getInformation(user.id)),
      tap((partners) => {
        window.localStorage.removeItem(PARTNER_KEY);
        window.localStorage.setItem(PARTNER_KEY, JSON.stringify(partners[0]));
        window.localStorage.setItem(PARTNERS_KEY, JSON.stringify(partners));
        ctx.setState({
          ...state,
          partners,
          currentPartner: partners[0]
        });
      }),
      tap(() => window.location.reload())
    );
  }

  @Action(DisconnectUser)
  disconnectUser(ctx: StateContext<AppStateModel>): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      user: null
    });
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    window.localStorage.removeItem(USER_KEY);
    this.router.navigateByUrl('/login');
  }

  @Action(RenewToken)
  renewToken(ctx: StateContext<AppStateModel>): Observable<TokenRefresh> {
    const state = ctx.getState();
    return this.accountService
      .refreshSession(state.refreshToken as string)
      .pipe(
        tap((newToken) => {
          window.localStorage.removeItem(TOKEN_KEY);
          window.localStorage.setItem(TOKEN_KEY, newToken.accessToken);

          ctx.setState({
            ...state,
            token: newToken.accessToken
          });
        })
      );
  }

  @Action(TimeSlotSelect)
  timeSlotSelect(
    ctx: StateContext<AppStateModel>,
    action: TimeSlotSelect
  ): Observable<TimeSlot> {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      timeSlot: action.timeSlot,
      internship: null
    });

    return of(action.timeSlot);
  }

  @Action(InternshipSelect)
  internshipSelect(
    ctx: StateContext<AppStateModel>,
    action: InternshipSelect
  ): Observable<Internship> {
    ctx.patchState({
      internship: action.internship,
      timeSlot: null
    });

    return of(action.internship);
  }

  @Action(UpdateTimeSlotSelected)
  updateTimeSlotSelect(
    ctx: StateContext<AppStateModel>,
    action: UpdateTimeSlotSelected
  ): Observable<TimeSlot> {
    const state = ctx.getState();
    const partner = state.currentPartner as Partner;

    return this.timeSlotService
      .update(partner.id, action.timeSlotModel, action.methodeUpdate === 'all')
      .pipe(
        tap(() => {
          ctx.setState({
            ...state,
            timeSlot: new TimeSlot({
              ...state.timeSlot,
              ...action.timeSlotModel
            })
          });
        }),
        switchMap(() => of(state.timeSlot as TimeSlot))
      );
  }

  @Action(RegisterToTimeSlot)
  registerToTimeSlotSelect(
    ctx: StateContext<AppStateModel>,
    action: RegisterToTimeSlot
  ): Observable<TimeSlot> {
    const state = ctx.getState();
    const prevTimeSlot = state.timeSlot;
    const newTimeSlot = new TimeSlot({
      ...prevTimeSlot,
      registered: action.registered
    });

    ctx.setState({
      ...state,
      timeSlot: newTimeSlot
    });

    return of(newTimeSlot);
  }

  @Action(ChangePartner)
  changePartner(ctx: StateContext<AppStateModel>, action: ChangePartner) {
    window.localStorage.setItem(PARTNER_KEY, JSON.stringify(action.partner));
    window.location.reload();
  }

  @Action(TriggerAlert)
  triggerAlert(ctx: StateContext<AppStateModel>, action: TriggerAlert): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      alert: action.alert
    });
  }
}
