import moment, { Moment } from 'moment';
import { TypePromoEnum } from './enum/type-promo.enum';

export class PromoCode {
  id: number;
  name: string;
  applyNumber: number;
  dateValidityStart: Moment;
  dateValidityEnd: Moment;
  typePromo: TypePromoEnum;
  used: number;
  usedLimit: number;
  minPrice: number;

  constructor(obj: Partial<PromoCode>) {
    Object.assign(this, obj);

    if (this.dateValidityStart) {
      this.dateValidityStart = moment(this.dateValidityStart, 'YYYY-MM-DD');
    }

    if (this.dateValidityEnd) {
      this.dateValidityEnd = moment(this.dateValidityEnd, 'YYYY-MM-DD');
    }
  }
}
