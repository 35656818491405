export class ConsumerCategory {
  id: number;
  name: string;
  ageMin: number;
  ageMax: number;

  constructor(obj: Partial<ConsumerCategory>) {
    Object.assign(this, obj);
  }
}
