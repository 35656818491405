<dash-list-edit title="Établissements"
                labelCreate="Ajouter un établissement"
                paginationId="establishment"
                (createEvent)="openEditDrawer()"
                (paginateEvent)="onTableDataChange($event)"
                [isEmpty]="!pageEstablishment.content.length"
                [headers]="headers">
  <div filters class="flex flex-row mb-4">
    <atc-input icon="search"
               placeholder="Rechercher par nom ..."
               label="Recherche"
               [control]="nameSearchForm"></atc-input>
  </div>

  <ng-container body *ngFor="let establishment of pageEstablishment.content | paginate : {
                  id: 'establishment',
                  itemsPerPage: pageEstablishment.pageable.pageSize,
                  currentPage: pageEstablishment.pageable.pageNumber + 1,
                  totalItems: pageEstablishment.totalElements }">
    <tr [ngClass]="{'selected': establishment.id === selectedEstablishment?.id}"
        class="clickable"
        (click)="selectEstablishment(establishment)">
      <td>{{establishment.name}}</td>
      <td>{{establishment.openingDate.format('DD/MM/YYYY')}}</td>
      <td>{{establishment.closingDate.format('DD/MM/YYYY')}}</td>
      <td class="flex items-center cursor-pointer h-[48px]">
        <svg class="max-h-6 w-6 stroke-primary" (click)="openEditDrawer(establishment)">
          <use [attr.xlink:href]="'assets/icons/icons.svg#pencil'"></use>
        </svg>
      </td>
    </tr>
  </ng-container>
</dash-list-edit>

<atc-side-drawer *ngIf="openDrawer"
                 [close]="false"
                 [loading]="loading"
                 [cssClass]="['backoffice']"
                 (closeChange)="openDrawer = false">
  <div header class="w-full my-4">
    <h1 class="text-center">{{editMode === 'edit' ? 'Modification établissement' : 'Nouvel établissement'}}</h1>
  </div>

  <div body class="vsk__body">
    <div class="vsk__inline">
      <!-- Establishment's name -->
      <atc-input [control]="nameForm"
                 errorMessage="Champs obligatoire"
                 placeholder="Hôtel des 2 Alpes"
                 label="Nom de l'établissement*"></atc-input>

      <!-- Establishment's email -->
      <atc-input [control]="emailForm"
                 errorMessage="Champs obligatoire"
                 placeholder="labellemaison@montagne.fr"
                 label="Adresse email*"></atc-input>
    </div>

    <div class="vsk__inline">
      <!-- Establishment's address -->
      <atc-input [control]="addressForm"
                 errorMessage="Champs obligatoire"
                 placeholder="13 montée de la coline"
                 label="Adresse postale*"></atc-input>

      <!-- Establishment's city -->
      <atc-input [control]="cityForm"
                 errorMessage="Champs obligatoire"
                 placeholder="Chambery"
                 label="Ville*"></atc-input>
    </div>

    <div class="vsk__inline">
      <!-- Establishment's zip -->
      <atc-input [control]="zipForm"
                 errorMessage="Champs obligatoire"
                 placeholder="69001"
                 label="Code postal*"></atc-input>

      <!-- Establishment's country -->
      <atc-input [control]="countryForm"
                 errorMessage="Champs obligatoire"
                 placeholder="France"
                 label="Pays*"></atc-input>
    </div>


    <div class="vsk__inline">
      <!-- Establishment's phone1 -->
      <atc-input [control]="phone1Form"
                 [specialCharacters]="['.']"
                 [dropSpecialCharacters]="false"
                 errorMessage="Champs obligatoire"
                 placeholder="06.01.02.03.04"
                 mask="00.00.00.00.00"
                 label="Téléphone 1*"></atc-input>

      <!-- Establishment's phone2 -->
      <atc-input [control]="phone2Form"
                 [specialCharacters]="['.']"
                 [dropSpecialCharacters]="false"
                 placeholder="06.01.02.03.04"
                 mask="00.00.00.00.00"
                 label="Téléphone 2"></atc-input>
    </div>

    <div class="vsk__inline">
      <!-- Establishment's opening date -->
      <atc-input-date [control]="openingDateForm"
                      label="Date d'ouverture*"></atc-input-date>

      <!-- Establishment's closing date -->
      <atc-input-date [control]="closingDateForm"
                      label="Date de fermeture*"></atc-input-date>
    </div>


    <div class="vsk__inline">
      <!-- Establishment's opening time -->
      <atc-input-time [control]="openingTimeForm"
                      label="Heure d'ouverture*"></atc-input-time>

      <!-- Establishment's closing time -->
      <atc-input-time [control]="closingTimeForm"
                      label="Heure de fermeture*"></atc-input-time>
    </div>

    <atc-select [control]="resortForm"
                [optionElements]="resortElements"
                [searchable]="true"
                label="Station">
    </atc-select>

    <atc-select [control]="typeForm"
                [optionElements]="accommodationTypesElements"
                label="Type">
    </atc-select>

    <hr>

    <div class="flex flex-col gap-y-4 px-2">
      <label>Équipements</label>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of equipmentsFilter"
                            [control]="getControl(element.control)">
          {{element.label}}
        </atc-input-checkbox>
      </div>
    </div>

    <hr>

    <div class="flex flex-col gap-y-4 px-2">
      <label>Service</label>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of servicesFilter"
                            [control]="getControl(element.control)">
          {{element.label}}
        </atc-input-checkbox>
      </div>
    </div>

    <hr>

    <div class="flex flex-col gap-y-4 px-2">
      <label>Accessibilité</label>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of accessFilter"
                            [control]="getControl(element.control)">
          {{element.label}}
        </atc-input-checkbox>
      </div>
    </div>

    <hr>

    <!-- Establishment's Images -->
    <atc-input-file label="Photos de l'établissement Hiver"
                    [files]="photosFilesWinter"
                    [images]="photosImagesWinter"
                    (imagesChanged)="listUpdated($event, SeasonEnum.WINTER)"
                    (filesChanged)="addPhotoFile($event, SeasonEnum.WINTER)">
    </atc-input-file>

    <!-- Establishment's Images -->
    <atc-input-file label="Photos de l'établissement Eté"
                    [files]="photosFilesSummer"
                    [images]="photosImagesSummer"
                    (imagesChanged)="listUpdated($event, SeasonEnum.SUMMER)"
                    (filesChanged)="addPhotoFile($event, SeasonEnum.SUMMER)">
    </atc-input-file>

    <!-- Establishment's description summer -->
    <div class="vsk__column">
      <label>Description été</label>
      <atc-text-editor [(text)]="descriptionSummerForm"
                       label="Description été"></atc-text-editor>
    </div>

    <!-- Establishment's description winter -->
    <div class="vsk__column">
      <label>Description hiver</label>
      <atc-text-editor [(text)]="descriptionWinterForm"
                       label="Description hiver"></atc-text-editor>
    </div>

    <!-- Establishment's access winter -->
    <div class="vsk__column">
      <label>Accès été</label>
      <atc-text-editor [(text)]="accessSummerForm"
                       label="Accès été"></atc-text-editor>
    </div>

    <!-- Establishment's access winter -->
    <div class="vsk__column">
      <label>Accès hiver</label>
      <atc-text-editor [(text)]="accessWinterForm"
                       label="Accès hiver"></atc-text-editor>
    </div>

    <!-- Establishment's facilities summer -->
    <div class="vsk__column">
      <label>Facilités été</label>
      <atc-text-editor [(text)]="facilitiesSummerForm"
                       label="Facilités été"></atc-text-editor>
    </div>

    <!-- Establishment's facilities winter -->
    <div class="vsk__column">
      <label>Facilités hiver</label>
      <atc-text-editor [(text)]="facilitiesWinterForm"
                       label="Facilités hiver"></atc-text-editor>
    </div>

    <!-- Establishment's information summer -->
    <div class="vsk__column">
      <label>Informations pratiques été</label>
      <atc-text-editor [(text)]="informationSummerForm"
                       label="Informations pratiques été"></atc-text-editor>
    </div>

    <!-- Establishment's information winter -->
    <div class="vsk__column">
      <label>Informations pratiques hiver</label>
      <atc-text-editor [(text)]="informationWinterForm"
                       label="Informations pratiques hiver"></atc-text-editor>
    </div>

  </div>

  <div footer class="vsk__footer">
    <atc-button class="vsk__flex"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                [disabled]="establishmentForm.invalid"

                (click)="upsert()">
    </atc-button>
  </div>
</atc-side-drawer>
