<atc-popup (closeChanged)="closeChanged.emit()"
           [withImg]="false"
           height="600px"
           width="900px">
  <div body class="flex flex-col gap-y-4">
    <h1 class="mt-4 text-center">Demande de refus de créneau</h1>

    <atc-select [control]="reasonDenyForm"
                [optionElements]="reasonDenyOptions"
                id="id__select-edit-activity-category"
                label="Catégorie*">
    </atc-select>

    <atc-textarea
      *ngIf="reasonDenyForm.value === 3"
      [control]="commentForm"
      label="Motif de la demande"
      placeholder="La raison en quelques lignes  …"></atc-textarea>

    <div class="vsk__inline">
      <div class="vsk__flex"></div>
      <atc-button (click)="sendMessage()"
                  [disabled]="reasonDenyForm.value === 3 && !commentForm.valid"

                  id="id__button-refuse-reservation-popup-add"
                  label="Demander le changement du créneau">
      </atc-button>
    </div>
  </div>
</atc-popup>

