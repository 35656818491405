import { Component, Input } from '@angular/core';
import { ExperienceGroupService } from '../../../service/experience-group.service';
import { PageComponent } from '../../../shared/component/page.component';
import { Experience } from '../../../shared/models/experience';
import { ExperienceGroup } from '../../../shared/models/experience-group';

@Component({
  selector: 'dash-experience-group',
  templateUrl: './experience-group.component.html',
  styleUrls: ['./experience-group.component.scss']
})
export class ExperienceGroupComponent extends PageComponent<ExperienceGroup> {
  @Input() experiences: Experience[] = [];

  constructor(protected experienceGroupExperience: ExperienceGroupService) {
    super(experienceGroupExperience);
  }
}
