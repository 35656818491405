import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { Internship } from '../shared/models/time-slot/internship';
import { TimeSlotModel } from '../shared/models/time-slot/time-slot-model';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class InternshipService extends RepositoryService {
  private URL_INTERNSHIP = 'api/backoffice/internship';

  upsert(
    partnerId: number,
    internship: Internship,
    timeSlots: TimeSlotModel[]
  ): Observable<number> {
    return this.post(this.URL_INTERNSHIP + '/upsert?partnerId=' + partnerId, {
      internship,
      timeSlots
    });
  }

  getBetweenDates(
    partnerId: number,
    dateStart: Moment,
    dateEnd: Moment,
    filteredExperiences: number[]
  ): Observable<Internship[]> {
    return this.get(
      this.URL_INTERNSHIP +
        '/get-internship-between-dates?partnerId=' +
        partnerId +
        '&start=' +
        dateStart.format('YYYY-MM-DD') +
        '&end=' +
        dateEnd.format('YYYY-MM-DD') +
        '&filteredExperiences=' +
        filteredExperiences
    ).pipe(
      map((internships) =>
        internships.map(
          (internship: Internship) =>
            new Internship({
              ...internship,
              id: internship.id || uuidv4()
            })
        )
      )
    );
  }

  deleteById(idInternship: number, partnerId: number): Observable<void> {
    return this.delete(
      this.URL_INTERNSHIP +
        '/delete?internshipId=' +
        idInternship +
        '&partnerId=' +
        partnerId
    );
  }
}
