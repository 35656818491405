import { Component } from '@angular/core';
import { Role } from '../../shared/models/enum/Role';
import { Link } from '../../shared/sub-menu/sub-menu.component';

@Component({
  selector: 'dash-marketing-menu',
  templateUrl: './marketing-menu.component.html',
  styleUrls: ['./marketing-menu.component.scss']
})
export class MarketingMenuComponent {
  roleMarketing = [Role.ROLE_ADMIN, Role.ROLE_MARKETING];

  links: Link[] = [
    {
      roles: this.roleMarketing,
      page: 'Suivi des ventes',
      route: '/marketing/sales-report',
      icon: 'cart',
      id: 'id__link-skiset-orders'
    },
    {
      roles: this.roleMarketing,
      page: 'Avant vente',
      route: '/marketing/before-sell',
      icon: 'credit-card',
      id: 'id__link-marketing'
    },
    {
      roles: this.roleMarketing,
      page: 'Bannière',
      route: '/marketing/information-banner',
      icon: 'information-circle',
      id: 'id__link-information-banner'
    },
    {
      roles: this.roleMarketing,
      page: 'Code promo',
      route: '/marketing/promo-code',
      icon: 'gift',
      id: 'id__link-marketing-promo-code'
    },
    {
      roles: this.roleMarketing,
      page: 'Réservations refusées',
      route: '/marketing/refused-reservations',
      icon: 'close',
      id: 'id__link-admin-refused-reservations'
    },
    {
      roles: this.roleMarketing,
      page: 'Commandes Skiset',
      route: '/marketing/skiset-orders',
      icon: 'skis',
      id: 'id__link-skiset-orders'
    }
  ];
}
