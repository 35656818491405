<h2>{{ title }}</h2>

<ng-container *ngFor="let link of links">
  <ng-container *ngIf="isEnableForEnv(link)">
    <a *ngIf="containsRoles(link.roles)" [id]="link?.id" [ngClass]="{'vsk__is-active': isActive(link.route)}"
       [routerLink]="link.route" class="vsk__button-link" routerLinkActive="true">
      <svg class="max-h-6 w-6 stroke-white">
        <use [attr.xlink:href]="'assets/icons/icons.svg#' + (link?.icon || 'calendar')"></use>
      </svg>
      <label>{{ link.page }}</label>
    </a>
  </ng-container>
</ng-container>

