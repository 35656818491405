import { Component, Input } from '@angular/core';
import { PackageGridService } from '../../../service/package-grid.service';
import { PageComponent } from '../../../shared/component/page.component';
import { Experience } from '../../../shared/models/experience';
import { PackageGrid } from '../../../shared/models/package-grid';

@Component({
  selector: 'dash-package-grid',
  templateUrl: './package-grid.component.html',
  styleUrls: ['./package-grid.component.scss']
})
export class PackageGridComponent extends PageComponent<PackageGrid> {
  @Input() experiences: Experience[] = [];

  constructor(protected packageGridService: PackageGridService) {
    super(packageGridService);
  }
}
