<atc-side-drawer (closeChange)="visualisationComplete.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 [title]="'Les informations'">
  <div class="w-full my-4" header>
    <h1 class="text-center">Information sur la réservation</h1>
  </div>
  <div body class="flex flex-col gap-y-4">
    <div class="flex flex-col gap-y-4">
      <hr>
      <h2 class="vsk__bold">Réservataire :</h2>
      <ul>
        <li><label>Prénom: </label> <span>{{ reservation?.user?.firstName }}</span></li>
        <li><label>Nom: </label> <span>{{ reservation?.user?.lastName }}</span></li>
        <li><label>Email: </label> {{ reservation?.user?.email }}</li>
        <li><label>Téléphone: </label> {{ reservation?.user?.phone }}</li>
      </ul>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Les participants</h2>
      <ul>
        <li *ngFor="let participant of reservation?.participants">{{ formatParticipant(participant) }}</li>
      </ul>
    </div>

    <div class="flex flex-col gap-y-4">
      <hr>
      <h2>Détail de l'activité</h2>
      <ul>
        <li><label>Activité: </label> {{ reservation?.experienceName }}</li>
        <li>
          <label>Choix du créneau: </label>
          {{ formatDate(reservation?.dateStart, reservation?.internshipId ? reservation?.dateEnd : undefined) }}
        </li>
        <li>
          <label>Date de la réservation: </label>
          {{ formatDate(reservation?.dateRegistration) }}
        </li>
        <ng-container *ngIf="containsRoles([Role.ROLE_ADMIN])">
          <li><label>Motif du refus: </label> {{ reservation?.denyMessage }}</li>
          <li><label>Date du refus: </label> {{ formatDate(reservation?.denyTime) }}</li>
        </ng-container>
      </ul>
    </div>

  </div>
</atc-side-drawer>
