import moment, { Moment } from 'moment';

export class CatalogPriceReduction {
  id: number;
  catalog: number;
  startDate: Moment;
  endDate: Moment;
  reduction: number;

  constructor(obj: Partial<CatalogPriceReduction>) {
    Object.assign(this, obj);

    if (this.startDate) {
      this.startDate = moment(this.startDate);
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate);
    }
  }
}
