import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { TriggerAlert } from '../../../../app.action';
import { AppState } from '../../../../app.state';
import { MapsResource } from '../../../../resource/maps.resource';
import { ActivityService } from '../../../../service/activity.service';
import { CustomFieldService } from '../../../../service/custom-field.service';
import { ImagesService } from '../../../../service/images.service';
import { TagService } from '../../../../service/tag.service';
import { RxjsComponent } from '../../../../shared/component/rxjs.component';
import { Picture } from '../../../../shared/models/accomodation/picture';
import { ActivityPartner } from '../../../../shared/models/activity-partner';
import { Alert } from '../../../../shared/models/alert';
import { CustomField } from '../../../../shared/models/custom-field';
import { CustomFields } from '../../../../shared/models/custom-fields-ids';
import { Role } from '../../../../shared/models/enum/Role';
import { SeasonEnum } from '../../../../shared/models/enum/season.enum';
import { Experience } from '../../../../shared/models/experience';
import { Tag } from '../../../../shared/models/tag';
import PlaceResult = google.maps.places.PlaceResult;

export interface OptionEdit {
  activity: ActivityPartner;
  option: Experience;
}

export interface CreateExperienceForm {
  id: FormControl<number | null>;
  name: FormControl<string | null>;
  difficulty: FormControl<string | null>;
  duration: FormControl<number | null>;
  color: FormControl<string | null>;
  phone: FormControl<string | null>;
  address: FormControl<string | null>;
  latitude: FormControl<number | null>;
  longitude: FormControl<number | null>;
  description: FormControl<string | null>;
  message: FormControl<string | null>;
  weLove: FormControl<string | null>;
  videoUrl: FormControl<string | null>;
  included: FormControl<string | null>;
  notIncluded: FormControl<string | null>;
  predictable: FormControl<string | null>;
  withAccommodation: FormControl<boolean | null>;
  heart: FormControl<boolean | null>;
  experienceCategory: FormControl<string | null>;
  minDaysBeforeBooking: FormControl<number | null>;
}

export interface ImageUrl {
  name: string;
  url: string;
}

@Component({
  selector: 'dash-edit-experience',
  templateUrl: './edit-experience.component.html',
  styleUrls: ['./edit-experience.component.scss']
})
export class EditExperienceComponent extends RxjsComponent {
  readonly DEFAULT_COLOR = '#1A4E71';
  @Select(AppState.partnerId) partnerId$: Observable<number>;
  @Select(AppState.roles) roles$: Observable<string[]>;
  @Select(AppState.isAdmin) isAdmin$: Observable<boolean>;
  @Select(AppState.isEliberty) isEliberty$: Observable<boolean>;
  roles: string[] = [];
  isAdmin = this.store.selectSnapshot(AppState.isAdmin);
  difficulties: OptionElement<string>[] = [
    {
      id: null,
      label: 'Choisir une difficulté',
      disabled: true,
      classCss: 'disabled'
    },
    {
      id: 'FOR_ALL',
      label: 'Pour tous'
    },
    {
      id: 'SPORT',
      label: 'Sportif'
    },
    {
      id: 'EXPERIMENTED',
      label: 'Expérimenté'
    }
  ];
  categories: OptionElement<string>[] = [
    {
      id: null,
      label: 'Choisir une catégorie',
      disabled: true,
      classCss: 'disabled'
    },
    {
      id: 'GENERAL',
      label: 'Général'
    },
    {
      id: 'BABYSITTING',
      label: 'Baby-sitting'
    },
    {
      id: 'SKIPASS',
      label: 'Forfait'
    },
    {
      id: 'SKILESSON',
      label: 'Cours de ski'
    },
    {
      id: 'EQUIPMENT',
      label: 'Matériel'
    }
  ];
  activities: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une activité',
      disabled: true,
      classCss: 'disabled'
    }
  ];
  customFieldSelectItem: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir un champ personnalisé',
      control: new FormControl<boolean | null>({
        value: false,
        disabled: true
      }),
      disabled: true,
      classCss: 'disabled'
    }
  ];
  tagSelectItem: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir un tag',
      control: new FormControl<boolean | null>({
        value: false,
        disabled: true
      }),
      disabled: true,
      classCss: 'disabled'
    }
  ];
  optionEdit: OptionEdit;
  loading = false;
  customFieldsIdsToCompareFromBackEnd: number[] = [];
  tagsToCompareFromBackEnd: number[] = [];
  // Iamges
  photosImagesSummer: HTMLImageElement[] = [];
  photosImagesWinter: HTMLImageElement[] = [];
  photosFilesSummer: File[] = [];
  photosFilesWinter: File[] = [];
  // Form
  idForm = new FormControl<number | null>(null);
  activityForm = new FormControl<number | null>(null, [
    Validators.required,
    Validators.min(1)
  ]);
  nameForm = new FormControl<string>('', Validators.required);
  difficultyForm = new FormControl<string | null>(
    'FOR_ALL',
    Validators.required
  );
  durationForm = new FormControl<number>(0, [
    Validators.required,
    Validators.min(1)
  ]);
  colorForm = new FormControl<string>(this.DEFAULT_COLOR, Validators.required);
  descriptionForm = new FormControl<string>(
    '' +
      '<b>On adore :</b><br>' +
      'Découvrez le <i>xxx</i> à <i>xxx</i> et survolez l’un des plus beaux panoramas au monde face au Mont-Blanc…<br>' +
      '<b>On vous raconte :</b><br>' +
      'Vous retrouverez <i>xxx</i> 1h avant pour découvrir l’activité et vous équiper. Vous vous dirigerez ensuite en camion jusqu’au lieu de décollage…<br>',
    Validators.minLength(50)
  );
  phoneForm = new FormControl<string | null>('', Validators.required);
  addressForm = new FormControl<string | null>('', Validators.required);
  latitudeForm = new FormControl<number | null>(null, Validators.required);
  longitudeForm = new FormControl<number | null>(null, Validators.required);
  minDaysBeforeBookingForm = new FormControl<number | null>(
    null,
    Validators.required
  );
  weLoveForm = new FormControl<string | null>('');
  includedForm = new FormControl<string | null>('');
  notIncludedForm = new FormControl<string | null>('');
  predictableForm = new FormControl<string | null>('');
  YOUTUBE_URL_PATTERN =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|embed|watch)(?:\?v=|\/|\\?.+&v=))|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[?&][^\s]*)?$/;
  videoForm = new FormControl<string | null>(
    '',
    Validators.pattern(this.YOUTUBE_URL_PATTERN)
  );
  messageForm = new FormControl<string | null>(
    '' +
      '<b>Bon à savoir :</b><br>' +
      '<ol>' +
      '<li> RDV au plan de l’aiguille pour vous équiper.</li>' +
      '<li> N’oubliez pas votre gourde.</li>' +
      '<li> Le forfait n’est pas inclus dans le prix</li>' +
      '</ol>'
  );
  withAccommodationForm = new FormControl<boolean | null>(
    false,
    Validators.required
  );
  heartForm = new FormControl<boolean | null>(false, Validators.required);
  experienceCategoryForm = new FormControl<string | null>(
    'GENERAL',
    Validators.required
  );
  createExperienceForm: FormGroup<CreateExperienceForm>;
  @Input() editMode: 'create' | 'edit' = 'create';
  @Output() optionChanged: EventEmitter<void> = new EventEmitter<void>();
  protected readonly SeasonEnum = SeasonEnum;
  protected readonly Role = Role;
  private _activityPartner: ActivityPartner[] = [];

  constructor(
    private activityService: ActivityService,
    private imagesService: ImagesService,
    private store: Store,
    private mapsResource: MapsResource,
    private customFieldService: CustomFieldService,
    private tagsService: TagService
  ) {
    super();
    this.initForm();
    this.roles$.subscribe((roles) => (this.roles = roles));
  }

  @Input() set activitiesPartner(activityPartners: ActivityPartner[]) {
    this._activityPartner = activityPartners;
    this.activities = [
      ...this.activities,
      ...activityPartners.map((activityPartner) => {
        return {
          id: activityPartner.id,
          label: this.isAdmin
            ? `${activityPartner.id} - ${activityPartner.activity.name} - ${activityPartner.resorts.map((resort) => resort.name).join(', ')}`
            : `${activityPartner.activity.name} - ${activityPartner.resorts.map((resort) => resort.name).join(', ')}`
        };
      })
    ];
  }

  @Input() set customFieldsInput(customFields: CustomField[]) {
    if (!customFields?.length) {
      return;
    }

    this.customFieldSelectItem = [
      ...this.customFieldSelectItem,
      ...customFields.map((customField) => {
        return {
          id: customField.id,
          label: customField.label as string,
          control: new FormControl<boolean | null>(false)
        };
      })
    ];
  }

  @Input() set tags(tags: Tag[]) {
    if (!tags?.length) {
      return;
    }

    this.tagSelectItem = [
      ...this.tagSelectItem,
      ...tags.map((tag) => {
        return {
          id: tag.id,
          label: tag.label,
          control: new FormControl<boolean | null>(false)
        };
      })
    ];
  }

  @Input() set experience(value: OptionEdit | undefined) {
    if (value) {
      const fieldsSelected = value.option.customFields.map((field) => field.id);
      this.customFieldSelectItem = [
        ...this.customFieldSelectItem.map((element) => {
          return {
            ...element,
            control: new FormControl<boolean | null>(
              fieldsSelected.indexOf(element.id as number) !== -1
            )
          } as OptionElement<number>;
        })
      ];
      const tagsSelected = value.option.tags.map((tag) => tag.id);
      this.tagSelectItem = [
        ...this.tagSelectItem.map((element) => {
          return {
            ...element,
            control: new FormControl<boolean | null>(
              tagsSelected.indexOf(element.id as number) !== -1
            )
          };
        })
      ];
      this.customFieldsIdsToCompareFromBackEnd = [
        ...value.option.customFields.map((customField) => customField.id)
      ];
      this.tagsToCompareFromBackEnd = [
        ...value.option.tags.map((tag) => tag.id)
      ];
      this.idForm.setValue(value.option.id);
      this.activityForm = new FormControl<number | null>(value.activity.id);
      this.nameForm.setValue(value.option.name);
      this.difficultyForm = new FormControl<string | null>(
        value.option.difficulty
      );
      this.durationForm.setValue(value.option.duration);
      this.colorForm.setValue(value.option.color);
      this.phoneForm.setValue(value.option.phone);
      this.addressForm.setValue(value.option.address);
      this.latitudeForm.setValue(value.option.latitude);
      this.longitudeForm.setValue(value.option.longitude);
      this.messageForm.setValue(value.option.message);
      this.descriptionForm.setValue(value.option.description);
      this.videoForm.setValue(value.option.videoUrl);
      this.weLoveForm.setValue(value.option.weLove);
      this.includedForm.setValue(value.option.included);
      this.notIncludedForm.setValue(value.option.notIncluded);
      this.predictableForm.setValue(value.option.predictable);
      this.withAccommodationForm.setValue(value.option.withAccommodation);
      this.heartForm.setValue(value.option.heart);
      this.experienceCategoryForm.setValue(value.option.experienceCategory);
      this.minDaysBeforeBookingForm.setValue(value.option.minDaysBeforeBooking);
      this.optionEdit = value;

      if (value.option.id) {
        this.editMode = 'edit';
        this.loadPhotos(value);
      } else if (value.option.pictureEntities.length) {
        this.loadPhotos(value);
      }

      this.initForm();
    }
  }

  photosValid(): boolean {
    const validPhotoCount = (photos: File[], images: HTMLImageElement[]) =>
      (photos.length > 0 && photos.length < 16) || images.length > 0;

    return (
      validPhotoCount(this.photosFilesWinter, this.photosImagesWinter) ||
      validPhotoCount(this.photosFilesSummer, this.photosImagesSummer)
    );
  }

  addPhotoFile(files: File[], season: SeasonEnum) {
    if (season === SeasonEnum.WINTER) {
      this.photosFilesWinter.push(...files);
    } else {
      this.photosFilesSummer.push(...files);
    }
  }

  formIsValid(): boolean {
    return (
      this.createExperienceForm.valid &&
      this.activityForm.valid &&
      this.photosValid() &&
      this.addressForm.valid &&
      this.latitudeForm.valid &&
      this.longitudeForm.valid &&
      this.latitudeForm.value !== null &&
      this.longitudeForm.value !== null
    );
  }

  uploadPictures(
    experienceId: number,
    photos: File[],
    photoImages: HTMLImageElement[],
    season: SeasonEnum
  ) {
    if (!photos.length) {
      return this.activityService.upsertExperiencePictures(
        experienceId,
        photoImages.map(
          (image) => new Picture({ url: image.srcset, season: season })
        )
      );
    }
    return forkJoin(
      photos.map((file) =>
        this.imagesService
          .uploadImage(
            file,
            `activity-partner/${this.activityForm.value}/${experienceId}/`,
            `image-exp-${uuidv4()}`
          )
          .pipe(
            map((url) => {
              return { name: file.name, url } as ImageUrl;
            })
          )
      )
    ).pipe(
      switchMap((pictures) => {
        const allPictures: Picture[] = photoImages.map((image) => {
          const found = pictures.find((picture) => picture.name === image.alt);
          return found
            ? new Picture({ url: found.url, season: season })
            : new Picture({ url: image.srcset, season: season });
        });
        return this.activityService.upsertExperiencePictures(
          experienceId,
          allPictures
        );
      })
    );
  }

  upsert(): void {
    this.descriptionForm = new FormControl<string | null>(
      this.descriptionForm.value,
      Validators.minLength(50)
    );
    this.initForm();

    if (this.formIsValid()) {
      this.loading = true;

      const customFieldsToAdd: number[] = this.customFieldSelectItem
        .filter((element) => !!element.control?.value)
        .map((element) => element.id as number);

      const customFieldsToDelete: number[] = this.customFieldSelectItem
        .filter(
          (element) =>
            this.customFieldsIdsToCompareFromBackEnd.indexOf(
              element.id as number
            ) !== -1 && !element.control?.value
        )
        .map((element) => element.id as number);

      if (this.editMode === 'edit' && customFieldsToDelete.length) {
        this.register(
          this.customFieldService
            .deleteFieldsExperience(
              this.idForm.value as number,
              new CustomFields({ customFieldsIds: customFieldsToDelete })
            )
            .subscribe()
        );
      }

      const tagsToAdd: Tag[] = this.tagSelectItem
        .filter((element) => !!element.control?.value)
        .map((element) => {
          return new Tag({
            id: element.id as number,
            label: element.label
          });
        });

      const tagsToDelete: Tag[] = this.tagSelectItem
        .filter(
          (element) =>
            this.tagsToCompareFromBackEnd.indexOf(element.id as number) !==
              -1 && !element.control?.value
        )
        .map((element) => {
          return new Tag({
            id: element.id as number,
            label: element.label
          });
        });

      if (this.editMode === 'edit' && tagsToDelete.length) {
        this.register(
          this.tagsService
            .deleteTagsByExperienceId(this.idForm.value as number, tagsToDelete)
            .subscribe()
        );
      }

      const activityId = this.activityForm.value as number;
      const partnerId = this.store.selectSnapshot(AppState.partnerId) as number;

      this.register(
        this.activityService
          .upsertExperience(
            activityId,
            partnerId,
            new Experience({
              ...this.createExperienceForm.getRawValue(),
              customFieldsIds: customFieldsToAdd,
              tags: tagsToAdd
            } as Experience)
          )
          .pipe(
            // Upload pictures
            switchMap((experienceId) => {
              return combineLatest([
                this.uploadPictures(
                  experienceId,
                  this.photosFilesWinter,
                  this.photosImagesWinter,
                  SeasonEnum.WINTER
                ),
                this.uploadPictures(
                  experienceId,
                  this.photosFilesSummer,
                  this.photosImagesSummer,
                  SeasonEnum.SUMMER
                )
              ]);
            })
          )
          .subscribe(
            () => {
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    level: 'success',
                    timeout: 2000,
                    message: 'Expérience créée / modifiée'
                  })
                )
              );
              this.optionChanged.emit();
              this.loading = false;
            },
            () => {
              this.loading = false;
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    level: 'error',
                    timeout: 5000,
                    message:
                      'Une erreur est survenue, veuillez réessayer plus tard ...'
                  })
                )
              );
            }
          )
      );
    }
  }

  geocodeAddress(address: PlaceResult | null): void {
    this.register(
      this.addressForm.valueChanges.subscribe(() => {
        this.latitudeForm.setValue(null);
        this.longitudeForm.setValue(null);
      })
    );

    if (address?.place_id) {
      this.addressForm.setValue(address.formatted_address as string);
      this.latitudeForm.setValue(address.geometry?.location?.lat() as number);
      this.longitudeForm.setValue(address.geometry?.location?.lng() as number);
    } else {
      this.addressForm.setValue(null);
      this.latitudeForm.setValue(null);
      this.longitudeForm.setValue(null);
    }
  }

  loadPhotos(experience: OptionEdit): void {
    if (experience.option.pictureEntities) {
      experience.option.pictureEntities.forEach((picture, index) => {
        const image = new Image();
        image.srcset = picture.url;
        image.alt = `Photo ${index} de l'expérience`;
        switch (picture.season) {
          case SeasonEnum.WINTER:
            this.photosImagesWinter.push(image);
            break;
          case SeasonEnum.SUMMER:
            this.photosImagesSummer.push(image);
            break;
          case SeasonEnum.YEAR:
            this.photosImagesWinter.push(image);
            this.photosImagesSummer.push(image);
            break;
        }
      });
    }
  }

  delete(): void {
    const partnerId = this.store.selectSnapshot(AppState.partnerId) as number;
    this.activityService
      .deleteOptionPartner(this.optionEdit.option.id as number, partnerId)
      .subscribe(() => this.optionChanged.emit());
  }

  containsRoles(rolesToHave: string[]): boolean {
    return (
      rolesToHave.filter((role) => this.roles?.indexOf(role) !== -1).length > 0
    );
  }

  getPrefix(): string {
    const activity = this._activityPartner.find(
      (activityPartner) => activityPartner.id === this.activityForm.value
    );
    return activity ? `${activity.activity.name} • ` : '';
  }

  getLoader() {
    return this.mapsResource.mapsLoader();
  }

  listUpdated(imgs: HTMLImageElement[], season: SeasonEnum) {
    switch (season) {
      case SeasonEnum.WINTER:
        this.photosImagesWinter = imgs;
        break;
      case SeasonEnum.SUMMER:
        this.photosImagesSummer = imgs;
        break;
    }
  }

  private initForm() {
    this.createExperienceForm = new FormGroup<CreateExperienceForm>({
      id: this.idForm,
      name: this.nameForm,
      difficulty: this.difficultyForm,
      duration: this.durationForm,
      color: this.colorForm,
      phone: this.phoneForm,
      address: this.addressForm,
      latitude: this.latitudeForm,
      longitude: this.longitudeForm,

      message: this.messageForm,
      description: this.descriptionForm,

      weLove: this.weLoveForm,
      included: this.includedForm,
      notIncluded: this.notIncludedForm,
      predictable: this.predictableForm,
      videoUrl: this.videoForm,

      withAccommodation: this.withAccommodationForm,
      heart: this.heartForm,
      experienceCategory: this.experienceCategoryForm,
      minDaysBeforeBooking: this.minDaysBeforeBookingForm
    });
  }
}
