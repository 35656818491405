<atc-side-drawer [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading"
                 (closeChange)="editionComplete.emit()">
  <div header class="w-full my-4">
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification groupement expériences' : 'Nouveau groupement expériences' }}</h1>
  </div>

  <div body class="vsk__body">
    <atc-input [control]="nameForm"
               placeholder="Canyoning"
               label="Nom du groupement"></atc-input>

    <div class="flex flex-row gap-x-4">
      <!-- Experience -->
      <atc-select [control]="experienceForm"
                  [optionElements]="experiencesOptions"
                  class="flex-1"
                  id="id__select-edit-activity-option-activity"
                  label="Choisir une expérience">
      </atc-select>

      <atc-button label="Ajouter"
                  size="small"
                  (click)="addExperience()"></atc-button>
    </div>

    <atc-drag-drop-list [elements]="elements"
                        (elementsChanged)="updateOptions($event)">
    </atc-drag-drop-list>
  </div>

  <div footer class="flex flex-row justify-between w-full items-center pt-4">
    <atc-button *ngIf="editMode === 'edit'" color="danger" label="Supprimer" (click)="deleteById()"></atc-button>

    <atc-button id="id__button-validate-form"
                [disabled]="!isFormValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                (click)="create()">
    </atc-button>
  </div>
</atc-side-drawer>
