import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import moment from 'moment/moment';
import { InformationBannerService } from '../../../service/information-banner.service';
import { EditComponent } from '../../../shared/component/edit.component';
import { InformationBanner } from '../../../shared/models/information-banner';

export interface InformationBannerForm {
  name: FormControl<string | null>;
  content: FormControl<string | null>;
  startDate: FormControl<string | null>;
  endDate: FormControl<string | null>;
}

@Component({
  selector: 'dash-edit-information-banner',
  templateUrl: './edit-information-banner.component.html',
  styleUrls: ['./edit-information-banner.component.scss']
})
export class EditInformationBannerComponent extends EditComponent<InformationBanner> {
  name = 'Bannière';

  nameForm = new FormControl<string>('', Validators.required);
  contentForm = new FormControl<string>('', Validators.required);
  startDateForm = new FormControl<string | null>(
    moment().format('DD/MM/YYYY'),
    Validators.required
  );
  endDateForm = new FormControl<string | null>(
    moment().add(6, 'month').format('DD/MM/YYYY'),
    Validators.required
  );
  fontColor = '#FFFFFF';
  backgroundColor = '#69608A';
  createBannerForm: FormGroup<InformationBannerForm>;

  @Input() set entityToEdit(informationBanner: InformationBanner | undefined) {
    if (informationBanner) {
      this.id = informationBanner.id;
      this.nameForm.setValue(informationBanner.name);
      this.contentForm.setValue(informationBanner.content);
      this.startDateForm.setValue(
        informationBanner.startDate.format('DD/MM/YYYY')
      );
      this.endDateForm.setValue(informationBanner.endDate.format('DD/MM/YYYY'));
      this.fontColor = informationBanner.fontColor;
      this.backgroundColor = informationBanner.backgroundColor;
      this.editMode = 'edit';
    }
  }

  constructor(
    protected service: InformationBannerService,
    protected store: Store
  ) {
    super(service, store);
  }

  public initForm(): void {
    this.createBannerForm = new FormGroup<InformationBannerForm>({
      name: this.nameForm,
      content: this.contentForm,
      startDate: this.startDateForm,
      endDate: this.endDateForm
    });
  }

  public isFormValid() {
    return (
      this.createBannerForm.valid && !!this.backgroundColor && !!this.fontColor
    );
  }

  public buildEntity(): InformationBanner {
    return new InformationBanner({
      ...(this.createBannerForm.getRawValue() as any),
      id: this.id,
      startDate: moment(this.startDateForm.value, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      endDate: moment(this.endDateForm.value, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      backgroundColor: this.backgroundColor,
      fontColor: this.fontColor
    });
  }
}
