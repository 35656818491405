import { Component } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppState } from './app.state';
import { HealthService } from './service/health.service';
import { Alert } from './shared/models/alert';
import { Role } from './shared/models/enum/Role';
import { Partner } from './shared/models/partner';

@Component({
  selector: 'dash-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @Select(AppState.isUserLoggedIn) isUserLoggedIn$: Observable<boolean>;
  @Select(AppState.alert) alert$: Observable<Alert>;
  @Select(AppState.roles) roles$: Observable<string[]>;
  @Select(AppState.partner) partner$: Observable<Partner>;

  roles: string[] = [];
  alert: Alert | null;
  showPopupSelectPartner = false;
  healthCheck$: Observable<number>;

  readonly whiteListPages = ['/forgot-password', '/login', '/onboarding'];
  protected readonly Role = Role;
  protected readonly environment = environment;

  constructor(
    private healthService: HealthService,
    private router: Router
  ) {
    this.alert$.pipe(filter((alert) => !!alert)).subscribe((alert) => {
      this.alert = alert;
      setTimeout(() => {
        this.alert = null;
      }, alert.timeout);
    });
    this.roles$.subscribe((roles) => (this.roles = roles));

    this.router.events.subscribe((val) => {
      if (val instanceof RouterEvent) {
        const isWhiteList = this.whiteListPages
          .map((page) => val.url.includes(page))
          .reduce((prev, curr) => prev || curr);

        if (isWhiteList) {
          return;
        }

        if (!this.healthCheck$) {
          this.healthCheck$ = this.healthService.isConnected();
          this.healthCheck$.subscribe();
        }
      }
    });
  }

  containsRoles(rolesToHave: string[]): boolean {
    return (
      rolesToHave.filter((role) => this.roles.indexOf(role) !== -1).length > 0
    );
  }

  changePartner(): void {
    if (
      this.containsRoles([
        Role.ROLE_ADMIN,
        Role.ROLE_COMMERCIAL,
        Role.ROLE_MARKETING
      ])
    ) {
      this.showPopupSelectPartner = true;
    }
  }
}
