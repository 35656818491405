<dash-list-edit id="id__table-list-tag"
                searchLabel="Recherche de tag"
                searchPlaceholder="Rechercher par nom ..."
                labelCreate="Ajouter un tag"
                title="Tags"
                paginationId="tags"
                [searchForm]="searchForm"
                [headers]="headers"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)">
  <ng-container body *ngFor="let tag of page.content | paginate : {
                  id: 'tags',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(tag)">
      <td>{{ tag.id }}</td>
      <td>{{ tag.label }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
