<div class="vsk__column">
  <dash-list-pre-made-cart
    (createChanged)="showEdit()"
    (isChanged)="edit($event)"
    (isDeleted)="delete()"
    (pageChanged)="fetchPageable($event, searchForm.value)"
    [page]="page"
    [searchForm]="searchForm">
  </dash-list-pre-made-cart>
  <dash-edit-pre-made-cart
    (editionComplete)="editCompleted()"
    *ngIf="showEditor"
    [entityToEdit]="toEdit"
    [resorts]="resorts$ | async">
  </dash-edit-pre-made-cart>
</div>
