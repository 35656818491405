import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { Observable } from 'rxjs';
import { TriggerAlert } from '../../../app.action';
import { AppState } from '../../../app.state';
import { ActivityService } from '../../../service/activity.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { Activity } from '../../../shared/models/activity';
import { ActivityPartner } from '../../../shared/models/activity-partner';
import { Alert } from '../../../shared/models/alert';
import { Role } from '../../../shared/models/enum/Role';
import { ResortMin } from '../../../shared/models/resort-min';
import { Resort } from '../../../shared/models/resort/resort';

@Component({
  selector: 'dash-edit-activity-partner',
  templateUrl: './edit-activity-partner.component.html',
  styleUrls: ['./edit-activity-partner.component.scss']
})
export class EditActivityPartnerComponent extends RxjsComponent {
  @Select(AppState.partnerId) partnerId$: Observable<number>;
  @Select(AppState.roles) roles$: Observable<string[]>;

  roles: string[] = [];
  loading = false;
  id: number | undefined;

  // Form
  resortElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une station',
      disabled: true,
      classCss: 'disabled'
    }
  ];
  activityElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une activité',
      disabled: true,
      classCss: 'disabled'
    }
  ];
  activityForm = new FormControl<number | null>(null, Validators.required);
  commissionRateForm = new FormControl<string>('10', Validators.required);
  VTAForm = new FormControl<string>('20', Validators.required);
  editMode: 'create' | 'edit' = 'create';
  @Output() editionComplete = new EventEmitter<void>();
  protected readonly Role = Role;

  constructor(
    private activityService: ActivityService,
    private store: Store
  ) {
    super();
    this.roles$.subscribe((roles) => (this.roles = roles));
  }

  @Input() set stations(resorts: Resort[]) {
    if (resorts?.length) {
      this.resortElements = [
        {
          id: null,
          label: 'Choisir une station',
          control: new FormControl<boolean | null>({
            value: false,
            disabled: true
          }),
          disabled: true,
          classCss: 'disabled'
        },
        ...resorts.map((resort) => {
          return {
            id: resort.id,
            label: resort.name,
            control: new FormControl<boolean | null>(false)
          } as OptionElement<number>;
        })
      ];
    }
  }

  @Input() set activities(activities: Activity[]) {
    if (activities?.length) {
      this.activityElements = [
        {
          id: null,
          label: 'Choisir une activité',
          disabled: true,
          classCss: 'disabled'
        },
        ...activities.map((activity) => {
          return {
            id: activity.id,
            label: activity.name
          } as OptionElement<number>;
        })
      ];
    }
  }

  @Input() set activityPartnerToEdit(value: ActivityPartner | undefined) {
    if (value) {
      const resortIds = value.resorts.map((resort) => resort.id);
      this.id = value.id;
      this.resortElements = this.resortElements.map((resort) => {
        resort.control?.setValue(resortIds.indexOf(resort.id) !== -1);
        return {
          ...resort
        };
      });
      this.activityForm = new FormControl<number>(value.activity.id);
      this.commissionRateForm.setValue(
        value.commissionRate.toString().replace('.', ',')
      );
      this.VTAForm.setValue(value.vat?.toString().replace('.', ','));
      this.editMode = 'edit';
    }
  }

  formIsValid(): boolean {
    return (
      this.resortElements.filter((element) => !!element.control?.value).length >
        0 && this.activityForm.valid
    );
  }

  create(): void {
    this.loading = true;
    const resortsSelected = this.resortElements.filter(
      (element) => !!element.control?.value
    );
    const partnerId = this.store.selectSnapshot(AppState.partnerId) as number;
    const resortsSelectedMapped = resortsSelected.map(
      (element) =>
        new ResortMin({
          id: element.id,
          name: element.label
        })
    );

    this.register(
      this.activityService
        .upsertActivityPartner(
          this.activityForm.value as number,
          partnerId,
          new ActivityPartner({
            id: this.id,
            commissionRate: Number(
              this.commissionRateForm.value?.replace('%', '').replace(',', '.')
            ),
            vat: Number(this.VTAForm.value?.replace('%', '').replace(',', '.')),
            resorts: resortsSelectedMapped
          })
        )
        .subscribe(
          () => {
            this.loading = false;
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  message:
                    this.editMode === 'create'
                      ? 'Activité créée avec succès'
                      : 'Activité modifiée avec succès',
                  level: 'success',
                  timeout: 2000
                })
              )
            );
            this.editMode = 'create';
            this.editionComplete.emit();
          },
          (err) => {
            this.loading = false;
            console.error(err);
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  message:
                    'Une erreur est survenue, veuillez réessayer plus tard ...',
                  level: 'error',
                  timeout: 5000
                })
              )
            );
          }
        )
    );
  }

  containsRoles(rolesToHave: string[]): boolean {
    return (
      rolesToHave.filter((role) => this.roles.indexOf(role) !== -1).length > 0
    );
  }

  delete() {
    this.activityService.deleteActivityPartner(this.id as number).subscribe(
      () => {
        this.loading = false;
        this.store.dispatch(
          new TriggerAlert(
            new Alert({
              message: 'Activité supprimée avec succès',
              level: 'success',
              timeout: 2000
            })
          )
        );
        this.editMode = 'create';
        this.editionComplete.emit();
      },
      (err) => {
        this.loading = false;
        console.error(err);
        this.store.dispatch(
          new TriggerAlert(
            new Alert({
              message:
                'Une erreur est survenue, veuillez réessayer plus tard ...',
              level: 'error',
              timeout: 5000
            })
          )
        );
      }
    );
  }
}
