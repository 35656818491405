<atc-side-drawer (closeChange)="ageRangeChanged.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading">
  <div class="w-full my-4" header>
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification tranche d\'âge' : 'Nouvelle tranche d\'âge' }}</h1>
  </div>
  <div body class="vsk__body">
    <!-- Name -->
    <atc-input [control]="nameForm"
               label="Nom*"
               placeholder="Adulte"></atc-input>


    <div class="vsk__inline">
      <!-- Âge min.* -->
      <atc-input [control]="minAgeForm"
                 label="Âge min.*"
                 type="number"></atc-input>

      <!-- Âge min.* -->
      <atc-input [control]="maxAgeForm"
                 label="Âge max.*"
                 type="number"></atc-input>
    </div>

    <div *ngIf="keyCardOptions.length" class="flex flex-col gap-2">
      <label>KeyCard ELiberty*</label>
      <atc-select [control]="keyCardForm"
                  [optionElements]="keyCardOptions"
                  label="KeyCard ELiberty*">
      </atc-select>
    </div>
  </div>
  <div class="flex flex-row justify-between w-full items-center pt-4" footer>
    <atc-button (click)="delete()" *ngIf="editMode === 'edit'" color="danger" label="Supprimer"></atc-button>
    <atc-button (click)="upsert()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                id="id__button-validate-form">
    </atc-button>
  </div>
</atc-side-drawer>
