import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { AgencyService } from '../../../service/agency.service';
import { EditComponent } from '../../../shared/component/edit.component';
import { Agency } from '../../../shared/models/agency';
import { OriginEnum } from '../../../shared/models/enum/origin.enum';

export interface AgencyForm {
  name: FormControl<string | null>;
  partnerCode: FormControl<string | null>;
  origin: FormControl<OriginEnum | null>;
  commission: FormControl<string | null>;
}

@Component({
  selector: 'dash-edit-agency',
  templateUrl: './edit-agency.component.html',
  styleUrls: ['./edit-agency.component.scss']
})
export class EditAgencyComponent extends EditComponent<Agency> {
  nameForm = new FormControl<string>('', Validators.required);
  codeForm = new FormControl<string>('', Validators.required);
  commissionForm = new FormControl<string>('0', Validators.required);
  originForm: FormControl<OriginEnum | null> =
    new FormControl<OriginEnum | null>(null, Validators.required);
  agencyForm: FormGroup<AgencyForm>;
  originOptions: OptionElement<OriginEnum>[] = [
    {
      id: null,
      label: 'Choisir une origine',
      disabled: true,
      classCss: 'disabled'
    },
    {
      id: OriginEnum.ARKIANE,
      label: 'Arkiane'
    },
    {
      id: OriginEnum.RESALYS,
      label: 'Resalys'
    }
  ];

  @Input() set entityToEdit(entity: Agency | undefined) {
    if (entity) {
      this.nameForm.setValue(entity.name);
      this.codeForm.setValue(entity.partnerCode);
      this.originForm.setValue(entity.origin);
      this.commissionForm.setValue(
        entity.commission?.toString().replace('.', ',')
      );
      this.editMode = 'edit';
    }
  }

  constructor(
    protected agencyService: AgencyService,
    protected store: Store
  ) {
    super(agencyService, store);
    this.name = 'Agence';
  }

  public isFormValid(): boolean {
    return this.agencyForm.valid;
  }

  public buildEntity(): Agency {
    return new Agency({
      ...(this.agencyForm.getRawValue() as any),
      commission: Number(
        this.commissionForm.value?.replace('%', '').replace(',', '.')
      )
    });
  }

  public initForm(): void {
    this.agencyForm = new FormGroup<AgencyForm>({
      name: this.nameForm,
      partnerCode: this.codeForm,
      origin: this.originForm,
      commission: this.commissionForm
    });
  }
}
