<atc-side-drawer (closeChange)="editionComplete.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading">
  <div class="w-full my-4" header>
    <h1 class="text-center">Détails de la commande #{{ order?.id }} - {{ order?.payed ? 'Payée' : 'Non payée' }}</h1>
  </div>

  <div body class="vsk__body">

    <div class="p-1">
      <h2 class="text-lg font-semibold mb-2">👤 Infos Client</h2>
      <ul class="pl-5 space-y-2">
        <li><span class="font-semibold">Nom :</span> {{ order?.user?.firstName }} {{ order?.user?.lastName }}</li>
        <li><span class="font-semibold">Email :</span> <a class="underline"
                                                          href="mailto:{{ order?.user?.email }}"> {{ order?.user?.email }}</a>
        </li>
        <li><span class="font-semibold">Téléphone :</span> <a class="underline"
                                                              href="tel:{{ order?.user?.phone }}"> {{ order?.user?.phone }}</a>
        </li>
      </ul>
    </div>

    <div class="border-t border-gray-200 my-2"></div>

    <div class="p-1">
      <h2 class="text-lg font-semibold mb-2">📦 Détails de la commande</h2>
      <ul class="pl-5 space-y-2">
        <li><span class="font-semibold">Total :</span> {{ order?.totalPrice?.toFixed(2) }} €</li>
        <li><span class="font-semibold">Commande passée le :</span> {{ order?.date?.format('DD/MM/YYYY') }}</li>
        <li><span class="font-semibold">Station :</span> {{ order?.resort }}</li>
        <li><span class="font-semibold">Dates :</span> {{ order?.startDate?.format('DD/MM/YYYY') }}
          - {{ order?.endDate?.format('DD/MM/YYYY') }}
        <li><span class="font-semibold">Échéance :</span> {{ getStatus(order?.startDate, order?.endDate) }}</li>
        <li>
          <span class="font-semibold">Participants : </span> {{ getParticipantsList(orderDetails?.uniqueParticipants) }}
        </li>
        <ng-container *ngIf="order?.paymentIntentComplete">
          <li *ngIf="order?.paymentIntent">
            <span class="font-semibold">Paiement acompte : </span> <a
            [href]="getLinkStripe(order?.paymentIntent)" target="_blank">{{ order?.paymentIntent }}</a>
          </li>
          <li>
            <span class="font-semibold">Complément paiement : </span> <a
            [href]="getLinkStripe(order?.paymentIntentComplete)" target="_blank">{{ order?.paymentIntentComplete }}</a>
          </li>
          <li>
            <span
              class="font-semibold">Date du complément de paiement :</span> {{ order?.fullPaymentLimitDate?.format('DD/MM/YYYY') }}
          </li>
          <li>
            <span class="font-semibold">Complément payé : </span> {{ order?.hasPayedFull ? 'Oui' : 'Non' }}
          </li>
        </ng-container>

        <ng-container *ngIf="!order?.paymentIntentComplete">
          <li *ngIf="order?.paymentIntent">
            <span class="font-semibold">Paiement : </span> <a
            [href]="getLinkStripe(order?.paymentIntent)" target="_blank">{{ order?.paymentIntent }}</a>
          </li>
        </ng-container>

        <div class="flex flex-row gap-x-4 items-center">
          <li><span class="font-semibold">Statut de la commande :</span></li>
          <atc-select [control]="statusForm"
                      [optionElements]="statusElements"
                      [searchable]="false"
                      label="Mettre à jour le statut">
          </atc-select>
        </div>

        <li>
          <atc-button (click)="sendConfirmationOrder()"
                      color="primary"
                      icon="mail"
                      label="Renvoyer la confirmation de commande">
          </atc-button>
        </li>
      </ul>
    </div>

    <div *ngIf="orderDetails?.accommodations?.length" class="border-t border-gray-200 my-2"></div>


    <div *ngIf="orderDetails && orderDetails.accommodations && orderDetails.accommodations.length" class="p-1">
      <h2 class="text-lg font-semibold mb-2">🏠 Hébergement</h2>
      <!-- Accommodations loop on accomodations-->
      <div *ngFor="let accommodation of orderDetails?.accommodations; let i = index"
           class="flex flex-row justify-between">
        <ul class="pl-5 space-y-2">
          <li><span class="font-semibold">Partenaire :</span> {{ accommodation.partnerName }}</li>
          <li *ngIf="accommodation.reservationId"><span
            class="font-semibold">Numéro de réservation :</span> {{ accommodation.reservationId }}
          </li>
          <li><span class="font-semibold">Établissement :</span> {{ accommodation?.establishmentName }}</li>
          <li><span class="font-semibold">Téléphone :</span> <a class="underline"
                                                                href="tel:{{ accommodation?.establishmentPhone }}"> {{ accommodation?.establishmentPhone }}</a>
          </li>
          <li><span class="font-semibold">Chambre :</span> {{ accommodation?.roomName }}</li>
          <li><span class="font-semibold">Dates :</span> {{ accommodation?.startDate?.format('DD/MM/YYYY') }}
            - {{ accommodation?.endDate?.format('DD/MM/YYYY') }}
          </li>
          <li><span class="font-semibold">Prix :</span> {{ accommodation?.price?.toFixed(2) }} €</li>
          <li><span class="font-semibold">Participants : </span> {{ getParticipantsList(accommodation.participants) }}
          </li>
          <div class="flex flex-row gap-x-4 items-center">
            <p class="font-semibold">Statut du produit :</p>
            <atc-select [control]="getCancelStatusControl(accommodation.id, accommodationsForm)"
                        [optionElements]="cancelStatusOptions"
                        [searchable]="false"
                        label="Statut d'annulation">
            </atc-select>
          </div>
          <li *ngIf="i < orderDetails.accommodations.length - 1" class="p-2">---</li>
        </ul>
      </div>
    </div>

    <div *ngIf="orderDetails?.experiences?.length" class="border-t border-gray-200 my-2"></div>

    <div *ngIf="orderDetails && orderDetails.experiences && orderDetails.experiences.length" class="p-1">
      <h2 class="text-lg font-semibold mb-2">🚵‍♂️ Expériences</h2>
      <div *ngFor="let experience of orderDetails?.experiences; let i = index" class="flex flex-row justify-between">
        <ul class="pl-5 space-y-2">
          <li><span class="font-semibold">Partenaire :</span> {{ experience.partnerName }}</li>
          <li><span class="font-semibold">Activité :</span> {{ experience?.activityName }}</li>
          <li><span class="font-semibold">Nom :</span> {{ experience?.experienceName }}</li>
          <li><span class="font-semibold">Téléphone :</span> <a class="underline"
                                                                href="tel:{{ experience?.partnerPhone }}"> {{ experience?.partnerPhone }}</a>
          </li>
          <li><span class="font-semibold">Dates :</span> {{ experience?.startDate?.format('DD/MM/YYYY HH:mm') }}
            - {{ experience?.endDate?.format('DD/MM/YYYY HH:mm') }}
          </li>
          <li><span class="font-semibold">Prix :</span> {{ experience?.price?.toFixed(2) }} €</li>
          <li><span class="font-semibold">Participants : </span> {{ getParticipantsList(experience.participants) }}</li>
          <div class="flex flex-row gap-x-4 items-center">
            <p class="font-semibold">Statut du produit :</p>
            <atc-select [control]="getCancelStatusControl(experience.id, experiencesForm)"
                        [optionElements]="cancelStatusOptions"
                        [searchable]="false"
                        label="Statut d'annulation">
            </atc-select>
          </div>
          <li *ngIf="i < orderDetails.experiences.length - 1" class="p-2">---</li>
        </ul>
      </div>
    </div>

    <div *ngIf="orderDetails?.skiPasses?.length" class="border-t border-gray-200 my-2"></div>

    <div *ngIf="orderDetails && orderDetails.skiPasses && orderDetails.skiPasses.length" class="p-1">
      <h2 class="text-lg font-semibold mb-2">🚠 Forfaits</h2>
      <div *ngFor="let skiPass of orderDetails?.skiPasses; let i = index" class="flex flex-row justify-between">
        <ul class="pl-5 space-y-2">
          <li><span class="font-semibold">Partenaire :</span> {{ skiPass.origin }}</li>
          <li><span class="font-semibold">Forfait :</span> {{ skiPass?.passName }}</li>
          <li><span class="font-semibold">Premier jour de ski :</span> {{ skiPass?.firstSkiDate?.format('DD/MM/YYYY') }}
          </li>
          <li><span class="font-semibold">Prix :</span> {{ skiPass?.price?.toFixed(2) }} €</li>
          <li><span class="font-semibold">Participants : </span> {{ getParticipantsList([skiPass.participant]) }}</li>
          <div class="flex flex-row gap-x-4 items-center">
            <p class="font-semibold">Statut du produit :</p>
            <atc-select [control]="getCancelStatusControl(skiPass.id, skiPassesForm)"
                        [optionElements]="cancelStatusOptions"
                        [searchable]="false"
                        label="Statut d'annulation">
            </atc-select>
          </div>
          <li *ngIf="i < orderDetails.skiPasses.length - 1" class="p-2">---</li>
        </ul>
      </div>
    </div>

    <div *ngIf="orderDetails?.skiEquipments?.length" class="border-t border-gray-200 my-2"></div>

    <div *ngIf="orderDetails && orderDetails.skiEquipments && orderDetails.skiEquipments.length" class="p-1">
      <h2 class="text-lg font-semibold mb-2">🎿 Location de matériel - <a
        class="underline" href="https://www.skiset-pro.com/booking/booking" target="_blank">Backoffice Skiset</a></h2>
      <div *ngFor="let skiEquipment of orderDetails?.skiEquipments; let i = index"
           class="flex flex-row justify-between">
        <ul class="pl-5 space-y-2">
          <li><span class="font-semibold">Nom du pack :</span> {{ skiEquipment.packName }}</li>
          <li><span class="font-semibold">Description :</span> {{ skiEquipment.packDescription }}</li>
          <li><span class="font-semibold">Catégorie d'âge :</span> {{ skiEquipment.packAgeCategory }}</li>
          <li><span class="font-semibold">Dates :</span> {{ skiEquipment?.startDate?.format('DD/MM/YYYY') }}
            - {{ skiEquipment?.endDate?.format('DD/MM/YYYY') }}
          </li>
          <li><span class="font-semibold">Durée :</span> {{ skiEquipment?.duration }} jours</li>
          <li><span class="font-semibold">Prix :</span> {{ skiEquipment?.price?.toFixed(2) }} €</li>
          <li><span class="font-semibold">Participants : </span> {{ getParticipantsList([skiEquipment.participant]) }}
          </li>
          <li><a
            class="underline"
            href="https://www.skiset-pro.com/booking/booking/downloadvoucher/id/{{ skiEquipment.externalOrderId }}/lang/fr">Télécharger
            le bon de retrait</a></li>
          <div class="flex flex-row gap-x-4 items-center">
            <p class="font-semibold">Statut du produit :</p>
            <atc-select [control]="getCancelStatusControl(skiEquipment.id, skiEquipmentsForm)"
                        [optionElements]="cancelStatusOptions"
                        [searchable]="false"
                        label="Statut d'annulation">
            </atc-select>
          </div>
          <li *ngIf="i < orderDetails.skiEquipments.length - 1" class="p-2">---</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="flex justify-end items-center w-full pt-4" footer>
    <atc-button (click)="create()"
                [disabled]="!isFormValid()"
                [label]="'Mettre à jour'"
                color="secondary"
                id="id__button-validate-form">
    </atc-button>
  </div>

</atc-side-drawer>

