import { Moment } from 'moment';

export class Account {
  firstname: string | null;
  lastname: string | null;
  email: string | null;
  birthdate: Moment | string;
  password: string | null;

  constructor(obj: Partial<Account>) {
    Object.assign(this, obj);
  }
}
