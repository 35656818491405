import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OptionElement } from 'atomic-lib';
import moment from 'moment';
import { AccommodationService } from '../../service/accommodation.service';
import { ResortService } from '../../service/resort.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { AccommodationRoomPackage } from '../../shared/models/accomodation/accommodation-room-package';
import { SearchRoom } from '../../shared/models/accomodation/search-room';
import { Page } from '../../shared/models/page';
import { Pageable } from '../../shared/models/pageable';

export interface SearchForm {
  partner: FormControl<string | null>;
  startDate: FormControl<string | null>;
  endDate: FormControl<string | null>;
  minPeople: FormControl<number | null>;
  maxPeople: FormControl<number | null>;
  resort: FormControl<number | null>;
}

@Component({
  selector: 'dash-search-availability-accommodation',
  templateUrl: './search-availability-accommodation.component.html',
  styleUrls: ['./search-availability-accommodation.component.scss']
})
export class SearchAvailabilityAccommodationComponent extends RxjsComponent {
  headers = [
    'Partner',
    'Station',
    'Nom établissement',
    'Nom chambre',
    'Pers. min',
    'Pers. max',
    'Prix public',
    'Prix'
  ];
  page: Page<AccommodationRoomPackage> = new Page<AccommodationRoomPackage>({
    content: [],
    pageable: new Pageable({
      pageNumber: 0,
      pageSize: 10
    }),
    totalElements: 0,
    numberOfElements: 0
  });
  partners: OptionElement<string>[] = [
    {
      id: null,
      label: 'Choisir un partenaire'
    }
  ];

  resortElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une station'
    }
  ];

  startDateForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  endDateForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  partnerForm: FormControl<string | null> = new FormControl<string | null>(
    null
  );
  resortForm: FormControl = new FormControl<number | null>(null);
  minPeopleForm: FormControl<number | null> = new FormControl<number | null>(
    null
  );
  maxPeopleForm: FormControl<number | null> = new FormControl<number | null>(
    null
  );
  searchForm: FormGroup<SearchForm>;

  constructor(
    private accommodationService: AccommodationService,
    private resortService: ResortService
  ) {
    super();

    this.searchForm = new FormGroup<SearchForm>({
      startDate: this.startDateForm,
      endDate: this.endDateForm,
      partner: this.partnerForm,
      resort: this.resortForm,
      minPeople: this.minPeopleForm,
      maxPeople: this.maxPeopleForm
    });

    this.register(
      this.resortService.getAllStations().subscribe((resorts) => {
        this.resortElements = [
          ...this.resortElements,
          ...resorts.map((resort) => {
            return {
              id: resort.id,
              label: resort.name
            } as OptionElement<number>;
          })
        ];
      })
    );

    this.register(
      this.accommodationService.getAllPartners().subscribe((partners) => {
        this.partners = [
          ...this.partners,
          ...partners.map((partner) => {
            return {
              id: partner.partnerCode,
              label: partner.name + ' - ' + partner.origin.toLowerCase()
            } as OptionElement<string>;
          })
        ];

        this.partnerForm.setValue(this.partners[0].id);
      })
    );
  }

  search() {
    if (this.searchForm.valid) {
      const searchRoom: SearchRoom = new SearchRoom({
        ...(this.searchForm.getRawValue() as any),
        startDate: moment(this.startDateForm.value, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        ),
        endDate: moment(this.endDateForm.value, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        )
      });

      this.register(
        this.accommodationService
          .getProposalsByDates(
            searchRoom,
            this.page.pageable.pageNumber,
            this.page.pageable.pageSize
          )
          .subscribe((page) => (this.page = page))
      );
    }
  }

  onTableDataChange(event: any) {
    this.page.pageable.pageNumber = event - 1;
    this.search();
  }
}
