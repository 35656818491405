import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { ActivityService } from '../../service/activity.service';
import { AgeRangeService } from '../../service/age-range.service';
import { PackageService } from '../../service/package.service';
import { PageComponent } from '../../shared/component/page.component';
import { AgeRange } from '../../shared/models/age-range';
import { Experience } from '../../shared/models/experience';
import { ExperienceActivityPartner } from '../../shared/models/experience-activity-partner';
import { Package } from '../../shared/models/package/package';
import { PackageGroup } from '../../shared/models/package/package-group';

@Component({
  selector: 'dash-package-management',
  templateUrl: './package-management.component.html',
  styleUrls: ['./package-management.component.scss']
})
export class PackageManagementComponent extends PageComponent<Package> {
  @Select(AppState.partnerId) partnerId$: Observable<number>;

  experienceActivityPartners: ExperienceActivityPartner[] = [];
  experiences: Experience[] = [];
  ageRanges: AgeRange[] = [];
  packages: Package[] = [];
  packagesGroup: PackageGroup[] = [];

  constructor(
    private packageService: PackageService,
    private activityService: ActivityService,
    private ageRangeService: AgeRangeService
  ) {
    super(packageService);

    this.register(
      this.partnerId$
        .pipe(
          switchMap((partnerId) =>
            this.activityService.getActivitiesPartner(partnerId)
          )
        )
        .subscribe((activities) => {
          this.experienceActivityPartners = activities
            .map((activityPartner) =>
              activityPartner.experiences.map(
                (option) =>
                  new ExperienceActivityPartner({
                    option,
                    activity: activityPartner
                  })
              )
            )
            .reduce((previousValue, currentValue) => [
              ...previousValue,
              ...currentValue
            ]);

          this.experiences = activities
            .map((activity) => activity.experiences)
            .reduce((previousValue, currentValue) => [
              ...previousValue,
              ...currentValue
            ]);
        })
    );

    this.register(
      this.partnerId$
        .pipe(
          switchMap((partnerId) =>
            this.ageRangeService.getAllByPartnerId(partnerId)
          )
        )
        .subscribe((ageRanges) => (this.ageRanges = ageRanges))
    );

    this.updatePackagesChanged();
  }

  updatePackagesChanged(): void {
    this.register(
      this.partnerId$
        .pipe(
          switchMap((partnerId) =>
            this.packageService.getAllByPartnerId(partnerId)
          )
        )
        .subscribe(
          (packages) =>
            (this.packages = packages.sort((prev, curr) =>
              prev.experience.activity.name < curr.experience.activity.name
                ? -1
                : 1
            ))
        )
    );

    this.register(
      this.partnerId$
        .pipe(
          switchMap((partnerId) => this.packageService.fetchGroup(partnerId))
        )
        .subscribe((groups) => (this.packagesGroup = groups))
    );
  }
}
