<dash-list-edit id="id__table-list-category"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                [headers]="headers"
                [searchForm]="searchForm"
                paginationId="category"
                labelCreate="Ajouter une catégorie"
                searchLabel="Recherche de catégorie"
                searchPlaceholder="Rechercher par nom ..."
                title="Catégories">
  <ng-container body *ngFor="let category of page.content | paginate : {
                  id: 'category',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(category)">
      <td>{{ category.id }}</td>
      <td>{{ category.name }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
