import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import moment from 'moment';
import { PartnerAccount } from 'src/app/shared/models/partner-account';
import { User } from 'src/app/shared/models/user';
import { TriggerAlert } from '../../../app.action';
import { AccountService } from '../../../service/account.service';
import { PartnerService } from '../../../service/partner.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { Alert } from '../../../shared/models/alert';
import { Partner } from '../../../shared/models/partner';

export interface CreatePartnerForm {
  name: FormControl<string | null>;
  address: FormControl<string | null>;
  societyName: FormControl<string | null>;
  reservationEmail: FormControl<string | null>;
  siret: FormControl<string | null>;
  iban: FormControl<string | null>;
  bic: FormControl<string | null>;
  cartePro: FormControl<string | null>;
  phone: FormControl<string | null>;
  enabled: FormControl<boolean | null>;
  review: FormControl<number | null>;
  nbReviews: FormControl<number | null>;
}

export interface CreateUserPartnerForm {
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  email: FormControl<string | null>;
  birthDate: FormControl<string | null>;
  userAddress: FormControl<string | null>;
  postalCode: FormControl<string | null>;
  city: FormControl<string | null>;
  country: FormControl<string | null>;
  phone: FormControl<string | null>;
}

@Component({
  selector: 'dash-edit-partner',
  templateUrl: './edit-partner.component.html',
  styleUrls: ['./edit-partner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditPartnerComponent extends RxjsComponent {
  partnerId: number | undefined;
  userId: number | undefined;
  loading = false;
  editMode: 'create' | 'edit' = 'create';

  // Form partner
  createPartnerForm: FormGroup<CreatePartnerForm>;
  nameForm = new FormControl<string>('', Validators.required);
  addressEnterpriseForm = new FormControl<string>('', Validators.required);
  commissionRateForm = new FormControl<string>('', Validators.required);
  reviewForm = new FormControl<number | null>(null);
  nbReviewsForm = new FormControl<number | null>(null);
  externalIdForm = new FormControl<string>('');
  dataImportationConditionForm = new FormControl<boolean>(false);
  societyNameForm = new FormControl<string>('', Validators.required);
  reservationEmailForm = new FormControl<string>('', [
    Validators.required,
    Validators.email
  ]);
  siretForm = new FormControl<string>('');
  ibanForm = new FormControl<string>('');
  bicForm = new FormControl<string>('');
  carteProForm = new FormControl<string>('');
  enabledForm = new FormControl<boolean>(true, Validators.required);
  // Form partner user
  createPartnerUserForm: FormGroup<CreateUserPartnerForm>;
  firstNameForm = new FormControl<string>('');
  lastNameForm = new FormControl<string>('');
  emailUserForm = new FormControl<string>('');
  birthdateForm = new FormControl<string | null>('');
  postalCodeForm = new FormControl<string>('');
  cityForm = new FormControl<string>('');
  countryForm = new FormControl<string>('');
  phoneForm = new FormControl<string>('');
  addressForm = new FormControl<string>('');

  @Input() set partnerToEdit(value: Partner | undefined) {
    if (value) {
      this.register(
        this.accountService.getUserById(value.user).subscribe((user) => {
          this.firstNameForm.setValue(user.firstName);
          this.lastNameForm.setValue(user.lastName);
          this.birthdateForm.setValue(
            moment(user.birthdate).format('DD/MM/YYYY')
          );
          this.emailUserForm.setValue(user.email);
          this.postalCodeForm.setValue(user.postCode);
          this.cityForm.setValue(user.city);
          this.countryForm.setValue(user.country);
          this.phoneForm.setValue(user.phone);
          this.addressForm.setValue(user.address);
        })
      );
      this.partnerId = value.id;
      this.userId = value.user;
      this.nameForm.setValue(value.name);
      this.addressEnterpriseForm.setValue(value.address);
      this.commissionRateForm.setValue(
        value.commissionRate.toString().replace('.', ',')
      );
      this.reviewForm.setValue(value.review);
      this.nbReviewsForm.setValue(value.nbReviews);
      this.externalIdForm.setValue(value.externalId);
      this.societyNameForm.setValue(value.societyName);
      this.reservationEmailForm.setValue(value.reservationEmail);
      this.siretForm.setValue(value.siret);
      this.ibanForm.setValue(value.iban);
      this.bicForm.setValue(value.bic);
      this.carteProForm.setValue(value.cartePro);
      this.enabledForm.setValue(value.enabled);
      this.editMode = 'edit';
      this.updateValidatorsBasedOnMode();
      this.createPartnerForm.updateValueAndValidity();
    }
  }

  @Output() editionComplete = new EventEmitter<void>();

  constructor(
    private partnerService: PartnerService,
    private store: Store,
    private accountService: AccountService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.initForms();
    this.updateValidatorsBasedOnMode();
  }

  initForms() {
    this.createPartnerForm = new FormGroup<CreatePartnerForm>({
      name: this.nameForm,
      address: this.addressEnterpriseForm,
      societyName: this.societyNameForm,
      reservationEmail: this.reservationEmailForm,
      siret: this.siretForm,
      iban: this.ibanForm,
      bic: this.bicForm,
      cartePro: this.carteProForm,
      phone: this.phoneForm,
      enabled: this.enabledForm,
      review: this.reviewForm,
      nbReviews: this.nbReviewsForm
    });
    this.createPartnerUserForm = new FormGroup<CreateUserPartnerForm>({
      firstName: this.firstNameForm,
      lastName: this.lastNameForm,
      email: this.emailUserForm,
      birthDate: this.birthdateForm,
      userAddress: this.addressForm,
      postalCode: this.postalCodeForm,
      city: this.cityForm,
      country: this.countryForm,
      phone: this.phoneForm
    });
    const userControls = [
      this.firstNameForm,
      this.lastNameForm,
      this.emailUserForm
    ];
    userControls.forEach((control) => {
      control.valueChanges.subscribe(() => {
        this.updateUserFormValidators();
      });
    });
    this.externalIdForm.valueChanges.subscribe((value) => {
      if (value) {
        this.dataImportationConditionForm.enable();
      } else {
        this.dataImportationConditionForm.disable();
        this.dataImportationConditionForm.setValue(false);
      }
    });
  }

  isFormValid(): boolean {
    const partnerFormValid =
      this.createPartnerForm.valid && this.commissionRateForm.valid;
    const userFormValid =
      this.createPartnerUserForm.valid || !this.isAnyUserFieldFilled();

    return partnerFormValid && userFormValid;
  }

  create(): void {
    this.updateUserFormValidators();
    if (this.isFormValid()) {
      this.loading = true;
      const partner = new Partner({
        id: this.partnerId as number,
        address: this.addressEnterpriseForm.value as string,
        name: this.nameForm.value as string,
        commissionRate: Number(
          this.commissionRateForm.value?.replace('%', '').replace(',', '.')
        ),
        review: this.reviewForm.value as number,
        nbReviews: this.nbReviewsForm.value as number,
        externalId: this.externalIdForm.value as string,
        societyName: this.societyNameForm.value as string,
        reservationEmail: this.reservationEmailForm.value as string,
        siret: this.siretForm.value as string,
        iban: this.ibanForm.value as string,
        bic: this.bicForm.value as string,
        cartePro: this.carteProForm.value as string,
        phoneNumber: this.phoneForm.value as string,
        enabled: this.enabledForm.value as boolean
      });
      const user = new User({
        id: this.userId as number,
        firstName: this.firstNameForm.value as string,
        lastName: this.lastNameForm.value as string,
        birthdate: this.birthdateForm.value
          ? moment(this.birthdateForm.value as string, 'DD/MM/YYYY')
          : moment().subtract(18, 'years'),
        email: this.emailUserForm.value as string,
        phone: this.phoneForm.value as string,
        address: this.addressForm.value as string,
        city: this.cityForm.value as string,
        postCode: this.postalCodeForm.value as string,
        country: this.countryForm.value as string
      });
      const partnerAccount = new PartnerAccount(partner, user);
      this.register(
        this.partnerService
          .upsertPartner(
            partnerAccount,
            this.dataImportationConditionForm.value as boolean
          )
          .subscribe(
            () => {
              this.editionComplete.emit();
              this.loading = false;

              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    level: 'success',
                    timeout: 2000,
                    message:
                      this.editMode === 'create'
                        ? 'Partenaire crée avec succès'
                        : 'Partenaire modifié avec succès'
                  })
                )
              );
            },
            (err) => {
              this.loading = false;
              const alertMessage = new Alert({
                level: 'error',
                timeout: 5000,
                message:
                  'Une erreur est survenue, veuillez réessayer ultérieurement ...'
              });
              switch (err.status) {
                case 400:
                  alertMessage.message =
                    'teamId YoPlanning invalide. Vérifiez que le partenaire est bien rattaché à une équipe.';
                  break;
                case 507:
                  alertMessage.message =
                    "Aucun produits YoPlanning n'est associé à ce partenaire";
                  break;
                case 503:
                  alertMessage.message =
                    "L'API YoPlanning est actuellement indisponible, veuillez réessayer ultérieurement ...";
                  break;
                case 409:
                  alertMessage.message =
                    'Le partenaire existe déjà (adresse email unique)';
                  break;
              }

              this.store.dispatch(new TriggerAlert(new Alert(alertMessage)));
              this.cdr.markForCheck();
              console.error(err);
            }
          )
      );
    }
  }

  updateValidatorsBasedOnMode(): void {
    const validators = this.editMode === 'edit' ? [] : [Validators.required];
    const formControlsToUpdate = [
      this.firstNameForm,
      this.lastNameForm,
      this.emailUserForm
    ];

    formControlsToUpdate.forEach((formControl) => {
      formControl.setValidators(validators);
      formControl.updateValueAndValidity();
    });
  }

  updateUserFormValidators() {
    const isUserFieldFilled = this.isAnyUserFieldFilled();
    const requiredValidator = isUserFieldFilled ? Validators.required : null;
    const emailValidator = isUserFieldFilled
      ? [Validators.required, Validators.email]
      : null;

    this.firstNameForm.setValidators(requiredValidator);
    this.lastNameForm.setValidators(requiredValidator);
    this.emailUserForm.setValidators(emailValidator);

    this.firstNameForm.updateValueAndValidity({ emitEvent: false });
    this.lastNameForm.updateValueAndValidity({ emitEvent: false });
    this.emailUserForm.updateValueAndValidity({ emitEvent: false });
  }

  isAnyUserFieldFilled(): boolean {
    return !!(
      this.firstNameForm.value ||
      this.lastNameForm.value ||
      this.emailUserForm.value
    );
  }

  resetPassword(): void {
    this.accountService
      .resetPasswordPartner(this.userId as number, this.partnerId as number)
      .subscribe(
        () => {
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                level: 'success',
                timeout: 2000,
                message: 'Le mot de passe a été réinitialisé avec succès'
              })
            )
          );
        },
        () => {
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                level: 'error',
                timeout: 5000,
                message:
                  'Une erreur est survenue, veuillez réessayer ultérieurement ...'
              })
            )
          );
        }
      );
  }
}
