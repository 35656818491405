<div class="flex ml-6 mt-6 -m-6">
  <div *ngFor="let filter of filterOptions" class="mb-6 mr-4">
    <atc-button-select (click)="onFilterSelected(filter.value)"
                       [label]="filter.label"
                       [selected]="filter.value === selectedFilter">
    </atc-button-select>
  </div>
</div>

<dash-list-edit (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                [headers]="headers"
                [searchForm]="searchForm"
                id="id__table-list-order"
                labelCreate="Créer une commande"
                paginationId="order"
                searchLabel="Recherche de commandes"
                searchPlaceholder="Rechercher par nom ou e-mail..."
                title="Liste des commandes">
  <ng-container *ngFor="let order of filteredOrders | paginate : {
                id: 'order',
                itemsPerPage: page.pageable.pageSize,
                currentPage: page.pageable.pageNumber + 1,
                totalItems: page.totalElements }" body>
    <tr (click)="edit(order)">
      <td>{{ order.id }}</td>
      <td>{{ order.date.format('DD/MM/YYYY') }}</td>
      <td>{{ order.resort }}</td>
      <td>{{ order.startDate.format('DD/MM/YYYY') }} - {{ order.endDate.format('DD/MM/YYYY') }}</td>
      <td>{{ order.user.lastName }} {{ order.user.firstName }}</td>
      <td>{{ order.totalPrice ? (order.totalPrice | number: '1.2-2' : 'fr') : 0 }} €</td>
    </tr>
  </ng-container>
</dash-list-edit>

<div class="flex flex-row">
  <div class="graph">
    <h2>Volume vendu du mois en cours
      <ng-container *ngIf="totalVolumeCurrentMonth">
        - {{ totalVolumeCurrentMonth | number: '1.2-2' : 'fr' }} €
      </ng-container>
    </h2>
    <canvas *ngIf="chartVolumeCurrentMonth"
            [data]="chartVolumeCurrentMonth"
            [options]="lineChartOptions"
            baseChart
            type="line">
    </canvas>
  </div>

  <div class="graph">
    <h2>Volume non converti du mois en cours
      <ng-container *ngIf="totalNotPaidVolumeCurrentMonth">
        - {{ totalNotPaidVolumeCurrentMonth | number: '1.2-2' : 'fr' }} €
      </ng-container>
    </h2>
    <canvas *ngIf="chartNotPaidVolumeCurrentMonth"
            [data]="chartNotPaidVolumeCurrentMonth"
            [options]="lineChartOptions"
            baseChart
            type="line">
    </canvas>
  </div>
</div>

