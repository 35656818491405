import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PartnerInformation } from '../shared/models/PartnerInformation';
import { Account } from '../shared/models/account';
import { Page } from '../shared/models/page';
import { Partner } from '../shared/models/partner';
import { PartnerAccount } from '../shared/models/partner-account';
import { PartnerWithUser } from '../shared/models/partnerWithUser';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerService
  extends RepositoryService
  implements PageableService<Partner>
{
  private URL_PARTNER = 'api/backoffice/partner';

  constructor(http: HttpClient) {
    super(http);
  }

  getPartnerById(partnerId: number): Observable<Partner> {
    return this.get(this.URL_PARTNER + '/get-partner?partnerId=' + partnerId);
  }

  getPartneWithUserById(partnerId: number): Observable<PartnerWithUser> {
    return this.get(
      this.URL_PARTNER + '/get-partner-user?partnerId=' + partnerId
    );
  }

  upsertPartner(
    partnerAccount: PartnerAccount,
    importData: boolean
  ): Observable<number> {
    return this.post(
      this.URL_PARTNER + '/create-partner?importData=' + importData,
      partnerAccount
    );
  }

  upsertPartnerInformation(
    partnerMinEnterprise: PartnerInformation
  ): Observable<void> {
    return this.post(
      this.URL_PARTNER + '/upsert-partner-information',
      partnerMinEnterprise
    );
  }

  getInformation(userId: number): Observable<Partner[]> {
    return this.get(this.URL_PARTNER + '/information?userId=' + userId);
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<Partner>> {
    return this.get(
      this.URL_PARTNER +
        `/all-partners?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map((page: Page<Partner>) => new Page<Partner>({ ...page }, Partner))
    );
  }

  getPartnerByToken(token: string): Observable<Partner> {
    return this.get(this.URL_PARTNER + '/get-partner-by-token?token=' + token);
  }

  createUserPartner(account: Account, token: string): Observable<Partner> {
    return this.post(this.URL_PARTNER + '/create-user?token=' + token, account);
  }

  forgotPassword(email: string): Observable<Partner> {
    return this.put(this.URL_PARTNER + '/forgot-password', { email });
  }
}
