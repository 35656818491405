<atc-side-drawer [loading]="loading"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 (closeChange)="editionComplete.emit()">
  <div header class="w-full my-4">
    <h1 class="text-center">{{editMode === 'edit' ? 'Modification de label' : 'Nouveau label'}}</h1>
  </div>

  <div body class="vsk__body">
    <!-- name -->
    <atc-input [control]="nameForm"
               label="Nom du label"
               placeholder="Label">
    </atc-input>

    <!-- Season -->
    <atc-select id="id__input-edit-resort-region"
                label="Saison*"
                [control]="periodForm"
                [optionElements]="seasons">
    </atc-select>

    <!-- Link -->
    <atc-input [control]="linkForm"
               label="Lien du blog"
               placeholder="https://blog.verymountain.fr">
    </atc-input>
  </div>

  <div footer class="vsk__footer">
    <atc-button *ngIf="editMode === 'edit'" color="danger" label="Supprimer" (click)="delete()"></atc-button>
    <div class="vsk__flex"></div>
    <atc-button id="id__button-validate-form"

                [disabled]="disabled()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                (click)="upsert()">
    </atc-button>
  </div>
</atc-side-drawer>
