import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImagesService } from 'src/app/service/images.service';
import { TextBlockService } from 'src/app/service/text-block.service';
import { EditComponent } from 'src/app/shared/component/edit.component';
import { SeasonEnum } from 'src/app/shared/models/enum/season.enum';
import { TextBlock } from 'src/app/shared/models/text-block';
import { TextBlockContent } from 'src/app/shared/models/text-block-content';

export interface Content {
  season: SeasonEnum;
  coverPhotoUrl: string;
}

@Component({
  selector: 'dash-edit-text-block',
  templateUrl: './edit-text-block.component.html',
  styleUrls: ['./edit-text-block.component.scss']
})
export class EditTextBlockComponent extends EditComponent<TextBlock> {
  protected readonly SeasonEnum = SeasonEnum;
  name = 'Bloc de texte';

  // Photo
  winterCoverFirebase: string[] = [];
  winterCover: HTMLImageElement[] = [];
  fileWinter: File[] = [];

  // Photo
  summerCoverFirebase: string[] = [];
  summerCover: HTMLImageElement[] = [];
  fileSummer: File[] = [];

  nameForm = new FormControl<string>('', Validators.required);
  summerIdForm = new FormControl<number | null>(null);
  summerTitleForm = new FormControl<string>('', Validators.required);
  summerDescriptionForm = new FormControl<string>('', Validators.required);
  summerCoverForm = new FormControl<string>('', Validators.required);
  winterIdForm = new FormControl<number | null>(null);
  winterTitleForm = new FormControl<string>('', Validators.required);
  winterDescriptionForm = new FormControl<string>('', Validators.required);
  winterCoverForm = new FormControl<string>('', Validators.required);

  createdTextBlockForm: FormGroup;

  @Input() set entityToEdit(textBlock: TextBlock | undefined) {
    if (textBlock) {
      this.id = textBlock.id;
      this.nameForm.setValue(textBlock.name);
      const summerContent = textBlock.textBlockContents.find(
        (content) => content.season === SeasonEnum.SUMMER
      );
      if (summerContent) {
        this.summerIdForm.setValue(summerContent.id);
        this.summerTitleForm.setValue(summerContent.title);
        this.summerDescriptionForm.setValue(summerContent.description);
        this.summerCoverForm.setValue(summerContent.coverPhotoUrl);
      } else {
        this.summerIdForm.setValue(null);
        this.summerTitleForm.setValue('');
        this.summerDescriptionForm.setValue('');
        this.summerCoverForm.setValue('');
      }
      const winterContent = textBlock.textBlockContents.find(
        (content) => content.season === SeasonEnum.WINTER
      );
      if (winterContent) {
        this.winterIdForm.setValue(winterContent.id);
        this.winterTitleForm.setValue(winterContent.title);
        this.winterDescriptionForm.setValue(winterContent.description);
        this.winterCoverForm.setValue(winterContent.coverPhotoUrl);
      } else {
        this.winterIdForm.setValue(null);
        this.winterTitleForm.setValue('');
        this.winterDescriptionForm.setValue('');
        this.winterCoverForm.setValue('');
      }
      this.loadPhotos(textBlock.textBlockContents);
      this.editMode = 'edit';
    }
  }

  constructor(
    protected service: TextBlockService,
    private imagesService: ImagesService,
    protected store: Store
  ) {
    super(service, store);
  }

  public initForm(): void {
    const summerContentForm = new FormGroup({
      id: this.summerIdForm,
      title: this.summerTitleForm,
      description: this.summerDescriptionForm,
      season: new FormControl(SeasonEnum.SUMMER),
      coverPhotoUrl: new FormControl('')
    });

    const winterContentForm = new FormGroup({
      id: this.winterIdForm,
      title: this.winterTitleForm,
      description: this.winterDescriptionForm,
      season: new FormControl(SeasonEnum.WINTER),
      coverPhotoUrl: new FormControl('')
    });

    this.createdTextBlockForm = new FormGroup({
      name: this.nameForm,
      textBlockContents: new FormArray([summerContentForm, winterContentForm])
    });
  }

  public isFormValid(): boolean {
    return (
      this.createdTextBlockForm.valid &&
      this.winterCover.length > 0 &&
      this.summerCover.length > 0
    );
  }

  listUpdated(imgs: HTMLImageElement[], season: SeasonEnum) {
    switch (season) {
      case SeasonEnum.WINTER:
        this.winterCover = imgs;
        break;

      case SeasonEnum.SUMMER:
        this.summerCover = imgs;
        break;
    }
  }

  addPhotoFile(files: File[], season: SeasonEnum) {
    if (season === SeasonEnum.WINTER) {
      this.fileWinter.push(...files);
    } else {
      this.fileSummer.push(...files);
    }
  }

  loadPhotos(textBlockContents: TextBlockContent[]) {
    if (textBlockContents) {
      textBlockContents.forEach((content) => {
        const img = new Image();
        img.srcset = content.coverPhotoUrl;
        img.src = content.coverPhotoUrl;
        switch (content.season) {
          case SeasonEnum.WINTER:
            img.alt = 'Image hiver';
            this.winterCoverFirebase.push(content.coverPhotoUrl);
            this.winterCover.push(img);
            break;

          case SeasonEnum.SUMMER:
            img.alt = 'Image été';
            this.summerCoverFirebase.push(content.coverPhotoUrl);
            this.summerCover.push(img);
            break;
          default:
            break;
        }
      });
    }
  }

  uploadPhotos(): Observable<TextBlockContent[]> {
    const uploads = [];

    if (this.fileWinter.length) {
      const winterUpload = this.imagesService
        .uploadImage(
          this.fileWinter[0],
          `text-block/${this.nameForm.value}/winter/`,
          this.fileWinter[0].name
        )
        .pipe(
          map((url) => ({ season: SeasonEnum.WINTER, coverPhotoUrl: url }))
        );
      uploads.push(winterUpload);
    }

    if (this.fileSummer.length) {
      const summerUpload = this.imagesService
        .uploadImage(
          this.fileSummer[0],
          `text-block/${this.nameForm.value}/summer/`,
          this.fileSummer[0].name
        )
        .pipe(
          map((url) => ({ season: SeasonEnum.SUMMER, coverPhotoUrl: url }))
        );
      uploads.push(summerUpload);
    }

    return forkJoin(uploads).pipe(
      map((results) => {
        return results.map((result) => {
          const content = new TextBlockContent({});
          content.season = result.season;
          content.coverPhotoUrl = result.coverPhotoUrl;
          return content;
        });
      })
    );
  }

  create(): void {
    if (this.isFormValid()) {
      this.uploadPhotos().subscribe((contents) => {
        this.updateTextBlockContentsWithPhotos(contents);
        const textBlock = this.buildEntity();
        this.upsert(textBlock);
        return;
      });
      const contents: Content[] = [
        {
          season: SeasonEnum.SUMMER,
          coverPhotoUrl: this.summerCoverForm.value || ''
        },
        {
          season: SeasonEnum.WINTER,
          coverPhotoUrl: this.winterCoverForm.value || ''
        }
      ];
      this.updateTextBlockContentsWithPhotos(contents);
      this.upsert(this.buildEntity());
    }
  }

  updateTextBlockContentsWithPhotos(contents: Content[]): void {
    const textBlockContentsArray = this.createdTextBlockForm.get(
      'textBlockContents'
    ) as FormArray;

    contents.forEach((content) => {
      const index = content.season === SeasonEnum.SUMMER ? 0 : 1;
      const contentFormGroup = textBlockContentsArray.at(index) as FormGroup;
      contentFormGroup.controls['coverPhotoUrl'].setValue(
        content.coverPhotoUrl
      );
    });
  }

  buildEntity(): TextBlock {
    return new TextBlock({
      ...(this.createdTextBlockForm.getRawValue() as any),
      id: this.id
    });
  }
}
