<dash-list-edit id="id__table-list-activity"
                paginationId="activity"
                [headers]="headers"
                [searchForm]="searchForm"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                labelCreate="Ajouter une activité"
                searchLabel="Recherche d'activité"
                searchPlaceholder="Rechercher par nom ..."
                title="Activités">
  <ng-container body *ngFor="let activity of page.content | paginate : {
                  id: 'activity',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(activity)">
      <td>{{ activity.id }}</td>
      <td>{{ activity.name }}</td>
      <td>{{ activity.dateStart.format('DD/MM/YYYY') }}</td>
      <td>{{ activity.dateEnd.format('DD/MM/YYYY') }}</td>
      <td [ngClass]="activity.enabled ? 'vsk__payed':'vsk__not-payed'">{{ activity.enabled ? 'Activé' : 'Désactivé' }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
