<dash-list-edit [headers]="headers"
                [searchForm]="searchForm"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                paginationId="partenaire"
                searchLabel="Recherche de partenaire"
                searchPlaceholder="Rechercher par nom ..."
                labelCreate="Ajouter un partenaire"
                id="id__table-list-partner"
                title="Partenaires">
  <ng-container body *ngFor="let partner of page.content | paginate : {
                  id: 'partenaire',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(partner)">
      <td>{{partner.id}}</td>
      <td>{{partner.name}}</td>
      <td>{{partner.address}}</td>
    </tr>
  </ng-container>
</dash-list-edit>
