import { TextBlockContent } from './text-block-content';

export class TextBlock {
  id: number;
  name: string;
  textBlockContents: TextBlockContent[];

  constructor(obj: Partial<TextBlock>) {
    Object.assign(this, obj);

    if (this.textBlockContents) {
      this.textBlockContents = this.textBlockContents.map(
        (textBlockContent) => new TextBlockContent(textBlockContent)
      );
    }
  }
}
