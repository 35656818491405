<div class="vsk__column">
  <dash-list-resort-label [page]="page"
                          [searchForm]="searchForm"
                          (pageChanged)="fetchPageable($event, searchForm.value)"
                          (createChanged)="showEdit()"
                          (isChanged)="edit($event)">
  </dash-list-resort-label>
  <dash-edit-resort-label *ngIf="showEditor"
                          [resortLabelToEdit]="toEdit"
                          (editionComplete)="editCompleted()">
  </dash-edit-resort-label>
</div>
