import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ElasticsearchService extends RepositoryService {
  private URL_ES = 'api/admin/es';

  constructor(http: HttpClient) {
    super(http);
  }

  indexTrip(): Observable<void> {
    return this.post(this.URL_ES + '/remap-search-map', {});
  }

  indexAccommodation(): Observable<void> {
    return this.post(this.URL_ES + '/remap-search-establishment', {});
  }

  indexExperience(): Observable<void> {
    return this.post(this.URL_ES + '/remap-search-experience', {});
  }

  indexExperienceGroup(): Observable<void> {
    return this.post(this.URL_ES + '/remap-search-experience-group', {});
  }
}
