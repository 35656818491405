<atc-side-drawer [loading]="loading"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 (closeChange)="customFieldToEditComplete.emit()">
  <div header class="w-full my-4">
    <h1 class="text-center">{{editMode === 'edit' ? 'Modification activité' : 'Nouvelle activité'}}</h1>
  </div>

  <div body class="vsk__body">
    <atc-input [control]="labelForm"
               id="id__input-text-activity-name"
               label="Libellé du champ"
               placeholder="Libellé">
    </atc-input>
  </div>


  <div footer class="vsk__footer">
    <atc-button *ngIf="editMode === 'edit'" color="danger" label="Supprimer" (click)="delete()"></atc-button>
    <div class="vsk__flex"></div>
    <atc-button id="id__button-validate-form"
                [disabled]="!this.customFieldFormGroup.valid"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"

                (click)="upsertCustomField()">
    </atc-button>
  </div>

</atc-side-drawer>
