import { Component } from '@angular/core';
import { PageableComponent } from '../../../../shared/component/pageable.component';
import { Product } from '../../../../shared/models/eliberty/product';
import { ProductPricePromotion } from '../../../../shared/models/eliberty/product-price-promotion';

@Component({
  selector: 'dash-list-product-price-promotion',
  templateUrl: './list-product-price-promotion.component.html',
  styleUrls: ['./list-product-price-promotion.component.scss']
})
export class ListProductPricePromotionComponent extends PageableComponent<ProductPricePromotion> {
  headers = ['Id', 'Période', 'Promotion', 'Produits'];

  getLabelProducts(products: Product[]) {
    if (!products.length) {
      return '';
    }

    return products.map((product) => product.name).join(',');
  }
}
