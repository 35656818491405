import { AccommodationRoomPackage } from './accommodation-room-package';

export class RoomDetails {
  codeRoom: string;
  name: string;
  surface: number;
  maxPeople: number;
  proposals: AccommodationRoomPackage[];

  constructor(obj: Partial<RoomDetails>) {
    Object.assign(this, obj);

    if (this.proposals) {
      this.proposals = this.proposals.map(
        (proposal) => new AccommodationRoomPackage({ ...proposal })
      );
    }
  }
}
