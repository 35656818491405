import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '../../app.state';
import { AgeRangeService } from '../../service/age-range.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { AgeRange } from '../../shared/models/age-range';

@Component({
  selector: 'dash-age-range-management',
  templateUrl: './age-range-management.component.html',
  styleUrls: ['./age-range-management.component.scss']
})
export class AgeRangeManagementComponent extends RxjsComponent {
  @Select(AppState.partnerId) partnerId$: Observable<number>;

  ageRanges: AgeRange[] = [];
  ageToEdit: AgeRange | undefined;
  showEditor = false;

  constructor(
    private ageRangeService: AgeRangeService,
    private store: Store
  ) {
    super();
    this.updateAgeRangeChanged();
  }

  ageRangeChanged(): void {
    this.ageToEdit = undefined;
    this.updateAgeRangeChanged();
    this.showEdit();
  }

  updateAgeRangeChanged(): void {
    const partnerId = this.store.selectSnapshot(AppState.partnerId) as number;
    this.register(
      this.ageRangeService
        .getAllByPartnerId(partnerId)
        .subscribe((ageRanges) => (this.ageRanges = ageRanges))
    );
  }

  edit(event: AgeRange) {
    this.ageToEdit = event;
    this.showEdit();
  }

  showEdit() {
    this.showEditor = !this.showEditor;
  }
}
