import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class HealthService extends RepositoryService {
  private URL_HEALTH = 'api/backoffice/health';

  isConnected(): Observable<number> {
    return this.get(this.URL_HEALTH + '/is-connected');
  }
}
