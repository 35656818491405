<dash-list-edit [headers]="headers"
                (createEvent)="createEventChanged.emit()"
                labelCreate="Ajouter une expérience"
                title="Expériences">
  <ng-container body *ngFor="let activity of activityPartners">
    <tr [id]="'id__tr-dash-table-list-'+ option.name"
        *ngFor="let option of activity.experiences"
        (click)="editExperience(activity, option)">
      <td *ngIf="roles$ | async | isAdmin">{{option.id}}</td>
      <td>{{activity.activity.name}}</td>
      <td><span *ngIf="isYoPlanning(option.origin)" title="YOPLANNING">🇾</span> {{ option.name }}</td>
      <td>{{translateDifficulty(option.difficulty)}}</td>
      <td>{{option.duration | duration}}</td>
      <td>
        <img [id]="'id__img-dash-table-list-'+option.name" (click)="duplicateExperience($event, activity, option)" alt="Duplicate" src="../../../../../assets/icones/copy.svg">
      </td>
    </tr>
  </ng-container>
</dash-list-edit>
