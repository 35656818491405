<atc-side-drawer (closeChange)="editionComplete.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']">
  <div class="w-full my-4" header>
    <h1 class="text-center">{{ variant.sku }}</h1>
  </div>

  <div body class="vsk__body">
    <h1>Informations générales</h1>
    <hr>
    <div class="flex flex-row gap-x-2">
      <h2>Identifiant :</h2>
      <p>{{ variant.id }}</p>
    </div>
    <div class="flex flex-row gap-x-2">
      <h2>Tranche d'âge :</h2>
      <p>{{ variant.consumerCategory.name }}</p>
    </div>
    <div class="flex flex-row gap-x-2">
      <h2>Âge min :</h2>
      <p>{{ variant.consumerCategory.ageMin }}</p>
    </div>
    <div class="flex flex-row gap-x-2">
      <h2>Âge max :</h2>
      <p>{{ variant.consumerCategory.ageMax }}</p>
    </div>

    <h1 class="mt-8">Grille de prix</h1>
    <hr>
    <table>
      <thead>
      <tr>
        <th>Id</th>
        <th>Catégorie</th>
        <th>Prix</th>
        <th>Période de validité</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let price of variant.prices">
        <td>{{ price.id }}</td>
        <td>{{ price.priceCategory.id }} - {{ price.priceCategory.name }}</td>
        <td>{{ (price.publicPrice / 100) | number : '1.2-2' }} €</td>
        <td>{{ price.validityPeriod.startDate?.format('DD/MM/YYYY') || 'Vide' }}
          - {{ price.validityPeriod.endDate?.format('DD/MM/YYYY') || 'Vide' }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="vsk__footer" footer>
  </div>
</atc-side-drawer>
