<dash-list-edit id="id__table-list-users"
                paginationId="users"
                labelCreate="Ajouter un utilisateur"
                [headers]="headers"
                [searchForm]="searchForm"
                (paginateEvent)="onTableDataChange($event)"
                (createEvent)="createChanged.emit()"
                searchLabel="Recherche des users"
                searchPlaceholder="Rechercher par email"
                title="Utilisateurs">

  <ng-container body *ngFor="let user of page.content | paginate : {
                id: 'users',
                itemsPerPage: page.pageable.pageSize,
                currentPage: page.pageable.pageNumber + 1,
                totalItems: page.totalElements }">
    <tr (click)="edit(user)">
      <td>{{ user.id }}</td>
      <td>{{ user.email }}</td>
      <td>{{ user.fullname }}</td>
      <td>{{ user.enabled ? 'Oui' : 'Non' }}</td>
      <td>{{ user.role }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
