import { ConsumerCategory } from './consumer-category';
import { Price } from './price';

export class Variant {
  id: number;
  sku: string;
  consumerCategory: ConsumerCategory;
  prices: Price[];

  constructor(obj: Partial<Variant>) {
    Object.assign(this, obj);

    if (this.consumerCategory) {
      this.consumerCategory = new ConsumerCategory({
        ...this.consumerCategory
      });
    }

    if (this.prices) {
      this.prices = this.prices.map((price) => new Price({ ...price }));
    }
  }
}
