import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InformationBanner } from '../shared/models/information-banner';
import { Page } from '../shared/models/page';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class InformationBannerService
  extends RepositoryService
  implements PageableService<InformationBanner>, CrudService<InformationBanner>
{
  private URL_INFO_BANNER = 'api/backoffice/information-banner';

  constructor(http: HttpClient) {
    super(http);
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<InformationBanner>> {
    return this.get(
      this.URL_INFO_BANNER +
        `/all-banners?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map(
        (page: Page<InformationBanner>) =>
          new Page<InformationBanner>({ ...page }, InformationBanner)
      )
    );
  }

  upsert(banner: InformationBanner): Observable<void> {
    return this.post(this.URL_INFO_BANNER, banner);
  }

  deleteById(id: number): Observable<void> {
    return this.delete(this.URL_INFO_BANNER + `?id=${id}`);
  }
}
