import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { AtomicLibModule } from 'atomic-lib';
import { BaseChartDirective } from 'ng2-charts';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { AccommodationMenuComponent } from './accommodation/accommodation-menu/accommodation-menu.component';
import { AccommodationProductComponent } from './accommodation/accommodation-product/accommodation-product.component';
import { EstablishmentManagementComponent } from './accommodation/accommodation-product/establishment-management/establishment-management.component';
import { RoomManagementComponent } from './accommodation/accommodation-product/room-management/room-management.component';
import { AccommodationComponent } from './accommodation/accommodation.component';
import { AgencyComponent } from './accommodation/agency/agency.component';
import { EditAgencyComponent } from './accommodation/agency/edit-agency/edit-agency.component';
import { ListAgencyComponent } from './accommodation/agency/list-agency/list-agency.component';
import { ConfirmBookingComponent } from './accommodation/confirm-booking/confirm-booking.component';
import { SearchAvailabilityAccommodationComponent } from './accommodation/search-availability-accommodation/search-availability-accommodation.component';
import { ActivityMenuComponent } from './activity/activity-menu/activity-menu.component';
import { ActivityPartnerManagementComponent } from './activity/activity-partner-management/activity-partner-management.component';
import { EditActivityPartnerComponent } from './activity/activity-partner-management/edit-activity-partner/edit-activity-partner.component';
import { ListActivityPartnerComponent } from './activity/activity-partner-management/list-activity-partner/list-activity-partner.component';
import { ActivityComponent } from './activity/activity.component';
import { AgeRangeManagementComponent } from './activity/age-range-management/age-range-management.component';
import { EditAgeRangeComponent } from './activity/age-range-management/edit-age-range/edit-age-range.component';
import { ListAgeRangeComponent } from './activity/age-range-management/list-age-range/list-age-range.component';
import { EditExperienceGroupComponent } from './activity/experience-management/experience-group/edit-experience-group/edit-experience-group.component';
import { ExperienceGroupComponent } from './activity/experience-management/experience-group/experience-group.component';
import { ListExperienceGroupComponent } from './activity/experience-management/experience-group/list-experience-group/list-experience-group.component';
import { ExperienceManagementComponent } from './activity/experience-management/experience-management.component';
import { EditExperienceComponent } from './activity/experience-management/experience/edit-experience/edit-experience.component';
import { ExperienceComponent } from './activity/experience-management/experience/experience.component';
import { ListExperienceComponent } from './activity/experience-management/experience/list-experience/list-experience.component';
import { EditPackageComponent } from './activity/package-management/edit-package/edit-package.component';
import { GroupPackageComponent } from './activity/package-management/group-package/group-package.component';
import { ListPackageComponent } from './activity/package-management/list-package/list-package.component';
import { EditPackageGridComponent } from './activity/package-management/package-grid/edit-package-grid/edit-package-grid.component';
import { ListPackageGridComponent } from './activity/package-management/package-grid/list-package-grid/list-package-grid.component';
import { PackageGridComponent } from './activity/package-management/package-grid/package-grid.component';
import { PackageManagementComponent } from './activity/package-management/package-management.component';
import { AdminMenuComponent } from './admin/admin-menu/admin-menu.component';
import { AdminComponent } from './admin/admin.component';
import { CustomFieldComponent } from './admin/custom-field/custom-field.component';
import { EditCustomFieldComponent } from './admin/custom-field/edit-custom-field/edit-custom-field.component';
import { ListCustomFieldComponent } from './admin/custom-field/list-custom-field/list-custom-field.component';
import { CatalogPriceReductionComponent } from './admin/eliberty-catalog/catalog-price-reduction/catalog-price-reduction.component';
import { EditCatalogPriceReductionComponent } from './admin/eliberty-catalog/catalog-price-reduction/edit-catalog-price-reduction/edit-catalog-price-reduction.component';
import { ListCatalogPriceReductionComponent } from './admin/eliberty-catalog/catalog-price-reduction/list-catalog-price-reduction/list-catalog-price-reduction.component';
import { EditVariantComponent } from './admin/eliberty-catalog/edit-variant/edit-variant.component';
import { ElibertyCatalogComponent } from './admin/eliberty-catalog/eliberty-catalog.component';
import { ElibertyCredentialsComponent } from './admin/eliberty-catalog/eliberty-credentials/eliberty-credentials.component';
import { ListProductComponent } from './admin/eliberty-catalog/list-product/list-product.component';
import { ListVariantComponent } from './admin/eliberty-catalog/list-variant/list-variant.component';
import { EditProductPricePromotionComponent } from './admin/eliberty-catalog/product-price-promotion/edit-product-price-promotion/edit-product-price-promotion.component';
import { ListProductPricePromotionComponent } from './admin/eliberty-catalog/product-price-promotion/list-product-price-promotion/list-product-price-promotion.component';
import { ProductPricePromotionComponent } from './admin/eliberty-catalog/product-price-promotion/product-price-promotion.component';
import { EditActivityComponent } from './admin/generic-activity/edit-activity/edit-activity.component';
import { GenericActivityComponent } from './admin/generic-activity/generic-activity.component';
import { ListActivityComponent } from './admin/generic-activity/list-activity/list-activity.component';
import { EditCategoryComponent } from './admin/generic-category/edit-category/edit-category.component';
import { GenericCategoryComponent } from './admin/generic-category/generic-category.component';
import { ListCategoryComponent } from './admin/generic-category/list-category/list-category.component';
import { EditPartnerComponent } from './admin/generic-partner/edit-partner/edit-partner.component';
import { GenericPartnerComponent } from './admin/generic-partner/generic-partner.component';
import { ListPartnerComponent } from './admin/generic-partner/list-partner/list-partner.component';
import { ImportFileComponent } from './admin/import-file/import-file.component';
import { EditInformationBannerComponent } from './admin/information-banner/edit-information-banner/edit-information-banner.component';
import { InformationBannerComponent } from './admin/information-banner/information-banner.component';
import { ListInformationBannerComponent } from './admin/information-banner/list-information-banner/list-information-banner.component';
import { EditPreMadeCartComponent } from './admin/premade-cart/edit-pre-made-cart/edit-pre-made-cart.component';
import { ListPreMadeCartComponent } from './admin/premade-cart/list-pre-made-cart/list-pre-made-cart.component';
import { PreMadeCartComponent } from './admin/premade-cart/pre-made-cart.component';
import { RefusedReservationsComponent } from './admin/refused-reservations/refused-reservations.component';
import { EditResortLabelComponent } from './admin/resort-label/edit-resort-label/edit-resort-label.component';
import { ListResortLabelComponent } from './admin/resort-label/list-resort-label/list-resort-label.component';
import { ResortLabelComponent } from './admin/resort-label/resort-label.component';
import { CreateResortComponent } from './admin/resort/create-resort.component';
import { EditResortComponent } from './admin/resort/edit-resort/edit-resort.component';
import { ListResortComponent } from './admin/resort/list-resort/list-resort.component';
import { SkisetOrdersComponent } from './admin/skiset-orders/skiset-orders.component';
import { EditTagComponent } from './admin/tag/edit-tag/edit-tag.component';
import { ListTagComponent } from './admin/tag/list-tag/list-tag.component';
import { TagComponent } from './admin/tag/tag.component';
import { EditUsersComponent } from './admin/users/edit-users/edit-users.component';
import { ListUsersComponent } from './admin/users/list-users/list-users.component';
import { UsersComponent } from './admin/users/users.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppState } from './app.state';
import { CalendarPartnerModule } from './calendar-partner/calendar-partner.module';
import { CarouselProductComponent } from './cms/carousel-product/carousel-product.component';
import { EditCarouselProductComponent } from './cms/carousel-product/edit-carousel-product/edit-carousel-product.component';
import { ListCarouselProductComponent } from './cms/carousel-product/list-carousel-product/list-carousel-product.component';
import { CarouselZoneComponent } from './cms/carousel-zone/carousel-zone.component';
import { EditCarouselZoneComponent } from './cms/carousel-zone/edit-carousel-zone/edit-carousel-zone.component';
import { ListCarouselZoneComponent } from './cms/carousel-zone/list-carousel-zone/list-carousel-zone.component';
import { CarouselComponent } from './cms/carousel/carousel.component';
import { EditCarouselComponent } from './cms/carousel/edit-carousel/edit-carousel.component';
import { ListCarouselComponent } from './cms/carousel/list-carousel/list-carousel.component';
import { CmsMenuComponent } from './cms/cms-menu/cms-menu.component';
import { CmsComponent } from './cms/cms.component';
import { EditTextBlockComponent } from './cms/text-block/edit-text-block/edit-text-block.component';
import { ListTextBlockComponent } from './cms/text-block/list-text-block/list-text-block.component';
import { TextBlockComponent } from './cms/text-block/text-block.component';
import { CustomersManagementModule } from './customers-management/customers-management.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { InvoicesMenuComponent } from './invoices/invoices-menu/invoices-menu.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { ListInvoicesComponent } from './invoices/my-invoices/list-invoices/list-invoices.component';
import { MyInvoicesComponent } from './invoices/my-invoices/my-invoices.component';
import { LoginComponent } from './login/login.component';
import { SelectPartnerComponent } from './login/select-partner/select-partner.component';
import { MarketingMenuComponent } from './marketing/marketing-menu/marketing-menu.component';
import { MarketingComponent } from './marketing/marketing.component';
import { EditPromoCodeComponent } from './marketing/promo-code/edit-promo-code/edit-promo-code.component';
import { ListPromoCodeComponent } from './marketing/promo-code/list-promo-code/list-promo-code.component';
import { PromoCodeComponent } from './marketing/promo-code/promo-code.component';
import { CreateOrderComponent } from './marketing/sales-report/create-order/create-order.component';
import { EditSalesComponent } from './marketing/sales-report/edit-sales/edit-sales.component';
import { ListSalesComponent } from './marketing/sales-report/list-sales/list-sales.component';
import { SalesReportComponent } from './marketing/sales-report/sales-report.component';
import { MyInformationsComponent } from './my-informations/my-informations.component';
import { MyPendingReservationDetailComponent } from './my-reservations/my-pending-reservations-list/my-pending-reservation-detail/my-pending-reservation-detail.component';
import { MyPendingReservationsListComponent } from './my-reservations/my-pending-reservations-list/my-pending-reservations-list.component';
import { RefuseReservationComponent } from './my-reservations/my-pending-reservations-list/refuse-reservation/refuse-reservation.component';
import { ReprogramTimeSlotComponent } from './my-reservations/my-pending-reservations-list/reprogram-time-slot/reprogram-time-slot.component';
import { MyReservationsComponent } from './my-reservations/my-reservations.component';
import { ReservationsValidatedListComponent } from './my-reservations/reservations-validated-list/reservations-validated-list.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { SharedModule } from './shared/shared.module';
import { ShowActivitiesComponent } from './show-activities/show-activities.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { authInterceptorProviders } from './utils/auth.interceptor';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    SidemenuComponent,
    LoginComponent,
    HomeComponent,
    SelectPartnerComponent,
    ActivityComponent,
    ActivityPartnerManagementComponent,
    ExperienceManagementComponent,
    ListActivityPartnerComponent,
    ListExperienceComponent,
    AdminComponent,
    GenericActivityComponent,
    EditActivityComponent,
    ListActivityComponent,
    AgeRangeManagementComponent,
    AdminMenuComponent,
    PackageManagementComponent,
    ActivityMenuComponent,
    EditExperienceComponent,
    EditActivityPartnerComponent,
    EditAgeRangeComponent,
    ListAgeRangeComponent,
    EditPackageComponent,
    ListPackageComponent,
    EditActivityComponent,
    ListActivityComponent,
    CreateResortComponent,
    EditResortComponent,
    ListResortComponent,
    GenericPartnerComponent,
    ListPartnerComponent,
    EditPartnerComponent,
    GenericCategoryComponent,
    ListCategoryComponent,
    EditCategoryComponent,
    CustomFieldComponent,
    EditCustomFieldComponent,
    ListCustomFieldComponent,
    MyReservationsComponent,
    MyPendingReservationsListComponent,
    ReprogramTimeSlotComponent,
    ReservationsValidatedListComponent,
    OnboardingComponent,
    ForgotPasswordComponent,
    MyInformationsComponent,
    ForgotPasswordComponent,
    ShowActivitiesComponent,
    AccommodationProductComponent,
    EstablishmentManagementComponent,
    RoomManagementComponent,
    MyPendingReservationDetailComponent,
    TagComponent,
    EditTagComponent,
    ListTagComponent,
    SearchAvailabilityAccommodationComponent,
    RefusedReservationsComponent,
    SearchAvailabilityAccommodationComponent,
    RefuseReservationComponent,
    GroupPackageComponent,
    ImportFileComponent,
    PreMadeCartComponent,
    SkisetOrdersComponent,
    ResortLabelComponent,
    EditResortLabelComponent,
    ListResortLabelComponent,
    InformationBannerComponent,
    EditInformationBannerComponent,
    ListInformationBannerComponent,
    EditPreMadeCartComponent,
    ListPreMadeCartComponent,
    MarketingComponent,
    MarketingMenuComponent,
    PromoCodeComponent,
    ListPromoCodeComponent,
    EditPromoCodeComponent,
    EditExperienceGroupComponent,
    ListExperienceGroupComponent,
    ExperienceComponent,
    ExperienceGroupComponent,
    PackageGridComponent,
    EditPackageGridComponent,
    ListPackageGridComponent,
    SalesReportComponent,
    ListSalesComponent,
    InvoicesComponent,
    MyInvoicesComponent,
    InvoicesMenuComponent,
    ListInvoicesComponent,
    EditSalesComponent,
    AccommodationMenuComponent,
    AccommodationComponent,
    AgencyComponent,
    EditAgencyComponent,
    ListAgencyComponent,
    ElibertyCatalogComponent,
    ListProductComponent,
    ListVariantComponent,
    EditVariantComponent,
    CatalogPriceReductionComponent,
    ListCatalogPriceReductionComponent,
    EditCatalogPriceReductionComponent,
    ProductPricePromotionComponent,
    EditProductPricePromotionComponent,
    ListProductPricePromotionComponent,
    CmsComponent,
    CmsMenuComponent,
    TextBlockComponent,
    ListTextBlockComponent,
    EditTextBlockComponent,
    UsersComponent,
    ListUsersComponent,
    EditUsersComponent,
    ConfirmBookingComponent,
    CreateOrderComponent,
    CarouselProductComponent,
    ListCarouselProductComponent,
    EditCarouselProductComponent,
    CarouselComponent,
    ListCarouselComponent,
    EditCarouselComponent,
    CarouselZoneComponent,
    ListCarouselZoneComponent,
    EditCarouselZoneComponent
  ],
  imports: [
    NgxPaginationModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxsModule.forRoot([AppState]),
    CalendarPartnerModule,
    CustomersManagementModule,
    NgxMaskDirective,
    NgxMaskPipe,
    AtomicLibModule,
    ColorPickerModule,
    BaseChartDirective,
    ElibertyCredentialsComponent
  ],
  providers: [
    authInterceptorProviders,
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    provideNgxMask()
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
