import { Component } from '@angular/core';
import { PromoCodeService } from '../../service/promo-code.service';
import { PageComponent } from '../../shared/component/page.component';
import { PromoCode } from '../../shared/models/promo-code';

@Component({
  selector: 'dash-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent extends PageComponent<PromoCode> {
  constructor(protected service: PromoCodeService) {
    super(service);
  }

  delete() {}
}
