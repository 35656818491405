import { UntypedFormGroup } from '@angular/forms';

export class FormUtils {
  public static findInvalidControls(formGroup: UntypedFormGroup): string[] {
    const invalid = [];
    const controls = formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
}
