import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccommodationEstablishment } from '../shared/models/accomodation/accommodation-establishment';
import { AccommodationEstablishmentRoom } from '../shared/models/accomodation/accommodation-establishment-room';
import { AccommodationPartner } from '../shared/models/accomodation/accommodation-partner';
import { AccommodationRoom } from '../shared/models/accomodation/accommodation-room';
import { AccommodationRoomPackage } from '../shared/models/accomodation/accommodation-room-package';
import { Picture } from '../shared/models/accomodation/picture';
import { RoomFeature } from '../shared/models/accomodation/room-feature';
import { SearchRoom } from '../shared/models/accomodation/search-room';
import { Page } from '../shared/models/page';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class AccommodationService extends RepositoryService {
  private URL_ACCOMMODATION = 'api/backoffice/accommodation';

  constructor(http: HttpClient) {
    super(http);
  }

  getAllPartners(): Observable<AccommodationPartner[]> {
    return this.get(this.URL_ACCOMMODATION + '/get-all-partners');
  }

  getAllEstablishmentsByPartnerCode(
    partnerCode: string
  ): Observable<AccommodationEstablishment[]> {
    return this.get(
      this.URL_ACCOMMODATION +
        '/get-all-establishments-by-partner-code?partnerCode=' +
        partnerCode
    );
  }

  getEstablishmentsByPartnerCode(
    partnerCode: string,
    searchName: string,
    currentPage: number,
    pageSize: number
  ): Observable<Page<AccommodationEstablishment>> {
    return this.get(
      this.URL_ACCOMMODATION +
        `/get-establishments-by-partner-code?partnerCode=${partnerCode}&search=${searchName}&page=${currentPage}&size=${pageSize}`
    ).pipe(
      map(
        (page: Page<AccommodationEstablishment>) =>
          new Page<AccommodationEstablishment>(
            { ...page },
            AccommodationEstablishment
          )
      )
    );
  }

  upsertEstablishment(
    establishment: AccommodationEstablishment
  ): Observable<number> {
    return this.post(
      this.URL_ACCOMMODATION + '/upsert-establishment',
      establishment
    );
  }

  getRoomsByPartnerCodeAndEstablishmentId(
    partnerCode: string,
    establishmentId: number,
    currentPage: number,
    pageSize: number
  ): Observable<Page<AccommodationRoom>> {
    return this.get(
      this.URL_ACCOMMODATION +
        `/get-rooms-by-partner-code-and-establishment-id?partnerCode=${partnerCode}&establishmentId=${establishmentId}&page=${currentPage}&size=${pageSize}`
    );
  }

  upsertRoom(accommodationRoom: AccommodationRoom): Observable<string> {
    return this.post(
      this.URL_ACCOMMODATION + '/upsert-room',
      accommodationRoom,
      'text'
    );
  }

  upsertEstablishmentPictures(
    establishmentId: number,
    partner: string,
    allPictures: Picture[]
  ) {
    return this.post(
      this.URL_ACCOMMODATION + `/upsert-establishment-pictures`,
      {
        establishmentId,
        partnerCode: partner,
        pictureEntities: allPictures
      }
    );
  }

  upsertRoomPictures(
    roomCode: string,
    establishmentCode: number,
    partner: string,
    allPictures: string[]
  ) {
    return this.post(this.URL_ACCOMMODATION + `/upsert-room-pictures`, {
      roomCode,
      establishmentCode,
      partnerCode: partner,
      pictureEntities: allPictures
    });
  }

  getProposalsByDates(
    searchRoom: SearchRoom,
    currentPage: number,
    pageSize: number
  ): Observable<Page<AccommodationRoomPackage>> {
    return this.post(
      this.URL_ACCOMMODATION +
        `/get-proposals-by-dates-and-partner-code?page=${currentPage}&size=${pageSize}`,
      searchRoom
    ).pipe(
      map((page: Page<AccommodationRoomPackage>) => {
        return new Page<AccommodationRoomPackage>({
          ...page,
          content: page.content.map(
            (roomPackage) => new AccommodationRoomPackage({ ...roomPackage })
          )
        });
      })
    );
  }

  getAllRoomFeatures(): Observable<RoomFeature[]> {
    return this.get(this.URL_ACCOMMODATION + '/get-all-room-features');
  }

  bookRoom(
    userId: number,
    sessionId: string,
    createOrder: boolean
  ): Observable<AccommodationPartner[]> {
    return this.get(
      this.URL_ACCOMMODATION +
        `/confirm-booking-by-session-id?sessionId=${sessionId}&userId=${userId}&createOrder=${createOrder}`
    );
  }

  getEstablishmentsByStation(
    resortId: number | null,
    startDate: string | null,
    endDate: string | null
  ): Observable<AccommodationEstablishmentRoom[]> {
    return this.get(
      this.URL_ACCOMMODATION +
        `/get-establishments-by-station?resortId=${resortId}&startDate=${startDate}&endDate=${endDate}`
    ).pipe(
      map((establishments: AccommodationEstablishmentRoom[]) =>
        establishments.map(
          (establishment: AccommodationEstablishmentRoom) =>
            new AccommodationEstablishmentRoom({ ...establishment })
        )
      )
    );
  }
}
