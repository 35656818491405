<div class="expand">
  <img alt="VeryDashboard" src="assets/images/logo-blanc.png " />

  <div class="separation"></div>
  <div class="vsk__column">
    <div class="vsk__scroll-auto vsk__flex overflow-x-hidden">
      <a *ngIf="containsRoles([Role.ROLE_PARTNER, Role.ROLE_COMMERCIAL])"
         [ngClass]="{'vsk__selected': page === 'calendar'}"
         class="vsk__button-link"
         id="id__link-home-planning"
         routerLink="/calendar"
         routerLinkActive="true">
        <svg class="max-h-6 min-h-6 w-6 stroke-white">
          <use [attr.xlink:href]="'assets/icons/icons.svg#calendar'"></use>
        </svg>
        <label>Planning</label>
      </a>
      <a *ngIf="containsRoles([Role.ROLE_PARTNER, Role.ROLE_COMMERCIAL])"
         [ngClass]="{'vsk__selected': page === 'reservations'}"
         class="vsk__button-link"
         id="id__link-home-my-reservations"
         routerLink="/reservations"
         routerLinkActive="true">
        <svg class="max-h-6 min-h-6 w-6 stroke-white">
          <use [attr.xlink:href]="'assets/icons/icons.svg#ticket'"></use>
        </svg>
        <label>Réservations</label>
      </a>

      <a *ngIf="containsRoles([Role.ROLE_PARTNER, Role.ROLE_COMMERCIAL])"
         [ngClass]="{'vsk__selected': page === 'activity'}"
         class="vsk__button-link"
         id="id__link-home-activity"
         routerLink="/activity/activity-partner"
         routerLinkActive="true">
        <svg class="max-h-6 min-h-6 w-6 stroke-white">
          <use [attr.xlink:href]="'assets/icons/icons.svg#activity'"></use>
        </svg>
        <label>Activités</label>
      </a>

      <a *ngIf="containsRoles([Role.ROLE_PARTNER, Role.ROLE_COMMERCIAL])"
         [ngClass]="{'vsk__selected': page === 'my-informations'}"
         class="vsk__button-link"
         id="id__link-home-my-informations"
         routerLink="/my-informations"
         routerLinkActive="true">
        <svg class="max-h-6 min-h-6 w-6 stroke-white">
          <use [attr.xlink:href]="'assets/icons/icons.svg#list'"></use>
        </svg>
        <label>Mes infos</label>
      </a>

      <a *ngIf="containsRoles([Role.ROLE_PARTNER, Role.ROLE_COMMERCIAL])"
         [ngClass]="{'vsk__selected': page === 'invoices'}"
         class="vsk__button-link"
         id="id__link-dash-invoices"
         routerLink="/invoices/my-invoices"
         routerLinkActive="true">
        <svg class="max-h-6 min-h-6 w-6 stroke-white">
          <use [attr.xlink:href]="'assets/icons/icons.svg#document'"></use>
        </svg>
        <label>Facturation</label>
      </a>

      <a *ngIf="containsRoles([Role.ROLE_ADMIN, Role.ROLE_COMMERCIAL, Role.ROLE_MARKETING])"
         [ngClass]="{'vsk__selected': page === 'admin'}"
         class="vsk__button-link"
         id="id__link-home-admin"
         routerLink="/admin/partner"
         routerLinkActive="true">
        <svg class="max-h-6 min-h-6 w-6 stroke-white">
          <use [attr.xlink:href]="'assets/icons/icons.svg#identity'"></use>
        </svg>
        <label>Admin</label>
      </a>

      <a *ngIf="containsRoles([Role.ROLE_ADMIN, Role.ROLE_MARKETING])"
         [ngClass]="{'vsk__selected': page === 'marketing'}"
         class="vsk__button-link"
         id="id__link-home-marketing"
         routerLink="/marketing/sales-report"
         routerLinkActive="true">
        <svg class="max-h-6 min-h-6 w-6 stroke-white">
          <use [attr.xlink:href]="'assets/icons/icons.svg#chart-line'"></use>
        </svg>
        <label>Marketing</label>
      </a>

      <a *ngIf="containsRoles([Role.ROLE_ADMIN, Role.ROLE_MARKETING])"
         [ngClass]="{'vsk__selected': page === 'cms'}"
         class="vsk__button-link"
         id="id__link-home-cms"
         routerLink="/cms/text-block"
         routerLinkActive="true">
        <svg class="max-h-6 min-h-6 w-6 stroke-white">
          <use [attr.xlink:href]="'assets/icons/icons.svg#picture'"></use>
        </svg>
        <label>CMS</label>
      </a>

      <a *ngIf="containsRoles([Role.ROLE_ADMIN])"
         [ngClass]="{'vsk__selected': page === 'accommodation'}"
         class="vsk__button-link"
         id="id__link-home-accommodation"
         routerLink="/accommodation/manage-products"
         routerLinkActive="true">
        <svg class="max-h-6 min-h-6 w-6 stroke-white">
          <use [attr.xlink:href]="'assets/icons/icons.svg#office'"></use>
        </svg>
        <label>Hébergement</label>
      </a>
    </div>

    <div>
      <a (click)="logout()"
         class="vsk__button-link vsk__deconnect"
         href="#"
         id="id__link-home-sign-out">
        <svg class="max-h-6 min-h-6 w-6 stroke-white">
          <use [attr.xlink:href]="'assets/icons/icons.svg#logout'"></use>
        </svg>
        <label>Déconnexion</label>
      </a>
    </div>
  </div>
</div>

