import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegisterCustomerTimeSlot } from '../shared/models/register-customer-time-slot';
import { RegisterTimeSlot } from '../shared/models/register-time-slot';
import { TimeSlot } from '../shared/models/time-slot/time-slot';
import { TimeSlotModel } from '../shared/models/time-slot/time-slot-model';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class TimeSlotService extends RepositoryService {
  private URL_TIME_SLOT = 'api/backoffice/time-slot';

  constructor(http: HttpClient) {
    super(http);
  }

  createTimeSlotModel(
    partnerId: number,
    timeSlotModel: TimeSlotModel
  ): Observable<void> {
    return this.post(
      this.URL_TIME_SLOT + '/create-time-slot-model?partnerId=' + partnerId,
      timeSlotModel
    );
  }

  createTimeSlot(partnerId: number, timeSlot: TimeSlot): Observable<TimeSlot> {
    return this.post(
      this.URL_TIME_SLOT + '/create-time-slot?partnerId=' + partnerId,
      timeSlot
    ).pipe(
      map((id) => {
        timeSlot.id = id;
        return timeSlot;
      })
    );
  }

  update(
    partnerId: number,
    timeSlotModel: TimeSlotModel | TimeSlot,
    all = false
  ): Observable<void> {
    return this.put(
      this.URL_TIME_SLOT + '/update?partnerId=' + partnerId + '&all=' + all,
      timeSlotModel
    );
  }

  getAllBetweenDates(
    partnerId: number,
    dateStart: Moment,
    dateEnd: Moment,
    filteredExperiences: number[]
  ): Observable<TimeSlot[]> {
    return this.get(
      this.URL_TIME_SLOT +
        '/get-slots-between-dates?partnerId=' +
        partnerId +
        '&start=' +
        dateStart.format('YYYY-MM-DD') +
        '&end=' +
        dateEnd.format('YYYY-MM-DD') +
        '&filteredExperiences=' +
        filteredExperiences +
        '&onlyPayed=' +
        true
    ).pipe(
      map((timeSlots) =>
        timeSlots.map((timeSlot: TimeSlot) => new TimeSlot({ ...timeSlot }))
      )
    );
  }

  timeSlotsByExperienceName(
    partnerId: number,
    timeSlotId: number,
    registerTimeSlotId: number,
    experienceId: number,
    sessionId: string
  ): Observable<TimeSlot[]> {
    return this.get(
      this.URL_TIME_SLOT +
        '/time-slots-by-experience-id?partnerId=' +
        partnerId +
        '&timeSlotId=' +
        timeSlotId +
        '&registerTimeSlotId=' +
        registerTimeSlotId +
        '&experienceId=' +
        experienceId +
        '&sessionId=' +
        sessionId
    );
  }

  timeSlotExist(
    partnerId: number,
    date: Moment,
    timeSlotModelId: number
  ): Observable<boolean> {
    return this.get(
      this.URL_TIME_SLOT +
        '/time-slot-exist?partnerId=' +
        partnerId +
        '&date=' +
        date.format('YYYY-MM-DD') +
        '&timeSlotModelId=' +
        timeSlotModelId
    );
  }

  deleteTimeSlot(
    partnerId: number,
    timeSlotModel: TimeSlotModel | TimeSlot,
    all: boolean
  ): Observable<void> {
    return this.delete(
      this.URL_TIME_SLOT + '/delete?partnerId=' + partnerId + '&all=' + all,
      timeSlotModel
    );
  }

  register(
    partnerId: number,
    registerCustomerTimeSlot: RegisterCustomerTimeSlot
  ): Observable<RegisterTimeSlot[]> {
    return this.post(
      this.URL_TIME_SLOT + '/register?partnerId=' + partnerId,
      registerCustomerTimeSlot
    ).pipe(
      map((registerTimeSlots) =>
        registerTimeSlots.map(
          (registerTimeSlot: RegisterTimeSlot) =>
            new RegisterTimeSlot(registerTimeSlot)
        )
      )
    );
  }

  updateRegister(
    partnerId: number,
    registerTimeSlot: RegisterTimeSlot,
    timeSlotId: number
  ): Observable<RegisterTimeSlot[]> {
    return this.post(
      this.URL_TIME_SLOT +
        '/update-register?partnerId=' +
        partnerId +
        '&timeSlotId=' +
        timeSlotId,
      registerTimeSlot
    ).pipe(
      map((registerTimeSlots) =>
        registerTimeSlots.map(
          (register: RegisterTimeSlot) => new RegisterTimeSlot(register)
        )
      )
    );
  }

  unregister(
    partnerId: number,
    timeSlotId: number,
    registerId: number
  ): Observable<RegisterTimeSlot[]> {
    return this.post(
      this.URL_TIME_SLOT +
        '/unregister?partnerId=' +
        partnerId +
        '&timeSlotId=' +
        timeSlotId +
        '&registerId=' +
        registerId,
      {}
    ).pipe(
      map((registerTimeSlots) =>
        registerTimeSlots.map(
          (registerTimeSlot: RegisterTimeSlot) =>
            new RegisterTimeSlot(registerTimeSlot)
        )
      )
    );
  }
}
