<div class="vsk__column">
  <dash-list-tag [page]="page"
                 [searchForm]="searchForm"
                 (pageChanged)="fetchPageable($event, searchForm.value)"
                 (createChanged)="showEdit()"
                 (isChanged)="edit($event)">
  </dash-list-tag>
  <dash-edit-tag *ngIf="showEditor"
                 [tagToEdit]="toEdit"
                 (editionComplete)="editCompleted()">
  </dash-edit-tag>
</div>
