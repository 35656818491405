import moment, { Moment } from 'moment';
import { AccommodationEstablishment } from './accommodation-establishment';
import { AccommodationPartner } from './accommodation-partner';
import { AccommodationRoom } from './accommodation-room';

export class AccommodationRoomPackage {
  establishment: AccommodationEstablishment;
  room: AccommodationRoom;
  partner: AccommodationPartner;
  startDate: Moment;
  endDate: Moment;
  publicPrice: number;
  price: number;
  available: boolean;

  constructor(obj: Partial<AccommodationRoomPackage>) {
    Object.assign(this, obj);

    if (this.startDate) {
      this.startDate = moment(this.startDate, 'YYYY-MM-DD');
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate, 'YYYY-MM-DD');
    }

    if (this.establishment) {
      this.establishment = new AccommodationEstablishment({
        ...this.establishment
      });
    }

    if (this.room) {
      this.room = new AccommodationRoom({ ...this.room });
    }

    if (this.partner) {
      this.partner = new AccommodationPartner({ ...this.partner });
    }
  }
}
