import { deburr } from 'lodash-es';

export class StringUtils {
  static formatString(name: string | null): string {
    if (!name) {
      return '';
    }
    return deburr(
      name
        .toLowerCase()
        .replace(/â/g, 'a')
        .replace(/é/g, 'e')
        .replace(/è/g, 'e')
        .replace(/ê/g, 'e')
        .replace(/’/g, '-')
        .replace(/'/g, '-')
        .replace(/\//g, '-')
        .replace(new RegExp(' ', 'g'), '-')
    );
  }
}
