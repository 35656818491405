<atc-side-drawer (closeChange)="packageChanged.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading">
  <div class="w-full my-4" header>
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification tarif' : 'Nouveau tarif' }}</h1>
  </div>

  <div body class="vsk__body">
    <div class="flex flex-row gap-x-4">
      <div class="flex flex-col gap-y-4 flex-1">
        <h3>Expérience</h3>
        <atc-select [control]="experienceForm"
                    [optionElements]="experienceOptions"
                    class="flex-1"
                    label="Expérience*">
        </atc-select>
      </div>

      <div class="flex flex-col gap-y-4 flex-1">
        <h3>Grille de tarif</h3>
        <atc-select [control]="packageGridForm"
                    [optionElements]="packageGridElements"
                    class="flex-1">
        </atc-select>
      </div>
    </div>

    <div class="flex flex-col gap-y-4 flex-1">
      <h3>Tranche d'âge</h3>
      <atc-select [control]="ageRangeForm"
                  [optionElements]="ageRangeExperiences"
                  class="flex-1"
                  label="Tranche d'âge*">
      </atc-select>
    </div>

    <ng-container *ngIf="prices.length">
      <h3>Variation de prix</h3>

      <p *ngIf="hasErrors" class="italic text-red-700 font-bold">
        Il semble que plusieurs prix sont applicables pour une même période de dates
      </p>

      <table>
        <thead>
        <tr>
          <th>Min-Max pers.</th>
          <th>Période</th>
          <th>Prix Net</th>
          <th>Prix Public</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let price of prices; let i = index">
          <td>De {{ price.nbMin }} à {{ price.nbMax }} pers.</td>
          <td>{{ price.startDate.format('DD/MM/YYYY') }} - {{ price.endDate.format('DD/MM/YYYY') }}</td>
          <td>{{ price.price | number : '1.2-2':'fr' }} €</td>
          <td>{{ price.publicPrice | number : '1.2-2':'fr' }} €</td>
          <td>
            <atc-button
              (click)="editPrice(price, i)"
              label="Éditer"
              size="small">
            </atc-button>
          </td>
          <td>
            <atc-button (click)="deletePrice(i)"
                        color="danger"
                        label="Supprimer"
                        size="small">
            </atc-button>
          </td>
        </tr>
        </tbody>
      </table>
    </ng-container>

    <h3>Ajout d'un tarif · prix par personne en fonction de la taille du groupe</h3>

    <div class="flex flex-col gap-y-4">
      <div class="flex flex-row gap-x-2">
        <atc-input [control]="nbMinForm"
                   errorMessage="Champs obligatoire"
                   icon="person"
                   label="Min. personne"
                   placeholder="1"
                   type="number"></atc-input>

        <atc-input [control]="nbMaxForm"
                   errorMessage="Champs obligatoire"
                   icon="person"
                   label="Max. personne"
                   placeholder="5"
                   type="number"></atc-input>
      </div>

      <div class="flex flex-row gap-x-2">
        <atc-input-date [control]="startDateForm"
                        class="flex-1"
                        label="Date de début">
        </atc-input-date>

        <atc-input-date [control]="endDateForm"
                        class="flex-1"
                        label="Date de fin">
        </atc-input-date>
      </div>

      <div class="flex flex-row gap-x-2">
        <atc-input [allowNegativeNumbers]="false"
                   [control]="priceForm"
                   [dropSpecialCharacters]="false"
                   [specialCharacters]="[',', '.']"
                   errorMessage="Le prix est obligatoire"
                   icon="credit-card"
                   label="Prix par personne"
                   mask="separator.2"
                   placeholder="15,50 €"
                   suffix=" €"></atc-input>

        <atc-input [allowNegativeNumbers]="false"
                   [control]="publicPriceForm"
                   [dropSpecialCharacters]="false"
                   [specialCharacters]="[',', '.']"
                   errorMessage="Le prix est obligatoire"
                   icon="credit-card"
                   label="Prix public par personne"
                   mask="separator.2"
                   placeholder="15,50 €"
                   suffix=" €"></atc-input>
      </div>

      <atc-button (click)="addPrice()"
                  [disabled]="priceFormInvalid()"
                  class="flex flex-row justify-end"
                  label="Valider"></atc-button>
    </div>
  </div>

  <div class="flex flex-row justify-between w-full items-center pt-4 footer" footer>
    <atc-button (click)="delete()" *ngIf="editMode === 'edit'" color="danger" label="Supprimer"></atc-button>
    <div class="vsk__flex"></div>
    <atc-button (click)="upsert()"
                [disabled]="!isFormValid()"

                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                id="id__button-validate-form">
    </atc-button>
  </div>
</atc-side-drawer>
