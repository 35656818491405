import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import { debounceTime } from 'rxjs/operators';
import { OrderService } from 'src/app/service/order.service';
import { PageableComponent } from 'src/app/shared/component/pageable.component';
import { Order } from 'src/app/shared/models/order/order';
import { Page } from 'src/app/shared/models/page';

// Enregistrement des contrôleurs et éléments nécessaires
Chart.register(...registerables);

export type OrderType = 'all' | 'past' | 'upcoming' | 'not-paid';

export interface FilterOption {
  label: string;
  value: OrderType;
}

@Component({
  selector: 'dash-list-sales',
  templateUrl: './list-sales.component.html',
  styleUrls: ['./list-sales.component.scss']
})
export class ListSalesComponent
  extends PageableComponent<Order>
  implements OnInit, OnChanges
{
  @Input() page: Page<Order> = new Page<Order>({});
  orders: Order[] = [];
  filteredOrders: Order[] = [];
  totalElements = 0;
  currentPage = 0;
  pageSize = 10;
  headers = ['Id', 'Date de commande', 'Station', 'Séjour', 'Client', 'Prix'];
  filterOptions: FilterOption[] = [
    { label: 'Toutes', value: 'all' },
    { label: 'Passées', value: 'past' },
    { label: 'À venir', value: 'upcoming' },
    { label: 'Paniers abandonnés', value: 'not-paid' }
  ];

  selectedFilter: OrderType = 'all';

  totalVolumeCurrentMonth = 0;
  chartVolumeCurrentMonth: ChartData<'line'>;

  totalNotPaidVolumeCurrentMonth = 0;
  chartNotPaidVolumeCurrentMonth: ChartData<'line'>;

  lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top'
      }
    }
  };

  constructor(private orderService: OrderService) {
    super();

    this.orderService.getCurrentMonthData().subscribe((orders) => {
      this.chartVolumeCurrentMonth = {
        labels: orders.map((data) => data.date.format('YYYY-MM-DD')),
        datasets: [
          {
            data: orders.map((data) => data.price),
            label: 'Prix',
            fill: true
          }
        ]
      };

      this.totalVolumeCurrentMonth = orders
        .map((data) => data.price)
        .reduce((prev, curr) => prev + curr);
    });

    this.orderService.getCurrentMonthData(false).subscribe((orders) => {
      this.chartNotPaidVolumeCurrentMonth = {
        labels: orders.map((data) => data.date.format('YYYY-MM-DD')),
        datasets: [
          {
            data: orders.map((data) => data.price),
            label: 'Prix',
            fill: true
          }
        ]
      };

      this.totalNotPaidVolumeCurrentMonth = orders
        .map((data) => data.price)
        .reduce((prev, curr) => prev + curr);
    });
  }

  ngOnInit(): void {
    this.searchForm = new FormControl();
    this.searchForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.page.pageable.pageNumber = 0;
      this.fetchFilteredOrders(this.selectedFilter);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['page']) {
      this.filteredOrders = this.page.content;
      this.totalElements = this.page.totalElements;
      this.currentPage = this.page.number;
      this.fetchFilteredOrders(this.selectedFilter);
    }
  }

  onTableDataChange(event: number) {
    this.page.pageable.pageNumber = event - 1;
    this.fetchFilteredOrders(this.selectedFilter);
  }

  fetchFilteredOrders(filter: OrderType) {
    this.orderService
      .getPageable(
        this.page.pageable.pageNumber,
        this.pageSize,
        this.searchForm.value || '',
        filter
      )
      .subscribe((page) => {
        this.page = page;
        this.filteredOrders = page.content;
        this.currentPage = page.number;
      });
  }

  onFilterSelected(filterValue: OrderType) {
    this.page.pageable.pageNumber = 0;
    this.selectedFilter = filterValue;
    this.fetchFilteredOrders(filterValue);
  }
}
