import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import moment from 'moment/moment';
import { PromoCodeService } from '../../../service/promo-code.service';
import { EditComponent } from '../../../shared/component/edit.component';
import { TypePromoEnum } from '../../../shared/models/enum/type-promo.enum';
import { PromoCode } from '../../../shared/models/promo-code';

export interface CreatePromoCodeForm {
  name: FormControl<string | null>;
  typePromo: FormControl<TypePromoEnum | null>;
  applyNumber: FormControl<number | null>;
  usedLimit: FormControl<number | null>;
  minPrice: FormControl<number | null>;
  dateValidityStart: FormControl<string | null>;
  dateValidityEnd: FormControl<string | null>;
}

@Component({
  selector: 'dash-edit-promo-code',
  templateUrl: './edit-promo-code.component.html',
  styleUrls: ['./edit-promo-code.component.scss']
})
export class EditPromoCodeComponent extends EditComponent<PromoCode> {
  typeElements: OptionElement<TypePromoEnum>[] = [
    {
      id: TypePromoEnum.REDUCTION,
      label: 'Réduction (%)'
    },
    {
      id: TypePromoEnum.VOUCHER,
      label: "Bon d'achat"
    }
  ];

  createPromoCodeForm: FormGroup<CreatePromoCodeForm>;
  nameForm = new FormControl<string>('', Validators.required);
  typeForm = new FormControl<TypePromoEnum>(
    TypePromoEnum.REDUCTION,
    Validators.required
  );
  applyNumberForm = new FormControl<number | null>(null, Validators.required);
  usedLimitForm = new FormControl<number | null>(100, Validators.required);
  minPriceForm = new FormControl<number | null>(100, Validators.required);
  validityStartDateForm = new FormControl<string | null>(
    null,
    Validators.required
  );
  validityEndDateForm = new FormControl<string | null>(
    null,
    Validators.required
  );

  constructor(
    protected service: PromoCodeService,
    protected store: Store
  ) {
    super(service, store);
    this.name = 'Code promo';
  }

  @Input() set entityToEdit(entity: PromoCode | undefined) {
    if (entity) {
      this.id = entity.id;
      this.nameForm.setValue(entity.name);
      this.typeForm.setValue(entity.typePromo);
      this.applyNumberForm.setValue(entity.applyNumber);
      this.usedLimitForm.setValue(entity.usedLimit);
      this.minPriceForm.setValue(entity.minPrice);
      this.validityStartDateForm.setValue(
        entity.dateValidityStart.format('DD/MM/YYYY')
      );
      this.validityEndDateForm.setValue(
        entity.dateValidityEnd.format('DD/MM/YYYY')
      );
      this.editMode = 'edit';
    }
  }

  public isFormValid(): boolean {
    return this.createPromoCodeForm.valid;
  }

  public buildEntity(): PromoCode {
    return new PromoCode({
      ...(this.createPromoCodeForm.getRawValue() as any),
      id: this.id,
      dateValidityStart: moment(
        this.validityStartDateForm.value as string,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD'),
      dateValidityEnd: moment(
        this.validityEndDateForm.value as string,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD')
    });
  }

  public initForm(): void {
    this.createPromoCodeForm = new FormGroup<CreatePromoCodeForm>({
      name: this.nameForm,
      typePromo: this.typeForm,
      applyNumber: this.applyNumberForm,
      usedLimit: this.usedLimitForm,
      minPrice: this.minPriceForm,
      dateValidityStart: this.validityStartDateForm,
      dateValidityEnd: this.validityEndDateForm
    });
  }
}
