import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ButtonComponent, InputCheckboxComponent } from 'atomic-lib';
import { environment } from '../../../environments/environment';
import { TriggerAlert } from '../../app.action';
import { ElasticsearchService } from '../../service/elasticsearch.service';
import { ParametersService } from '../../service/parameters.service';
import { Alert } from '../../shared/models/alert';
import { Parameters } from '../../shared/models/parameters';

export interface ParametersForm {
  eliberty: FormControl<boolean | null>;
  resalys: FormControl<boolean | null>;
  maeva: FormControl<boolean | null>;
  arkiane: FormControl<boolean | null>;
  skiset: FormControl<boolean | null>;
}

@Component({
  selector: 'dash-parameters',
  standalone: true,
  imports: [InputCheckboxComponent, ButtonComponent, NgIf],
  templateUrl: './parameters.component.html',
  styleUrl: './parameters.component.scss'
})
export class ParametersComponent {
  readonly env = environment.name;

  eLibertyForm = new FormControl<boolean>(false);
  resalysForm = new FormControl<boolean>(false);
  maevaForm = new FormControl<boolean>(false);
  arkianeForm = new FormControl<boolean>(false);
  skisetForm = new FormControl<boolean>(false);

  parametersForm: FormGroup<ParametersForm>;

  constructor(
    private parametersService: ParametersService,
    private elasticsearchService: ElasticsearchService,
    private store: Store
  ) {
    this.parametersForm = new FormGroup<ParametersForm>({
      arkiane: this.arkianeForm,
      eliberty: this.eLibertyForm,
      maeva: this.maevaForm,
      skiset: this.skisetForm,
      resalys: this.resalysForm
    });
    this.parametersService.getCurrentParameters().subscribe((param) => {
      this.eLibertyForm.setValue(param.eliberty);
      this.arkianeForm.setValue(param.arkiane);
      this.resalysForm.setValue(param.resalys);
      this.skisetForm.setValue(param.skiset);
      this.maevaForm.setValue(param.maeva);
    });
  }

  upsert() {
    const parameters = new Parameters({
      ...(this.parametersForm.getRawValue() as Parameters),
      id: 1
    });
    this.parametersService.upsert(parameters).subscribe(
      () => this.sendAlert('Mise à jour terminé'),
      () => this.sendAlert('Une erreur est survenue', 'error')
    );
  }

  indexExperiences() {
    this.elasticsearchService.indexExperience().subscribe(
      () =>
        this.sendAlert("Indexation des suggestions d'expériences en cours ..."),
      () => this.sendAlert('Une erreur est survenue', 'error')
    );
  }

  indexExperiencesGroup() {
    this.elasticsearchService.indexExperienceGroup().subscribe(
      () => this.sendAlert('Indexation des expériences en cours ...'),
      () => this.sendAlert('Une erreur est survenue', 'error')
    );
  }

  indexAccommodation() {
    this.elasticsearchService.indexAccommodation().subscribe(
      () => this.sendAlert('Indexation des établissements en cours ...'),
      () => this.sendAlert('Une erreur est survenue', 'error')
    );
  }

  indexTrip() {
    this.elasticsearchService.indexTrip().subscribe(
      () => this.sendAlert('Indexation des séjours en cours ...'),
      () => this.sendAlert('Une erreur est survenue', 'error')
    );
  }

  sendAlert(message: string, level: 'success' | 'error' = 'success') {
    this.store.dispatch(
      new TriggerAlert(
        new Alert({
          level,
          message,
          timeout: 3000
        })
      )
    );
  }
}
