import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import moment from 'moment';
import { TriggerAlert } from 'src/app/app.action';
import { OrderService } from 'src/app/service/order.service';
import { EditComponent } from 'src/app/shared/component/edit.component';
import { Alert } from 'src/app/shared/models/alert';
import { CancelOrderItem } from 'src/app/shared/models/cancel-order-item';
import { Order } from 'src/app/shared/models/order/order';
import { OrderDetails } from 'src/app/shared/models/order/order-details';
import { Participant } from 'src/app/shared/models/participant';
import { environment } from '../../../../environments/environment';
import { EmailService } from '../../../service/email.service';

@Component({
  selector: 'dash-edit-sales',
  templateUrl: './edit-sales.component.html',
  styleUrls: ['./edit-sales.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSalesComponent extends EditComponent<Order> {
  order: Order | undefined;
  orderDetails: OrderDetails | undefined;
  loading = false;
  statusElements: OptionElement<string>[] = [
    { id: 'PENDING', label: 'En attente' },
    { id: 'PAID', label: 'Payée' },
    { id: 'CANCELLED', label: 'Annulée' }
  ];
  cancelStatusOptions: OptionElement<boolean>[] = [
    { id: true, label: 'Annulé' },
    { id: false, label: 'Confirmé' }
  ];

  statusForm = new FormControl<string | null>(null, Validators.required);
  experiencesForm = new FormGroup({});
  skiPassesForm = new FormGroup({});
  accommodationsForm = new FormGroup({});
  skiEquipmentsForm = new FormGroup({});

  constructor(
    private cdr: ChangeDetectorRef,
    protected orderService: OrderService,
    protected emailService: EmailService,
    protected store: Store
  ) {
    super(orderService, store);
  }

  @Input() set entityToEdit(value: Order | undefined) {
    if (value) {
      this.order = value;
      this.statusForm.setValue(value.status);
      this.loading = true;
      this.register(
        this.orderService
          .getOrderDetails(value.user.id, value.transactionId, value.payed)
          .subscribe(
            (order) => {
              this.orderDetails = order;
              this.addCancelStatusControls();
              this.cdr.markForCheck();
              this.loading = false;
            },
            () => {
              this.loading = false;
              this.cdr.markForCheck();
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    message: "La totalité du panier n'a pas pu être récupérée",
                    level: 'error',
                    timeout: 10000
                  })
                )
              );
            }
          )
      );
    }
  }

  getParticipantsList(participants: Participant[] | undefined): string {
    if (!participants) {
      return '';
    }

    participants.forEach((participant) => {
      if (!participant.firstname) {
        participant.firstname = '';
      }
      if (!participant.lastname) {
        participant.lastname = '';
      }
    });

    return participants
      .map((participant) => {
        return `${participant.firstname} ${participant.lastname} (${participant.age})`;
      })
      .join(', ');
  }

  getStatus(
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined
  ): string {
    const today = moment();

    if (today.isBefore(startDate, 'day')) {
      return 'À venir';
    } else if (today.isAfter(endDate, 'day')) {
      return 'Passé';
    } else {
      return 'En cours';
    }
  }

  public isFormValid(): boolean {
    return this.statusForm.valid;
  }

  create(): void {
    if (this.order) {
      this.loading = true;
      this.register(
        this.orderService
          .updateStatus(this.order.id, this.statusForm.value as string)
          .subscribe(() => {
            this.loading = false;
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  message: 'Le statut de la commande a été mis à jour',
                  level: 'success',
                  timeout: 2000
                })
              )
            );
            this.editionComplete.emit();
          })
      );
    }
    this.updateCancelStatuses();
  }

  public buildEntity(): Order {
    return this.order as Order;
  }

  public initForm(): void {
    //no form to init
  }

  addCancelStatusControls(): void {
    this.addCancelStatusControl(
      this.experiencesForm,
      this.orderDetails?.experiences
    );
    this.addCancelStatusControl(
      this.skiPassesForm,
      this.orderDetails?.skiPasses
    );
    this.addCancelStatusControl(
      this.accommodationsForm,
      this.orderDetails?.accommodations
    );
    this.addCancelStatusControl(
      this.skiEquipmentsForm,
      this.orderDetails?.skiEquipments
    );
  }

  addCancelStatusControl(
    form: FormGroup,
    items: { id: string | number; cancelled: boolean }[] | undefined
  ): void {
    items?.forEach((item) => {
      const controlName = `cancelStatus-${item.id}`;
      form.addControl(controlName, new FormControl<boolean>(item.cancelled));
    });
  }

  getCancelStatusControl(id: string | number, form: FormGroup): FormControl {
    const controlName = `cancelStatus-${id}`;
    const control = form.get(controlName);
    return control as FormControl;
  }

  updateCancelStatuses(): void {
    this.updateCancelStatus(
      'EXPERIENCE',
      this.experiencesForm,
      this.orderDetails?.experiences
    );
    this.updateCancelStatus(
      'SKIPASS',
      this.skiPassesForm,
      this.orderDetails?.skiPasses
    );
    this.updateCancelStatus(
      'ACCOMMODATION',
      this.accommodationsForm,
      this.orderDetails?.accommodations
    );
    this.updateSkiequipmentCancelStatuses();
  }

  updateCancelStatus(
    itemType: string,
    form: FormGroup,
    items: { id: string }[] | undefined
  ): void {
    items?.forEach((item) => {
      const controlName = `cancelStatus-${item.id}`;
      const isCancelled = form.get(controlName)?.value === true;

      const cancelItem: CancelOrderItem = new CancelOrderItem({
        itemId: item.id,
        itemType: itemType,
        cancelled: isCancelled,
        skiequipmentId: 0
      });

      this.orderService.cancelOrderItem(cancelItem).subscribe({
        next: () => {
          console.log(`${itemType} a été annulé/rétabli avec succès`);
        },
        error: (error) => {
          console.error(`Erreur lors de la mise à jour de ${itemType}`, error);
        }
      });
    });
  }

  updateSkiequipmentCancelStatuses(): void {
    this.orderDetails?.skiEquipments.forEach((skiequipment) => {
      const controlName = `cancelStatus-${skiequipment.id}`;
      const isCancelled =
        this.skiEquipmentsForm.get(controlName)?.value === 'true';

      const cancelItem: CancelOrderItem = new CancelOrderItem({
        itemId: '',
        itemType: 'SKIEQUIPMENT',
        cancelled: isCancelled,
        skiequipmentId: skiequipment.id
      });

      this.orderService.cancelOrderItem(cancelItem).subscribe({
        next: () => {
          console.log('Le matériel de ski a été annulé/rétabli avec succès');
        },
        error: (error) => {
          console.error(
            'Erreur lors de la mise à jour du matériel de ski',
            error
          );
        }
      });
    });
  }

  getLinkStripe(paymentIntent: string | null | undefined) {
    if (!paymentIntent) {
      return '';
    }

    return `https://dashboard.stripe.com${environment.production ? '/' : '/test/'}payments/${paymentIntent}`;
  }

  sendConfirmationOrder() {
    if (this.order) {
      this.emailService
        .sendConfirmationOrder(this.order.transactionId)
        .subscribe(
          () =>
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'success',
                  message: 'Email de confirmation envoyé avec succès',
                  timeout: 3000
                })
              )
            ),
          () =>
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'error',
                  message: 'Une erreur est survenue',
                  timeout: 5000
                })
              )
            )
        );
    } else {
      this.store.dispatch(
        new TriggerAlert(
          new Alert({
            level: 'error',
            message: "Impossible d'envoyer l'email de confirmation",
            timeout: 5000
          })
        )
      );
    }
  }
}
