<atc-side-drawer (closeChange)="editionComplete.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading">
  <div class="w-full my-4" header>
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification partenaire' : 'Nouveau partenaire' }}</h1>
  </div>

  <div body class="vsk__body">
    <div class="flex flex-row gap-x-4 items-center">
      <!-- Company name -->
      <atc-input [control]="nameForm"
                 [errorMessage]="'Champs obligatoire'"
                 id="id__form-create-partner-company-name"
                 label="Nom commercial"
                 placeholder="Ma petite compagnie dans les Alpes">
      </atc-input>

      <!-- Enabled -->
      <atc-input-checkbox [control]="enabledForm"
                          id="id__checkbox-partner-enabled">
        <label>Activé</label>
      </atc-input-checkbox>
    </div>

    <h2 class="text-lg font-bold">Informations Utilisateur</h2>

    <div>
      <div class="vsk__inline pb-2">

        <!-- Prénom -->
        <atc-input [control]="firstNameForm"
                   [errorMessage]="'Champs obligatoire'"
                   id="id__form-create-partner-first-name"
                   label="Prénom"
                   placeholder="Jean-Christophe">
        </atc-input>

        <!-- Nom -->
        <atc-input [control]="lastNameForm"
                   [errorMessage]="'Champs obligatoire'"
                   id="id__form-create-partner-last-name"
                   label="Nom"
                   placeholder="Lafaille">
        </atc-input>

        <!-- Date de naissance -->
        <atc-input-date [control]="birthdateForm"
                        id="id__form-create-partner-birth-date"
                        label="Date de naissance">
        </atc-input-date>

      </div>
      <div class="py-2">
        <!-- Email de connexion -->
        <atc-input [control]="emailUserForm"
                   [errorMessage]="'Champs obligatoire'"
                   id="id__form-create-partner-email"
                   label="Email de connexion"
                   placeholder="jean@monentreprise.fr">
        </atc-input>
      </div>

      <div class="vsk__inline py-2">
        <!-- Addresse de l'utilisateur-->
        <atc-input [control]="addressForm"
                   [errorMessage]="'Champs obligatoire'"
                   id="id__form-create-partner-company-address"
                   label="Adresse de l'utilisateur"
                   placeholder="10 rue de la station de ski">
        </atc-input>
        <!-- Pays -->
        <atc-input [control]="countryForm"
                   [errorMessage]="'Champs obligatoire'"
                   id="id__form-create-partner-country"
                   label="Pays"
                   placeholder="France">
        </atc-input>
      </div>

      <div class="vsk__inline py-2">
        <!-- Ville -->
        <atc-input [control]="cityForm"
                   [errorMessage]="'Champs obligatoire'"
                   id="id__form-create-partner-city"
                   label="Ville"
                   placeholder="Bourg-Saint-Maurice">
        </atc-input>

        <!-- Code Postal -->
        <atc-input [control]="postalCodeForm"
                   [errorMessage]="'Champs obligatoire'"
                   id="id__form-create-partner-postal-code"
                   label="Code Postal"
                   placeholder="73700">
        </atc-input>
      </div>
    </div>

    <div class="mb-2 border-t border-gray-200"></div>

    <h2 class="text-lg font-bold mb-2">Informations Partenaire</h2>

    <!-- Commercial name-->
    <atc-input [control]="societyNameForm"
               [errorMessage]="'Champs obligatoire'"
               id="id__form-create-partner-commercial-name"
               label="Raison sociale"
               placeholder="VeryMountain SAS">
    </atc-input>

    <div class="flex flex-row gap-x-4">
      <!-- Address -->
      <atc-input [control]="addressEnterpriseForm"
                 [errorMessage]="'Champs obligatoire'"
                 id="id__form-create-partner-user-address"
                 label="Adresse du partenaire"
                 placeholder="10 rue de la station de ski">
      </atc-input>

      <!-- Téléphone -->
      <atc-input [control]="phoneForm"
                 [errorMessage]="'Champs obligatoire'"
                 id="id__form-create-partner-phone"
                 label="Téléphone"
                 mask="0000000000"
                 placeholder="0601020304">
      </atc-input>
    </div>

    <div class="flex flex-row gap-x-4">
      <!-- Taux de commission -->
      <atc-input [allowNegativeNumbers]="false"
                 [control]="commissionRateForm"
                 [dropSpecialCharacters]="false"
                 [errorMessage]="'Champs obligatoire'"
                 [specialCharacters]="[',']"
                 id="id__form-commission-rate"
                 label="Taux de commission"
                 mask="separator.2"
                 placeholder="15,50 %"
                 suffix=" %">
      </atc-input>

      <!-- Google review -->
      <atc-input [allowNegativeNumbers]="false"
                 [control]="reviewForm"
                 [dropSpecialCharacters]="false"
                 [specialCharacters]="[',']"
                 label="Note google"
                 mask="0,0"
                 placeholder="4,3"
                 type="number">
      </atc-input>

      <!-- Google review -->
      <atc-input [allowNegativeNumbers]="false"
                 [control]="nbReviewsForm"
                 [dropSpecialCharacters]="false"
                 label="Nombre d'avis Google"
                 placeholder="13"
                 type="number">
      </atc-input>
    </div>

    <!-- reservation Email -->
    <atc-input [control]="reservationEmailForm"
               [errorMessage]="'Champs obligatoire'"
               id="id__form-create-partner-reservation-email"
               label="Email pour les réservations"
               placeholder="jean@monemaildereservation.fr">
    </atc-input>

    <div class="flex flex-row gap-x-4 items-center">
      <!-- External ID -->
      <atc-input [control]="externalIdForm"
                 [errorMessage]="'Champs obligatoire'"
                 id="id__form-create-partner-external-id"
                 label="ID externe"
                 placeholder="ID externe (YoPlanning, etc.)">
      </atc-input>
      <atc-input-checkbox [control]="dataImportationConditionForm"
                          id="id__checkbox-data-importation-condition">
        <label>Importation des données</label>
      </atc-input-checkbox>
    </div>

    <div class="flex flex-row gap-x-4">
      <!-- Siret -->
      <atc-input [control]="siretForm"
                 [errorMessage]="'Champs obligatoire'"
                 id="id__form-create-partner-reservation-siret"
                 label="SIRET"
                 placeholder="Numéro de SIRET">
      </atc-input>

      <!-- IBAN -->
      <atc-input [control]="ibanForm"
                 [errorMessage]="'Champs obligatoire'"
                 id="id__form-create-partner-reservation-iban"
                 label="IBAN"
                 placeholder="FR00 1234 5678 9123 4567 8912 345">
      </atc-input>
    </div>

    <div class="flex flex-row gap-x-4">
      <!-- BIC -->
      <atc-input [control]="bicForm"
                 [errorMessage]="'Champs obligatoire'"
                 id="id__form-create-partner-reservation-bic"
                 label="BIC"
                 placeholder="ABCDEFGH">
      </atc-input>

      <!-- Carte pro -->
      <atc-input [control]="carteProForm"
                 [errorMessage]="'Champs obligatoire'"
                 id="id__form-create-partner-reservation-carte-pro"
                 label="Carte professionnelle"
                 placeholder="Numéro de carte professionnelle">
      </atc-input>
    </div>
  </div>

  <div class="vsk__footer flex justify-between pt-4" footer>
    <atc-button (click)="create()"
                [disabled]="!isFormValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"

                id="id__button-validate-form">
    </atc-button>
    <atc-button (click)="resetPassword()"
                *ngIf="editMode === 'edit' && userId"
                color="ternary"
                id="id__button-reset-password"
                label="Réinitialiser le mot de passe">
    </atc-button>
  </div>
</atc-side-drawer>
