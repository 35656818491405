import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { combineLatest, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { ImageUrl } from '../../../activity/experience-management/experience/edit-experience/edit-experience.component';
import { TriggerAlert } from '../../../app.action';
import { AccommodationService } from '../../../service/accommodation.service';
import { ImagesService } from '../../../service/images.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { AccommodationEstablishment } from '../../../shared/models/accomodation/accommodation-establishment';
import { AccommodationRoom } from '../../../shared/models/accomodation/accommodation-room';
import { RoomFeature } from '../../../shared/models/accomodation/room-feature';
import { Alert } from '../../../shared/models/alert';
import { OriginEnum } from '../../../shared/models/enum/origin.enum';
import { Page } from '../../../shared/models/page';
import { Pageable } from '../../../shared/models/pageable';
import { ResortMin } from '../../../shared/models/resort-min';

export interface AccommodationRoomForm {
  name: FormControl<string | null>;
  codeRoom: FormControl<string | null>;
  description: FormControl<string | null>;
  surface: FormControl<number | null>;
  totalBeds: FormControl<number | null>;
  minPeople: FormControl<number | null>;
  maxPeople: FormControl<number | null>;
  category: FormControl<string | null>;
  spa: FormControl<boolean | null>;
  sauna: FormControl<boolean | null>;
  hammam: FormControl<boolean | null>;
  balconyTerrace: FormControl<boolean | null>;
  tv: FormControl<boolean | null>;
  chimney: FormControl<boolean | null>;
  bbq: FormControl<boolean | null>;
  pmr: FormControl<boolean | null>;
  parking: FormControl<boolean | null>;
  animalsAdmitted: FormControl<boolean | null>;
}

@Component({
  selector: 'dash-room-management',
  templateUrl: './room-management.component.html',
  styleUrls: ['./room-management.component.scss']
})
export class RoomManagementComponent
  extends RxjsComponent
  implements OnChanges
{
  headers = [
    'Code',
    'Nom',
    'Nombre de lits',
    'Min.',
    'Max.',
    'Catégorie',
    'Editer'
  ];
  page: Page<AccommodationRoom> = new Page<AccommodationRoom>({
    content: [],
    pageable: new Pageable({
      pageNumber: 0,
      pageSize: 10
    }),
    totalElements: 0,
    numberOfElements: 0
  });
  id: number | undefined = undefined;
  editMode: 'create' | 'edit' = 'create';
  loading = false;
  openDrawer = false;
  labelSwitch = 'Modifier uniquement cette chambre';

  featuresElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir des équipements',
      disabled: true,
      classCss: 'disabled'
    }
  ];

  // Pictures
  photosImages: HTMLImageElement[] = [];
  photosFiles: File[] = [];

  // Form
  nameForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  codeForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  descriptionForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  surfaceForm: FormControl<number | null> = new FormControl<number>(
    0,
    Validators.required
  );
  totalBedsForm: FormControl<number | null> = new FormControl<number>(
    0,
    Validators.required
  );
  minPeopleForm: FormControl<number | null> = new FormControl<number>(
    0,
    Validators.required
  );
  maxPeopleForm: FormControl<number | null> = new FormControl<number>(
    0,
    Validators.required
  );
  categoryForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.required
  );
  upsertAllRoomsToggleForm: FormControl<boolean | null> =
    new FormControl<boolean>(false, Validators.required);
  accommodationRoomForm: FormGroup<AccommodationRoomForm>;

  // Equipments establishment & room
  spaForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  saunaForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  hammamForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  balconyTerraceForm: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );
  tvForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  chimneyForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  bbqForm: FormControl<boolean | null> = new FormControl<boolean>(false);

  // Access
  pmrForm: FormControl<boolean | null> = new FormControl<boolean>(false);

  // Services
  parkingForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  animalsAdmittedForm: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );

  equipmentsFilter: OptionElement<number>[] = [
    {
      id: 2,
      label: 'Spa',
      control: this.spaForm
    },
    {
      id: 3,
      label: 'Sauna',
      control: this.saunaForm
    },
    {
      id: 4,
      label: 'Hammam',
      control: this.hammamForm
    },
    {
      id: 5,
      label: 'Balcon / Terrasse',
      control: this.balconyTerraceForm
    },
    {
      id: 6,
      label: 'TV',
      control: this.tvForm
    },
    {
      id: 7,
      label: 'Cheminée',
      control: this.chimneyForm
    },
    {
      id: 8,
      label: 'Barbecue',
      control: this.bbqForm
    }
  ];

  accessFilter: OptionElement<number>[] = [
    {
      id: 1,
      label: 'Accès PMR',
      control: this.pmrForm
    }
  ];

  servicesFilter: OptionElement<number>[] = [
    {
      id: 1,
      label: 'Parking',
      control: this.parkingForm
    },
    {
      id: 2,
      label: 'Animaux admis',
      control: this.animalsAdmittedForm
    }
  ];

  @Input() partner: string | null;
  @Input() establishment: number;

  @Input() set roomFeatures(features: RoomFeature[]) {
    if (features?.length) {
      this.featuresElements = [
        {
          id: null,
          label: 'Choisir des équipements',
          control: new FormControl<boolean | null>({
            value: false,
            disabled: true
          }),
          disabled: true,
          classCss: 'disabled'
        },
        ...features.map((feature) => {
          return {
            id: feature.id,
            label: feature.label,
            control: new FormControl<boolean | null>(false)
          } as OptionElement<number>;
        })
      ];
    }
  }

  constructor(
    private accommodationService: AccommodationService,
    private imagesService: ImagesService,
    private store: Store
  ) {
    super();
    this.accommodationRoomForm = new FormGroup<AccommodationRoomForm>({
      category: this.categoryForm,
      codeRoom: this.codeForm,
      name: this.nameForm,
      description: this.descriptionForm,
      maxPeople: this.maxPeopleForm,
      minPeople: this.minPeopleForm,
      surface: this.surfaceForm,
      totalBeds: this.totalBedsForm,
      spa: this.spaForm,
      sauna: this.saunaForm,
      hammam: this.hammamForm,
      balconyTerrace: this.balconyTerraceForm,
      tv: this.tvForm,
      chimney: this.chimneyForm,
      bbq: this.bbqForm,
      pmr: this.pmrForm,
      parking: this.parkingForm,
      animalsAdmitted: this.animalsAdmittedForm
    });

    this.upsertAllRoomsToggleForm.valueChanges.subscribe(
      (isActivated) =>
        (this.labelSwitch = !isActivated
          ? 'Modifier toutes les chambres'
          : 'Modifier uniquement cette chambre')
    );
  }

  ngOnChanges(): void {
    if (this.partner && this.establishment) {
      this.getRooms(this.partner, this.establishment);
    }
  }

  private getRooms(partnerCode: string, establishmentId: number) {
    this.register(
      this.accommodationService
        .getRoomsByPartnerCodeAndEstablishmentId(
          partnerCode,
          establishmentId,
          this.page.pageable.pageNumber,
          this.page.pageable.pageSize
        )
        .subscribe((page) => (this.page = page))
    );
  }

  openEditDrawer(room?: AccommodationRoom) {
    this.id = undefined;
    this.accommodationRoomForm.reset();
    this.photosFiles = [];
    this.photosImages = [];
    this.upsertAllRoomsToggleForm.setValue(false);

    if (room) {
      this.id = room.id;
      this.codeForm.setValue(room.codeRoom);
      this.nameForm.setValue(room.name);
      this.descriptionForm.setValue(room.description);
      this.surfaceForm.setValue(room.surface);
      this.totalBedsForm.setValue(room.totalBeds);
      this.minPeopleForm.setValue(room.minPeople);
      this.maxPeopleForm.setValue(room.maxPeople);
      this.categoryForm.setValue(room.category);

      this.spaForm.setValue(room.spa);
      this.saunaForm.setValue(room.sauna);
      this.hammamForm.setValue(room.hammam);
      this.balconyTerraceForm.setValue(room.balconyTerrace);
      this.tvForm.setValue(room.tv);
      this.chimneyForm.setValue(room.chimney);
      this.bbqForm.setValue(room.bbq);
      this.pmrForm.setValue(room.pmr);
      this.parkingForm.setValue(room.parking);
      this.animalsAdmittedForm.setValue(room.animalsAdmitted);

      if (room.pictures) {
        this.photosImages = room.pictures.map((photo, index) => {
          const image = new Image();
          image.srcset = photo;
          image.alt = `Photo ${index} de la chambre`;
          return image;
        });
      }

      const featuresIds: (number | null)[] = room.features.map(
        (feature) => feature.id
      );
      this.featuresElements = this.featuresElements.map((feature) => {
        feature.control?.setValue(featuresIds.indexOf(feature.id) !== -1);
        return {
          ...feature
        };
      });

      this.editMode = 'edit';
    } else {
      this.editMode = 'create';
    }

    this.openDrawer = true;
  }

  formIsValid(): boolean {
    return this.accommodationRoomForm.valid;
  }

  onTableDataChange(event: any) {
    this.page.pageable.pageNumber = event - 1;
    this.getRooms(this.partner as string, this.establishment);
  }

  upsert() {
    this.loading = true;

    const featuresSelected = this.featuresElements
      .filter((element) => !!element.control?.value)
      .map(
        (element) =>
          new ResortMin({
            id: element.id,
            name: element.label
          })
      );

    const accommodationRoom = new AccommodationRoom({
      ...(this.accommodationRoomForm.getRawValue() as any),
      id: this.id,
      partnerCode: this.partner,
      establishment: this.upsertAllRoomsToggleForm.value
        ? null
        : new AccommodationEstablishment({ id: this.establishment }),
      origin: OriginEnum.RESALYS,
      features: featuresSelected
    });

    this.register(
      this.accommodationService
        .upsertRoom(accommodationRoom)
        .pipe(
          // Upload pictures
          switchMap((roomCode) => {
            if (this.photosFiles?.length) {
              return combineLatest([
                of(roomCode),
                forkJoin(
                  this.photosFiles.map((file) =>
                    this.imagesService
                      .uploadImage(
                        file,
                        `accommodation/${OriginEnum.RESALYS}/${this.partner}/${this.establishment}/${roomCode}/`,
                        `image-room-${uuidv4()}`
                      )
                      .pipe(
                        map((url) => {
                          return { name: file.name, url } as ImageUrl;
                        })
                      )
                  )
                )
              ]);
            }

            return combineLatest([of(roomCode), of([])]);
          }),
          switchMap(([roomCode, pictures]) => {
            const allPictures = this.photosImages.map((image) => {
              const found = pictures.find(
                (picture) => picture.name === image.alt
              );
              return found ? found.url : image.srcset;
            });

            return this.accommodationService.upsertRoomPictures(
              roomCode,
              this.establishment,
              this.partner as string,
              allPictures
            );
          })
        )
        .subscribe(
          () => {
            this.loading = false;
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'success',
                  message:
                    this.editMode === 'create'
                      ? "Création d'une chambre"
                      : "Modification d'une chambre"
                })
              )
            );
            this.openDrawer = false;
            this.getRooms(this.partner as string, this.establishment);
          },
          () => {
            this.loading = false;
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  timeout: 5000,
                  level: 'error',
                  message: "Une erreur s'est produite"
                })
              )
            );
          }
        )
    );
  }

  addPhotoFile(files: File[]) {
    this.photosFiles.push(...files);
  }

  getControl(control: FormControl<boolean | null> | undefined) {
    return control as FormControl<boolean | null>;
  }
}
