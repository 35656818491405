<dash-list-edit id="id__table-list-text-block"
                paginationId="text-block"
                [headers]="headers"
                [searchForm]="searchForm"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                labelCreate="Ajouter"
                searchLabel="Recherche de bloc de texte"
                searchPlaceholder="Rechercher par nom ..."
                title="Blocs de texte">
  <ng-container body *ngFor="let textBlock of page.content | paginate : {
                  id: 'text-block',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(textBlock)">
      <td>{{ textBlock.id }}</td>
      <td>{{ textBlock.name }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
