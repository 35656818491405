import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MinReservation } from '../shared/models/MinReservation';
import { RegisterStatusEnum } from '../shared/models/enum/register-status.enum';
import { Page } from '../shared/models/page';
import { Reservation } from '../shared/models/reservation';
import { TimeSlot } from '../shared/models/time-slot/time-slot';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterTimeSlotService extends RepositoryService {
  private URL_REGISTER_TIME_SLOT = 'api/backoffice/register-time-slot';

  constructor(http: HttpClient) {
    super(http);
  }

  updateRegisterStatus(
    registerTimeSlotId: number,
    statusEnum: RegisterStatusEnum,
    partnerId: number
  ): Observable<void> {
    return this.put(
      this.URL_REGISTER_TIME_SLOT + `/update-status?partnerId=${partnerId}`,
      {
        registerTimeSlotId,
        status: statusEnum
      }
    );
  }

  getReservations(
    partnerId: number,
    currentPage: number,
    pageSize: number,
    status: RegisterStatusEnum[]
  ): Observable<Page<Reservation>> {
    return this.get(
      this.URL_REGISTER_TIME_SLOT +
        `/get-reservations?partnerId=${partnerId}&page=${currentPage}&size=${pageSize}&registerStatus=${status.join(',')}`
    ).pipe(
      map(
        (page: Page<Reservation>) =>
          new Page<Reservation>({ ...page }, Reservation)
      )
    );
  }

  getAllRefusedRegisterTimeSlot(
    partnerId: number,
    currentPage: number,
    pageSize: number
  ): Observable<Page<Reservation>> {
    return this.get(
      this.URL_REGISTER_TIME_SLOT +
        `/get-reservations-refused?page=${currentPage}&size=${pageSize}`
    ).pipe(
      map(
        (page: Page<Reservation>) =>
          new Page<Reservation>({ ...page }, Reservation)
      )
    );
  }

  reprogramOrSendDeny(
    partnerId: number,
    registerTimeSlotId: number,
    internshipId: number,
    timeSlots: TimeSlot[],
    sessionId = '',
    message = '',
    timeSlotOriginId: number | undefined = undefined,
    denyReason = ''
  ): Observable<void> {
    return this.post(
      this.URL_REGISTER_TIME_SLOT +
        `/reprogram-or-send-deny?partnerId=${partnerId}`,
      new MinReservation({
        partnerId: partnerId,
        registerTimeSlotId: registerTimeSlotId,
        internshipId: internshipId,
        message: message,
        denyReason: denyReason,
        timeSlots: timeSlots,
        sessionId: sessionId,
        timeSlotOriginId: timeSlotOriginId
      })
    );
  }
}
