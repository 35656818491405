import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '../models/enum/Role';

@Pipe({
  name: 'isAdmin'
})
export class IsAdminPipe implements PipeTransform {
  transform(roles: string[] | null): boolean {
    if (!roles) {
      return false;
    }

    return roles.indexOf(Role.ROLE_ADMIN) !== -1;
  }
}
