import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { tap } from 'rxjs/operators';
import { CarouselProductService } from 'src/app/service/carousel-product.service';
import { CarouselService } from 'src/app/service/carousel.service';
import { EditComponent } from 'src/app/shared/component/edit.component';
import { Carousel } from 'src/app/shared/models/carousel';
import { CarouselProduct } from 'src/app/shared/models/carousel-product';
import { CarouselZone } from 'src/app/shared/models/carousel-zone';
import { CarouselProductTypeEnum } from 'src/app/shared/models/enum/carousel-product-type.enum';
import { Page } from 'src/app/shared/models/page';

@Component({
  selector: 'dash-edit-carousel',
  templateUrl: './edit-carousel.component.html',
  styleUrls: ['./edit-carousel.component.scss']
})
export class EditCarouselComponent extends EditComponent<Carousel> {
  titleForm = new FormControl<string>('', Validators.required);
  typeForm: FormControl = new FormControl<CarouselProductTypeEnum | null>(
    null,
    Validators.required
  );
  zoneForm: FormControl = new FormControl<number | null>(
    null,
    Validators.required
  );
  enabledForm = new FormControl<boolean>(false, Validators.required);
  productTypesElements: OptionElement<CarouselProductTypeEnum>[] = [
    {
      id: null,
      label: 'Choisir un type',
      disabled: true,
      classCss: 'disabled'
    },
    {
      id: CarouselProductTypeEnum.EXPERIENCE,
      label: 'Expérience'
    },
    {
      id: CarouselProductTypeEnum.STAY,
      label: 'Séjour'
    },
    {
      id: CarouselProductTypeEnum.ACCOMMODATION,
      label: 'Hébergement'
    }
  ];
  zoneElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une zone',
      disabled: true,
      classCss: 'disabled'
    }
  ];
  productElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir un produit',
      control: new FormControl<boolean | null>({
        value: false,
        disabled: true
      }),
      disabled: true,
      classCss: 'disabled'
    }
  ];

  createdCarouselForm: FormGroup;
  page: Page<CarouselProduct> | null = null;
  productForm = new FormControl<number | null>(null);
  elements: CarouselProduct[] = [];

  @Input() set entityToEdit(carousel: Carousel | undefined) {
    if (carousel) {
      this.id = carousel.id;
      this.titleForm.setValue(carousel.title);
      this.typeForm.setValue(carousel.type);
      this.zoneForm.setValue(carousel.zoneId);
      this.enabledForm.setValue(carousel.enabled);
      this.editMode = 'edit';
      this.productService
        .getPageable(0, 500, '')
        .pipe(
          tap((page) => {
            this.page = page;
            this.setProductElements(carousel.products);
          })
        )
        .subscribe(() => {
          this.cd.markForCheck();
        });
    }
  }

  @Input() set carouselZones(carouselZones: CarouselZone[]) {
    if (carouselZones.length) {
      this.zoneElements = [
        ...this.zoneElements,
        ...carouselZones.map((zone) => ({
          id: zone.id,
          label: `${zone.name} - zone ${zone.index}`
        }))
      ];
    }
  }

  constructor(
    protected service: CarouselService,
    protected productService: CarouselProductService,
    private cd: ChangeDetectorRef,
    protected store: Store
  ) {
    super(service, store);
    this.getAllProducts();
    this.initTypeFormListener();
  }

  initForm(): void {
    this.createdCarouselForm = new FormGroup({
      title: this.titleForm,
      type: this.typeForm,
      zoneId: this.zoneForm,
      enabled: this.enabledForm
    });
  }

  isFormValid(): boolean {
    return (
      this.createdCarouselForm.valid &&
      this.elements.every(
        (product) => product.productType === this.typeForm.value
      )
    );
  }

  buildEntity(): Carousel {
    return new Carousel({
      ...(this.createdCarouselForm.getRawValue() as any),
      id: this.id,
      products: this.elements
    });
  }

  getAllProducts() {
    this.productService
      .getPageable(0, 500, '')
      .subscribe((page: Page<CarouselProduct>) => {
        this.page = page;
        this.productElements = [
          ...this.productElements,
          ...page.content.map((product) => ({
            id: product.id,
            label: product.name,
            control: new FormControl<boolean | null>({
              value: false,
              disabled: false
            })
          }))
        ];
      });
  }

  setProductElements(products: CarouselProduct[]) {
    if (this.page) {
      this.elements = products;
      this.updateProductElements();
    }
  }

  addProduct() {
    const selectedProductId = this.productForm.value;
    const alreadyAdded = this.elements.some(
      (element) => element.id === selectedProductId
    );

    if (!alreadyAdded && this.page && selectedProductId) {
      const productToAdd = this.page.content.find(
        (p) =>
          p &&
          p.id === selectedProductId &&
          p.productType === this.typeForm.value
      );
      if (productToAdd) {
        this.elements.push(productToAdd);
        this.updateOptions(this.elements);
      }
    }
  }

  updateOptions(newOrder: CarouselProduct[]) {
    this.elements = newOrder;
    this.updateProductElements();
  }

  updateProductElements() {
    if (this.page) {
      this.productElements = this.page.content
        .filter(
          (product) =>
            product.productType === this.typeForm.value &&
            !this.elements.some((p) => p.id === product.id)
        )
        .map((product) => ({
          id: product.id,
          label: product.name
        }));

      if (this.productElements.length === 0) {
        this.productElements = [
          {
            id: null,
            label: 'Aucun produit disponible',
            control: new FormControl<boolean | null>({
              value: false,
              disabled: true
            }),
            disabled: true,
            classCss: 'disabled'
          }
        ];
      }
    }
  }

  initTypeFormListener() {
    this.typeForm.valueChanges.subscribe(() => {
      this.updateProductElements();
      this.cd.markForCheck();
    });
  }
}
