import { PackagePrice } from './package-price';

export class PackageMin {
  id: number;
  ageRange: number;
  experience: number;
  enabled: boolean;
  partner: number;
  packageGrid: number;
  prices: PackagePrice[];

  constructor(obj: Partial<PackageMin>) {
    Object.assign(this, obj);

    if (this.prices) {
      this.prices = this.prices.map((price) => new PackagePrice({ ...price }));
    }
  }
}
