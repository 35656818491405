import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import moment from 'moment/moment';
import { Observable } from 'rxjs';
import { TriggerAlert } from '../../../app.action';
import { AppState } from '../../../app.state';
import { PackageService } from '../../../service/package.service';
import { Alert } from '../../../shared/models/alert';
import { Role } from '../../../shared/models/enum/Role';
import { ExperienceActivityPartner } from '../../../shared/models/experience-activity-partner';
import { Package } from '../../../shared/models/package/package';
import { PackageGroup } from '../../../shared/models/package/package-group';

export interface PackageGroupForm {
  name: FormControl<string | null>;
  description: FormControl<string | null>;
  startDate: FormControl<string | null>;
  endDate: FormControl<string | null>;
}

@Component({
  selector: 'dash-group-package',
  templateUrl: './group-package.component.html',
  styleUrls: ['./group-package.component.scss']
})
export class GroupPackageComponent {
  @Select(AppState.roles) roles$: Observable<string[]>;
  @Select(AppState.isAdmin) isAdmin$: Observable<boolean>;

  showDrawer = false;
  loading = false;
  editMode: 'create' | 'edit' = 'create';
  headers = [
    'Nom',
    'Description',
    'Prix',
    'Prix public',
    'Début',
    'Fin',
    'Dupliquer'
  ];
  isAdmin = this.store.selectSnapshot(AppState.isAdmin);

  experienceOptions: OptionElement<number>[] = [
    {
      id: null,
      label: `Choisir une expérience`,
      disabled: true,
      classCss: 'disabled'
    }
  ];

  packageSelectItems: OptionElement<number>[] = [];
  packagesForm: FormControl<number | null>[] = [
    new FormControl<number | null>(null, Validators.required)
  ];

  // Form
  idGroup: number | undefined;
  nameForm = new FormControl<string | null>('', Validators.required);
  descriptionForm = new FormControl<string | null>('', Validators.required);
  startDateForm = new FormControl<string | null>(
    moment().format('DD/MM/YYYY'),
    Validators.required
  );
  endDateForm = new FormControl<string | null>(
    moment().add(6, 'month').format('DD/MM/YYYY'),
    Validators.required
  );
  priceForm = new FormControl<string | null>(null, Validators.required);
  publicPriceForm = new FormControl<string | null>(null, Validators.required);
  experienceForm = new FormControl<number | null>(null, Validators.required);
  packageGroupForm: FormGroup<PackageGroupForm>;

  @Input() groupPackages: PackageGroup[] = [];
  @Input() packages: Package[];

  @Input() set experiences(experiences: ExperienceActivityPartner[]) {
    this.experienceOptions = [
      ...this.experienceOptions,
      ...experiences.map((exp) => {
        return {
          id: exp.option.id,
          label: this.isAdmin
            ? `${exp.option.id} - ${exp.option.name} - ${exp.activity.activity.name}`
            : `${exp.option.name} - ${exp.activity.activity.name}`
        };
      })
    ];
  }

  @Output() packageChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private packageService: PackageService,
    private store: Store
  ) {
    this.packageGroupForm = new FormGroup<PackageGroupForm>({
      name: this.nameForm,
      description: this.descriptionForm,
      startDate: this.startDateForm,
      endDate: this.endDateForm
    });

    this.roles$.subscribe((roles) => {
      if (roles.indexOf(Role.ROLE_ADMIN) !== -1) {
        this.headers.unshift('Id');
      }
    });

    this.experienceForm.valueChanges.subscribe((experience) => {
      if (experience && this.packages && this.packages.length) {
        const filteredPackages = this.packages.filter(
          (pck) => pck.experience.id === experience
        );
        this.packageSelectItems = [
          {
            id: null,
            label: 'Choisir un tarif unitaire',
            disabled: true,
            classCss: 'disabled'
          },
          ...filteredPackages.map((pck) => {
            return {
              id: pck.id,
              label: this.isAdmin
                ? `${pck.id} - ${pck.ageRange.name}`
                : `${pck.ageRange.name}`
            };
          })
        ];
      }
    });
  }

  isFormValid() {
    return (
      this.packageGroupForm.valid &&
      this.priceForm.valid &&
      this.publicPriceForm.valid &&
      this.packagesForm
        .map((form) =>
          this.packages.find((pck) => pck.id === (form.value as number))
        )
        .filter((pck) => !!pck).length > 1 &&
      this.packagesForm.every((form) => form.valid)
    );
  }

  editPackageGroup(event: Event, group: PackageGroup, isDuplicate = false) {
    event.stopPropagation();

    if (!isDuplicate) {
      this.idGroup = group.id;
    }

    this.nameForm.setValue(group.name);
    this.descriptionForm.setValue(group.description);
    this.priceForm.setValue(group.price.toString());
    this.publicPriceForm.setValue(group.publicPrice.toString());
    this.startDateForm.setValue(group.startDate.format('DD/MM/YYYY'));
    this.endDateForm.setValue(group.endDate.format('DD/MM/YYYY'));

    if (group.packages?.length) {
      const ids = group.packages.map((pck) => pck.id);
      this.experienceForm.setValue(group.packages[0].experience.id);
      this.packagesForm = ids.map(
        (id) => new FormControl<number | null>(id, Validators.required)
      );
    } else {
      this.experienceForm.reset();
      this.packagesForm = [];
    }

    this.editMode = 'edit';
    this.showDrawer = true;
  }

  upsert() {
    const selectedPackages = this.packagesForm
      .map((form) =>
        this.packages.find((pck) => pck.id === (form.value as number))
      )
      .filter((pck) => !!pck) as Package[];

    const group = new PackageGroup({
      id: this.idGroup,
      ...(this.packageGroupForm.getRawValue() as any),
      startDate: moment(this.startDateForm.value, 'DD/MM/YYYY'),
      endDate: moment(this.endDateForm.value, 'DD/MM/YYYY'),
      price: Number(
        this.priceForm.value
          ?.replace(',', '.')
          .replace(' €', '')
          .replace(' ', '')
      ),
      publicPrice: Number(
        this.publicPriceForm.value
          ?.replace(',', '.')
          .replace(' €', '')
          .replace(' ', '')
      ),
      packages: selectedPackages
    });

    this.packageService
      .upsertGroup(
        this.store.selectSnapshot(AppState.partnerId) as number,
        group
      )
      .subscribe(
        () => {
          this.packageChanged.emit();
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message: 'Tarif de groupe créée / modifié',
                level: 'success',
                timeout: 2000
              })
            )
          );
          this.idGroup = undefined;
          this.packageGroupForm.reset();
          this.packagesForm = [];
          this.showDrawer = false;
        },
        (err: HttpErrorResponse) => {
          this.loading = false;
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message: err.message,
                level: 'error',
                timeout: 5000
              })
            )
          );
        }
      );
  }

  openDrawer() {
    this.resetForms();
    this.showDrawer = true;
    this.editMode = 'create';
  }

  resetForms() {
    this.idGroup = undefined;
    this.nameForm.reset('');
    this.descriptionForm.reset('');
    this.startDateForm.reset(moment().format('DD/MM/YYYY'));
    this.endDateForm.reset(moment().add(6, 'month').format('DD/MM/YYYY'));
    this.priceForm.reset('');
    this.publicPriceForm.reset('');
    this.experienceForm.reset(null);
    this.packagesForm = [
      new FormControl<number | null>(null, Validators.required)
    ];
  }

  addForm() {
    this.packagesForm.push(
      new FormControl<number | null>(null, Validators.required)
    );
  }

  removeForm(index: number) {
    if (this.packagesForm.length === 1) {
      return;
    }

    this.packagesForm.splice(index, 1);
  }

  delete() {
    if (this.idGroup) {
      this.packageService.deleteGroup(this.idGroup).subscribe(
        () => {
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message: 'Tarif de groupe supprimé',
                level: 'success',
                timeout: 2000
              })
            )
          );
          this.packageChanged.emit();
          this.idGroup = undefined;
          this.packageGroupForm.reset();
          this.packagesForm = [];
          this.showDrawer = false;
        },
        () => {
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message:
                  'Une erreur est survenue, veuillez réessayer dans quelques instants ...',
                level: 'error',
                timeout: 5000
              })
            )
          );
        }
      );
    }
  }
}
