<dash-list-edit (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                [headers]="headers"
                [searchForm]="searchForm"
                id="id__table-list-credentials"
                labelCreate="Ajouter une connexion"
                paginationId="credentials"
                searchLabel="Recherche d'identifiants"
                searchPlaceholder="Rechercher par station ..."
                title="Connexion API ELiberty">
  <ng-container *ngFor="let credentials of page.content | paginate : {
                  id: 'credentials',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }" body>
    <tr (click)="edit(credentials)">
      <td>{{ credentials.resort.id }}</td>
      <td>{{ credentials.resort.name }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
