<atc-side-drawer [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading"
                 (closeChange)="editionComplete.emit()">
  <div header class="w-full my-4">
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification bloc de texte' : 'Nouveau bloc de texte' }}</h1>
  </div>

  <div body class="vsk__body">
    <!-- Name -->
    <atc-input id="id__input-text-activity-name"
               label="Nom du block de texte"
               placeholder="Home main"
               [control]="nameForm">
    </atc-input>
    <!-- Titres -->
    <h2>Contenu été</h2>
    <atc-input id="id__input-text-activity-title"
              label="Titre été"
              placeholder="Mon titre cool d'été"
              [control]="summerTitleForm">
    </atc-input>

    <div class="vsk__column">
      <label>Description été</label>
      <atc-text-editor [(text)]="this.summerDescriptionForm"
                       [maxLength]="300"
                       placeholder="Ma superbe description été"></atc-text-editor>
    </div>

    <atc-input-file (filesChanged)="addPhotoFile($event, SeasonEnum.SUMMER)"
                    (imagesChanged)="listUpdated($event, SeasonEnum.SUMMER)"
                    [files]="fileSummer"
                    [images]="summerCover"
                    [multiple]="true"
                    class="mb-5"
                    label="Photo de couverture été">
    </atc-input-file>

    <h2>Contenu hiver</h2>
    <atc-input id="id__input-text-activity-title"
              label="Titre hiver"
              placeholder="Mon titre cool d'hiver"
              [control]="winterTitleForm">
    </atc-input>

    <div class="vsk__column">
      <label>Description hiver</label>
      <atc-text-editor [(text)]="this.winterDescriptionForm"
                       [maxLength]="300"
                       placeholder="Ma suberbe description hiver"></atc-text-editor>
    </div>

    <atc-input-file (filesChanged)="addPhotoFile($event, SeasonEnum.WINTER)"
                    (imagesChanged)="listUpdated($event, SeasonEnum.WINTER)"
                    [files]="fileWinter"
                    [images]="winterCover"
                    [multiple]="true"
                    class="mb-5"
                    label="Photo de couverture hiver">
    </atc-input-file>

  </div>
  <div footer class="flex flex-row justify-between w-full items-center pt-4">
    <atc-button *ngIf="editMode === 'edit'" color="danger" label="Supprimer" (click)="deleteById()"></atc-button>

    <atc-button id="id__button-validate-form"
                [disabled]="!isFormValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                (click)="create()">
    </atc-button>
  </div>

</atc-side-drawer>
