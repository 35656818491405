import moment, { Moment } from 'moment';
import { Participant } from '../participant';

export class OrderDetailsExperience {
  id: string;
  partnerName: string;
  partnerPhone: string;
  partnerEmail: string;
  experienceName: string;
  activityName: string;
  startDate: Moment;
  endDate: Moment;
  price: number;
  resort: string;
  cancelled: boolean;
  participants: Participant[];

  constructor(obj: Partial<OrderDetailsExperience>) {
    Object.assign(this, obj);

    if (this.startDate) {
      this.startDate = moment(this.startDate, 'YYYY-MM-DDTHH:mm:ss');
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate, 'YYYY-MM-DDTHH:mm:ss');
    }

    if (this.participants) {
      this.participants = this.participants.map(
        (participant) => new Participant(participant)
      );
    }
  }
}
