<dash-list-edit id="id__table-list-agency"
                paginationId="agency"
                [headers]="headers"
                [searchForm]="searchForm"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                labelCreate="Ajouter une agence"
                searchLabel="Recherche de agence"
                searchPlaceholder="Rechercher par nom ..."
                title="Agences">
  <ng-container body *ngFor="let agency of page.content | paginate : {
                  id: 'agency',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(agency)">
      <td>{{ agency.name }}</td>
      <td>{{ agency.partnerCode }}</td>
      <td>{{ agency.origin }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
