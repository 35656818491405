<div class="flex flex-col justify-evenly">
  <dash-list-catalog-price-reduction [page]="page"
                                     [searchForm]="searchForm"
                                     (pageChanged)="fetchPageable($event)"
                                     (createChanged)="showEdit()"
                                     (isChanged)="edit($event)">
  </dash-list-catalog-price-reduction>
  <dash-edit-catalog-price-reduction *ngIf="showEditor && catalog"
                                     [catalog]="catalog"
                                     [entityToEdit]="toEdit"
                                     (editionComplete)="editCompleted()">
  </dash-edit-catalog-price-reduction>
</div>
