<atc-side-drawer (closeChange)="editionComplete.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading">
  <div class="w-full my-4" header>
    <h1 class="text-center">Création d'une commande</h1>
  </div>

  <div body class="vsk__body">
    <atc-input [control]="sessionForm"
               class="flex flex-1"
               errorMessage="Champs obligatoire"
               label="Session id*"
               placeholder="608162ff-b3ce-4e65-a221-91e94249b78e"></atc-input>

    <atc-input [control]="userForm"
               class="flex flex-1"
               errorMessage="Champs obligatoire"
               label="Client de la réservation (id)*"
               placeholder="500"
               type="number"></atc-input>

    <atc-select [control]="resortForm"
                [optionElements]="resortsElement"
                [searchable]="true"
                label="Station">
    </atc-select>

    <atc-input [control]="paymentIntentForm"
               class="flex flex-1"
               errorMessage="Identifiant stripe obligatoire"
               label="Identifiant Stripe"
               placeholder="pi_3Q9lO2AXm0QCbE230yS5yHpj"></atc-input>
  </div>

  <div class="flex justify-end items-center w-full pt-4" footer>
    <atc-button (click)="createOrder()"
                [disabled]="!isFormValid()"
                color="secondary"
                id="id__button-validate-form"
                label="Créer">
    </atc-button>
  </div>
</atc-side-drawer>

