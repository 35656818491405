import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from '../shared/models/page';
import { CredentialsSkiPass } from '../shared/models/resort/credentials-ski-pass';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CredentialsSkiPassService
  extends RepositoryService
  implements
    PageableService<CredentialsSkiPass>,
    CrudService<CredentialsSkiPass>
{
  private URL_ELIBERTY = 'api/backoffice/eliberty';

  constructor(http: HttpClient) {
    super(http);
  }

  upsert(entity: CredentialsSkiPass): Observable<void> {
    return this.post(this.URL_ELIBERTY + `/upsert-credentials`, entity);
  }

  deleteById(): Observable<void> {
    return of();
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string
  ): Observable<Page<CredentialsSkiPass>> {
    return this.get(
      this.URL_ELIBERTY +
        `/pageable-credentials?page=${currentPage}&size=${pageSize}&search=${search}`
    ).pipe(
      map(
        (page: Page<CredentialsSkiPass>) =>
          new Page<CredentialsSkiPass>({ ...page }, CredentialsSkiPass)
      )
    );
  }
}
