import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { environment } from '../../../environments/environment';
import { AppState } from '../../app.state';

export interface Link {
  route: string;
  roles: string[];
  page: string;
  icon?: string;
  id?: string;
  disabledEnvs?: string[];
}

@Component({
  selector: 'dash-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent {
  @Input() title: string;
  @Input() links: Link[] = [];

  constructor(
    private store: Store,
    private router: Router
  ) {}

  containsRoles(rolesToHave: string[]): boolean {
    const roles = this.store.selectSnapshot(AppState.roles);
    return rolesToHave.filter((role) => roles.indexOf(role) !== -1).length > 0;
  }

  isActive(link: string): boolean {
    return this.router.url === link;
  }

  isEnableForEnv(link: Link) {
    if (!link.disabledEnvs?.length) {
      return true;
    }

    return link.disabledEnvs?.indexOf(environment.name) === -1;
  }
}
