import { Injectable } from '@angular/core';
import { Partner } from '../shared/models/partner';
import { UserSession } from '../shared/models/user-session';

const TOKEN_KEY = 'auth-token';
const REFRESH_TOKEN_KEY = 'auth-refresh-token';
const USER_KEY = 'auth-user';
const PARTNER_KEY = 'partner';
const PARTNERS_KEY = 'partners';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageResource {
  static getUser(): UserSession | null {
    if (window.localStorage.getItem(USER_KEY) === null) {
      return null;
    }
    return new UserSession(
      JSON.parse(localStorage.getItem(USER_KEY) as string)
    );
  }

  static getToken(): string {
    return localStorage.getItem(TOKEN_KEY) as string;
  }

  static getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN_KEY) as string;
  }

  static getPartner(): Partner | null {
    if (window.localStorage.getItem(PARTNER_KEY) === null) {
      return null;
    }
    return new Partner(JSON.parse(localStorage.getItem(PARTNER_KEY) as string));
  }

  static getPartners(): Partner[] | [] {
    if (window.localStorage.getItem(PARTNERS_KEY) === null) {
      return [];
    }
    const partners: [] = JSON.parse(
      localStorage.getItem(PARTNERS_KEY) as string
    );
    return partners.map((partner) => new Partner(partner));
  }
}
