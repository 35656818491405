import { Component } from '@angular/core';
import { AgencyService } from '../../service/agency.service';
import { PageComponent } from '../../shared/component/page.component';
import { Agency } from '../../shared/models/agency';

@Component({
  selector: 'dash-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss']
})
export class AgencyComponent extends PageComponent<Agency> {
  constructor(protected agencyService: AgencyService) {
    super(agencyService);
  }
}
