import { Component, Input } from '@angular/core';
import { PageableComponent } from 'src/app/shared/component/pageable.component';
import { CarouselProduct } from 'src/app/shared/models/carousel-product';
import { CarouselProductTypeEnum } from 'src/app/shared/models/enum/carousel-product-type.enum';
import { Resort } from '../../../shared/models/resort/resort';

@Component({
  selector: 'dash-list-carousel-product',
  templateUrl: './list-carousel-product.component.html',
  styleUrls: ['./list-carousel-product.component.scss']
})
export class ListCarouselProductComponent extends PageableComponent<CarouselProduct> {
  headers = ['Id', 'Nom', 'Type', 'Station', 'Date de début', 'Date de fin'];
  resorts: Resort[] = [];

  @Input() set stations(resorts: Resort[]) {
    this.resorts = resorts;
  }

  getType(type: CarouselProductTypeEnum): string {
    switch (type) {
      case CarouselProductTypeEnum.EXPERIENCE:
        return 'Expérience';
      case CarouselProductTypeEnum.STAY:
        return 'Séjour';
      case CarouselProductTypeEnum.ACCOMMODATION:
        return 'Hébergement';
      default:
        return '-';
    }
  }

  getResortName(id: number): string {
    const resort = this.resorts.find((resort) => resort.id === id);
    return resort ? resort.name : '-';
  }
}
