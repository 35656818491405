<div class="vsk__column">
  <dash-list-users [page]="page"
                 [searchForm]="searchForm"
                 (pageChanged)="fetchPageable($event, searchForm.value)"
                 (createChanged)="showEdit()"
                 (isChanged)="edit($event)">
  </dash-list-users>
  <dash-edit-users *ngIf="showEditor"
                 [entityToEdit]="toEdit"
                 (editionComplete)="editCompleted()">
  </dash-edit-users>
</div>
