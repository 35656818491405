import { OriginEnum } from '../enum/origin.enum';
import { AccommodationEstablishment } from './accommodation-establishment';
import { RoomFeature } from './room-feature';

export class AccommodationRoom {
  id: number;
  origin: OriginEnum;
  partnerCode: string;
  name: string;
  codeRoom: string;
  establishment: AccommodationEstablishment;
  description: string;
  surface: number;
  totalBeds: number;
  minPeople: number;
  maxPeople: number;
  category: string;
  pictures: string[];
  features: RoomFeature[] = [];
  spa: boolean;
  sauna: boolean;
  hammam: boolean;
  balconyTerrace: boolean;
  tv: boolean;
  chimney: boolean;
  bbq: boolean;
  pmr: boolean;
  parking: boolean;
  animalsAdmitted: boolean;

  constructor(obj: Partial<AccommodationRoom>) {
    Object.assign(this, obj);
  }
}
