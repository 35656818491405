import moment, { Moment } from 'moment';

export class InformationBanner {
  id: number;
  name: string;
  content: string;
  startDate: Moment;
  endDate: Moment;
  fontColor: string;
  backgroundColor: string;

  constructor(obj: Partial<InformationBanner>) {
    Object.assign(this, obj);

    if (this.startDate) {
      this.startDate = moment(this.startDate, 'YYYY-MM-DD');
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate, 'YYYY-MM-DD');
    }
  }
}
