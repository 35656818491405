<div class="vsk__column">
  <dash-list-custom-field (customFieldToEdit)="onCustomFieldEdit($event)"
                          (createEventChanged)="showEdit()"
                          [customFields]="customFields">
  </dash-list-custom-field>
  <dash-edit-custom-field
    *ngIf="showEditor"
    (customFieldToEditComplete)="onEditionComplete()"
    [customFieldToEdit]="customFieldToEdit">

  </dash-edit-custom-field>
</div>
