import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OrderType } from '../marketing/sales-report/list-sales/list-sales.component';
import { CancelOrderItem } from '../shared/models/cancel-order-item';
import { Order } from '../shared/models/order/order';
import { OrderData } from '../shared/models/order/order-data';
import { OrderDetails } from '../shared/models/order/order-details';
import { Page } from '../shared/models/page';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService
  extends RepositoryService
  implements PageableService<Order>, CrudService<Order>
{
  private URL_ORDER = 'api/backoffice/order';

  constructor(http: HttpClient) {
    super(http);
  }

  getPageable(
    currentPage: number,
    pageSize: number,
    search: string,
    filter: OrderType = 'all'
  ): Observable<Page<Order>> {
    let filterParam = '';
    if (filter !== 'all') {
      filterParam = `&filter=${filter}`;
    }
    return this.get(
      this.URL_ORDER +
        `/get-all?page=${currentPage}&size=${pageSize}&search=${search}${filterParam}`
    ).pipe(map((page: Page<Order>) => new Page<Order>({ ...page }, Order)));
  }

  upsert(): Observable<void> {
    return new Observable<void>();
  }

  deleteById(): Observable<void> {
    return new Observable<void>();
  }

  getOrderDetails(
    userId: number,
    sessionId: string,
    isPaid: boolean
  ): Observable<OrderDetails> {
    return this.get(
      this.URL_ORDER +
        `/get-details?userId=${userId}&sessionId=${sessionId}&isPaid=${isPaid}`
    ).pipe(
      map((orderDetails: OrderDetails) => new OrderDetails(orderDetails)),
      catchError((err) => {
        throw new Error(`Error getting order details : ${err.message()}`);
      })
    );
  }

  getCurrentMonthData(isPaid = true): Observable<OrderData[]> {
    return this.get(
      this.URL_ORDER + `/get-current-month-data?isPaid=${isPaid}`
    ).pipe(
      map((orders: OrderData[]) => orders.map((order) => new OrderData(order))),
      catchError((err) => {
        throw new Error(`Error getting order data : ${err.message()}`);
      })
    );
  }

  updateStatus(orderId: number, status: string): Observable<void> {
    return this.post(
      this.URL_ORDER + `/update-status?orderId=${orderId}&status=${status}`,
      ''
    );
  }

  cancelOrderItem(cancelOrderItem: CancelOrderItem): Observable<void> {
    return this.post(this.URL_ORDER + `/cancel-item`, cancelOrderItem);
  }

  createOrder(
    sessionId: string,
    userId: number,
    resort: string,
    paymentIntent: string
  ): Observable<void> {
    return this.get(
      this.URL_ORDER +
        `/create-order?sessionId=${sessionId}&resort=${resort}&userId=${userId}&paymentIntent=${paymentIntent}`
    );
  }
}
