import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CatalogPriceReduction } from '../shared/models/eliberty/catalog-price-reduction';
import { Page } from '../shared/models/page';
import { CrudService } from './interface/crud.service';
import { PageableService } from './interface/pageable.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ElibertyCatalogService
  extends RepositoryService
  implements
    PageableService<CatalogPriceReduction>,
    CrudService<CatalogPriceReduction>
{
  private URL = 'api/backoffice/eliberty';

  getPageable(
    currentPage: number,
    pageSize: number,
    catalogId: number
  ): Observable<Page<CatalogPriceReduction>> {
    return this.get(
      this.URL +
        `/pageable-catalog-price-reduction?page=${currentPage}&size=${pageSize}&catalogId=${catalogId}`
    ).pipe(
      map(
        (page: Page<CatalogPriceReduction>) =>
          new Page<CatalogPriceReduction>({ ...page }, CatalogPriceReduction)
      )
    );
  }

  upsert(entity: CatalogPriceReduction): Observable<void> {
    return this.post(this.URL + '/upsert-catalog-price-reduction', entity);
  }

  deleteById(id: number): Observable<void> {
    return this.delete(this.URL + `/catalog-price-reduction?id=${id}`);
  }
}
