<atc-side-drawer (closeChange)="editionComplete.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading">
  <div class="w-full my-4" header>
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification agence' : 'Nouvelle agence' }}</h1>
  </div>

  <div body class="vsk__body">
    <!-- Name -->
    <atc-input [control]="nameForm"
               label="Nom"
               placeholder="MMV">
    </atc-input>

    <!-- Code -->
    <atc-input [control]="codeForm"
               label="Code"
               placeholder="1498">
    </atc-input>

    <atc-input [control]="commissionForm"
               [dropSpecialCharacters]="false"
               [specialCharacters]="[',']"
               id="id__input-edit-commission"
               label="Taux de commission"
               mask="separator.2"
               suffix=" %">
    </atc-input>

    <!-- Origin -->
    <atc-select
      [control]="originForm"
      [optionElements]="originOptions"
      label="Origine">
    </atc-select>
  </div>

  <div class="flex flex-row justify-between w-full items-center pt-4" footer>
    <atc-button (click)="deleteById()" *ngIf="editMode === 'edit'" color="danger" label="Supprimer"></atc-button>

    <atc-button (click)="create()"
                [disabled]="!isFormValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"

                id="id__button-validate-form">
    </atc-button>
  </div>
</atc-side-drawer>
