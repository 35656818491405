import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { CarouselZoneService } from 'src/app/service/carousel-zone.service';
import { CarouselService } from 'src/app/service/carousel.service';
import { EditComponent } from 'src/app/shared/component/edit.component';
import { Carousel } from 'src/app/shared/models/carousel';
import { CarouselZone } from 'src/app/shared/models/carousel-zone';

@Component({
  selector: 'dash-edit-carousel-zone',
  templateUrl: './edit-carousel-zone.component.html',
  styleUrls: ['./edit-carousel-zone.component.scss']
})
export class EditCarouselZoneComponent extends EditComponent<CarouselZone> {
  nameForm = new FormControl<string | null>('', Validators.required);
  indexForm = new FormControl<number | null>(0, Validators.required);
  carousels: Carousel[] = [];

  createdCarouselZoneForm: FormGroup;

  @Input() set entityToEdit(carousel: CarouselZone | undefined) {
    if (carousel) {
      this.id = carousel.id;
      this.nameForm.setValue(carousel.name);
      this.indexForm.setValue(carousel.index);
      this.register(
        this.carouselService
          .getAllByZoneId(carousel.id)
          .subscribe((carousels) => this.updateOptions(carousels))
      );
      this.editMode = 'edit';
    }
  }

  constructor(
    protected service: CarouselZoneService,
    private carouselService: CarouselService,
    protected store: Store
  ) {
    super(service, store);
  }

  initForm(): void {
    this.createdCarouselZoneForm = new FormGroup({
      name: this.nameForm,
      index: this.indexForm
    });
  }

  isFormValid(): boolean {
    return this.nameForm.valid && this.indexForm.valid;
  }

  buildEntity(): CarouselZone {
    return new CarouselZone({
      id: this.id as any,
      name: this.nameForm.value ?? '',
      index: this.indexForm.value ?? 0
    });
  }

  create(): void {
    if (this.isFormValid()) {
      const updatedCarousels = this.carousels.map((carousel, index) => ({
        ...carousel,
        index
      }));
      this.upsert(this.buildEntity());
      this.carouselService.updateCarouselsOrder(updatedCarousels).subscribe();
    }
  }

  updateOptions(carousels: Carousel[]): void {
    this.carousels = carousels;
  }
}
