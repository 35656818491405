import { Activity } from './activity';
import { Experience } from './experience';
import { ResortMin } from './resort-min';

export class ActivityPartner {
  id: number;
  resorts: ResortMin[];
  experiences: Experience[];
  activity: Activity;
  commissionRate: number;
  vat: number;

  constructor(obj: Partial<ActivityPartner>) {
    Object.assign(this, obj);

    if (this.activity) {
      this.activity = new Activity(this.activity);
    }

    if (this.experiences !== null && this.experiences) {
      this.experiences = this.experiences.map(
        (activity) => new Experience(activity)
      );
    }
  }
}
