import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { PageableService } from '../../service/interface/pageable.service';
import { Page } from '../models/page';
import { Pageable } from '../models/pageable';
import { RxjsComponent } from './rxjs.component';

@Component({ standalone: true, template: '' })
export abstract class PageComponent<T> extends RxjsComponent implements OnInit {
  toEdit: T | undefined = undefined;
  showEditor = false;
  searchForm: FormControl<string | null> = new FormControl();
  page: Page<T> = new Page<T>({
    content: [],
    pageable: new Pageable({
      pageNumber: 0,
      pageSize: 10
    }),
    totalElements: 0,
    numberOfElements: 0
  });

  protected constructor(
    @Inject(Pageable) protected service: PageableService<T>
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchPageable(this.page, this.searchForm.value as string);
    this.searchForm.valueChanges.pipe(debounceTime(300)).subscribe((search) => {
      this.page.pageable.pageNumber = 0;
      this.fetchPageable(this.page, search?.trim() as string);
    });
  }

  fetchPageable(page: Page<T>, search: string | null | undefined): void {
    this.register(
      this.service
        .getPageable(
          page.pageable.pageNumber,
          page.pageable.pageSize,
          search || ''
        )
        .subscribe((page) => (this.page = page))
    );
  }

  editCompleted(): void {
    this.toEdit = undefined;
    this.fetchPageable(this.page, this.searchForm.value as string);
    this.showEdit();
  }

  showEdit() {
    this.showEditor = !this.showEditor;
  }

  edit(editObject: T): void {
    this.toEdit = editObject;
    this.showEdit();
  }
}
