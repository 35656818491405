import { Component } from '@angular/core';
import { TagService } from '../../service/tag.service';
import { PageComponent } from '../../shared/component/page.component';
import { Tag } from '../../shared/models/tag';

@Component({
  selector: 'dash-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent extends PageComponent<Tag> {
  constructor(tagService: TagService) {
    super(tagService);

    this.page.pageable.pageSize = 12;
  }
}
