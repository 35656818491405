<div class="vsk__header">
  <h1>Activités</h1>
  <atc-button (click)="newActivity()"
              label="+ Ajouter une activité"></atc-button>
</div>
<div class="vsk__announce-message">
  Pour qu’une expérience soit réservable, elle doit comporter au moins un tarif et un créneau.
</div>
<div class="vsk__column">
  <div *ngFor="let activity of activitiesPartner" class="vsk__activity-card">
    <div class="vsk__inline">
      <img (click)="editActivityPartner(activity)" alt="edit" class="vsk__circle" src="assets/icones/admin.svg">
      <h1>{{activity.activity.name + ' -'}}</h1>
      <div class="vsk__align-center">{{toStringResorts(activity.resorts)}}</div>
      <div class="vsk__flex"></div>
      <atc-button (click)="showExperienceEdit()"

                  label="+ Ajouter une expérience"></atc-button>
    </div>
    <div class="vsk__margin-bottom-1">Aperçu</div>
    <div class="vsk__grid">
      <div (click)="setOptionToEdit({activity:activity , option:experience})"
           *ngFor="let experience of activity.experiences" class="vsk__card-box-shadow">
        <h4>{{experience.name}}</h4>
        <p>{{transformMinutes(experience.duration)}}</p>
        <ul>
          <li>Tarifs : {{experience.packages.length || 0}}</li>
          <li>Créneaux disponibles : {{experience.timeSlots.length || 0}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<dash-edit-activity-partner
  (editionComplete)="activityPartnerChanged()"
  *ngIf="showEditor"
  [activities]="activities"
  [stations]="resorts"
  [activityPartnerToEdit]="activityPartnerToEdit"

  id="form-edit-activity-partner">
</dash-edit-activity-partner>
<dash-edit-experience (optionChanged)="optionChanged()"
                           *ngIf="showExperience"
                           [activitiesPartner]="activitiesPartner"
                           [customFieldsInput]="customFields"
                           [experience]="optionToEdit">
</dash-edit-experience>
