import moment, { Moment } from 'moment';
import { Picture } from '../accomodation/picture';
import { ResortMaeva } from '../accomodation/resort-maeva';
import { ResortLabel } from '../resort-label';
import { CredentialsSkiPass } from './credentials-ski-pass';

export class Resort {
  id: number;
  totalSlopes: number;
  altitudeLowest: number;
  altitudeHighest: number;
  openingWinter: Moment;
  closingWinter: Moment;
  openingSummer: Moment;
  closingSummer: Moment;
  axess: boolean;
  skidata: boolean;
  enabled: boolean;
  region: number;
  name: string;
  urlLogo: string;
  urlsCover: Picture[];
  latitude: number;
  longitude: number;
  greenSlopes: number;
  blueSlopes: number;
  redSlopes: number;
  blackSlopes: number;
  skiSlopesKm: number;
  nordicSkiKm: number;
  hikingKm: number;
  bikeKm: number;
  bikePark: boolean;
  bathe: boolean;
  descriptionWinter: string;
  descriptionSummer: string;
  priceSkiPass: number;
  withAccommodation: boolean;
  partner: number;
  skisetIds: number[];
  resortsMaeva: ResortMaeva[];
  labels: ResortLabel[];
  credentialsSkiPass?: CredentialsSkiPass;

  constructor(obj: Partial<Resort>) {
    Object.assign(this, obj);

    if (this.openingWinter) {
      this.openingWinter = moment(this.openingWinter, 'YYYY-MM-DD');
    }

    if (this.openingSummer) {
      this.openingSummer = moment(this.openingSummer, 'YYYY-MM-DD');
    }

    if (this.closingWinter) {
      this.closingWinter = moment(this.closingWinter, 'YYYY-MM-DD');
    }

    if (this.closingSummer) {
      this.closingSummer = moment(this.closingSummer, 'YYYY-MM-DD');
    }

    if (this.resortsMaeva) {
      this.resortsMaeva = this.resortsMaeva.map(
        (resort) => new ResortMaeva({ ...resort })
      );
    }

    if (this.labels) {
      this.labels = this.labels.map((label) => new ResortLabel({ ...label }));
    }

    if (this.credentialsSkiPass) {
      this.credentialsSkiPass = new CredentialsSkiPass({
        ...this.credentialsSkiPass
      });
    }
  }
}
