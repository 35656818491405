import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OptionEdit } from '../activity/experience-management/experience/edit-experience/edit-experience.component';
import { AppState } from '../app.state';
import { ActivityService } from '../service/activity.service';
import { CustomFieldService } from '../service/custom-field.service';
import { ResortService } from '../service/resort.service';
import { RxjsComponent } from '../shared/component/rxjs.component';
import { Activity } from '../shared/models/activity';
import { ActivityPartner } from '../shared/models/activity-partner';
import { CustomField } from '../shared/models/custom-field';
import { ResortMin } from '../shared/models/resort-min';
import { Resort } from '../shared/models/resort/resort';

@Component({
  selector: 'dash-show-activities',
  templateUrl: './show-activities.component.html',
  styleUrls: ['./show-activities.component.scss']
})
export class ShowActivitiesComponent extends RxjsComponent {
  @Select(AppState.partnerId) partnerId$: Observable<number>;

  optionToEdit: OptionEdit | undefined;
  customFields: CustomField[] = [];
  resorts: Resort[] = [];
  activities: Activity[] = [];
  activitiesPartner: ActivityPartner[] = [];
  activityPartnerToEdit: ActivityPartner | undefined;
  showEditor = false;
  showExperience = false;

  constructor(
    private activityService: ActivityService,
    private resortService: ResortService,
    private customFieldService: CustomFieldService
  ) {
    super();
    this.register(
      this.resortService
        .getAllStations()
        .subscribe((stations) => (this.resorts = stations))
    );

    this.register(
      this.activityService
        .getActivities()
        .subscribe((activities) => (this.activities = activities))
    );

    this.register(
      this.customFieldService
        .getCustomFields()
        .subscribe((customFields) => (this.customFields = customFields))
    );

    this.updateActivitiesPartner();
  }

  activityPartnerChanged(): void {
    this.activityPartnerToEdit = undefined;
    this.updateActivitiesPartner();
    this.showEdit();
  }

  newActivity(): void {
    this.activityPartnerToEdit = undefined;
    this.showEdit();
  }

  editActivityPartner(activityPartnerToEdit: ActivityPartner): void {
    this.activityPartnerToEdit = activityPartnerToEdit;
    this.showEdit();
  }

  showEdit() {
    this.showEditor = !this.showEditor;
  }

  updateActivitiesPartner(): void {
    this.register(
      this.partnerId$
        .pipe(
          switchMap((partnerId) =>
            this.activityService.getActivitiesPartner(partnerId)
          )
        )
        .subscribe((activities) => {
          this.activitiesPartner = activities;
        })
    );
  }

  setOptionToEdit(optionToEdit: OptionEdit): void {
    this.optionToEdit = optionToEdit;
    this.showExperienceEdit();
  }

  optionChanged(): void {
    this.optionToEdit = undefined;
    this.updateActivitiesPartner();
    this.showExperienceEdit();
  }

  showExperienceEdit(): void {
    this.showExperience = !this.showExperience;
  }

  transformMinutes(minutes: number) {
    if (minutes < 60) {
      return minutes + ' min';
    }
    const time = minutes / 60;
    const hour = Math.floor(time);
    if (time - hour > 0) {
      return hour + ' h ' + Math.floor((time - hour) * 60) + ' min';
    }
    return hour + ' h';
  }

  toStringResorts(resorts: ResortMin[]) {
    if (!resorts || !resorts.length) {
      return '';
    }

    return resorts.map((resort) => resort.name).join(', ');
  }
}
