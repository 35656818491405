import { Component } from '@angular/core';
import { Role } from '../../shared/models/enum/Role';
import { Link } from '../../shared/sub-menu/sub-menu.component';

@Component({
  selector: 'dash-invoices-menu',
  templateUrl: './invoices-menu.component.html',
  styleUrls: ['./invoices-menu.component.scss']
})
export class InvoicesMenuComponent {
  links: Link[] = [
    {
      id: 'my-invoices',
      roles: [
        Role.ROLE_ADMIN,
        Role.ROLE_PARTNER,
        Role.ROLE_COMMERCIAL,
        Role.ROLE_MARKETING
      ],
      page: 'Mes factures',
      icon: 'credit-card',
      route: '/invoices/my-invoices'
    }
  ];
}
