import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { TriggerAlert } from '../../../app.action';
import { CategoryService } from '../../../service/category.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { Alert } from '../../../shared/models/alert';
import { Category } from '../../../shared/models/category';

@Component({
  selector: 'dash-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss']
})
export class EditCategoryComponent extends RxjsComponent {
  @Output() editionComplete = new EventEmitter<void>();

  @Input() set categoryToEdit(value: Category | undefined) {
    if (value) {
      this.categoryId = value.id;
      this.nameForm.setValue(value.name);
      this.editMode = 'edit';
    }
  }

  editMode: 'create' | 'edit' = 'create';
  loading = false;
  categoryId: number | undefined;
  nameForm = new FormControl<string>('', Validators.required);

  constructor(
    private categoryService: CategoryService,
    private store: Store
  ) {
    super();
  }

  upsertCategory(): void {
    if (this.nameForm.valid) {
      this.loading = true;
      this.register(
        this.categoryService
          .upsertCategory(
            new Category({
              id: this.categoryId,
              name: this.nameForm.value as string
            })
          )
          .subscribe(
            () => {
              this.loading = false;
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    message:
                      this.editMode === 'create'
                        ? 'Catégorie créée avec succès'
                        : 'Catégorie modifiée avec succès',
                    level: 'success',
                    timeout: 2000
                  })
                )
              );
              this.editionComplete.emit();
            },
            () => {
              this.loading = false;
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    message:
                      'Une erreur est survenue, veuillez réessayer ultérieurement ...',
                    level: 'error',
                    timeout: 5000
                  })
                )
              );
            }
          )
      );
    } else {
      this.store.dispatch(
        new TriggerAlert(
          new Alert({
            message: 'Nom de la catégorie',
            level: 'warning',
            timeout: 5000
          })
        )
      );
    }
  }

  delete() {
    this.register(
      this.categoryService
        .deleteCategory(this.categoryId as number)
        .subscribe(() => this.editionComplete.emit())
    );
  }
}
