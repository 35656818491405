import { Component, Input } from '@angular/core';

@Component({
  selector: 'dash-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss']
})
export class TabGroupComponent {
  tabs: TabComponent[] = [];

  selectTab(tab: TabComponent): void {
    if (!tab.disable) {
      this.tabs.forEach((t) => {
        t.active = false;
      });
      tab.active = true;
    }
  }

  addTab(tab: TabComponent): void {
    this.tabs.push(tab);
  }
}

@Component({
  selector: 'dash-tab',
  template: `
    <div *ngIf="active" class="vsk__tab">
      <ng-content></ng-content>
    </div>
  `
})
export class TabComponent {
  active = false;

  @Input() label: string;
  @Input() disable = false;
  @Input() set selected(value: boolean) {
    this.active = value;
  }

  constructor(tabs: TabGroupComponent) {
    tabs.addTab(this);
  }
}
