<div class="vsk__card vsk__flex">
  <div class="flex flex-row justify-between mb-4">
    <h1 class="flex-1">{{title}}</h1>

    <div class="flex flex-row gap-x-4">
      <atc-input *ngIf="searchForm"
                 icon="search"
                 [placeholder]="searchPlaceholder"
                 [label]="searchLabel"
                 [control]="searchForm">
      </atc-input>

      <atc-button *ngIf="labelCreate"
                  [label]="labelCreate"
                  (click)="createEvent.emit()">
      </atc-button>

      <ng-content select="[action]"></ng-content>

    </div>
  </div>

  <ng-content select="[filters]"></ng-content>

  <ng-container *ngIf="!isEmpty">
    <table *ngIf="headers.length > 0">
      <thead>
      <tr>
        <th *ngFor="let header of headers">{{header}}</th>
      </tr>
      </thead>
      <tbody>
      <ng-content select="[body]"></ng-content>
      </tbody>
    </table>
  </ng-container>

  <div *ngIf="paginationId"
       class="flex flex-row justify-center mt-2">
    <pagination-controls
      (pageChange)="paginateEvent.emit($event)"
      [id]="paginationId"
      [maxSize]="9"
      [autoHide]="true"
      [responsive]="true"
      nextLabel="Suivant"
      previousLabel="Précédent">
    </pagination-controls>
  </div>

  <div class="vsk__loader" *ngIf="isEmpty">
    <atc-loader></atc-loader>
  </div>
</div>
