<atc-side-drawer [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading"
                 (closeChange)="editionComplete.emit()">
  <div header class="w-full my-4">
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification produit de carousel' : 'Nouveau produit de carousel' }}</h1>
  </div>

<div body class="vsk__body">
  <!-- Name -->
  <atc-input id="id__input-carousel-product-name"
             label="Nom du produit"
             placeholder="Canyon VTT"
             [control]="nameForm">
  </atc-input>
  <!-- Type -->
  <atc-select label="Type de produit"
              [control]="typeForm"
              [optionElements]="productTypesElements">
  </atc-select>
  <!-- Station -->
  <atc-select label="Station"
              [control]="resortForm"
              [searchable]="true"
              [optionElements]="resortElements">
  </atc-select>

  <div *ngIf="typeForm.value !== 'STAY'" class="vsk__inline">
    <atc-input-date label="Date de début"
                    class="vsk__flex"
                    [control]="startDateForm">
    </atc-input-date>

    <atc-input-date label="Date de fin"
                    class="vsk__flex"
                    [control]="endDateForm">
    </atc-input-date>
  </div>

  <!-- Champ conditionnel pour les séjours -->
  <atc-input *ngIf="typeForm.value === 'STAY'"
             id="id__input-stay-details"
             label="Id du session master"
             placeholder="a8d95fb8-cbd8-409e-a4a5-d7baac11f808"
             [control]="sessionMasterForm">
  </atc-input>
  <!-- Champ conditionnel pour les expériences -->
  <atc-select *ngIf="typeForm.value === 'EXPERIENCE' && resortForm.value"
              label="Expérience"
              [searchable]="true"
              [control]="experienceIdForm"
              [optionElements]="experienceElements">
  </atc-select>
  <!-- Champ conditionnel pour les hébergements -->
  <atc-select *ngIf="typeForm.value === 'ACCOMMODATION' && resortForm.value && startDateForm.value && endDateForm.value"
              label="Établissement"
              [searchable]="true"
              [control]="establishmentForm"
              [optionElements]="establishmentElements">
  </atc-select>

  <atc-select *ngIf="typeForm.value === 'ACCOMMODATION' && establishmentForm.value"
              label="Chambre"
              [searchable]="true"
              [control]="codeRoomForm"
              [optionElements]="roomElements">
  </atc-select>
</div>

  <div footer class="flex flex-row justify-between w-full items-center pt-4">
    <atc-button *ngIf="editMode === 'edit'" color="danger" label="Supprimer" (click)="deleteById()"></atc-button>
    <atc-button id="id__button-validate-form"
                [disabled]="!isFormValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"
                (click)="create()">
    </atc-button>
  </div>

</atc-side-drawer>