import { Component } from '@angular/core';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { Activity } from '../../../shared/models/activity';

@Component({
  selector: 'dash-list-activity',
  templateUrl: './list-activity.component.html',
  styleUrls: ['./list-activity.component.scss']
})
export class ListActivityComponent extends PageableComponent<Activity> {
  headers = ['Id', 'Activité', 'Date de début', 'Date de fin', 'Activé'];
}
