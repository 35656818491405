import { PriceCategory } from './price-category';
import { ValidityPeriod } from './validity-period';

export class Price {
  id: number;
  publicPrice: number;
  frontPrice: number;
  ourPrice: number;
  validityPeriod: ValidityPeriod;
  priceCategory: PriceCategory;

  constructor(obj: Partial<Price>) {
    Object.assign(this, obj);

    if (this.validityPeriod) {
      this.validityPeriod = new ValidityPeriod({ ...this.validityPeriod });
    }

    if (this.priceCategory) {
      this.priceCategory = new PriceCategory({ ...this.priceCategory });
    }
  }
}
