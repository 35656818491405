import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { ButtonToggle } from 'atomic-lib';
import moment from 'moment';
import { CustomerService } from 'src/app/service/customer.service';
import { Customer } from 'src/app/shared/models/customer';
import { TriggerAlert } from '../../app.action';
import { Alert } from '../../shared/models/alert';
import { FormUtils } from '../../utils/form-utils';

@Component({
  selector: 'dash-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent {
  toggleClientType: string;
  id: number | undefined;
  editMode = false;
  firstnameForm = new UntypedFormControl('', Validators.required);
  lastnameForm = new UntypedFormControl('', Validators.required);
  birthdateForm = new FormControl<string | null>(
    moment().add(-26, 'year').format('DD/MM/YYYY'),
    Validators.required
  );
  emailForm = new UntypedFormControl('', Validators.required);
  phoneNumberForm = new UntypedFormControl('');
  nameEnterpriseForm = new UntypedFormControl('', Validators.required);
  siretEnterpriseForm = new UntypedFormControl('', [
    Validators.pattern(new RegExp('^[0-9]{14}$')),
    Validators.required
  ]);
  addressForm = new UntypedFormControl('');
  postCodeForm = new UntypedFormControl(
    '',
    Validators.pattern(new RegExp('^[0-9]{2,}$'))
  );
  cityForm = new UntypedFormControl('');
  particularForm: UntypedFormGroup;
  enterpriseForm: UntypedFormGroup;

  @Input() inPopup = false;
  @Input() partnerId: number | null;

  buttonsToggle: ButtonToggle<string>[] = [
    {
      label: 'Particulier',
      value: 'particular',
      selected: true
    },
    {
      label: 'Professionnel',
      value: 'professional',
      selected: false
    }
  ];

  @Input() set edit(customer: Customer | null) {
    if (customer) {
      this.editMode = true;
      this.id = customer.id;
      this.firstnameForm.setValue(customer.firstname);
      this.lastnameForm.setValue(customer.lastname);
      this.birthdateForm.setValue(
        moment(customer.birthdate).format('DD/MM/YYYY')
      );
      this.emailForm.setValue(customer.email);
      this.phoneNumberForm.setValue(customer.phoneNumber);
      this.nameEnterpriseForm.setValue(customer.nameEnterprise);
      this.siretEnterpriseForm.setValue(customer.siretEnterprise);
      this.addressForm.setValue(customer.address);
      this.postCodeForm.setValue(customer.postCode);
      this.cityForm.setValue(customer.city);
      this.toggleClientType = customer.isEnterprise
        ? 'professional'
        : 'particular';
    } else {
      this.id = undefined;
      this.firstnameForm.setValue('');
      this.lastnameForm.setValue('');
      this.birthdateForm.setValue('');
      this.emailForm.setValue('');
      this.phoneNumberForm.setValue('');
      this.nameEnterpriseForm.setValue('');
      this.siretEnterpriseForm.setValue('');
      this.addressForm.setValue('');
      this.postCodeForm.setValue('');
      this.cityForm.setValue('');
    }
  }

  @Output() done: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private customerService: CustomerService,
    private store: Store
  ) {
    this.changeClientType('particular');
  }

  confirm(): void {
    if (
      this.toggleClientType === 'particular' &&
      this.particularForm?.valid &&
      this.partnerId
    ) {
      const customer = new Customer({
        ...this.particularForm.getRawValue(),
        partner: this.partnerId
      });
      this.upsert(customer, this.partnerId);
    } else if (
      this.toggleClientType === 'professional' &&
      this.enterpriseForm?.valid &&
      this.partnerId
    ) {
      const customer = new Customer({
        ...this.enterpriseForm.getRawValue(),
        firstname: '',
        lastname: '',
        partner: this.partnerId
      });
      this.upsert(customer, this.partnerId);
    } else if (this.toggleClientType === 'professional') {
      const invalid = FormUtils.findInvalidControls(this.enterpriseForm)
        .map((name) => {
          switch (name) {
            case 'phoneNumber':
              return String('Téléphone');
            case 'siretEnterprise':
              return String('SIRET');
            case 'nameEnterprise':
              return String("Nom de l'entreprise");
            case 'address':
              return String('Adresse');
            case 'postCode':
              return String('Code postal');
            case 'city':
              return String('Ville');
          }
          return '';
        })
        .reduce((prev, next) => `${prev}, ${next}`, '')
        .substring(1);

      this.store.dispatch(
        new TriggerAlert(
          new Alert({
            message: invalid,
            level: 'warning',
            timeout: 5000
          })
        )
      );
    } else {
      const invalid = FormUtils.findInvalidControls(this.particularForm)
        .map((name) => {
          switch (name) {
            case 'firstname':
              return String('Prénom');
            case 'lastname':
              return String('Nom');
            case 'email':
              return String('Email');
            case 'birthdate':
              return String('Date de naissance');
            case 'phoneNumber':
              return String('Téléphone');
            case 'address':
              return String('Adresse');
            case 'postCode':
              return String('Code postal');
            case 'city':
              return String('Ville');
          }
          return '';
        })
        .reduce((prev, next) => `${prev}, ${next}`, '')
        .substring(1);

      this.store.dispatch(
        new TriggerAlert(
          new Alert({
            message: invalid,
            level: 'warning',
            timeout: 5000
          })
        )
      );
    }
  }

  upsert(customer: Customer, partnerId: number): void {
    if (this.editMode && this.id) {
      customer.id = this.id;
      this.customerService.update(partnerId, customer).subscribe(
        () => this.done.emit(true),
        () =>
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message:
                  'Une erreur est survenue, veuillez réessayer dans quelques instants',
                level: 'error',
                timeout: 5000
              })
            )
          )
      );
    } else {
      this.customerService.create(partnerId, customer).subscribe(
        () => this.done.emit(true),
        () =>
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message:
                  'Une erreur est survenue, veuillez réessayer dans quelques instants',
                level: 'error',
                timeout: 5000
              })
            )
          )
      );
    }
  }

  delete(): void {
    if (this.partnerId && this.id) {
      this.customerService.deleteCustomer(this.partnerId, this.id).subscribe(
        () => this.done.emit(true),
        () =>
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message:
                  'Une erreur est survenue, veuillez réessayer dans quelques instants',
                level: 'error',
                timeout: 5000
              })
            )
          )
      );
    }
  }

  changeClientType(value: string): void {
    this.toggleClientType = value;
    if (value === 'particular') {
      this.particularForm = new UntypedFormGroup({
        firstname: this.firstnameForm,
        lastname: this.lastnameForm,
        email: this.emailForm,
        birthdate: this.birthdateForm,
        phoneNumber: this.phoneNumberForm,
        address: this.addressForm,
        postCode: this.postCodeForm,
        city: this.cityForm
      });
    } else {
      this.enterpriseForm = new UntypedFormGroup({
        phoneNumber: this.phoneNumberForm,
        nameEnterprise: this.nameEnterpriseForm,
        email: this.emailForm,
        siretEnterprise: this.siretEnterpriseForm,
        address: this.addressForm,
        postCode: this.postCodeForm,
        city: this.cityForm
      });
    }
  }
}
