import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TriggerAlert } from '../../../app.action';
import { ActivityService } from '../../../service/activity.service';
import { CategoryService } from '../../../service/category.service';
import { ImagesService } from '../../../service/images.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { Activity } from '../../../shared/models/activity';
import { Alert } from '../../../shared/models/alert';
import { Category } from '../../../shared/models/category';
import { FormUtils } from '../../../utils/form-utils';
import { StringUtils } from '../../../utils/string-utils';

@Component({
  selector: 'dash-edit-activity',
  templateUrl: './edit-activity.component.html',
  styleUrls: ['./edit-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditActivityComponent extends RxjsComponent {
  loading = false;
  editMode: 'create' | 'edit' = 'create';
  categories: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une catégorie',
      disabled: true,
      classCss: 'disabled'
    }
  ];

  // Icon
  iconFirebase: string[] = [];
  iconImage: HTMLImageElement[] = [];
  iconFile: File[] = [];

  // Form
  id: number | undefined;
  nameForm = new FormControl<string>('', Validators.required);
  categoryForm = new FormControl<number | null>(null, Validators.required);
  dateStartForm = new FormControl<string | null>(
    moment().format('DD/MM/YYYY'),
    Validators.required
  );
  dateEndForm = new FormControl<string | null>(
    moment().add(6, 'month').format('DD/MM/YYYY'),
    Validators.required
  );
  enabledForm = new FormControl<boolean>(true, Validators.required);
  createActivityForm: UntypedFormGroup;

  @Input() set activityToEdit(value: Activity | undefined) {
    if (value) {
      this.id = value.id;
      this.nameForm.setValue(value.name);
      this.categoryForm.setValue(value.category.id);
      this.dateStartForm.setValue(moment(value.dateStart).format('DD/MM/YYYY'));
      this.dateEndForm.setValue(moment(value.dateEnd).format('DD/MM/YYYY'));
      this.enabledForm.setValue(value.enabled);
      this.editMode = 'edit';

      if (value.urlIcon) {
        const image = new Image();
        image.srcset = value.urlIcon;
        image.src = value.urlIcon;
        image.alt = `Icone de l'activité`;

        this.iconFirebase = [value.urlIcon];
        this.iconImage = [image];

        setTimeout(() => this.changeRef.markForCheck(), 500);
      }
    }
  }

  @Output() editionComplete = new EventEmitter<void>();

  constructor(
    private activityService: ActivityService,
    private imagesService: ImagesService,
    private store: Store,
    private categoryService: CategoryService,
    private changeRef: ChangeDetectorRef
  ) {
    super();
    this.register(
      this.categoryService.getCategories().subscribe((categories) => {
        this.categories = [
          ...this.categories,
          ...categories.map((category) => {
            return {
              id: category.id,
              label: category.name
            };
          })
        ];
      })
    );

    this.createActivityForm = new UntypedFormGroup({
      name: this.nameForm,
      category: this.categoryForm,
      dateEnd: this.dateEndForm,
      dateStart: this.dateStartForm,
      enabled: this.enabledForm
    });
  }

  checkIconValidity(): boolean {
    if (this.editMode === 'create') {
      return !!this.iconFile;
    }

    return !!this.iconFile.length || !!this.iconImage.length;
  }

  formIsValid() {
    return this.createActivityForm.valid && this.checkIconValidity();
  }

  create(): void {
    if (this.formIsValid()) {
      const dateStart = moment(
        this.dateStartForm.value as string,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD');
      const dateEnd = moment(
        this.dateEndForm.value as string,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD');
      const icon$ = this.iconFile.length
        ? this.imagesService.uploadImage(
            this.iconFile[0],
            `activities/${StringUtils.formatString(this.nameForm.value)}/icon/${new Date().getTime()}/`,
            'icon.svg',
            'svg'
          )
        : of(this.iconFirebase[0]);

      this.register(
        icon$
          .pipe(
            switchMap((urlIcon) => {
              return this.activityService.upsertActivity(
                new Activity({
                  id: this.id,
                  ...this.createActivityForm.getRawValue(),
                  dateStart,
                  dateEnd,
                  urlIcon,
                  category: new Category({
                    id: this.categoryForm.value as number
                  })
                })
              );
            })
          )
          .subscribe(
            () => {
              this.loading = false;
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    message: 'Activité créée avec succès',
                    level: 'success',
                    timeout: 2000
                  })
                )
              );
              this.editionComplete.emit();
            },
            () => {
              this.loading = false;
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    message:
                      'Une erreur est survenue, veuillez réessayer plus tard ...',
                    level: 'error',
                    timeout: 5000
                  })
                )
              );
            }
          )
      );
    } else {
      const invalid = [
        ...FormUtils.findInvalidControls(this.createActivityForm),
        !this.iconFile.length && !this.iconImage.length ? 'icon' : ''
      ]
        .map((name) => {
          switch (name) {
            case 'name':
              return String('Nom');
            case 'category':
              return String('Catégorie');
            case 'icon':
              return String('Icone');
            case 'dateEnd':
              return String('Date de fin');
            case 'dateStart':
              return String('Date de début');
            case 'enabled':
              return String('Activé');
          }
          return '';
        })
        .filter((value) => !!value)
        .join(', ');

      this.store.dispatch(
        new TriggerAlert(
          new Alert({
            message: invalid,
            level: 'warning',
            timeout: 5000
          })
        )
      );
    }
  }
}
