import { Experience } from './experience';

export class ExperienceGroup {
  id: number;
  name: string;
  experiences: Experience[] = [];

  constructor(obj: Partial<ExperienceGroup>) {
    Object.assign(this, obj);

    if (this.experiences?.length) {
      this.experiences = this.experiences.map(
        (experience) => new Experience({ ...experience })
      );
    }
  }
}
