<dash-list-edit id="id__table-list-carousel-zone"
                paginationId="carouselZones"
                [headers]="headers"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                title="Zones de carrousels">
  <ng-container body *ngFor="let carouselZone of page.content | paginate : {
                  id: 'carouselZone',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(carouselZone)">
      <td>{{ carouselZone.id }}</td>
      <td>{{ carouselZone.name }}</td>
      <td>{{ carouselZone.index }}</td>
    </tr>
  </ng-container>
</dash-list-edit>