<atc-popup (closeChanged)="closeChanged.emit()"
           [withImg]="false"
           height="430px"
           width="900px">
  <div body class="flex flex-col gap-y-4">
    <h1 class="mt-4 text-center">Changement de partenaire</h1>

    <p *ngIf="error" class="vsk__error">{{ error }}</p>
    <div class="vsk__column">
      <atc-select
        (selectChange)="choosePartner($event)"
        [control]="partnerForm"
        [optionElements]="partnerOptions"
        [searchable]="true"
      ></atc-select>
    </div>
  </div>
</atc-popup>
