<dash-list-edit id="id__table-list-custom-field"
                (createEvent)="createEventChanged.emit()"
                [headers]="headers"
                labelCreate="Ajouter un champ personnalisé"
                title="Champs personnalisés">
  <tr body *ngFor="let customField of customFields">
    <td>{{customField.id}}</td>
    <td>{{customField.label}}</td>
    <td>{{customField.type}}</td>
    <td>
      <img (click)="editActivity(customField)" alt="Edit" src="assets/icones/edit.svg">
    </td>
  </tr>
</dash-list-edit>
