import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { TriggerAlert } from '../../app.action';
import { AppState } from '../../app.state';
import { ImportService } from '../../service/import.service';
import { Alert } from '../../shared/models/alert';
import { ErrorImport } from '../../shared/models/import-file/error-import';

export enum ImportType {
  RESORT = 'RESORT',
  PARTNER = 'PARTNER',
  ACTIVITY_PARTNER = 'ACTIVITY_PARTNER',
  EXPERIENCE = 'EXPERIENCE',
  PRICING = 'PRICING',
  PRICING_GROUP = 'PRICING_GROUP',
  TIMESLOTS = 'TIMESLOTS',
  ESTABLISHMENT = 'ESTABLISHMENT',
  ROOM = 'ROOM',
  POINT_OF_INTEREST = 'POINT_OF_INTEREST'
}

@Component({
  selector: 'dash-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss']
})
export class ImportFileComponent {
  loading = false;
  errors: ErrorImport[] = [];
  file: File | null;
  onlyCurrentPartnerForm: FormControl<boolean | null> = new FormControl<
    boolean | null
  >(false, Validators.required);
  importForm: FormControl<ImportType | null> =
    new FormControl<ImportType | null>(null, Validators.required);
  importOptions: OptionElement<ImportType>[] = [
    {
      id: null,
      label: 'Choisir un import',
      disabled: true,
      classCss: 'disabled'
    },
    {
      id: ImportType.RESORT,
      label: 'Station'
    },
    {
      id: ImportType.PARTNER,
      label: 'Partenaire'
    },
    {
      id: ImportType.ACTIVITY_PARTNER,
      label: 'Activité partenaire'
    },
    {
      id: ImportType.EXPERIENCE,
      label: 'Expérience'
    },
    {
      id: ImportType.PRICING,
      label: 'Prix unitaire'
    },
    {
      id: ImportType.PRICING_GROUP,
      label: 'Prix de groupe'
    },
    {
      id: ImportType.TIMESLOTS,
      label: 'Créneaux'
    },
    {
      id: ImportType.ESTABLISHMENT,
      label: 'Établissements'
    },
    {
      id: ImportType.ROOM,
      label: 'Chambres'
    },
    {
      id: ImportType.POINT_OF_INTEREST,
      label: "Points d'intérêts"
    }
  ];

  constructor(
    private importService: ImportService,
    private store: Store
  ) {}

  fileChanged(files: File[]) {
    this.file = files[0];
  }

  isDisabled() {
    return !this.file || this.importForm.invalid || this.loading;
  }

  importFile() {
    this.loading = true;
    this.importService
      .importFile(
        this.file as File,
        this.importForm.getRawValue() as ImportType
      )
      .subscribe(
        (errorsImport) => {
          if (errorsImport.errors.length) {
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  message:
                    'Import ' + this.importForm.getRawValue() + ' échoué !',
                  timeout: 5000,
                  level: 'warning'
                })
              )
            );
            this.errors = errorsImport.errors;
          } else {
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  message:
                    'Import ' + this.importForm.getRawValue() + ' terminé !',
                  timeout: 5000,
                  level: 'success'
                })
              )
            );
            this.errors = [];
            this.importForm.setValue(null);
            this.file = null;
          }

          this.loading = false;
        },
        (error) => {
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message:
                  'Import ' +
                  this.importForm.getRawValue() +
                  ' échoué : ' +
                  error.message,
                timeout: 5000,
                level: 'error'
              })
            )
          );
          this.errors = [];
          this.loading = false;
        }
      );
  }

  exportFile() {
    this.loading = true;
    const partnerId = this.onlyCurrentPartnerForm.value
      ? this.store.selectSnapshot(AppState.partner)?.id
      : undefined;
    this.importService
      .exportFile(this.importForm.getRawValue() as ImportType, partnerId)
      .subscribe(
        () => (this.loading = false),
        () => (this.loading = false)
      );
  }

  getPartnerName() {
    return this.store.selectSnapshot(AppState.partner)?.name || '';
  }
}
