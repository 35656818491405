import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class SkisetService extends RepositoryService {
  private URL = 'api/skiset';

  constructor(http: HttpClient) {
    super(http);
  }

  sendVoucher(externalOrderId: string): Observable<any> {
    return this.post(
      this.URL + '/send-vouchers?externalOrderId=' + externalOrderId,
      null
    );
  }
}
