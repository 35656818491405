import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'dash-color-selector',
  templateUrl: './color-selector.component.html',
  styleUrls: ['./color-selector.component.scss']
})
export class ColorSelectorComponent {
  @Input() colors = [
    '#1A4E71',
    '#ff1616',
    '#ff914d',
    '#ff66c4',
    '#8c52ff',
    '#004add',
    '#00c2cb',
    '#6fc9dc'
  ];
  @Input() colorForm = new UntypedFormControl('#1A4E71');
}
