import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OptionElement } from 'atomic-lib';

export type Reason = 'Accident, blessure, …' | 'Météo' | 'Autre';

export interface DenialMessage {
  denyReason: Reason;
  comment: string | null;
}

@Component({
  selector: 'dash-refuse-reservation',
  templateUrl: './refuse-reservation.component.html',
  styleUrls: ['./refuse-reservation.component.scss']
})
export class RefuseReservationComponent {
  reasonDenyForm = new FormControl<number>(1);
  commentForm = new FormControl<string>('', Validators.required);
  reasonDenyOptions: OptionElement<number>[] = [
    {
      id: 1,
      label: 'Accident, blessure, …'
    },
    {
      id: 2,
      label: 'Météo'
    },
    {
      id: 3,
      label: 'Autre'
    }
  ];

  @Output() closeChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() denialMessageChanged: EventEmitter<DenialMessage> =
    new EventEmitter<DenialMessage>();

  sendMessage(): void {
    if (this.commentForm.valid) {
      this.denialMessageChanged.emit({
        denyReason: 'Autre',
        comment: this.commentForm.value
      });
    } else {
      this.denialMessageChanged.emit({
        denyReason: this.reasonDenyOptions.find(
          (reason) => reason.id === this.reasonDenyForm.value
        )?.label as Reason,
        comment: null
      });
    }
  }
}
