import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Region } from '../shared/models/region';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class RegionService extends RepositoryService {
  private URL_REGION = 'api/backoffice/region';

  constructor(http: HttpClient) {
    super(http);
  }

  getRegions(): Observable<Region[]> {
    return this.get(this.URL_REGION + '/get-regions');
  }
}
