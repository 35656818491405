import { CalendarEvent, Days } from 'atomic-lib';
import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { FrequencyEnum } from '../enum/frequency.enum';
import { RegisterTimeSlot } from '../register-time-slot';
import { TimeSlotModel } from './time-slot-model';

export class TimeSlot {
  uuid = uuidv4();
  id: number;
  timeSlotModelId: number;
  experienceId: number;
  packageGridId: number;
  experienceName: string;
  activityName: string;
  color: string;
  frequencyDayNumber: number;
  frequency: FrequencyEnum;
  dateStart: Moment;
  dateEnd: Moment;
  hourStart: string;
  hourEnd: string;
  slotsAvailable: number;
  registered: RegisterTimeSlot[];
  enable: boolean;
  comment: string;
  dateEndFrequency: Moment;
  daysToRepeat: Days[];
  selected = false;
  hasNoPrices: boolean;
  pricesAreNotValid: boolean;

  constructor(obj: Partial<TimeSlot>) {
    Object.assign(this, obj);
    this.dateStart = moment(this.dateStart);
    this.dateEnd = moment(this.dateEnd);

    if (this.dateEndFrequency) {
      this.dateEndFrequency = moment(this.dateEndFrequency);
    }

    if (this.daysToRepeat) {
      this.daysToRepeat = this.daysToRepeat.map((day) => Number(Days[day]));
    }
  }

  toCalendarEvent(currentDay: Moment): CalendarEvent {
    let dateToDisplay = this.dateStart;
    if (
      this.frequency === FrequencyEnum.WEEK &&
      !dateToDisplay.isSame(currentDay, 'week')
    ) {
      dateToDisplay = currentDay.day(dateToDisplay.day());
    }

    return {
      eventId: this.uuid,
      start: dateToDisplay
        .clone()
        .set('hour', Number(this.hourStart.split(':')[0]))
        .set('minute', Number(this.hourStart.split(':')[1])),
      end: dateToDisplay
        .clone()
        .set('hour', Number(this.hourEnd.split(':')[0]))
        .set('minute', Number(this.hourEnd.split(':')[1])),
      title: this.experienceName,
      color: this.color,
      cssClass:
        this.registered.length === this.slotsAvailable
          ? 'vsk__full-slot'
          : 'vsk__open-slot',
      slotsAvailable: this.slotsAvailable,
      slotsTaken: this.registered.length,
      subtitle: this.activityName,
      isValid: !this.hasNoPrices && !this.pricesAreNotValid
    };
  }

  toTimeSlotModel(): TimeSlotModel {
    return new TimeSlotModel({
      id: this.timeSlotModelId,
      timeSlotId: this.id,
      experienceId: this.experienceId,
      packageGridId: this.packageGridId,
      hourStart: this.hourStart,
      hourEnd: this.hourEnd,
      frequencyDayNumber: this.frequencyDayNumber,
      slotsAvailable: this.slotsAvailable,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      frequency: this.frequency,
      enable: this.enable,
      comment: this.comment,
      dateEndFrequency: this.dateEndFrequency
    });
  }

  public calculateTimeActivity(): string {
    let minutes =
      Number(this.hourEnd.split(':')[1]) - Number(this.hourStart.split(':')[1]);
    let hours =
      Number(this.hourEnd.split(':')[0]) - Number(this.hourStart.split(':')[0]);

    if (minutes < 0) {
      hours--;
      minutes = Math.abs(minutes);
    }

    const hoursString = hours + 'h';
    const minutesString = minutes < 10 ? '0' + minutes : minutes;
    if (minutesString === '00') {
      return hoursString;
    }
    return hoursString + minutesString;
  }

  public tooManyPeople(): boolean {
    return this.slotsTakenByRegistrations() > this.slotsAvailable;
  }

  public hoursToDisplay(isStart = true): string {
    const hour = isStart ? this.hourStart : this.hourEnd;
    const hoursToDisplay = hour.length > 5 ? hour.slice(0, -3) : hour;
    return hoursToDisplay;
  }

  public frenchTime(time: Moment): string {
    const result = time.locale('fr').format('dddd D MMMM');
    return result.charAt(0).toLocaleUpperCase() + result.slice(1);
  }

  public slotsTakenByRegistrations(): number {
    if (
      this.registered === null ||
      this.registered === undefined ||
      this.registered.length === 0
    ) {
      return 0;
    }

    return this.registered
      .map((registration) => registration.participants)
      .reduce((prev, current) => [...prev, ...current])
      .map((participant) => participant.nbParticipants)
      .reduce((prev, current) => prev + current);
  }
}
