import { Component } from '@angular/core';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { SeasonEnum } from '../../../shared/models/enum/season.enum';
import { ResortLabel } from '../../../shared/models/resort-label';

@Component({
  selector: 'dash-list-resort-label',
  templateUrl: './list-resort-label.component.html',
  styleUrls: ['./list-resort-label.component.scss']
})
export class ListResortLabelComponent extends PageableComponent<ResortLabel> {
  headers = ['Id', 'Label', 'Période', 'Lien'];

  translate(season: SeasonEnum): string {
    switch (season) {
      case SeasonEnum.YEAR:
        return "Toute l'année";
      case SeasonEnum.SUMMER:
        return 'Été';
      case SeasonEnum.WINTER:
        return 'Hiver';
      default:
        return '-';
    }
  }
}
