import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { TriggerAlert } from '../../../app.action';
import { OrderService } from '../../../service/order.service';
import { ResortService } from '../../../service/resort.service';
import { Alert } from '../../../shared/models/alert';

@Component({
  selector: 'dash-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent {
  sessionForm: FormControl<string | null> = new FormControl<string | null>(
    null,
    Validators.required
  );
  resortForm: FormControl<string | null> = new FormControl<string | null>(
    null,
    Validators.required
  );
  userForm: FormControl<number | null> = new FormControl<number | null>(
    null,
    Validators.required
  );
  paymentIntentForm: FormControl<string | null> = new FormControl<
    string | null
  >(null, Validators.required);
  resortsElement: OptionElement<string>[] = [];

  loading = false;

  @Output() editionComplete: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private orderService: OrderService,
    private resortService: ResortService,
    private store: Store
  ) {
    this.resortService.getAllStations().subscribe((resorts) => {
      this.resortsElement = resorts.map((resort) => {
        return {
          id: resort.name,
          label: resort.name
        };
      });
    });
  }

  createOrder() {
    this.loading = true;

    this.orderService
      .createOrder(
        this.sessionForm.value as string,
        this.userForm.value as number,
        this.resortForm.value as string,
        this.paymentIntentForm.value as string
      )
      .subscribe(
        () => {
          this.loading = false;
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message: `Réservation effectuée avec succès`,
                level: 'success',
                timeout: 2000
              })
            )
          );
          this.editionComplete.emit();
        },
        (err: any) => {
          this.loading = false;
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message: err.error,
                level: 'error',
                timeout: 5000
              })
            )
          );
        }
      );
  }

  isFormValid() {
    return (
      this.userForm.valid && this.sessionForm.valid && this.resortForm.valid
    );
  }
}
