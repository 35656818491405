<dash-list-package-grid
  [searchForm]="searchForm"
  [page]="page"
  (pageChanged)="fetchPageable($event, searchForm.value)"
  (createChanged)="showEdit()"
  (isChanged)="edit($event)">
</dash-list-package-grid>

<dash-edit-package-grid
  id="form-edit-experience"
  *ngIf="showEditor"
  [experiences]="experiences"
  [entityToEdit]="toEdit"
  (editionComplete)="editCompleted()">
</dash-edit-package-grid>
