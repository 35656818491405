<atc-side-drawer [loading]="loading"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 (closeChange)="editionComplete.emit()">
  <div header class="w-full my-4">
    <h1 class="text-center">{{editMode === 'edit' ? 'Modification catégorie' : 'Nouvelle catégorie'}}</h1>
  </div>

  <div body class="vsk__body">
    <atc-input [control]="nameForm"
               id="id__input-text-activity-name"
               label="Nom de la catégorie"
               placeholder="Catégorie">
    </atc-input>
  </div>

  <div footer class="vsk__footer">
    <atc-button *ngIf="editMode === 'edit'" color="danger" label="Supprimer" (click)="delete()"></atc-button>
    <div class="vsk__flex"></div>
    <atc-button id="id__button-validate-form"
                [disabled]="!nameForm.valid"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"

                (click)="upsertCategory()">
    </atc-button>
  </div>
  </atc-side-drawer>
