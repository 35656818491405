import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResortMaeva } from '../shared/models/accomodation/resort-maeva';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class MaevaService extends RepositoryService {
  private URL_MAEVA = 'api/admin/maeva';

  constructor(http: HttpClient) {
    super(http);
  }

  getResorts(): Observable<ResortMaeva[]> {
    return this.get(this.URL_MAEVA + '/resorts');
  }
}
