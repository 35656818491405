<div class="vsk__column">
  <dash-list-resort [page]="page"
                    [searchForm]="searchForm"
                    (pageChanged)="fetchPageable($event, searchForm.value)"
                    (createChanged)="showEdit()"
                    (isChanged)="edit($event)"></dash-list-resort>
  <dash-edit-resort id="form-edit-resort"
                    *ngIf="showEditor"
                    [resortsMaeva]="resortsMaeva"
                    [resortLabels]="resortLabels"
                    [resortToEdit]="toEdit"
                    (editionComplete)="editCompleted()">
  </dash-edit-resort>
</div>
