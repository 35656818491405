import { Component } from '@angular/core';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { InformationBanner } from '../../../shared/models/information-banner';

@Component({
  selector: 'dash-list-information-banner',
  templateUrl: './list-information-banner.component.html',
  styleUrls: ['./list-information-banner.component.scss']
})
export class ListInformationBannerComponent extends PageableComponent<InformationBanner> {
  headers = ['Id', 'Nom', 'Date de début', 'Date de fin'];
}
