import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import moment from 'moment';
import { combineLatest } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap
} from 'rxjs/operators';
import { AccommodationService } from 'src/app/service/accommodation.service';
import { CarouselProductService } from 'src/app/service/carousel-product.service';
import { ExperienceService } from 'src/app/service/experience.service';
import { EditComponent } from 'src/app/shared/component/edit.component';
import { CarouselProduct } from 'src/app/shared/models/carousel-product';
import { CarouselProductTypeEnum } from 'src/app/shared/models/enum/carousel-product-type.enum';
import { Resort } from '../../../shared/models/resort/resort';

@Component({
  selector: 'dash-edit-carousel-product',
  templateUrl: './edit-carousel-product.component.html',
  styleUrls: ['./edit-carousel-product.component.scss']
})
export class EditCarouselProductComponent extends EditComponent<CarouselProduct> {
  nameForm = new FormControl<string>('', Validators.required);
  typeForm: FormControl = new FormControl<CarouselProductTypeEnum | null>(
    null,
    Validators.required
  );
  resortForm = new FormControl<number | null>(null, Validators.required);
  startDateForm: FormControl<string | null> = new FormControl<string | null>(
    null
  );
  endDateForm: FormControl<string | null> = new FormControl<string | null>(
    null
  );
  sessionMasterForm: FormControl<string | null> = new FormControl(null);
  experienceIdForm: FormControl<number | null> = new FormControl(null);
  establishmentForm: FormControl<number | null> = new FormControl(null);
  agencyPartnerCodeForm = new FormControl<string | null>(null);
  codeRoomForm: FormControl<string | null> = new FormControl(null);
  establishmentElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir un établissement',
      disabled: true,
      classCss: 'disabled'
    }
  ];
  roomElements: OptionElement<string>[] = [
    {
      id: null,
      label: 'Choisir une chambre',
      disabled: true,
      classCss: 'disabled'
    }
  ];

  productTypesElements: OptionElement<CarouselProductTypeEnum>[] = [
    {
      id: null,
      label: 'Choisir un type',
      disabled: true,
      classCss: 'disabled'
    },
    {
      id: CarouselProductTypeEnum.EXPERIENCE,
      label: 'Expérience'
    },
    {
      id: CarouselProductTypeEnum.STAY,
      label: 'Séjour'
    },
    {
      id: CarouselProductTypeEnum.ACCOMMODATION,
      label: 'Hébergement'
    }
  ];

  resortElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une station',
      disabled: true,
      classCss: 'disabled'
    }
  ];

  experienceElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une expérience',
      disabled: true,
      classCss: 'disabled'
    }
  ];

  createdCarouselProductForm: FormGroup;

  constructor(
    protected service: CarouselProductService,
    protected experienceService: ExperienceService,
    protected accommodationService: AccommodationService,
    protected store: Store
  ) {
    super(service, store);
    this.watchFormChanges();
  }

  @Input() set entityToEdit(carouselProduct: CarouselProduct | undefined) {
    if (carouselProduct) {
      this.id = carouselProduct.id;
      this.nameForm.setValue(carouselProduct.name);
      this.typeForm.setValue(carouselProduct.productType);
      this.resortForm.setValue(carouselProduct.resortId);
      this.startDateForm.setValue(
        carouselProduct.startDate.format('DD/MM/YYYY')
      );
      this.endDateForm.setValue(carouselProduct.endDate.format('DD/MM/YYYY'));
      this.sessionMasterForm.setValue(carouselProduct.sessionMaster);
      this.experienceIdForm.setValue(carouselProduct.experienceId);
      this.establishmentForm.setValue(carouselProduct.establishmentId);
      this.codeRoomForm.setValue(carouselProduct.codeRoom);
      this.editMode = 'edit';
    }
  }

  @Input() set stations(resorts: Resort[]) {
    if (resorts?.length) {
      this.resortElements = [
        {
          id: null,
          label: 'Choisir une station',
          control: new FormControl<boolean | null>({
            value: false,
            disabled: true
          }),
          disabled: true,
          classCss: 'disabled'
        },
        ...resorts.map((resort) => {
          return {
            id: resort.id,
            label: resort.name,
            control: new FormControl<boolean | null>(false)
          } as OptionElement<number>;
        })
      ];
    }
  }

  initForm(): void {
    this.createdCarouselProductForm = new FormGroup({
      name: this.nameForm,
      productType: this.typeForm,
      resortId: this.resortForm,
      sessionMaster: this.sessionMasterForm,
      experienceId: this.experienceIdForm,
      startDate: this.startDateForm,
      endDate: this.endDateForm,
      establishmentId: this.establishmentForm,
      agencyPartnerCode: this.agencyPartnerCodeForm,
      codeRoom: this.codeRoomForm
    });
  }

  isFormValid(): boolean {
    return (
      this.createdCarouselProductForm.valid &&
      this.startDateForm.valid &&
      this.endDateForm.valid
    );
  }

  buildEntity(): CarouselProduct {
    return new CarouselProduct({
      ...(this.createdCarouselProductForm.getRawValue() as any),
      id: this.id,
      startDate: this.startDateForm.value
        ? moment(this.startDateForm.value, 'DD/MM/YYYY')
        : null,
      endDate: this.endDateForm.value
        ? moment(this.endDateForm.value, 'DD/MM/YYYY')
        : null
    });
  }

  watchFormChanges(): void {
    this.updatevalidators();
    this.watchExperienceChanges();
    this.watchAccommodationChanges();
  }

  isValidDate(date: string): boolean {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    return dateRegex.test(date);
  }

  updatevalidators(): void {
    this.typeForm.valueChanges.subscribe((type: CarouselProductTypeEnum) => {
      if (type === CarouselProductTypeEnum.STAY) {
        this.sessionMasterForm.setValidators([Validators.required]);
        this.sessionMasterForm.updateValueAndValidity();
      }

      if (type === CarouselProductTypeEnum.EXPERIENCE) {
        this.startDateForm.setValidators([Validators.required]);
        this.endDateForm.setValidators([Validators.required]);
        this.experienceIdForm.setValidators([Validators.required]);
        this.startDateForm.updateValueAndValidity();
        this.endDateForm.updateValueAndValidity();
        this.experienceIdForm.updateValueAndValidity();
      }

      if (type === CarouselProductTypeEnum.ACCOMMODATION) {
        this.startDateForm.setValidators([Validators.required]);
        this.endDateForm.setValidators([Validators.required]);
        this.establishmentForm.setValidators([Validators.required]);
        this.codeRoomForm.setValidators([Validators.required]);
        this.agencyPartnerCodeForm.setValidators([Validators.required]);
        this.startDateForm.updateValueAndValidity();
        this.endDateForm.updateValueAndValidity();
        this.establishmentForm.updateValueAndValidity();
        this.codeRoomForm.updateValueAndValidity();
        this.agencyPartnerCodeForm.updateValueAndValidity();
      }
    });
  }

  watchExperienceChanges(): void {
    combineLatest([
      this.typeForm.valueChanges.pipe(startWith(this.typeForm.value)),
      this.resortForm.valueChanges.pipe(startWith(this.resortForm.value))
    ])
      .pipe(
        filter(
          ([type, resort]) =>
            type === CarouselProductTypeEnum.EXPERIENCE && resort !== null
        ),
        switchMap(([, resort]) =>
          this.experienceService.getExperiencesByResortAndDates(resort)
        )
      )
      .subscribe((experiences) => {
        this.experienceElements = [
          {
            id: null,
            label: 'Choisir une expérience',
            disabled: true,
            classCss: 'disabled'
          },
          ...experiences.map((experience) => ({
            id: experience.id,
            label: experience.name
          }))
        ];
      });
  }

  watchAccommodationChanges(): void {
    combineLatest([
      this.typeForm.valueChanges.pipe(startWith(this.typeForm.value)),
      this.resortForm.valueChanges.pipe(startWith(this.resortForm.value)),
      this.startDateForm.valueChanges.pipe(startWith(this.startDateForm.value)),
      this.endDateForm.valueChanges.pipe(startWith(this.endDateForm.value))
    ])
      .pipe(
        debounceTime(600),
        filter(
          ([type, resort, startDate, endDate]) =>
            type === 'ACCOMMODATION' &&
            resort !== null &&
            startDate !== null &&
            endDate !== null &&
            this.isValidDate(startDate) &&
            this.isValidDate(endDate)
        ),
        switchMap(([, resort, startDate, endDate]) =>
          this.accommodationService.getEstablishmentsByStation(
            resort,
            moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          )
        )
      )
      .subscribe((establishments) => {
        this.establishmentElements = [
          {
            id: null,
            label: 'Choisir un établissement',
            disabled: true,
            classCss: 'disabled'
          },
          ...establishments.map((establishment) => ({
            id: establishment.id,
            label: `${establishment.partnerCode?.toUpperCase()} - ${establishment.name}`
          }))
        ];

        this.establishmentForm.valueChanges
          .pipe(
            startWith(this.establishmentForm.value),
            filter((id: number | null): id is number => id !== null),
            map((id: number) => {
              const establishment = establishments.find((e) => e.id === id);
              this.agencyPartnerCodeForm.setValue(
                establishment ? establishment.partnerCode : null
              );
              return establishment ? establishment.rooms : [];
            })
          )
          .subscribe((rooms) => {
            this.roomElements = [
              {
                id: null,
                label: 'Choisir une chambre',
                disabled: true,
                classCss: 'disabled'
              },
              ...rooms.map((room) => ({
                id: room.codeRoom,
                label: `${room.name} - ${room.surface}m² - ${room.proposals[0].price} €`
              }))
            ];
          });
      });
  }
}
