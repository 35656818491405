import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import moment from 'moment';
import { MarketingService } from '../../../service/marketing.service';
import { EditComponent } from '../../../shared/component/edit.component';
import { SessionMaster } from '../../../shared/models/marketing/session-master';
import { PromoCode } from '../../../shared/models/promo-code';
import { Resort } from '../../../shared/models/resort/resort';

export interface SessionMasterForm {
  sessionId: FormControl<string | null>;
  name: FormControl<string | null>;
  dateToCheck: FormControl<string | null>;
  startDate: FormControl<string | null>;
  endDate: FormControl<string | null>;
  resort: FormControl<number | null>;
}

@Component({
  selector: 'dash-edit-pre-made-cart',
  templateUrl: './edit-pre-made-cart.component.html',
  styleUrls: ['./edit-pre-made-cart.component.scss']
})
export class EditPreMadeCartComponent extends EditComponent<SessionMaster> {
  resortElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir une station',
      disabled: true,
      classCss: 'disabled'
    }
  ];
  promoCodeElements: OptionElement<number>[] = [
    {
      id: null,
      label: 'Choisir un code promo',
      disabled: true,
      classCss: 'disabled'
    }
  ];
  sessionForm = new FormControl<string | null>(null, Validators.required);
  nameForm = new FormControl<string>('', Validators.required);
  resortForm = new FormControl<number | null>(null, Validators.required);
  promoCodeForm = new FormControl<number | null>(null);
  dateToCheckForm = new FormControl<string>(
    moment().add(1, 'month').format('DD/MM/YYYY'),
    Validators.required
  );
  startDateForm = new FormControl<string | null>(null, Validators.required);
  endDateForm = new FormControl<string | null>(null, Validators.required);
  sessionMasterForm: FormGroup<SessionMasterForm>;

  constructor(
    protected service: MarketingService,
    protected store: Store
  ) {
    super(service, store);
    this.name = 'Campagne ADS';
  }

  _resorts: Resort[] = [];

  @Input() set resorts(resorts: Resort[] | null) {
    if (resorts) {
      this._resorts = resorts;
      this.resortElements = [
        {
          id: null,
          label: 'Choisir une station',
          disabled: true,
          classCss: 'disabled'
        },
        ...resorts.map((resort) => {
          return {
            id: resort.id,
            label: resort.name
          } as OptionElement<number>;
        })
      ];
    }
  }

  @Input()
  public set entityToEdit(entity: SessionMaster | undefined) {
    if (entity) {
      this.id = entity.sessionId;
      this.sessionForm.setValue(entity.sessionId);
      this.nameForm.setValue(entity.name);
      this.resortForm.setValue(entity.resort.id);
      this.promoCodeForm.setValue(entity.promoCode?.id || null);
      this.dateToCheckForm.setValue(entity.dateToCheck.format('DD/MM/YYYY'));
      this.startDateForm.setValue(entity.startDate.format('DD/MM/YYYY'));
      this.endDateForm.setValue(entity.endDate.format('DD/MM/YYYY'));
      this.editMode = 'edit';
    }
  }

  public isFormValid(): boolean {
    return this.sessionMasterForm.valid;
  }

  public buildEntity(): SessionMaster {
    return new SessionMaster({
      ...(this.sessionMasterForm.getRawValue() as any),
      resort: this._resorts.find(
        (resort) => resort.id === this.resortForm.value
      ),
      promoCode: new PromoCode({
        id: this.promoCodeForm.value as any
      }),
      startDate: moment(this.startDateForm.value, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      endDate: moment(this.endDateForm.value, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      dateToCheck: moment(this.dateToCheckForm.value, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      )
    });
  }

  public initForm(): void {
    this.sessionMasterForm = new FormGroup<SessionMasterForm>({
      sessionId: this.sessionForm,
      name: this.nameForm,
      dateToCheck: this.dateToCheckForm,
      startDate: this.startDateForm,
      endDate: this.endDateForm,
      resort: this.resortForm
    });
  }
}
