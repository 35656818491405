import { OriginEnum } from './enum/origin.enum';

export class AgeRange {
  id: number;
  name: string;
  start: number;
  end: number;
  origin: OriginEnum;
  keyCardSlug: string;
  partner: number;

  constructor(obj: Partial<AgeRange>) {
    Object.assign(this, obj);
  }
}
