import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Catalog } from '../shared/models/eliberty/catalog';
import { Product } from '../shared/models/eliberty/product';
import { Variant } from '../shared/models/eliberty/variant';
import { Page } from '../shared/models/page';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ElibertyService extends RepositoryService {
  private URL = 'api/backoffice/eliberty';

  constructor(http: HttpClient) {
    super(http);
  }

  getCatalogs(contractorId: number): Observable<Catalog[]> {
    return this.get(
      this.URL + '/get-catalog-by-contractor-id?contractorId=' + contractorId
    );
  }

  getProductsPageable(
    currentPage: number,
    pageSize: number,
    search: string,
    catalogId: number
  ): Observable<Page<Product>> {
    return this.get(
      this.URL +
        `/pageable-products?page=${currentPage}&size=${pageSize}&search=${search}&catalogId=${catalogId}`
    ).pipe(
      map((page: Page<Product>) => new Page<Product>({ ...page }, Product))
    );
  }

  getVariantsPageable(
    currentPage: number,
    pageSize: number,
    productId: number
  ): Observable<Page<Variant>> {
    return this.get(
      this.URL +
        `/pageable-variants?page=${currentPage}&size=${pageSize}&productId=${productId}`
    ).pipe(
      map((page: Page<Variant>) => new Page<Variant>({ ...page }, Variant))
    );
  }
}
