import { Component } from '@angular/core';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { Agency } from '../../../shared/models/agency';

@Component({
  selector: 'dash-list-agency',
  templateUrl: './list-agency.component.html',
  styleUrls: ['./list-agency.component.scss']
})
export class ListAgencyComponent extends PageableComponent<Agency> {
  headers = ['Nom', 'Code', 'Origin'];
}
