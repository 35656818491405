<dash-list-edit id="id__table-list-banner"
                paginationId="banner"
                [headers]="headers"
                [searchForm]="searchForm"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)"
                labelCreate="Ajouter une bannière"
                searchLabel="Recherche de banière"
                searchPlaceholder="Rechercher par nom ..."
                title="Bannière d'information">
  <ng-container body *ngFor="let informationBanner of page.content | paginate : {
                  id: 'banner',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(informationBanner)">
      <td>{{ informationBanner.id }}</td>
      <td>{{ informationBanner.name }}</td>
      <td>{{ informationBanner.startDate.format('DD/MM/YYYY') }}</td>
      <td>{{ informationBanner.endDate.format('DD/MM/YYYY') }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
