import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {
  private URL_IMAGES = environment.urlImages;

  constructor(private http: HttpClient) {}

  uploadImage(
    file: File,
    path: string,
    name: string,
    format = 'webp'
  ): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', file, name);

    const headersQuery = new HttpHeaders();
    headersQuery.append('Content-Type', 'multipart/form-data');
    headersQuery.append('Accept', 'application/json');
    headersQuery.append('Access-Control-Allow-Origin', '*');
    headersQuery.append('timeout', '20000');

    return this.http.post<string>(
      this.URL_IMAGES +
        `/images-by-file?path=${path}&name=${name}&format=${format}`,
      formData,
      {
        headers: headersQuery,
        responseType: 'json'
      }
    );
  }
}
