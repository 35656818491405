<atc-side-drawer (closeChange)="editionComplete.emit()"
                 [close]="false"
                 [cssClass]="['backoffice']"
                 [loading]="loading">
  <div class="w-full my-4" header>
    <h1 class="text-center">{{ editMode === 'edit' ? 'Modification station' : 'Nouvelle station' }}</h1>
  </div>

  <div body class="vsk__body">
    <hr>
    <h2>Informations générales</h2>
    <div class="vsk__inline">
      <!-- Name resort -->
      <atc-input [control]="nameForm"
                 errorMessage="Champs obligatoire"
                 id="id__input-edit-resort-name"
                 label="Nom*"
                 placeholder="Serre-Chevalier"></atc-input>

      <!-- Address resort -->
      <atc-google-search-places (addressChanged)="geocodeAddress($event)"
                                [control]="addressForm"
                                [googleMapsService]="getLoader()"
                                id="id__input-edit-resort-address"
                                label="Adresse">
      </atc-google-search-places>
    </div>

    <div class="vsk__inline">
      <!-- Height min -->
      <atc-input [control]="altitudeLowestForm"
                 [min]="0"
                 label="Altitude en bas (mètre)"
                 placeholder="1700"
                 type="number"></atc-input>

      <!-- Height max -->
      <atc-input [control]="altitudeHighestForm"
                 [min]="0"
                 label="Altitude en haut (mètre)"
                 placeholder="3200"
                 type="number"></atc-input>
    </div>

    <div>
      <!-- Photo winter -->
      <atc-input-file (filesChanged)="addPhotoFile($event, SeasonEnum.WINTER)"
                      (imagesChanged)="listUpdated($event, SeasonEnum.WINTER)"
                      [files]="fileWinterResort"
                      [images]="winterCoverResort"
                      [multiple]="true"
                      class="mb-5"
                      id="id__input-edit-resort-photos-station"
                      label="Photo de couverture hiver">
      </atc-input-file>

      <!-- Photo summer -->
      <atc-input-file (filesChanged)="addPhotoFile($event, SeasonEnum.SUMMER)"
                      (imagesChanged)="listUpdated($event, SeasonEnum.SUMMER)"
                      [files]="fileSummerResort"
                      [images]="summerCoverResort"
                      [multiple]="true"
                      class="mb-5"
                      label="Photo de couverture été">
      </atc-input-file>

      <!-- Logo -->
      <atc-input-file (filesChanged)="fileLogoResort = $event"
                      (imagesChanged)="logoResort = $event"
                      [files]="fileLogoResort"
                      [images]="logoResort"
                      [multiple]="false"
                      class="mb-5"
                      id="id__input-edit-resort-logo"
                      label="Logo de la station">
      </atc-input-file>
    </div>

    <div class="flex flex-row items-center justify-between gap-x-4 w-full">
      <label class="min-w-fit">Type de forfait physique</label>
      <!-- Team Axess -->
      <atc-input-checkbox [control]="axessForm">
        <label>Axess</label>
      </atc-input-checkbox>

      <!-- Skidata -->
      <atc-input-checkbox [control]="skidataForm">
        <label>Skidata</label>
      </atc-input-checkbox>

      <!-- Ski pass price -->
      <atc-input [allowNegativeNumbers]="false"
                 [control]="priceSkiPassForm"
                 [dropSpecialCharacters]="false"
                 [specialCharacters]="[',', '.']"
                 errorMessage="Le prix est obligatoire"
                 icon="credit-card"
                 label="Prix pass physique forfait"
                 mask="separator.2"
                 placeholder="2,50 €"
                 suffix=" €"></atc-input>

      <!-- With accommodation -->
      <atc-input-checkbox [control]="withAccommodationForm">
        <label>Avec hébergement</label>
      </atc-input-checkbox>
    </div>

    <!-- Region -->
    <atc-select [control]="regionForm"
                [optionElements]="regions"
                id="id__input-edit-resort-region"
                label="Région*">
    </atc-select>

    <div class="flex flex-row items-center justify-end gap-x-4 w-full">
      <!-- Bike park -->
      <atc-input-checkbox [control]="bikeParkForm">
        <label>Bike park</label>
      </atc-input-checkbox>

      <!-- Bathe -->
      <atc-input-checkbox [control]="batheForm">
        <label>Baignade</label>
      </atc-input-checkbox>

      <!-- Enabled -->
      <atc-input-checkbox [control]="enabledForm">
        <label>Activé</label>
      </atc-input-checkbox>
    </div>

    <hr>
    <h2>Les dates d'ouvertures</h2>

    <div class="flex flex-row gap-x-2">
      <atc-input-date [control]="openingWinterForm"
                      class="flex-1"
                      label="Ouverture hiver">
      </atc-input-date>

      <atc-input-date [control]="closingWinterForm"
                      class="flex-1"
                      label="Fermeture hiver">
      </atc-input-date>
    </div>

    <div class="flex flex-row gap-x-2">
      <atc-input-date [control]="openingSummerForm"
                      class="flex-1"
                      label="Ouverture été">
      </atc-input-date>

      <atc-input-date [control]="closingSummerForm"
                      class="flex-1"
                      label="Fermeture été">
      </atc-input-date>
    </div>

    <hr>
    <h2>Les pistes</h2>

    <!-- Ski slopes -->
    <atc-input [control]="totalSlopesForm"
               [min]="0"
               label="Nombre de pistes"
               placeholder="72"
               type="number"></atc-input>

    <div class="vsk__inline">
      <!-- Green ski slopes -->
      <atc-input [control]="greenSlopesForm"
                 [min]="0"
                 label="Nombre de pistes vertes"
                 placeholder="12"
                 type="number"></atc-input>

      <!-- Blue ski slopes -->
      <atc-input [control]="blueSlopesForm"
                 [min]="0"
                 label="Nombre de pistes bleues"
                 placeholder="23"
                 type="number"></atc-input>

      <!-- Red ski slopes -->
      <atc-input [control]="redSlopesForm"
                 [min]="0"
                 label="Nombre de pistes rouges"
                 placeholder="18"
                 type="number"></atc-input>

      <!-- Black ski slopes -->
      <atc-input [control]="blackSlopesForm"
                 [min]="0"
                 label="Nombre de pistes noires"
                 placeholder="16"
                 type="number"></atc-input>
    </div>

    <div class="vsk__inline">
      <!-- Ski slopes km -->
      <atc-input [control]="skiSlopesKmForm"
                 [min]="0"
                 label="Nombre de km de pistes"
                 placeholder="186"
                 type="number"></atc-input>

      <!-- Nordic ski km -->
      <atc-input [control]="nordicSkiKmForm"
                 [min]="0"
                 label="Nombre de km de pistes nordique"
                 placeholder="186"
                 type="number"></atc-input>

      <!-- Hiking km -->
      <atc-input [control]="hikingKmForm"
                 [min]="0"
                 label="Nombre de km de sentiers rando"
                 placeholder="380"
                 type="number"></atc-input>

      <!-- Bike km -->
      <atc-input [control]="bikeKmForm"
                 [min]="0"
                 label="Nombre de km de pistes de VTT"
                 placeholder="250"
                 type="number"></atc-input>
    </div>

    <hr>
    <h2>Skiset API</h2>

    <!-- Skiset id -->
    <atc-input [control]="skisetIdsForm"
               errorMessage="Les ID Skiset doivent être séparés par des virgules"
               label="ID Skiset (séparés par des virgules)"
               placeholder="50, 100, 150"
               type="text"></atc-input>

    <hr>
    <h2>Maeva API</h2>
    <!-- Maeva resorts -->
    <atc-select [checkbox]="true"
                [optionElements]="resortsMaevaOptions"
                [searchable]="true"
                label="Stations Maeva">
    </atc-select>

    <hr>
    <h2>Label</h2>

    <!-- Labels resort -->
    <atc-select [checkbox]="true"
                [optionElements]="resortLabelsOptions"
                [searchable]="true"
                label="Label de station">
    </atc-select>


    <hr>
    <h2>Description</h2>

    <!-- Description -->
    <div class="vsk__inline">
      <div class="vsk__column">
        <label>Hiver</label>
        <atc-text-editor [(text)]="this.descriptionWinterForm"
                         [maxLength]="99999"
                         placeholder="Ma station est si belle"></atc-text-editor>
      </div>

      <div class="vsk__column">
        <label>Été</label>
        <atc-text-editor [(text)]="this.descriptionSummerForm"
                         [maxLength]="99999"
                         id="id__input-edit-resort-description"
                         placeholder="Ma station est si belle"></atc-text-editor>
      </div>
    </div>
  </div>

  <div class="flex flex-row justify-between w-full items-center pt-4" footer>
    <atc-button (click)="deleteById()" *ngIf="editMode === 'edit'" color="danger" label="Supprimer"></atc-button>
    <atc-button (click)="create()"
                [disabled]="!isFormValid()"
                [label]="editMode === 'edit' ? 'Modifier' : 'Créer'"

                id="id__button-validate-form">
    </atc-button>
  </div>
</atc-side-drawer>
