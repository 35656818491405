<dash-list-edit searchLabel="Recherche de label"
                searchPlaceholder="Rechercher par nom ..."
                labelCreate="Ajouter un label"
                title="Label de station"
                paginationId="labels"
                [searchForm]="searchForm"
                [headers]="headers"
                (createEvent)="createChanged.emit()"
                (paginateEvent)="onTableDataChange($event)">
  <ng-container body *ngFor="let label of page.content | paginate : {
                  id: 'labels',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }">
    <tr (click)="edit(label)">
      <td>{{ label.id }}</td>
      <td>{{ label.name }}</td>
      <td>{{ translate(label.period) }}</td>
      <td>{{ label.link }}</td>
    </tr>
  </ng-container>
</dash-list-edit>
