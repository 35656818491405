import { OriginEnum } from '../enum/origin.enum';
import { ResortMin } from '../resort-min';

export class CredentialsSkiPass {
  contractorId: number;
  clientId: string;
  clientSecret: string;
  url: string;
  partner: number;
  partnerVeryMountain: number;
  origin: OriginEnum;
  resort: ResortMin;

  constructor(obj: Partial<CredentialsSkiPass>) {
    Object.assign(this, obj);
  }
}
