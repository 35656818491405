<dash-eliberty-credentials [resorts]="allResorts"></dash-eliberty-credentials>

<div class="vsk__card">
  <h1>Catalogue ELiberty</h1>
  <div class="flex flex-row gap-x-4 mt-4">
    <atc-select [control]="resortForm"
                [optionElements]="resortEliberty"
                class="flex-1"
                label="Choisir une station">
    </atc-select>

    <atc-select [control]="catalogForm"
                [optionElements]="catalogOptions"
                class="flex-1"
                label="Choisir un catalogue">
    </atc-select>
  </div>
</div>

<div class="flex flex-row">
  <dash-catalog-price-reduction [catalogId]="catalogForm.value" class="flex-1"></dash-catalog-price-reduction>
  <dash-product-price-promotion [catalogId]="catalogForm.value" class="flex-1"></dash-product-price-promotion>
</div>

<div class="flex flex-row">
  <dash-list-product
    (isChanged)="editProduct($event)"
    (pageChanged)="fetchProducts()"
    [page]="pageProduct"
    [searchForm]="searchForm"
    class="flex-1">
  </dash-list-product>

  <dash-list-variant
    (isChanged)="editVariant($event)"
    (pageChanged)="fetchVariants()"
    [page]="pageVariant"
    class="flex-1">
  </dash-list-variant>
</div>

<dash-edit-variant
  (editionComplete)="currentVariant = null"
  *ngIf="currentVariant"
  [variant]="currentVariant">
</dash-edit-variant>
