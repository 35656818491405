import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TriggerAlert } from '../app.action';
import { AppState } from '../app.state';
import { PartnerService } from '../service/partner.service';
import { RxjsComponent } from '../shared/component/rxjs.component';
import { PartnerInformation } from '../shared/models/PartnerInformation';
import { Alert } from '../shared/models/alert';
import { Role } from '../shared/models/enum/Role';
import { UserSession } from '../shared/models/user-session';

export interface UpsertPartnerMinForm {
  address: FormControl<string | null>;
  siret: FormControl<string | null>;
  iban: FormControl<string | null>;
  bic: FormControl<string | null>;
  cartePro: FormControl<string | null>;
}

export interface UpsertUserMinForm {
  firstname: FormControl<string | null>;
  lastname: FormControl<string | null>;
  email: FormControl<string | null>;
  phone: FormControl<string | null>;
}

@Component({
  selector: 'dash-my-informations',
  templateUrl: './my-informations.component.html',
  styleUrls: ['./my-informations.component.scss']
})
export class MyInformationsComponent extends RxjsComponent {
  @Select(AppState.user) user$: Observable<UserSession>;
  partnerId: number;
  userId: number;
  name: string;
  connectedUserIsAdmin = false;
  addressEnterpriseForm = new FormControl<string>('', Validators.required);
  siretForm = new FormControl<string>('', Validators.required);
  ibanForm = new FormControl<string>('', Validators.required);
  bicForm = new FormControl<string>('', Validators.required);
  carteProForm = new FormControl<string>('', Validators.required);
  upsertPartnerForm: FormGroup<UpsertPartnerMinForm>;

  firstNameForm = new FormControl<string>('', Validators.required);
  lastNameForm = new FormControl<string>('', Validators.required);
  emailForm = new FormControl<string>('', Validators.required);
  phoneForm = new FormControl<string>('', Validators.required);
  upsertUserForm: FormGroup<UpsertUserMinForm>;

  constructor(
    private partnerService: PartnerService,
    private store: Store
  ) {
    super();
    this.emailForm.disable();
    this.user$.subscribe(
      (user) =>
        (this.connectedUserIsAdmin = user?.roles.includes(Role.ROLE_ADMIN))
    );
    this.initializeForm();
    this.initializeFormUser();
    this.partnerId = this.store.selectSnapshot(AppState.partnerId) as number;

    this.register(
      this.partnerService
        .getPartneWithUserById(this.partnerId)
        .subscribe((partner) => {
          if (partner) {
            this.userId = partner.userId;
            this.firstNameForm.setValue(partner.firstname);
            this.lastNameForm.setValue(partner.lastname);
            this.emailForm.setValue(partner.email);
            this.phoneForm.setValue(partner.phone);
            this.name = partner.name;
            this.addressEnterpriseForm.setValue(partner.address);
            this.siretForm.setValue(partner.siret);
            this.ibanForm.setValue(partner.iban);
            this.bicForm.setValue(partner.bic);
            this.carteProForm.setValue(partner.cartePro);
          }
        })
    );
  }

  initializeFormUser() {
    this.upsertUserForm = new FormGroup<UpsertUserMinForm>({
      firstname: this.firstNameForm,
      lastname: this.lastNameForm,
      email: this.emailForm,
      phone: this.phoneForm
    });
  }

  initializeForm() {
    this.upsertPartnerForm = new FormGroup<UpsertPartnerMinForm>({
      address: this.addressEnterpriseForm,
      siret: this.siretForm,
      iban: this.ibanForm,
      bic: this.bicForm,
      cartePro: this.carteProForm
    });
  }

  isValidForm() {
    return this.upsertPartnerForm.touched && this.upsertPartnerForm.valid;
  }

  upsert(): void {
    if (this.isValidForm()) {
      const partnerMinEnterprise = new PartnerInformation({
        id: this.partnerId as number,
        address: this.addressEnterpriseForm.value as string,
        siret: this.siretForm.value as string,
        iban: this.ibanForm.value as string,
        bic: this.bicForm.value as string,
        cartePro: this.carteProForm.value as string
      });
      this.register(
        this.partnerService
          .upsertPartnerInformation(partnerMinEnterprise)
          .subscribe(
            () => {
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    message: 'Modifications sont enregistrés',
                    level: 'success',
                    timeout: 2000
                  })
                )
              );
            },
            () => {
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    message:
                      'Une erreur est survenue, veuillez réessayer ultérieurement ...',
                    level: 'error',
                    timeout: 5000
                  })
                )
              );
            }
          )
      );
    }
  }

  askResetPassword(mail: FormControl<string | null>) {
    this.register(
      this.partnerService.forgotPassword(mail.value as string).subscribe(
        () => {
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message: ("Email envoyé a l'adresse suivante : " +
                  mail.value) as string,
                level: 'success',
                timeout: 2000
              })
            )
          );
        },
        () => {
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message: "L'email n'est tpas valide",
                level: 'error',
                timeout: 5000
              })
            )
          );
        }
      )
    );
  }
}
