import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../app.state';
import { PageableComponent } from '../../../shared/component/pageable.component';
import { Role } from '../../../shared/models/enum/Role';
import { Package } from '../../../shared/models/package/package';

@Component({
  selector: 'dash-list-package',
  templateUrl: './list-package.component.html',
  styleUrls: ['./list-package.component.scss']
})
export class ListPackageComponent extends PageableComponent<Package> {
  @Select(AppState.roles) roles$: Observable<string[]>;
  headers = [
    'Grille de tarif',
    'Activité',
    'Expérience',
    "Tranche d'âge",
    'Validité',
    'Dupliquer'
  ];

  constructor() {
    super();
    this.roles$.subscribe((roles) => {
      if (roles.indexOf(Role.ROLE_ADMIN) !== -1) {
        this.headers.unshift('Id');
      }
    });
  }

  duplicatePackage(event: Event, pck: Package) {
    event.stopPropagation();
    this.isChanged.emit({
      ...pck,
      id: null as any
    });
  }
}
