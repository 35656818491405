<div class="vsk__column">

  <div class="vsk__card">
    <div class="vsk__inline vsk__margin-bottom-1">
      <h2>{{ name }}</h2>
      <div class="vsk__flex"></div>
      <atc-button color="ternary" label="+ Ajouter logo"></atc-button>
    </div>
    <div class="vsk__column gap-4">
      <atc-input
        [control]="siretForm"
        [errorMessage]="'Champs obligatoire'"
        id="id__input-my-informations-siret"
        label="SIRET"
        placeholder="Numéro de SIRET"></atc-input>

      <atc-input
        [control]="addressEnterpriseForm"
        [errorMessage]="'Champs obligatoire'"
        id="id__input-my-informations-adress"
        label="Adresse"
        placeholder="Rechercher une adresse"></atc-input>

      <atc-input
        [control]="carteProForm"
        [errorMessage]="'Champs obligatoire'"
        id="id__input-my-informations-card-professional"
        label="Carte professionnelle"
        placeholder="Numéro de carte professionnelle"></atc-input>

      <div class="vsk__inline vsk__column-gap">
        <atc-input
          [control]="ibanForm"
          [errorMessage]="'Champs obligatoire'"
          id="id__input-my-informations-iban"
          label="IBAN"
          placeholder="FR00 1234 5678 9123 4567 8912 345"></atc-input>

        <atc-input
          [control]="bicForm"
          [errorMessage]="'Champs obligatoire'"
          id="id__input-my-informations-bic"
          label="BIC"
          placeholder="ABCDEFGH"></atc-input>
      </div>

      <div class="vsk__inline vsk__column-gap">
        <div class="vsk__flex"></div>
        <atc-button (click)="upsert()"
                    [disabled]="!isValidForm()"
                    id="id__button-validate-enterprise"
                    label="Enregistrer">
        </atc-button>
      </div>
    </div>
  </div>

  <div class="vsk__card">
    <h2 class="vsk__margin-bottom-1">Contact</h2>
    <div class="vsk__column gap-4">
      <div class="vsk__inline vsk__column-gap">
        <atc-input
          [control]="firstNameForm"
          [errorMessage]="'Champs obligatoire'"
          id="id__input-my-informations-last-name"
          label="Prénom"
          placeholder="Prénom"></atc-input>

        <atc-input
          [control]="lastNameForm"
          [errorMessage]="'Champs obligatoire'"
          id="id__input-my-informations-first-name"
          label="Nom"
          placeholder="Nom"></atc-input>
      </div>

      <atc-input
        [control]="emailForm"
        [errorMessage]="'Champs obligatoire'"
        id="id__input-my-informations-mail-adress"
        label="Adresse mail"
        placeholder="Adresse mail"></atc-input>

      <atc-input
        [control]="phoneForm"
        id="id__input-my-informations-telephone"
        label="Numéro de téléphone"
        placeholder="06 06 06 06 06"></atc-input>


      <div *ngIf="connectedUserIsAdmin" class="vsk__inline vsk__column-gap">
        <div class="vsk__flex"></div>
        <atc-button (click)="askResetPassword(emailForm)"
                    id="id__button-validate-contact"

                    label="Envoyer un mail de mise a jour de mot de passe">
        </atc-button>
      </div>

    </div>
  </div>
</div>
