<div class="vsk__card">
  <h1 class="mb-4">Choisir une agence</h1>
  <atc-select label="Agence"
              [searchable]="true"
              [control]="partnerControl"
              [optionElements]="partners">
  </atc-select>
</div>

<div class="flex flex-row">
  <dash-establishment-management class="flex-1"
                                 [partnerCode]="partnerControl.value"
                                 [resorts]="resorts"
                                 (establishmentChanged)="establishment = $event">
  </dash-establishment-management>

  <dash-room-management *ngIf="establishment"
                        class="flex-1"
                        [partner]="partnerControl.value"
                        [roomFeatures]="roomFeatures"
                        [establishment]="establishment">
  </dash-room-management>
</div>



