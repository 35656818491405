import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AtomicLibModule } from 'atomic-lib';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxWigModule } from 'ngx-wig';
import { ChipComponent } from './chip/chip.component';
import { ColorSelectorComponent } from './color-selector/color-selector.component';
import { RxjsComponent } from './component/rxjs.component';
import { PastePreventionDirective } from './directive/paste-prevention.directive';
import { PopupDirective } from './directive/popup.directive';
import { EditFormComponent } from './edit-form/edit-form.component';
import { ListEditComponent } from './list-edit/list-edit.component';
import { DurationPipe } from './pipe/duration.pipe';
import { IsAdminPipe } from './pipe/is-admin.pipe';
import { SortByIdPipe } from './pipe/sort-by-id.pipe';
import { SubMenuComponent } from './sub-menu/sub-menu.component';
import {
  TabComponent,
  TabGroupComponent
} from './tab-group/tab-group.component';

@NgModule({
  declarations: [
    RxjsComponent,
    PopupDirective,
    DurationPipe,
    SubMenuComponent,
    ChipComponent,
    EditFormComponent,
    ListEditComponent,
    SortByIdPipe,
    ColorSelectorComponent,
    PastePreventionDirective,
    TabGroupComponent,
    TabComponent,
    IsAdminPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    NgxWigModule,
    NgxMaskDirective,
    NgxMaskPipe,
    AtomicLibModule,
    NgxPaginationModule
  ],
  exports: [
    RxjsComponent,
    PopupDirective,
    DurationPipe,
    SubMenuComponent,
    ChipComponent,
    EditFormComponent,
    ListEditComponent,
    SortByIdPipe,
    ColorSelectorComponent,
    PastePreventionDirective,
    TabGroupComponent,
    TabComponent,
    IsAdminPipe
  ],
  providers: [provideNgxMask()]
})
export class SharedModule {}
