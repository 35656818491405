import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { TriggerAlert } from '../../../app.action';
import { ResortLabelService } from '../../../service/resort-label.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { Alert } from '../../../shared/models/alert';
import { SeasonEnum } from '../../../shared/models/enum/season.enum';
import { ResortLabel } from '../../../shared/models/resort-label';

export interface ResortLabelForm {
  name: FormControl<string | null>;
  period: FormControl<SeasonEnum | null>;
  link: FormControl<string | null>;
}

@Component({
  selector: 'dash-edit-resort-label',
  templateUrl: './edit-resort-label.component.html',
  styleUrls: ['./edit-resort-label.component.scss']
})
export class EditResortLabelComponent extends RxjsComponent {
  @Output() editionComplete = new EventEmitter<void>();
  editMode: 'create' | 'edit' = 'create';
  loading = false;
  id: number;
  seasons: OptionElement<SeasonEnum>[] = [
    {
      id: SeasonEnum.YEAR,
      label: "Toute l'année"
    },
    {
      id: SeasonEnum.SUMMER,
      label: 'Été'
    },
    {
      id: SeasonEnum.WINTER,
      label: 'Hiver'
    }
  ];
  nameForm = new FormControl<string>('', Validators.required);
  linkForm = new FormControl<string>('', Validators.required);
  periodForm = new FormControl<SeasonEnum>(
    SeasonEnum.YEAR,
    Validators.required
  );
  resortLabelForm: FormGroup<ResortLabelForm>;

  constructor(
    private resortLabelService: ResortLabelService,
    private store: Store
  ) {
    super();
    this.resortLabelForm = new FormGroup<ResortLabelForm>({
      name: this.nameForm,
      period: this.periodForm,
      link: this.linkForm
    });
  }

  @Input() set resortLabelToEdit(value: ResortLabel | undefined) {
    if (value) {
      this.id = value.id;
      this.nameForm.setValue(value.name);
      this.linkForm.setValue(value.link);
      this.periodForm.setValue(value.period);
      this.editMode = 'edit';
    }
  }

  upsert() {
    this.register(
      this.resortLabelService
        .upsert(
          new ResortLabel({
            ...(this.resortLabelForm.getRawValue() as ResortLabel),
            id: this.id
          })
        )
        .subscribe(
          () => {
            this.loading = false;
            this.editionComplete.emit();

            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'success',
                  timeout: 2000,
                  message:
                    this.editMode === 'create'
                      ? 'Label créé avec succès'
                      : 'Label modifié'
                })
              )
            );
          },
          (err: HttpErrorResponse) => {
            this.loading = false;

            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'error',
                  timeout: 5000,
                  message: err.error
                })
              )
            );
          }
        )
    );
  }

  delete() {
    this.register(
      this.resortLabelService.deleteById(this.id).subscribe(
        () => {
          this.loading = false;
          this.editionComplete.emit();

          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                level: 'success',
                timeout: 2000,
                message: 'Supprimé avec succès'
              })
            )
          );
        },
        (err: HttpErrorResponse) => {
          this.loading = false;

          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                level: 'error',
                timeout: 5000,
                message: err.error
              })
            )
          );
        }
      )
    );
  }

  disabled() {
    return this.resortLabelForm.invalid;
  }
}
