import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomField } from '../../../shared/models/custom-field';

@Component({
  selector: 'dash-list-custom-field',
  templateUrl: './list-custom-field.component.html',
  styleUrls: ['./list-custom-field.component.scss']
})
export class ListCustomFieldComponent {
  headers = ['Id', 'Libellé', 'Type', 'Editer'];
  @Input() customFields: CustomField[];
  @Output() customFieldToEdit = new EventEmitter<CustomField>();
  @Output() createEventChanged: EventEmitter<void> = new EventEmitter<void>();

  editActivity(customField: CustomField): void {
    this.customFieldToEdit.emit(customField);
  }
}
