import { Days } from 'atomic-lib';
import moment, { Moment } from 'moment';
import { FrequencyEnum } from '../enum/frequency.enum';

export class TimeSlotModel {
  id: number;
  timeSlotId: number;
  experienceId: number;
  packageGridId: number;
  frequencyDayNumber: number;
  frequency: FrequencyEnum;
  originDate: Moment;
  dateStart: Moment;
  dateEnd: Moment;
  hourStart: string;
  hourEnd: string;
  slotsAvailable: number;
  enable: boolean;
  comment: string;
  dateEndFrequency: Moment;
  daysToRepeat: Days[] = [];

  constructor(obj: Partial<TimeSlotModel>) {
    Object.assign(this, obj);
    this.originDate = moment(this.originDate);
    this.dateStart = moment(this.dateStart);
    this.dateEnd = moment(this.dateEnd);
    if (this.dateEndFrequency) {
      this.dateEndFrequency = moment(this.dateEndFrequency);
    }
    if (this.hourStart.length === 8) {
      this.hourStart = this.toHour(this.hourStart);
    }
    if (this.hourEnd.length === 8) {
      this.hourEnd = this.toHour(this.hourEnd);
    }
  }

  toHour(time: string): string {
    const hourMinuteSecond = time.split(':');
    return hourMinuteSecond[0] + ':' + hourMinuteSecond[1];
  }
}
