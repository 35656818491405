<dash-list-edit
  [headers]="headers"
  title="Réservations validées">
  <ng-container *ngFor="let reservation of page.content | paginate : {
                  id: 'validated',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }" body>
    <tr>
      <td>{{ reservation?.experienceName }}</td>
      <td>{{ formatDate(reservation.dateStart) }}</td>
      <td>{{ formatDate(reservation.dateRegistration) }}</td>
      <td>{{ reservation?.participants?.length }}</td>
      <td>{{ statusLabel(reservation.status) }}</td>
      <td>{{ reservation?.user?.email }}</td>
      <td>{{ reservation?.user?.phone }}</td>
    </tr>
  </ng-container>

  <div class="flex flex-row justify-center mt-2" paginate>
    <pagination-controls
      (pageChange)="onTableDataChange($event)"
      [autoHide]="true"
      [id]="'validated'"
      [maxSize]="9"
      [responsive]="true"
      nextLabel="Suivant"
      previousLabel="Précédent"
    >
    </pagination-controls>
  </div>
</dash-list-edit>
