<div class="vsk__card w-full gap-y-4">
  <h1>Import/Export de fichier CSV</h1>
  <hr>

  <atc-select
    [control]="importForm"
    [optionElements]="importOptions"
    label="Type d'import">
  </atc-select>

  <ng-container *ngIf="importForm.value">
    <h2>Import</h2>
    <atc-input-file (filesChanged)="fileChanged($event)"
                    [multiple]="false"
                    accept="text/csv"
                    label="Fichier CSV"></atc-input-file>

    <atc-button
      (click)="importFile()"
      [disabled]="isDisabled()"
      label="Importer"></atc-button>

    <ng-container *ngIf="errors.length">
      <hr>

      <table>
        <thead>
        <tr>
          <th>Ligne</th>
          <th>Message</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let error of errors">
          <td>{{ error.line }}</td>
          <td>{{ error.message }}</td>
        </tr>
        </tbody>
      </table>
    </ng-container>

    <h2>Export</h2>
    <div class="flex flex-row gap-x-4 max-w-fit items-center">
      <atc-input-checkbox [control]="onlyCurrentPartnerForm">
        <p>Filtrer l'export avec le partenaire actuel - {{ getPartnerName() }}</p>
      </atc-input-checkbox>

      <atc-button (click)="exportFile()"
                  [disabled]="this.importForm.invalid || this.loading"
                  label="Exporter"></atc-button>
    </div>
  </ng-container>
</div>


