<dash-list-edit
  [headers]="headers"
  title="Réservations refusées">
  <ng-container *ngFor="let reservation of page.content | paginate : {
                  id: 'pending',
                  itemsPerPage: page.pageable.pageSize,
                  currentPage: page.pageable.pageNumber + 1,
                  totalItems: page.totalElements }" body>
    <tr>
      <td>{{ reservation?.experienceName }}</td>
      <td>{{ formatDate(reservation.dateStart) }}</td>
      <td>{{ formatDate(reservation.dateRegistration) }}</td>
      <td>{{ reservation?.participants?.length }}</td>
      <td>
        <img (click)="setReservationToShow(reservation)" alt="Edit" src="assets/icones/edit.svg">
      </td>

    </tr>
  </ng-container>

  <div class="flex flex-row justify-center mt-2" paginate>
    <pagination-controls
      (pageChange)="onTableDataChange($event)"
      [autoHide]="true"
      [id]="'pending'"
      [maxSize]="9"
      [responsive]="true"
      nextLabel="Suivant"
      previousLabel="Précédent"
    >
    </pagination-controls>
  </div>
</dash-list-edit>

<dash-my-pending-reservation-detail (visualisationComplete)="closeDrawer()"
                                    *ngIf="showEdit"
                                    [reservation]="reservationToShow">

</dash-my-pending-reservation-detail>
